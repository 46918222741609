import React from 'react';
import styled from 'styled-components';
import { BrandContext } from '../App';
import BrandSelector from '../components/BrandSelector';
import Header from '../components/Header';
import BrandlessPage from '../components/layout/BrandlessPage';

const BrandSelect = ({ logout }) => {

	const { changeBrand } = React.useContext(BrandContext);
	changeBrand("agco");

    return ( 
        <BrandlessPage backgroundImage={'/images/background-sunset-wheat.jpg'} logout={ logout }>
            <Header hasNavLinks={false} logout={logout} selectorVisible={false}/>
            <BrandSelector />
        </BrandlessPage>
     );
}
 
export default BrandSelect;