const structure = [
	{
		id: "0",
		name: "General",
		shortdesc: "General information, checklists and other documents",
		items: [
			{
				id: "01",
				name: "Warranty",
				items: [],
			},
			{
				id: "02",
				name: "Bulletins",
				items: [],
			},
			{
				id: "03",
				name: "Maintenance Checklists",
				items: [
					{
						id: "031",
						name: "Pre-Season Checks",
						items: [
							{
								name: "Pre-Season Checklist",
								href: "/mcs/cl1",
								type: "checklist",
								app: 2,
							},
						]
					},
					{
						id: "032",
						name: "End of Season Checks",
						items: [
							{
								name: "End of Season Shut-Down",
								href: "/mcs/cl2",
								type: "checklist",
								app: 2,
							},
						]
					},
				],
			},
		],
	},
	{
		id: "1",
		name: "Rooftop Plenum",
		shortdesc: "Roof panels, roof sections and roof fittings",
		items: [],
	},
	{
		id: "2",
		name: "Heat Section",
		shortdesc: "Heat transfer unit, heat shields and filters",
		items: [],
	},
	{
		id: "3",
		name: "Burner Blower Section",
		shortdesc: "Blower, fans and housing assemblies",
		items: [],
	},
	{
		id: "4",
		name: "Unload Section",
		shortdesc: "Module, moisture sampler, sweep, platforms and doors",
		items: [
			{
				id: "41",
				name: "Unload Module",
				items: [
					{
						id: "411",
						name: "Supports",
						items: [],
					},
					{
						id: "412",
						name: "Gearbox",
						items: [
							{
								name: "Flat Unload Section",
								href: "/epc",
								type: "epc",
								app: 1,
							},
							{
								name: "Gearbox Oil Filter",
								href: "/si/D120186",
								type: "si",
								app: 0,
							},
							{
								name: "Gearbox Oil Level Check",
								href: "/si/D019938",
								type: "si",
								app: 0,
							},
							{
								name: "Gearbox Oil",
								href: "/si/D010162",
								type: "si",
								app: 0,
							},
							{
								name: "Trumpet Housing",
								href: "/si/D191220",
								type: "video",
								app: 0,
							},
							{
								name: "Gearbox Module",
								href: "/si/T011040",
								type: "schematics",
								app: 0,
							},
							{
								name: "Fluid Specifications",
								href: "/si/D240987",
								type: "si",
								app: 0,
							}
						],
					},
					{
						id: "413",
						name: "Hopper",
						items: [],
					},
					{
						id: "414",
						name: "Sensors",
						items: [],
					},
				],
			},
			{
				id: "42",
				name: "Door Assembly",
				items: [],
			},
			{
				id: "43",
				name: "Outside Platform",
				items: [],
			},
			{
				id: "44",
				name: "Moisture Sensor Housing Assembly",
				items: [],
			},
			{
				id: "45",
				name: "Moisture Sampler Blower",
				items: [],
			},
			{
				id: "46",
				name: "Sweep",
				items: [],
			},
		],
	},
	{
		id: "5",
		name: "Catwalk Assembly",
		shortdesc: "Catwalk braces, floors and guard rails",
		items: [],
	},
	{
		id: "6",
		name: "Ladder Assembly",
		shortdesc: "Ladders, safety rails and harnesses",
		items: [],
	},
	{
		id: "7",
		name: "Control Box",
		shortdesc: "Electrical equipment, control boxes and switches",
		items: [],
	},
	{
		id: "8",
		name: "Sensors",
		shortdesc: "Temperature sensors, level sensors, etc.",
		items: [],
	},
];

export default structure;