import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import PageWideContainer from '../components/layout/PageWideContainer';
import PageHeading from '../components/PageHeading';
import PartsList from '../components/PartsList';
import PartsGraphic from '../components/topic/PartsGraphic';
import partsLists from '../data/partslists';
import { SelectorContext } from '../App';
import topics from '../data/topics';
import useGetSvg from '../hooks/useGetSvg';

const PartsGraphicContext = createContext();

const FloatingContainer = styled.div`
	width: 49%;
	float: left;
	padding: 10px;
	overflow-x: hidden;

	g[id^=Annotation] {
		cursor: pointer;
		&.active path {
			fill: ${({ theme }) => theme.colors.PrimaryTint};
		}
	}
`;


const PartsCatalogue = ({ addToBasket }) => {

	const { get } = useGetSvg();
	const menuContext = useContext(SelectorContext);
	React.useEffect(() => {
		menuContext.setBreadcrumb(
			topics.filter(topic => topic.id === "D150218")[0].breadcrumb
		)
	}, []);
	const { id } = useParams();

	let listId = id || "PL1";

	const partsList = partsLists.filter((list) => list.id === listId)[0] || partsLists.filter((list) => list.id === "PL1")[0];

	return ( 
		<>
			<PartsGraphicContext.Provider value={"temp"}>
				<PageWideContainer>
					<PageHeading title={ partsList.title.toUpperCase() } subtitle={ partsList.subtitle.toUpperCase() } />
					<FloatingContainer id="PartsListGraphic" className="partsListGraphic">
						<TransformWrapper >
							<TransformComponent>
								{/* <PartsGraphic src={`/images/topic/${partsList.graphic}.svg`} /> */}
								{ get(partsList.graphic) }
							</TransformComponent>
						</TransformWrapper>
					</FloatingContainer>
					<FloatingContainer>
						<PartsList id={ listId } list={ partsList.parts } addToBasket={addToBasket} />
					</FloatingContainer>
				</PageWideContainer>
			</PartsGraphicContext.Provider>
		</>
	 );
}
 
export default PartsCatalogue;