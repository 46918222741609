import React from 'react';
import styled, { css } from 'styled-components';
 
export const LinkStyles = css`
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.Primary};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export default styled.a`
    ${LinkStyles}
`;