import React from 'react';

import structure from '../data/structure';

export default function useSelectorMenu() {
	
	const [menus, setMenus] = React.useState([structure, [], [], []]);
	const [selector, setSelector] = React.useState([null, null, null, null]);
	const [selectorVisible, setSelectorVisible] = React.useState(false);
	const [breadcrumb, setBreadcrumb] = React.useState([null, null, null, null]);

	function toggleSelector() {
		setSelectorVisible(!selectorVisible);
	}

	function updateSelector(id1, id2, id3, id4) {
		if (!window.location.href.includes("sel")) {
			return;
		} else {
			setSelectorVisible(true);
		}
		let menuData = [...menus];
		let sel = [...selector];
		let bc = [...breadcrumb];
		if (id1) {

			sel[0] = id1;
			menuData[1] = structure[structure.indexOf(structure.filter(item => item.id === id1)[0])].items;
			bc[0] = {
				name: structure.filter(item => item.id === id1)[0].name,
				href: `/sel/${id1}`,
				id: structure.filter(item => item.id === id1)[0].id,
			}
		}else {
			menuData[1] = [];
			for (var i = 0; i < bc.length; i++) {
				sel[i] = null;
				bc[i] = null;
			}
		}
		if (id2) {
			sel[1] = id2;
			menuData[2] = menuData[1][menuData[1].indexOf(menuData[1].filter(item => item.id === `${id1}${id2}`)[0])].items;
			bc[1] = {
				name: menuData[1].filter(item => item.id === `${id1}${id2}`)[0].name,
				href: `/sel/${id1}/${id2}`,
				id: menuData[1].filter(item => item.id === `${id1}${id2}`)[0].id,
			}
		} else {
			menuData[2] = [];
			menuData[3] = [];
			for (var i = 1; i < bc.length; i++) {
				sel[i] = null;
				bc[i] = null;
			}
		}
		if (id3) {
			console.log()
			menuData[3] = menuData[2][menuData[2].indexOf(menuData[2].filter(item => (item.id === `${id1}${id2}${id3}`))[0])].items.filter(item => JSON.parse(localStorage.getItem("user")).apps.includes(item.app));
			sel[2] = id3;
			bc[2] = {
				name: menuData[2].filter(item => item.id === `${id1}${id2}${id3}`)[0].name,
				href: `/sel/${id1}/${id2}/${id3}`,
				id: menuData[2].filter(item => item.id === `${id1}${id2}${id3}`)[0].id,
			}
			bc[3] = null;
		} else {
			menuData[3] = [];
			for (var i = 2; i < bc.length; i++) {
				sel[i] = null;
				bc[i] = null;
			}
		}
		if (id4) {
			sel[3] = id4;
		}
		setSelector(sel);
		setMenus(menuData);
		setBreadcrumb(bc);
	}

	return {
		menus,
		selector,
		updateSelector,
		selectorVisible,
		toggleSelector,
		breadcrumb,
		setBreadcrumb,
	}

}


 
