import React from 'react';

import P from '../components/typography/P';
import Procedure from '../components/topic/Procedure';
import Step from '../components/topic/Step';
import Figure from '../components/topic/Figure';
import Admonition from '../components/topic/Admonition';
import PageHeading from '../components/PageHeading';
import YouTube from 'react-youtube';
import Spacer from '../components/layout/Spacer';
import Schematic from '../components/Schematic';
import IncludedTopic from '../components/topic/IncludedTopic';
import ARoute from '../components/typography/ARoute';
import Table from '../components/topic/Table';

const useGetTopic = () => {	

	function pauseVideo(event) {
		event.target.pauseVideo();
	}

	function getTopic(topicId, embed = false) {

		switch (topicId) {
			case "D019938":
				return (
					<>
						{embed === false && (
							<PageHeading title="GEARBOX OIL LEVEL CHECK" subtitle="CHECK AND ADJUST" />
						)}
						<Procedure title="CHECK">
							<Step>
								<P>The oil quantity is correct when the oil is in the middle third of the sight glass.</P>
								<Figure src="/images/topic/SI000002-thumb.png" />
							</Step>
						</Procedure>
						<Procedure title="ADJUST">
							<Step>
								<P>Remove the oil drain plug and discard the O-ring.</P>
								<Figure src="/images/topic/SI000003-thumb.png" />
								<Admonition type="caution">
									<P>Ensure a suitable container is placed beneath the drain plug, before it is removed, to collect any oil.</P>
								</Admonition>
							</Step>
							<Step>
								<P>Fill the gearbox until the oil level is in the center of the sight glass.</P>
								<Figure src="/images/topic/SI000004-thumb.png" />
							</Step>
							<Step>
								<P>Install the new O-ring on the oil drain plug.</P>
								<Figure src="/images/topic/SI000003-thumb.png" />
							</Step>
							<Step>
								<P>Lubricate the O-ring with new oil.</P>
							</Step>
							<Step>
								<P>Install the oil drain plug.</P>
							</Step>
						</Procedure>
					</>
				);
				case "D191220":
				return (
					<>
						<PageHeading title="TRUMPET HOUSING" subtitle="INSTRUCTIONAL VIDEO" />
						<Spacer height="20px" block={true} />
						<YouTube videoId="KsJILFVRRXQ" onReady={pauseVideo} opts={{ width: '1000px', height: '562px' }} />
						<Admonition type="warning">Care must be taken when removing heavy components.</Admonition>
						<Admonition type="warning">Wear appropriate PPE to protect against harmful contaminants.</Admonition>
					</>
				);
				case "D120186":
					return(
						<>
							{embed === false && (
								<PageHeading title="GEARBOX OIL FILTER" subtitle="REMOVE AND INSTALL" />
							)}
							<Procedure title="REMOVE">
								<Step>
									<P>Remove the screws (x2) securing the access panel to the body.</P>
								</Step>
								<Step>
									<IncludedTopic topicId="D010162" />
								</Step>
								<Step>
									<P>Remove the oil filter contamination switch (<b>1</b>).</P>
									<Figure src="/images/topic/SI000001-thumb.png" />
								</Step>
								<Step>
									<P>Unscrew the oil filter from the gearbox housing.</P>
								</Step>
								<Step>
									<P>Remove and discard the oil filter gasket.</P>
									<Admonition type="danger">
										<P>Scalding can occur if hot gearbox oil comes into contact with the skin. When draining gearbox oil or changing the oil filter, always wear suitable protective gloves. Make sure that no gearbox oil gets inside the gloves.</P>
									</Admonition>
								</Step>
							</Procedure>
							<Procedure title="INSTALL">
								<Step>
									<P>Install the oil filter gasket.</P>
								</Step>
								<Step>
									<P>Install the oil filter to the gearbox housing.</P>
								</Step>
								<Step>
									<P>Install the oil filter contamination switch.</P>
									<Figure src="/images/topic/SI000001-thumb.png" />
								</Step>
								<Step>
								<IncludedTopic topicId="D010162" />
								</Step>
								<Step>
									<P>Install the access panel. Install the screws securing the access panel to the body.</P>
								</Step>
							</Procedure>
						</>
					)
				case 'D010162':
					return(
						<>
							{embed === false && (
								<PageHeading title="GEARBOX OIL" subtitle="DRAIN AND REFILL" />
							)}
							<Procedure title="DRAIN">
								<Step>
									<P>Remove the oil drain plug and discard the O-ring.</P>
									<Figure src="/images/topic/SI000003-thumb.png" />
									<Admonition type="caution">
										<P>Ensure a suitable container is placed beneath the drain plug, before it is removed, to collect any oil.</P>
									</Admonition>
								</Step>
								<Step>
									<P>Attach a vacuum drain system and remove the oil from the gearbox.</P>
								</Step>
								<Step>
									<P>Remove the vacuum drain system.</P>
								</Step>
							</Procedure>
							<Procedure title="REFILL">
								<Step>
									<P>Fill the gearbox until the oil level is in the center of the sight glass.</P>
									<Figure src="/images/topic/SI000004-thumb.png" />
								</Step>
								<Step>
									<P>Install the new O-ring on the oil drain plug.</P>
									<Figure src="/images/topic/SI000003-thumb.png" />
								</Step>
								<Step>
									<P>Lubricate the O-ring with new oil.</P>
								</Step>
								<Step>
									<P>Install the oil drain plug.</P>
								</Step>
							</Procedure>
						</>
					);
				case "D020662":
					return (
						<>
							<PageHeading title="GEARBOX MAINTENANCE"  />
							<Admonition type="danger">
								<P>Failure to <ARoute to="/trn/course/T019938">shut down and lock out</ARoute> a machine, prior to routine maintenance, may result in death or serious injury.</P>
							</Admonition>
							<P>The gearbox must be maintained regularly to ensure operating efficiency.</P>
						</>
					);
				case "D240987":
					return (
						<>
							<PageHeading title="FLUID SPECIFICATIONS" subtitle="TECHNICAL SPECIFICATIONS" />
							<Table>
								<thead>
									<tr>
										<th>Component</th>
										<th>Specification</th>
										<th>Volume</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Metering drum drive-shaft bearing</td>
										<td>Grade 2 Lithium-based grease</td>
										<td>500ml</td>
									</tr>
									<tr>
										<td>Blower shaft bearings</td>
										<td>Grade 2 Lithium-based grease</td>
										<td>100ml</td>
									</tr>
									<tr>
										<td>Blower motor bearings</td>
										<td>Grade 2 Lithium-based grease</td>
										<td>400ml</td>
									</tr>
									<tr>
										<td>Metering drum gearbox</td>
										<td>ISO VG-220 mineral-based oil. AGMA Viscosity Grade 5EP. (MobilGear 630 or equivalent).</td>
										<td>12 litres</td>
									</tr>
								</tbody>
							</Table>
						</>
					)
				case 'T019938':
					return (
						<>
							<PageHeading title="LOCK OUT AND TAG OUT" subtitle="eLEARNING" />
							<P>Watch the video carefully and prepare to answer questions about Lock Out and Tag Out. </P>
							<Spacer height="20px" block={true} />
							<YouTube videoId="QM1PUNJFq6c" onReady={pauseVideo} opts={{ width: '1000px', height: '562px' }} />
							<P>When the video has finished, press <b>START</b> in the sidebar to start the quiz.</P>                   
						</>
					);
				case 'T011040' :
					return (
						<>
							<PageHeading title="GEARBOX MODULE" subtitle="SCHEMATIC VIEWER" />
							<Schematic src="/images/topic/T011043_en-GB.svg" />
						</>
					)
			default:
				return(
					<PageHeading title="TOPIC DOES NOT EXIST" subtitle="SORRY!"/>
				);
		}
	}

	return { getTopic }

}
 
export default useGetTopic;