const quizzes = [
	{
		id: "T019938",
		title: "LOCK OUT AND TAG OUT",
		subtitle: "QUIZ",
		questions: [
			{ 
				id: 1,
				text: "What should you do, prior to routine maintenance or services?",
				answers: [
					{
						id: 1,
						text: "Notify all affected employees/personnel that the machine must be shut down and locked out",
						correct: true,
						checked: false,
					},
					{
						id: 2,
						text: "Connect the machine to a suitable power source",
						correct: false,
						checked: false,
					},
					{
						id: 3,
						text: "Ensure GSI Customer Services are aware of the upcoming maintenance",
						correct: false,
						checked: false,
					},
					{
						id: 4,
						text: "Remove lock out tags",
						correct: false,
						checked: false,
					}
				]
			},
			{
				id: 2,
				text: "Should you always refer to the company procedure to check the type and amount of energy the machine or equipment uses?",
				answers: [
					{
						id: 1,
						text: "Yes",
						correct: true,
						checked: false,
					},
					{
						id: 2,
						text: "No",
						correct: false,
						checked: false,
					},
				]
			},
			{
				id: 3,
				text: "You should always confirm any residual or stored energy is disippated. True or False?",
				answers: [
					{
						id: 1,
						text: "False",
						correct: false,
						checked: false,
					},
					{
						id: 2,
						text: "True",
						correct: true,
						checked: false,
					},
				]
			},
			{ 
				id: 4,
				text: "How can you verify the equipment is isolated?",
				answers: [
					{
						id: 1,
						text: "Activate the push button or other operating controls",
						correct: true,
						checked: false,
					},
					{
						id: 2,
						text: "Ask a colleague to check for you",
						correct: false,
						checked: false,
					},
					{
						id: 3,
						text: "Call technical support",
						correct: false,
						checked: false,
					},
					{
						id: 4,
						text: "Insert lock out tags",
						correct: false,
						checked: false,
					}
				]
			},
		]
	}
];

export default quizzes;