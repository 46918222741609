import React from 'react';
import styled from 'styled-components';
import FullPageBackground from '../FullPageBackground';

const BrandlessPage = ({ children, backgroundImage }) => {
	return ( 
		<FullPageBackground src={backgroundImage || "/images/background-brightFields.jpg"}>
			{ children }
		</FullPageBackground>
	 );
}
 
export default BrandlessPage;