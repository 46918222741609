const brands = [
    {
        id: 1,
        name: "Cimbria",
        logo: "/images/logo-cimbria.png",
        enabled: false,
    },
    {
        id: 2,
        name: "AP",
        logo: "/images/logo-ap.png",
        enabled: false,
    },
    {
        id: 3,
        name: "Cumberland",
        logo: "/images/logo-cumberland.png",
        enabled: false,
    },
    {
        id: 4,
        name: "GSI",
        logo: "/images/logo-gsi.png",
        enabled: true,
    },
    {
        id: 5,
        name: "Tecno",
        logo: "/images/logo-tecno.png",
        enabled: false,
    },
    {
        id: 6,
        name: "Chicken Boy",
        logo: "/images/logo-chicken-boy.png",
        enabled: false,
    }
]

export default brands;