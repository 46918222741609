import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from './Button';

const Mask = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0);
`;

const Menu = styled.div`
	position: fixed;
	display: block;
	top: 60px;
	z-index: 10;
`;

const UserMenuPopup = ({ onClose, visible }) => {
	
	const navigate = useNavigate();

	if (!visible)
		return null;

	function GoToBrands() {
		onClose();
		navigate('/brand');
	}

	return ( 
		<>
			<Mask onClick={onClose}/>
			<Menu>
				<Button type="menubutton" onClick={GoToBrands} block={true}>Select Brand</Button>
				<Button type="menubutton" onClick={ () => {navigate('/logout')} } block={true}>Log Out</Button>
			</Menu>
		</>
	 );
}
 
export default UserMenuPopup;