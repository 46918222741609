import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import Checkbox from './Checkbox';
import H2 from './typography/H2';

const Container = styled.div`
	margin-top: 100px;
`;

const Question = styled.div`
	font-family: ${({ theme }) => theme.fonts.regular};
	font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const AnswerContainer = styled.div`
	padding: 50px;
`;

const Answer = styled.div`
	display: flex;
	margin-top: 25px;
`;

const AnswerText = styled.div`
	margin-left: 36px;
	margin-top: 3px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: right;
	padding-right: 100px;
`;

const Quiz = ({ question, advance, onAnswerClick }) => {
	return ( 
		<Container>
			<H2>QUESTION {question.id}</H2>
			<Question>{question.text}</Question>
			<AnswerContainer>
				{ question.answers.map((answer) => (
					<Answer onClick={() => {onAnswerClick(answer.id)}}><Checkbox transitions={false} checked={ answer.checked } /><AnswerText>{answer.text}</AnswerText></Answer>
				)) }
			</AnswerContainer>
			<ButtonContainer>
				<Button width="200px" type="primary" onClick={advance}>&nbsp; &nbsp; CONTINUE &nbsp; &nbsp;</Button>
			</ButtonContainer>
		</Container>
	 );
}
 
export default Quiz;