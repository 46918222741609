import React from 'react';
import styled from 'styled-components';

const Background = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	background-image: url('${({ src }) => src}');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
`;

const FullPageBackground = ({ children, src }) => {
	return ( 
		<Background src={ src }>
			{ children }
		</Background>
	 );
}
 
export default FullPageBackground;