import React from 'react';

import themeGsi from '../config/theme-gsi';
import themeAgco from '../config/theme-agco';

export default function useBrand() {

	const [brand, setBrand] = React.useState(localStorage.getItem("brand") || "agco");
    const [brandFile, setBrandFile] = React.useState(getBrandFile(brand));

    function changeBrand(b) {
        localStorage.setItem("brand", b);
        setBrandFile(getBrandFile(b));
        setBrand(b);
        console.log("setBrandFile", b, "setBrand", b);
    }

    function getBrandFile(b) {
        if (localStorage.getItem("brand")) {
            switch (localStorage.getItem("brand")) {
                case "gsi":
                    return themeGsi;
                default: 
                    return themeAgco;
            }
        } else {
            switch (b) {
                case "gsi":
                    return themeGsi;
                default:
                    return themeAgco;
            }
        }
    }

	return {brand, changeBrand, getBrandFile, brandFile}

}