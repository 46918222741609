import React, { useContext } from 'react';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../Header';
import PageContainer from './PageContainer';

import { AuthContext, BrandContext } from '../../App';

const ContentContainer = styled.div`
	width: ${({ theme }) => theme.sizes.contentContainer};
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
`;

const Container = styled.div`
	padding-left: calc(100vw - 100%);
`;

const Page = ({ basket }) => {
	
	const { changeBrand } = useContext(BrandContext);
	const { authenticatedUser, setAuthenticatedUser, logout } = useContext(AuthContext);
	const navigate = useNavigate();
	
	React.useEffect(() => {
		changeBrand("gsi");
		if (!authenticatedUser) {
			if (localStorage.getItem("user")) {
				setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
			} else {
				navigate('/login');
			}
		}
	}, []);

	return ( 
		<><Header logout={logout} authenticatedUser={authenticatedUser} basket={ basket }></Header>
			<Container>
				<PageContainer>
					<Outlet />
				</PageContainer>
			</Container>
		</>
		);
}
 
export default Page;