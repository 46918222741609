import React from 'react';
import styled from 'styled-components';

const Element = styled.div`
    display: ${({ block }) => block ? "block" : "inline-block"};
    width: ${({ width }) => width ? width : "25px;"};
    height: ${({ height }) => height ? height : "25px;"};
    background-color: transparent;
`;

const Spacer = ({ width, height, block = false }) => {
    return ( 
        <Element width={ width } height={ height } block={ block } />
     );
}
 
export default Spacer;