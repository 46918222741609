import React from 'react';

import { ComingSoonContext } from '../App';

import TopicValidator from '../components/TopicValidator';
import SidebarPanel from '../components/SidebarPanel';
import SidebarPanelLink from '../components/SidebarPanelLink';
import DocTypeIcon from '../components/DocTypeIcon';

const useSidebar = () => {

	const { toggleComingSoon } = React.useContext(ComingSoonContext);

	function getSidebar(topicId, embed = false) {

		switch (topicId) {
			case 'D120186':
				return (
					<>
						<TopicValidator app={1}>
							<SidebarPanel title="SERVICE PARTS">
								<SidebarPanelLink to="/epc"><DocTypeIcon src="/images/icons/icon-epc-orange.svg" />Gearbox Oil Filter</SidebarPanelLink>
								<SidebarPanelLink to="/epc"><DocTypeIcon src="/images/icons/icon-epc-orange.svg" />Oil Filter Gasket</SidebarPanelLink>
							</SidebarPanel>
						</TopicValidator>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/D240987"><DocTypeIcon src="/images/icons/icon-si-orange.svg" />Fluid Specifications</SidebarPanelLink></TopicValidator>
							<TopicValidator app={3}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-orange.svg" />Fluid Disposal Safety</SidebarPanelLink></TopicValidator>
						</SidebarPanel>            
					</>
				);
			case 'D019938':
				return (
					<>
						<TopicValidator app={1}>
							<SidebarPanel title="SERVICE PARTS">
								<SidebarPanelLink to="/epc"><DocTypeIcon src="/images/icons/icon-epc-orange.svg" />Gearbox Oil - 5L</SidebarPanelLink>
							</SidebarPanel>
						</TopicValidator>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/D240987"><DocTypeIcon src="/images/icons/icon-si-orange.svg" />Fluid Specifications</SidebarPanelLink></TopicValidator>
							<TopicValidator app={3}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-orange.svg" />Fluid Disposal Safety</SidebarPanelLink></TopicValidator>
						</SidebarPanel>            
					</>
				);
			case "D191220":
				return (
					<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/T011040"><DocTypeIcon src="/images/icons/icon-schematics-orange.svg" />Gearbox Module</SidebarPanelLink></TopicValidator>
						</SidebarPanel>
				);
				case 'D020662':
				return (
					<>
						<TopicValidator app={1}>
							<SidebarPanel title="SERVICE PARTS">
								<SidebarPanelLink to="/epc"><DocTypeIcon src="/images/icons/icon-epc-orange.svg" />Gearbox Oil - 5L</SidebarPanelLink>
							</SidebarPanel>
						</TopicValidator>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/D240987"><DocTypeIcon src="/images/icons/icon-si-orange.svg" />Fluid Specifications</SidebarPanelLink></TopicValidator>
							<TopicValidator app={3}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-orange.svg" />Fluid Disposal Safety</SidebarPanelLink></TopicValidator>
						</SidebarPanel>            
					</>
				);
				case "cl1":
				return (
					<>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}>
							<SidebarPanelLink to="/si/D240987"><DocTypeIcon src="/images/icons/icon-si-orange.svg" />Fluid Specifications</SidebarPanelLink>
							</TopicValidator>
							<TopicValidator app={4}>
								<SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-orange.svg" />Fluid Disposal Safety</SidebarPanelLink>
							</TopicValidator>
						</SidebarPanel>
					</>
				);
			case 'T011040':
				return (
					<>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-orange.svg" />Electrical Safety</SidebarPanelLink></TopicValidator>
						</SidebarPanel>            
					</>
				);
			default:
				return(
					<></>
				);
		}
	}

	return { getSidebar }

}
 
export default useSidebar;