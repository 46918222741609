import React, { useContext } from 'react';
import BrandlessPage from '../components/layout/BrandlessPage';
import P from '../components/typography/P';
import LoginBox from '../components/LoginBox';
import { AuthContext, BrandContext } from '../App';
import { ThemeContext } from 'styled-components';

const Login = () => {

	const { changeBrand } = useContext(BrandContext);
	const { login } = useContext(AuthContext);

	const themeContext = useContext(ThemeContext);

	return ( 
		<BrandlessPage>
			<LoginBox login={ login } changeBrand={ changeBrand } />
		</BrandlessPage>
	 );
}
 
export default Login;