import React, { useContext } from 'react';
import styled from 'styled-components';

import TopicContainer from '../components/layout/TopicContainer';
import PageHeading from '../components/PageHeading';
import Table from '../components/topic/Table';
import H4 from '../components/typography/H4';
import A from '../components/typography/ARoute';
import Sidebar from '../components/Sidebar';
import SidebarPanel from '../components/SidebarPanel';
import SidebarPanelLink from '../components/SidebarPanelLink';
import DocTypeIcon from '../components/DocTypeIcon';
import { ComingSoonContext, SelectorContext } from '../App';
import topics from '../data/topics';
import useGetSidebar from '../hooks/useSidebar';

const CreateIcon = styled.img`
	display: inline;
`;

const MaintenanceDash = ({ history }) => {

	const menuContext = useContext(SelectorContext);
	const { toggleComingSoon } = useContext(ComingSoonContext);
	const { getSidebar } = useGetSidebar();
	React.useEffect(() => {
		menuContext.setBreadcrumb(
			topics.filter(topic => topic.id === "maintenancedash")[0].breadcrumb
		)
	}, []);

	return ( 
		<>
		<TopicContainer>
				<PageHeading title={"MAINTENANCE DASHBOARD"}
				subtitle={"HISTORY"} />
				<H4>MAINTENANCE HISTORY</H4>
				<Table>
					<thead>
						<tr>
							<th>Checklist</th>
							<th>Date</th>
							<th>User</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>End of Season Shut-Down</td>
							<td>12 Mar 2021, 9:02</td>
							<td>mickey.lewis</td>
						</tr>
						{history && history.map((hi, index) => (
							<tr key={ index }>
								<td>{ hi.title }</td>
								<td>{ hi.date }</td>
								<td>{ hi.username }</td>
							</tr>
						))}
					</tbody>
				</Table>
				<H4>FOR MY MACHINE</H4>
				<Table>
					<thead>
						<tr>
							<th>Checklist</th>
							<th>Description</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Pre-Season Service</td>
							<td>Performed before the start of each season</td>
							<td><A to="/mcs/cl1"><CreateIcon src="/images/icons/icon-createchecksheet-orange.svg" /></A></td>
						</tr>
						<tr>
							<td>End of Season Shut-Down</td>
							<td>End of season cleaning and shut-down</td>
							<td><A to="/mcs/cl2"><CreateIcon src="/images/icons/icon-createchecksheet-orange.svg" /></A></td>
						</tr>
					</tbody>
				</Table>
			</TopicContainer>
			<Sidebar>
			{ getSidebar(null) }
	</Sidebar></>
	 );
}
 
export default MaintenanceDash;