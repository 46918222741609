import React from 'react';
import styled from 'styled-components';

import Table from './topic/Table';

const PanelHeading = styled.div`
	margin-top: 40px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.Black};
	color: ${({ theme }) => theme.colors.GreyLight};
	font-family: ${({ theme }) => theme.fonts.title};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
	padding: 2px 7px 2px 7px;
	border-bottom: solid 2px ${({ theme }) => theme.colors.Primary};
`;

const StyledTable = styled(Table)`

	margin-top: 0px;

	td {
		border-bottom: none;
	}

`;

const SidebarPanel = ({ title, children }) => {
	return ( 
		<>
			<PanelHeading>{ title } </PanelHeading>
			<StyledTable>
				<tbody>
					{ children }
				</tbody>
			</StyledTable>
		</>
	 );
}
 
export default SidebarPanel;
