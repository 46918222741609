import React from 'react';
import styled from 'styled-components';
import useGetTopic from '../hooks/useGetTopic';

import Procedure from './topic/Procedure';
import Step from './topic/Step';
import P from './typography/P';

const Embed = styled.div`
	width: 100%;
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: ${({ visible }) => visible ? "60px" : "0px"};
	overflow: hidden;
	max-height: ${({ visible }) => visible ? "2000px" : "0px"};
	transition: padding-bottom 0.9s, max-height 0.8s;
`;

const TopicEmbed = ({ topicId, visible }) => {

	const { getTopic } = useGetTopic();

	return ( 
		<Embed visible={visible}>
			{ getTopic(topicId, true) }
		</Embed>
	 );
}
 
export default TopicEmbed;