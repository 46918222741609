import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { NavLink, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

import UserMenu from './UserMenu';

import apps from '../data/apps';
import theme from '../config/theme-gsi';
import SelectorBar from './SelectorBar';
import Search from './Search';
import { ComingSoonContext, SearchContext } from '../App';

const Bar = styled.div`
	background-color: ${({ theme }) => theme.brand === "gsi" ? theme.colors.Black : theme.colors.GreySuperLight};
	height: ${({ theme }) => theme.brand === "gsi" ? "140px" : "88px"};
	min-width: 100%;
	padding-left: calc(100vw - 100%);
`;

const Container = styled.div`
	position: relative;
	width: 1480px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
`;

const AccessoriesMenu = styled.span`
	position: absolute;
	top: ${({ theme }) => theme.brand === "gsi" ? '23px' : '30px;'};
	right: 10px;
`;

const MenuItem = styled.span`
	${({ visible }) => !visible && "display: none;"}
	margin-left: 20px;
	margin-right: 20px;
	color: ${({ theme }) => theme.brand === "gsi" ? theme.colors.White : theme.colors.Primary};
	cursor: pointer;
`;

const NavLinks = styled.span`
	${({ theme }) => theme.brand === "agco" ? "display: none;" : ""}
	position: absolute;
	top: 96px;
	left: 233px;	
`;

const LinkStyles = css`
	margin: 30px;
	font-family: ${({ theme }) => theme.fonts.nav};
	text-transform: uppercase;
	color: ${({ theme }) => theme.brand === "gsi" ? theme.colors.White : theme.colors.Primary};
	font-size: ${({ theme }) => theme.fontSizes.xs};
	padding-bottom: 5px;
	border-bottom: solid 3px ${({ theme }) => theme.colors.Black};
	transition: all 0.5s ease;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.colors.Primary};
	}
`;

const NavigationLink = styled(NavLink)`
	${LinkStyles}

	&.active {
		border-bottom: solid 3px ${({ theme }) => theme.colors.Primary}
	}
`;

const DeadLink = styled.a`
	${LinkStyles}
`;

const Logo = styled.img`
	display: inline;
	height: ${({ theme }) => theme.brand === "gsi" ? '124px' : '40px'};
	margin-top: ${({ theme }) => theme.brand === "gsi" ? '9px' : '24px'};
`;

const LanguageSelect = styled.img`
	display: inline;
`;

const User = styled.a`
	& > img {
		display: inline;
	}
`;

const ShoppingCart = styled.div`
	position: relative;
	display: inline-block;
`

const ShoppingCartIcon = styled.img`
	display: ${({ theme }) => theme.brand === "gsi" ? 'inline' : 'none'};
`;

const ShoppingCartHasParts = styled.div`
	position: absolute;
	display: inline-block;
	height: 12px;
	width: 12px;
	background-color: ${({ theme }) => theme.colors.Primary};
	border: solid 1px ${({ theme }) => theme.colors.White};
	border-radius: 50%;
	right: 0px;
	opacity: ${({ visible }) => visible ? "1" : "0"};
	transition: all 0.5s ease;
`;

const SearchIcon = styled.img`
	display: ${({ theme }) => theme.brand === "gsi" ? 'inline' : 'none'};
`;

const Header = ({ logout, basket, selectorVisible = true }) => {
	  
	const navigate = useNavigate();

	const themeContext = useContext(ThemeContext);
	const { toggleComingSoon } = useContext(ComingSoonContext);
	const { searchVisible, toggleSearch } = useContext(SearchContext);

	return ( 
		<>
			<Bar>
				<Container>
					<Logo src={themeContext.logo} />
					<NavLinks>
						{ apps.map((app, index) => {
							return localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).apps.includes(index) ? 
								( app.comingsoon === true ? <DeadLink key={ index } href='#' onClick={toggleComingSoon}>{ app.title }</DeadLink> : <NavigationLink key={ index } to={ app.href }>{app.title}</NavigationLink>) 
							:
								null
						}) }
					</NavLinks>
					<AccessoriesMenu>
						<MenuItem visible={themeContext.brand === "gsi"}>
							<SearchIcon src={themeContext.searchIcon} onClick={ toggleSearch }/>
						</MenuItem>
						<MenuItem visible={true}>
							<LanguageSelect onClick={toggleComingSoon} src={themeContext.languageIcon}></LanguageSelect>
						</MenuItem>
						<MenuItem visible={themeContext.brand === "gsi"}>
							<ShoppingCart src={themeContext.cartIcon} onClick={() => {navigate('/cart')}}>
								<ShoppingCartHasParts visible={basket && basket.length > 0} />
								<ShoppingCartIcon src={themeContext.cartIcon} />
							</ShoppingCart>
						</MenuItem>
						<MenuItem visible={true}>
							<UserMenu logout={logout} />
						</MenuItem>
					</AccessoriesMenu>
				</Container>
			</Bar>
			{/* Only show the selector on developer's request */}
			{ selectorVisible && (<SelectorBar />)}	
			<Search visible={ searchVisible } toggle={ toggleSearch } />		
		</>
		 );
}
 
export default Header;