import React from 'react';
import styled from 'styled-components';
import A from './typography/A';
import ARoute from './typography/ARoute';

const StyledLink = styled(A)`

`;

const StyledRouterLink = styled(ARoute)`

`;

const Row = styled.tr`
	border-bottom: none !important;
`;

const Cell = styled.td`
	padding: ${({ theme }) => theme.space.sidebarRow} !important;
`;

function getRouterLink(to, children, onClick) {
	return (
		<ARoute to={to} onClick={onClick}>{ children }</ARoute>
	)
}

function getLink(to, children, onClick) {
	return (
		<A href={to} target="_blank" onClick={onClick}>{ children }</A>
	)
}

const SidebarPanelLink = ({ to, children, externalLink = false, onClick }) => {
	return ( 
		<Row>
			<Cell colSpan="2">
				{ externalLink ? getLink(to, children, onClick) : getRouterLink(to, children, onClick)}
			</Cell>
		</Row>
	 );
}
 
export default SidebarPanelLink;