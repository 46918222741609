import React from 'react';
import styled from 'styled-components';
import Lightbox from '../Lightbox';

export const Image = styled.img`
	${({ mode }) => mode !== "expanded" && "margin-top: 20px;"} 
    cursor: pointer;
`;

const Figure = ({ src, onClick }) => {

	const [visible, setVisible] = React.useState(false);

	function toggle() {
		setVisible(!visible);
	}

    return ( 
		<>
        	<Image onClick={ toggle } src={ src } />
			<Lightbox isVisible={ visible } onClose={ toggle }>
				<Image src={ src.replace("-thumb", "") } mode="expanded" />
			</Lightbox>
		</>
     );
}
 
export default Figure;