import React, { useContext } from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import H5 from './typography/H5';

import topics from '../data/topics';
import SearchItem from './SearchItem';
import { AuthContext } from '../App';

const SearchHeader = styled.div`
	position: relative;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.Black};
	padding: 10px 20px 20px 20px;
`;

const CloseIcon = styled.img`
	position: absolute;
	top: 10px;
	right: 10px;
	display: inline;
	cursor: pointer;
`;

const SearchTitle = styled(H5)`
	margin-top: 0px;
	color: ${({ theme }) => theme.colors.White};
`;

const SearchBox = styled.input`
	margin-top: 10px;
	background-color: ${({ theme }) => theme.colors.GreyDark};
	border-bottom: solid 2px ${({ theme }) => theme.colors.GreyMidDark};
	width: 100%;
	min-height: 50px;
	padding: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	color: ${({ theme }) => theme.colors.GreyLight};
	font-size: ${({ theme }) => theme.fontSizes.xxl};
	font-family: ${({ theme }) => theme.fonts.bold};

	&:focus {
		border-bottom: solid 2px ${({ theme }) => theme.colors.Primary};
	}

	&::placeholder {
		font-family: ${({ theme }) => theme.fonts.regular} !important;
	}
`;

const SearchBody = styled.div`
	background-color: ${({ theme }) => theme.colors.White};
	padding: 10px;
	height: 60vh;
`;

const Search = ({ visible, toggle }) => {
	
	const [searchTerm, setSearchTerm] = React.useState("");
	const [searchItems, setSearchItems] = React.useState([]);
	const { authenticatedUser } = useContext(AuthContext);

	function handleTermChange(e) {
		setSearchTerm(e.target.value);
		const hits = e.target.value.length > 2 ? topics.filter(topic => ((topic.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || topic.keywords.includes(e.target.value)) && (authenticatedUser && authenticatedUser.apps.includes(topic.app)))) : [];

		setSearchItems(hits);
	}

	React.useEffect(() => {
		setSearchItems([]);
	}, [visible])
	
	return ( 
		<Modal isVisible={visible} width="630px" onClose={ toggle }>
			<SearchHeader>
				<SearchTitle>SEARCH</SearchTitle>
				<SearchBox autoFocus placeholder={'Search...'} onChange={handleTermChange}></SearchBox>
				<CloseIcon onClick={ toggle } src='/images/icons/icon-close-white.svg' />
			</SearchHeader>
			<SearchBody>
				{searchItems.map(item => (
					<SearchItem key={item.id} title={ item.title } icon={ item.icon } subtitle={ item.subtitle } toggleModal={ toggle } href={ item.href } />
				))}
			</SearchBody>
		</Modal>
	 );
}
 
export default Search;