import React from 'react';
import styled from 'styled-components';
import CartItem from './CartItem';
import Table from './topic/Table';

const Container = styled.div`
	width: 100%;
`;

const CartTable = ({ list, increaseQuantity, decreaseQuantity, removeItem }) => {
	return ( 
		<Container>
			{list && list.map(item => (
				<CartItem
					key={item.partNumber} 
					name={item.name}
					partNumber={item.partNumber} 
					quantity={item.quantity}
					page={item.page}
					increaseQuantity={increaseQuantity}
					decreaseQuantity={decreaseQuantity}
					removeItem={removeItem}
					/>	
			))}
		</Container>
	 );
}
 
export default CartTable;