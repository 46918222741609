import React from 'react';
import styled from 'styled-components';

const Tile = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100px;
	height: 220px;
	background-color: ${({ theme }) => theme.colors.GreySuperLight};
	margin: 5px;
	flex: 0 0 30%;
	cursor: pointer;
`;

const Logo = styled.img`
	height: 120px;
	align-self: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const Name = styled.div`
	position: absolute;
	bottom: 0px;
	display: flex-box;
	align-self: bottom;
	color: ${({ theme }) => theme.colors.Primary};
	font-family: ${({ theme }) => theme.fonts.robotoBold};
	font-size: ${({ theme }) => theme.fontSizes.xs};
	padding: 10px;
	width: 100%;
	border-top: solid 3px ${({ theme }) => theme.colors.Primary};
	text-transform: uppercase;
`;

const BrandTile = ({ logo, name, enabled, onClick }) => {
	
	return ( 
		<Tile onClick={() => {
			if(enabled){
				onClick(name.replace(" ", "").toLowerCase());
			} else {
				console.log("Can't touch this.");
			}
			}}>
			<Logo src={logo} />
			<Name>{ name }</Name>
		</Tile>
	 );
}
 
export default BrandTile;