import React from 'react';

import P from '../components/typography/P';

import {ReactComponent as PM000001} from '../images/PM000001.svg';

const useGetSvg = () => {

	function get(svgId) {

		function highlightAnnotation(number) {
			const rows = document.getElementsByClassName("annotation" + number);
			const annotation = document.getElementById("Annotation" + number);
			for (let row of rows){
				row.classList.add("active");
				annotation.classList.add("active");
			};
		}

		function lowlightAnnotation(number) {
			const rows = document.getElementsByClassName("annotation" + number);
			const annotation = document.getElementById("Annotation" + number);
			for (let row of rows){
				row.classList.remove("active");
				annotation.classList.remove("active");
			};
		}

		switch (svgId) {
			case "PM000001":
				return (
					<svg version="1.0" x="0px" y="0px" width="1004.001px" height="1004.001px" viewBox="0 0 1004.001 1004.001" enableBackground="new 0 0 1004.001 1004.001">
<g id="Background">
</g>
<g id="Standard_layer">
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M441.775,100.52c-42.622-27.834-101.963-42.069-161.92-42.131c-119.367,0-216.135,55.871-216.135,124.79    c0,46.672,44.383,87.363,111.192,109.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M441.777,105.831c-1.18-0.824-2.375-1.636-3.408-2.319"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M436.745,102.446c-41.435-26.841-98.885-40.551-156.89-40.603c-116.064,0-210.156,54.325-210.156,121.336"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M69.699,183.179c0,45.519,43.422,85.188,108.716,106.262"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="178.427" y1="448.083" x2="178.427" y2="289.446"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M410.573,122.576c-33.002-23.728-81.414-36.105-130.718-36.187c-92.585,0-167.643,43.332-167.643,96.789"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.212,183.179c0,37.681,37.297,70.331,92.692,86.577"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M206.35,270.166c1.156,0.326,2.313,0.644,3.301,0.907"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M406.323,126.254c-31.497-23.324-78.492-35.568-126.468-35.657c-88.56,0-160.354,41.45-160.354,92.582    c0,36.382,36.352,67.862,90.16,83.241"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="209.652" y1="267.051" x2="206.35" y2="269.735"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="269.735" x2="206.35" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="209.652" y1="266.416" x2="209.652" y2="267.051"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="209.652" y1="271.706" x2="206.35" y2="274.393"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="209.652" y1="271.073" x2="209.652" y2="271.706"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.781" y1="252.456" x2="162.781" y2="283.941"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="161.723" y1="251.856" x2="131.767" y2="269.27"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.817" y1="192.637" x2="183.906" y2="204.841"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="125.485" y1="220.929" x2="86.339" y2="230.499"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="112.228" y1="184.098" x2="69.709" y2="184.331"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="124.34" y1="147.032" x2="84.903" y2="137.867"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="160.311" y1="115.321" x2="129.996" y2="98.112"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="126.036" y1="221.662" x2="126.036" y2="265.853"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.228" y1="184.098" x2="112.228" y2="224.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="84.903" y1="137.867" x2="84.903" y2="184.247"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="129.996" y1="98.112" x2="129.996" y2="139.796"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.816" y1="96.77" x2="133.065" y2="96.346"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.816" y1="96.77" x2="133.816" y2="100.282"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M438.412,108.549c-2.887-1.915-5.851-3.764-8.482-5.302"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M421.918,98.775c-18.373-9.732-38.939-17.219-59.289-22.116"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M354.576,74.779c-24.179-5.307-48.999-7.873-71.906-7.913"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M277.203,66.862c-55.55,0.405-105.794,13.22-142.81,33.753"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M129.996,103.121c-18.438,10.83-32.639,23.149-41.95,35.484"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M84.903,142.877c-5.319,7.627-9.197,15.379-11.421,22.395"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M72.6,168.094c-0.735,2.524-1.326,5.042-1.715,7.21"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M70.413,178.189c-0.307,2.15-0.516,4.292-0.609,6.128"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="438.361" y1="103.27" x2="438.361" y2="107.948"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="438.361" y1="103.27" x2="441.781" y2="101.543"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="441.781" y1="100.52" x2="441.781" y2="101.543"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="441.781" y1="105.831" x2="441.781" y2="106.853"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="438.361" y1="108.579" x2="441.781" y2="106.853"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="409.941" y1="123.962" x2="406.323" y2="126.256"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.566" y1="122.569" x2="410.566" y2="128.843"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.566" y1="128.843" x2="406.323" y2="131.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.562,235.044c-0.84-0.616-1.692-1.224-2.429-1.736"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M405.95,231.809c-2.922-1.957-5.949-3.832-8.669-5.383"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M394.907,225.092c-3.252-1.799-6.598-3.506-9.583-4.904"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M383.402,219.294c-3.655-1.678-7.399-3.255-10.721-4.528"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M370.476,213.922c-4.272-1.601-8.622-3.074-12.475-4.241"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M355.891,209.041c-4.397-1.301-8.845-2.48-12.756-3.397"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M341.243,205.204c-4.541-1.04-9.111-1.957-13.114-2.647"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M325.943,202.185c-10.113-1.684-20.274-2.789-29.351-3.273"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M294.626,198.804c-4.76-0.246-9.49-0.372-13.573-0.377"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.668,198.427c-4.993,0.023-9.939,0.168-14.187,0.41"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M262.108,198.972c-1.149,0.074-2.298,0.151-3.269,0.221"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M179.195,217.994c-1.012,0.442-2.01,0.893-2.848,1.277"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M173.837,220.446c-3.692,1.763-7.221,3.595-10.134,5.255"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.306,227.076c-2.915,1.706-5.695,3.464-7.985,5.025"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M151.102,233.633c-1.107,0.779-2.189,1.564-3.089,2.245"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="409.655" y1="127.941" x2="406.797" y2="125.958"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="178.16" y1="290.405" x2="177.473" y2="290.797"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="176.533" y1="291.335" x2="174.902" y2="292.265"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="178.427" y1="295.246" x2="177.457" y2="295.799"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="176.385" y1="296.412" x2="174.902" y2="297.258"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M63.764,185.673c-0.032,0.882-0.047,1.766-0.044,2.515"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M63.72,188.188c0,46.673,44.383,87.363,111.192,109.091"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M125.399,270.466c15.818,9.9,33.83,17.935,51.886,23.624"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="258.839" y1="122.496" x2="258.839" y2="249.201"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="258.839" y1="122.496" x2="226.7" y2="106.602"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="226.7" y1="106.602" x2="214.025" y2="109.866"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="214.025" y1="109.866" x2="179.19" y2="141.362"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="179.19" y1="141.362" x2="179.19" y2="255.246"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="183.906" y1="144.856" x2="183.906" y2="249.418"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="254.123" y1="128.052" x2="254.123" y2="232.888"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="254.123" y1="128.052" x2="222.864" y2="112.594"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.123" y1="128.052" x2="258.839" y2="122.496"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="222.864" y1="112.594" x2="218.29" y2="113.77"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="218.29" y1="113.77" x2="183.906" y2="144.856"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.906" y1="144.856" x2="179.19" y2="141.362"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="218.29" y1="113.77" x2="214.025" y2="109.866"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="222.864" y1="112.594" x2="226.7" y2="106.602"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="258.839" y1="231.611" x2="254.123" y2="232.732"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.906" y1="249.418" x2="179.193" y2="250.539"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.906" y1="249.418" x2="210.675" y2="256.051"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="210.675" y1="256.051" x2="228.578" y2="252.121"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="228.578" y1="252.121" x2="254.123" y2="232.732"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.021" y1="255.341" x2="256.566" y2="235.952"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="211.541" y1="260.001" x2="229.444" y2="256.07"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="182.934" y1="253.341" x2="209.701" y2="259.976"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="209.701" y1="259.976" x2="210.954" y2="255.99"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="210.954" y1="255.99" x2="211.541" y2="260.001"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.444" y1="256.07" x2="228.578" y2="252.121"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="228.578" y1="252.121" x2="231.021" y2="255.341"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="210.954" y1="255.99" x2="195.39" y2="234.023"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="228.578" y1="252.121" x2="213.018" y2="230.154"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.906" y1="231.175" x2="195.39" y2="234.023"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="195.39" y1="234.023" x2="213.293" y2="230.092"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.123" y1="232.732" x2="238.563" y2="210.762"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="213.018" y1="230.154" x2="238.563" y2="210.762"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.563" y1="210.762" x2="238.563" y2="192.657"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.563" y1="186.771" x2="238.563" y2="120.358"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.123" y1="232.732" x2="256.566" y2="235.952"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.563" y1="210.762" x2="211.809" y2="228.128"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="193.906" y1="232.059" x2="211.809" y2="228.128"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="193.906" y1="232.059" x2="183.906" y2="230.847"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="234.69" y1="118.441" x2="234.69" y2="203.674"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="119.67" x2="231.196" y2="131.309"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="132.375" x2="231.196" y2="138.894"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="140.776" x2="231.196" y2="145.775"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="146.837" x2="231.196" y2="153.499"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="155.38" x2="231.196" y2="159.823"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="160.886" x2="231.196" y2="167.977"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="169.858" x2="231.196" y2="174.359"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="175.422" x2="231.196" y2="182.495"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="184.377" x2="231.196" y2="188.958"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="193.277" x2="231.196" y2="199.109"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="188.958" x2="183.906" y2="200.524"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.196" y1="188.958" x2="233.817" y2="191.929"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.817" y1="191.929" x2="233.817" y2="192.637"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="234.69" y1="203.674" x2="234.087" y2="203.82"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.137,181.669c-0.267,0.063-0.452,0.34-0.523,0.724"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.049,184.166c0.253,0.328,0.545,0.461,0.796,0.402"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.72,184.352c0.461-0.11,0.679-0.852,0.482-1.654c-0.196-0.8-0.73-1.357-1.191-1.245"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.012" y1="181.453" x2="232.137" y2="181.669"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.845" y1="184.568" x2="233.72" y2="184.352"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.605,184.031c0.282-0.068,0.414-0.523,0.293-1.012c-0.118-0.491-0.445-0.833-0.73-0.765"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.297,184.596c0.282-0.068,0.416-0.523,0.295-1.014c-0.118-0.489-0.447-0.833-0.73-0.763c-0.284,0.07-0.414,0.523-0.295,1.012    C229.688,184.324,230.014,184.664,230.297,184.596"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.862" y1="182.819" x2="232.168" y2="182.255"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.605" y1="184.031" x2="230.297" y2="184.596"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.394" y1="183.666" x2="234.267" y2="183.451"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.04" y1="182.216" x2="233.913" y2="182"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.366,129.124c-0.74,0.361-1.098,0.723-1.179,1.684c-0.012,0.161-0.023,0.363-0.03,0.591"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.156,132.138c0.014,1.049,0.114,2.315,0.353,3.06c0.277,0.872,0.356,1.21,0.858,1.41"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="131.981" x2="185.297" y2="143.598"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.116" y1="131.3" x2="186.952" y2="142.1"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="131.981" x2="232.728" y2="131.458"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.728" y1="131.458" x2="231.116" y2="131.3"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="235.2" y1="118.692" x2="204.554" y2="126.188"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.244" y1="115.749" x2="211.227" y2="120.153"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.366,172.173c-0.74,0.358-1.098,0.719-1.179,1.68c-0.012,0.161-0.023,0.365-0.03,0.593"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.156,175.185c0.014,1.049,0.114,2.319,0.353,3.062c0.277,0.868,0.356,1.208,0.858,1.408"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="175.029" x2="183.809" y2="187.009"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.116" y1="174.35" x2="183.906" y2="185.894"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="175.029" x2="232.728" y2="174.503"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.728" y1="174.503" x2="231.116" y2="174.35"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.366,187.067c-0.74,0.361-1.098,0.723-1.179,1.684c-0.012,0.161-0.028,1.103-0.032,1.331"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.457,192.971c0.014,0.06,0.033,0.116,0.051,0.174c0.277,0.87,0.356,1.208,0.858,1.41"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="234.087" y1="203.82" x2="233.234" y2="202.622"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="217.488" y1="104.975" x2="219.886" y2="108.36"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="212.45" y1="106.415" x2="214.763" y2="109.679"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.53" y1="91.018" x2="206.569" y2="89.578"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="212.45" y1="106.415" x2="217.488" y2="104.975"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.53" y1="91.018" x2="189.378" y2="101.693"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="197.663" y1="87.417" x2="174.209" y2="108.025"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.53" y1="91.018" x2="197.663" y2="87.417"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="197.663" y1="87.417" x2="210.399" y2="83.781"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.569" y1="89.578" x2="210.399" y2="83.781"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="210.399" y1="83.781" x2="225.602" y2="91.6"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.879,106.873c-0.973,0.717-1.559,1.922-1.568,3.052"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="160.311" y1="115.321" x2="160.311" y2="109.924"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="191.005" y1="85.439" x2="161.879" y2="106.873"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M242.105,88.875c-0.179-0.598-0.607-0.987-1.147-1.128"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="212.45" y1="106.415" x2="191.817" y2="129.948"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="217.488" y1="104.975" x2="222.448" y2="107.697"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.569" y1="89.578" x2="210.462" y2="95.071"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.53" y1="91.018" x2="205.39" y2="96.462"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.53" y1="91.018" x2="206.569" y2="89.578"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.569" y1="89.578" x2="214.651" y2="94.012"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="247.751" y1="124.899" x2="254.123" y2="134.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M212.022,79.907c-0.586-0.16-1.208-0.132-1.798,0.04"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="191.005" y1="85.439" x2="210.224" y2="79.948"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="212.022" y1="79.907" x2="240.958" y2="87.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="282.686" y1="61.853" x2="282.686" y2="86.403"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="282.686" y1="95.9" x2="282.686" y2="113.807"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="282.686" y1="119.593" x2="282.686" y2="167.647"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="282.686" y1="173.434" x2="282.686" y2="207.54"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="282.686" y1="213.326" x2="282.686" y2="248.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="277.214" y1="61.851" x2="277.214" y2="86.4"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.214" y1="95.897" x2="277.214" y2="113.804"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.214" y1="119.593" x2="277.214" y2="167.647"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.214" y1="173.433" x2="277.214" y2="207.538"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.214" y1="213.324" x2="277.214" y2="248.445"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.895" y1="61.843" x2="279.895" y2="58.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,151.904c-33.968-24.791-85.116-37.122-136.518-35.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M169.152,140.867c-25.899,13.708-42.418,31.115-49.211,49.187"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.566,149.98c-34.773-25.001-86.656-37.401-138.761-36.077"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M167.139,138.931c-23.449,12.3-39.474,27.553-47.643,43.42"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.566" y1="149.98" x2="410.566" y2="154.055"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.076" y1="150.785" x2="408.319" y2="151.903"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="271.805" y1="119.695" x2="271.805" y2="113.904"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="169.145" y1="140.204" x2="169.145" y2="140.869"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="169.145" y1="143.861" x2="169.145" y2="144.445"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="169.145" y1="140.204" x2="167.146" y2="138.935"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,155.48c-33.968-24.792-85.116-37.124-136.518-35.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M169.152,144.442c-25.434,13.461-41.822,30.492-48.815,48.182"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.566" y1="154.055" x2="408.323" y2="155.479"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.106" y1="153.49" x2="408.2" y2="152.134"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="169.145" y1="143.861" x2="166.606" y2="142.244"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.713" y1="126.965" x2="399.713" y2="142.911"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.713" y1="149.693" x2="399.713" y2="196.754"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.713" y1="203.534" x2="399.713" y2="236.645"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.713" y1="243.425" x2="399.713" y2="277.864"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="124.441" x2="395.674" y2="130.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="132.405" x2="395.674" y2="140.606"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="147.276" x2="395.674" y2="157.571"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="159.46" x2="395.674" y2="171.425"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="173.273" x2="395.674" y2="184.301"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="186.15" x2="395.674" y2="194.447"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="201.117" x2="395.674" y2="211.226"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="213.078" x2="395.674" y2="224.345"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="226.031" x2="395.674" y2="234.339"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="241.008" x2="395.674" y2="251.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="254.099" x2="395.674" y2="264.808"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="267.263" x2="395.674" y2="275.526"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,145.775c0.316,0.181,0.71,0.149,1.072-0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,144.133c-0.002-0.061-0.004-0.119-0.014-0.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="145.775" x2="161.055" y2="145.462"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.741,145.043c0.054,0.132,0.144,0.226,0.244,0.284"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.314,146.085c0.337,0.193,0.809-0.007,1.059-0.445s0.182-0.951-0.154-1.143c-0.335-0.189-0.809,0.009-1.059,0.447    C162.909,145.382,162.977,145.894,163.314,146.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="164.219" y1="144.496" x2="163.43" y2="144.047"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="145.327" x2="163.314" y2="146.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="194.047" x2="169.145" y2="194.712"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="197.701" x2="169.145" y2="198.288"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="194.047" x2="167.146" y2="192.774"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="197.701" x2="166.606" y2="196.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,199.616c0.316,0.182,0.71,0.149,1.072-0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,197.976c-0.002-0.063-0.004-0.123-0.014-0.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="199.614" x2="161.055" y2="199.302"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.741,198.886c0.054,0.128,0.144,0.223,0.244,0.281"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.314,199.925c0.335,0.195,0.809-0.007,1.059-0.445s0.182-0.947-0.154-1.14c-0.335-0.193-0.809,0.009-1.059,0.447    C162.909,199.225,162.977,199.733,163.314,199.925"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="164.219" y1="198.339" x2="163.43" y2="197.887"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="199.167" x2="163.314" y2="199.925"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,205.744c-33.968-24.791-85.116-37.118-136.518-35.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M169.152,194.707c-15.187,8.04-27.146,17.349-35.272,26.788"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="194.047" x2="169.145" y2="194.712"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="197.701" x2="169.145" y2="198.288"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="194.047" x2="167.146" y2="192.774"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.319,209.318c-33.968-24.792-85.114-37.12-136.518-35.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M169.151,198.279c-14.331,7.584-25.79,16.3-33.742,25.098"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="197.701" x2="166.606" y2="196.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,199.616c0.316,0.182,0.71,0.149,1.072-0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,197.976c-0.002-0.063-0.004-0.123-0.014-0.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="199.614" x2="161.055" y2="199.302"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.741,198.886c0.054,0.128,0.144,0.223,0.244,0.281"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.314,199.925c0.335,0.195,0.809-0.007,1.059-0.445s0.182-0.947-0.154-1.14c-0.335-0.193-0.809,0.009-1.059,0.447    C162.909,199.225,162.977,199.733,163.314,199.925"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="164.219" y1="198.339" x2="163.43" y2="197.887"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="199.167" x2="163.314" y2="199.925"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,245.635c-33.968-24.791-85.116-37.118-136.518-35.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M169.152,234.598c-4.77,2.526-9.22,5.176-12.84,7.605"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="233.939" x2="169.145" y2="234.603"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="237.594" x2="169.145" y2="238.179"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="233.939" x2="167.146" y2="232.665"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,249.211c-33.968-24.791-85.116-37.12-136.518-35.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M169.152,238.172c-3.72,1.971-7.247,4.016-10.146,5.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.145" y1="237.594" x2="166.606" y2="235.978"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,239.505c0.316,0.184,0.71,0.151,1.072-0.051"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,237.867c-0.002-0.063-0.004-0.123-0.014-0.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="239.505" x2="161.055" y2="239.193"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.741,238.778c0.054,0.128,0.144,0.223,0.244,0.281"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.314,239.816c0.335,0.195,0.809-0.007,1.059-0.445s0.182-0.947-0.154-1.14c-0.335-0.193-0.809,0.009-1.059,0.447    C162.909,239.116,162.977,239.625,163.314,239.816"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="164.219" y1="238.23" x2="163.43" y2="237.778"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="239.058" x2="163.314" y2="239.816"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.566,203.82c-34.773-24.999-86.656-37.401-138.761-36.073"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M167.139,192.774c-14.999,7.862-26.958,16.94-35.231,26.124"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.566,243.713c-34.773-25.001-86.656-37.403-138.761-36.075"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M167.139,232.665c-4.928,2.583-9.53,5.298-13.27,7.792"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="124.658" x2="163.661" y2="131.376"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="132.521" x2="163.661" y2="140.809"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="147.486" x2="163.661" y2="158.187"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="159.334" x2="163.661" y2="171.693"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="172.835" x2="163.661" y2="185.162"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="186.308" x2="163.661" y2="194.649"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="201.324" x2="163.661" y2="212.135"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="213.282" x2="163.661" y2="225.666"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="226.813" x2="163.661" y2="234.54"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.661" y1="241.219" x2="163.661" y2="246.982"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="159.499" y1="127.284" x2="159.499" y2="143.203"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="159.499" y1="149.993" x2="159.499" y2="197.043"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="159.499" y1="203.83" x2="159.499" y2="236.934"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="159.499" y1="250.551" x2="159.499" y2="253.147"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.044" y1="245.982" x2="157.275" y2="248.525"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.275" y1="248.525" x2="157.275" y2="249.202"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.906" y1="249.418" x2="182.934" y2="253.341"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.563" y1="186.771" x2="253.369" y2="207.208"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.563" y1="186.771" x2="237.94" y2="186.957"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="237.94" y1="186.957" x2="237.94" y2="191.797"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="237.94" y1="191.797" x2="252.749" y2="212.235"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="253.369" y1="212.051" x2="252.749" y2="212.235"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="253.369" y1="207.208" x2="253.369" y2="212.051"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M243.317,198.036c0.282-0.067,0.416-0.523,0.295-1.012c-0.119-0.489-0.444-0.833-0.728-0.761    c-0.282,0.068-0.416,0.523-0.296,1.012C242.709,197.766,243.033,198.106,243.317,198.036"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M247.388,203.566c0.281-0.068,0.416-0.523,0.293-1.012c-0.118-0.493-0.447-0.833-0.73-0.765c-0.282,0.068-0.414,0.523-0.295,1.014    C246.778,203.292,247.104,203.636,247.388,203.566"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M251.301,208.906c0.282-0.068,0.416-0.523,0.295-1.012c-0.119-0.489-0.447-0.833-0.73-0.761c-0.284,0.068-0.416,0.523-0.295,1.014    C250.689,208.636,251.018,208.978,251.301,208.906"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.217,192.26c0.282-0.068,0.416-0.523,0.298-1.014c-0.123-0.489-0.447-0.833-0.73-0.763c-0.284,0.068-0.417,0.523-0.296,1.012    C238.608,191.988,238.934,192.329,239.217,192.26"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.994,199.13c0.235,0.305,0.749,0.307,1.152,0.002c0.4-0.303,0.537-0.802,0.303-1.11c-0.235-0.307-0.749-0.309-1.152-0.002    C180.898,198.323,180.761,198.82,180.994,199.13"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.824,185.71c0.233,0.31,0.751,0.31,1.151,0.005c0.403-0.307,0.54-0.802,0.303-1.11c-0.232-0.307-0.747-0.31-1.151-0.004    C180.728,184.906,180.591,185.404,180.824,185.71"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.824,172.168c0.233,0.309,0.751,0.31,1.151,0.005c0.403-0.307,0.54-0.805,0.303-1.11c-0.232-0.31-0.747-0.312-1.151-0.004    C180.728,171.365,180.591,171.861,180.824,172.168"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.831,159.048c0.233,0.309,0.747,0.31,1.151,0.005c0.402-0.307,0.537-0.805,0.303-1.11c-0.235-0.31-0.751-0.312-1.151-0.007    C180.731,158.244,180.595,158.741,180.831,159.048"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.796,149.958c0.235,0.31,0.749,0.312,1.152,0.005c0.4-0.307,0.537-0.803,0.303-1.11c-0.237-0.309-0.749-0.31-1.152-0.005    C180.698,149.154,180.563,149.652,180.796,149.958"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.824,212.591c0.233,0.309,0.751,0.31,1.151,0.005c0.403-0.307,0.538-0.805,0.303-1.11c-0.233-0.31-0.751-0.31-1.151-0.004    C180.724,211.788,180.591,212.286,180.824,212.591"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.858,226.124c0.235,0.309,0.751,0.309,1.151,0.002c0.403-0.305,0.538-0.803,0.305-1.108c-0.235-0.309-0.749-0.31-1.152-0.005    C180.761,225.317,180.624,225.817,180.858,226.124"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.824,235.145c0.233,0.305,0.751,0.309,1.151,0.002c0.403-0.305,0.538-0.803,0.303-1.112c-0.233-0.307-0.751-0.307-1.151-0.004    C180.724,234.337,180.591,234.835,180.824,235.145"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M180.791,243.923c0.232,0.31,0.747,0.312,1.151,0.007c0.4-0.309,0.537-0.803,0.303-1.112c-0.233-0.309-0.751-0.31-1.151-0.005    C180.691,243.12,180.554,243.618,180.791,243.923"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.123" y1="213.112" x2="253.369" y2="212.051"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="258.839" y1="211.991" x2="254.123" y2="213.112"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.906" y1="229.8" x2="179.19" y2="230.92"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.684,181.374c0.233,0.307,0.747,0.31,1.151,0.002c0.4-0.305,0.537-0.801,0.303-1.108c-0.233-0.309-0.751-0.309-1.151-0.005    C255.584,180.567,255.447,181.065,255.684,181.374"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.51,167.957c0.237,0.305,0.751,0.307,1.154,0.002c0.4-0.303,0.537-0.802,0.303-1.11c-0.237-0.307-0.751-0.309-1.154-0.004    C255.414,167.15,255.279,167.647,255.51,167.957"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.51,154.412c0.237,0.309,0.751,0.31,1.154,0.005c0.4-0.305,0.537-0.801,0.303-1.112c-0.237-0.305-0.751-0.307-1.154-0.002    C255.414,153.609,255.279,154.105,255.51,154.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.519,141.292c0.232,0.309,0.749,0.309,1.149,0.005c0.403-0.305,0.54-0.803,0.303-1.112c-0.232-0.307-0.749-0.31-1.149-0.002    C255.419,140.489,255.282,140.985,255.519,141.292"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.482,132.205c0.237,0.307,0.751,0.307,1.154,0.004c0.4-0.307,0.535-0.803,0.303-1.112c-0.237-0.307-0.752-0.31-1.152-0.004    C255.384,131.398,255.249,131.896,255.482,132.205"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.51,194.835c0.237,0.309,0.751,0.31,1.154,0.005c0.4-0.305,0.537-0.802,0.303-1.112c-0.237-0.305-0.751-0.307-1.154-0.002    C255.414,194.032,255.279,194.528,255.51,194.835"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.547,208.366c0.233,0.31,0.747,0.312,1.151,0.007c0.4-0.309,0.537-0.803,0.303-1.112c-0.235-0.307-0.751-0.31-1.151-0.005    C255.447,207.563,255.31,208.061,255.547,208.366"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.51,217.388c0.237,0.309,0.751,0.31,1.154,0.005c0.4-0.307,0.537-0.805,0.303-1.11c-0.237-0.31-0.751-0.312-1.154-0.005    C255.414,216.584,255.279,217.079,255.51,217.388"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M250.355,238.341c0.237,0.307,0.751,0.31,1.154,0.004c0.4-0.305,0.535-0.803,0.303-1.108c-0.237-0.31-0.751-0.312-1.154-0.005    C250.257,237.538,250.122,238.032,250.355,238.341"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M184.339,141.036c0.233,0.31,0.747,0.312,1.152,0.004c0.4-0.305,0.535-0.803,0.303-1.108c-0.237-0.309-0.752-0.31-1.152-0.005    C184.239,140.232,184.102,140.731,184.339,141.036"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M198.147,128.305c0.235,0.309,0.749,0.31,1.152,0.002c0.4-0.305,0.537-0.801,0.303-1.108c-0.235-0.309-0.749-0.31-1.152-0.005    C198.051,127.498,197.914,127.998,198.147,128.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M212.181,115.572c0.233,0.307,0.747,0.307,1.151,0.002c0.4-0.305,0.537-0.803,0.303-1.108c-0.235-0.309-0.751-0.31-1.151-0.005    C212.081,114.765,211.945,115.263,212.181,115.572"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M194.56,123.641c0.233,0.307,0.751,0.31,1.151,0.005c0.403-0.309,0.537-0.805,0.303-1.112c-0.233-0.309-0.749-0.31-1.149-0.005    C194.46,122.834,194.325,123.332,194.56,123.641"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M183.038,133.28c0.233,0.309,0.751,0.31,1.151,0.005c0.403-0.307,0.54-0.805,0.303-1.112c-0.232-0.309-0.747-0.31-1.151-0.005    C182.941,132.477,182.804,132.973,183.038,133.28"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M219.263,111.087c0.237,0.309,0.751,0.31,1.151,0.005c0.405-0.307,0.54-0.805,0.303-1.11c-0.233-0.31-0.747-0.312-1.151-0.004    C219.167,110.284,219.03,110.78,219.263,111.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M226.484,111.848c0.233,0.309,0.747,0.31,1.151,0.005c0.4-0.305,0.537-0.802,0.303-1.112c-0.233-0.305-0.751-0.307-1.151-0.002    C226.384,111.043,226.247,111.541,226.484,111.848"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.529,118.262c0.235,0.305,0.749,0.309,1.152,0.002c0.4-0.305,0.537-0.803,0.303-1.108c-0.235-0.31-0.749-0.31-1.152-0.005    C239.433,117.455,239.296,117.952,239.529,118.262"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M252.172,124.628c0.235,0.305,0.749,0.309,1.152,0.002c0.4-0.305,0.537-0.803,0.303-1.108c-0.235-0.31-0.749-0.31-1.151-0.005    C252.074,123.823,251.939,124.318,252.172,124.628"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.706,141.5c0.235,0.309,0.751,0.31,1.151,0.002c0.403-0.305,0.538-0.802,0.305-1.108c-0.235-0.309-0.749-0.31-1.152-0.005    C201.609,140.694,201.472,141.194,201.706,141.5"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.839,156.205c0.237,0.307,0.752,0.31,1.152,0.004c0.403-0.305,0.538-0.803,0.303-1.108c-0.233-0.31-0.749-0.31-1.152-0.005    C201.742,155.401,201.607,155.896,201.839,156.205"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.749,170.507c0.237,0.309,0.751,0.31,1.154,0.005c0.4-0.307,0.535-0.805,0.303-1.112c-0.237-0.309-0.751-0.31-1.152-0.005    C201.651,169.704,201.516,170.2,201.749,170.507"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.616,185.034c0.233,0.309,0.749,0.31,1.152,0.002c0.4-0.305,0.535-0.802,0.303-1.108c-0.237-0.309-0.751-0.31-1.152-0.005    C201.516,184.228,201.381,184.728,201.616,185.034"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="271.805" y1="167.747" x2="271.809" y2="173.534"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="271.805" y1="207.638" x2="271.809" y2="213.426"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.562" y1="203.82" x2="410.562" y2="207.894"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.076" y1="204.625" x2="408.317" y2="205.742"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.562" y1="207.894" x2="408.319" y2="209.318"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.106" y1="207.328" x2="408.196" y2="205.974"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.562" y1="243.711" x2="410.562" y2="247.785"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.076" y1="244.516" x2="408.317" y2="245.633"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.562" y1="247.785" x2="408.319" y2="249.209"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.106" y1="247.219" x2="408.196" y2="245.865"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.611,197.587c-0.13,0.189-0.193,0.424-0.196,0.668"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.323,199.842c0.381,0.217,0.772,0.224,1.072,0.056"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.394" y1="199.898" x2="398.175" y2="199.451"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.01,199.448c0.274-0.154,0.368-0.517,0.263-0.887"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.686,200.209c0.333-0.193,0.402-0.705,0.153-1.144c-0.249-0.438-0.724-0.638-1.063-0.447    c-0.333,0.195-0.403,0.703-0.154,1.142C394.874,200.198,395.349,200.398,395.686,200.209"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="394.776" y1="198.618" x2="396.105" y2="197.86"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.01" y1="199.448" x2="395.683" y2="200.209"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.562,230.617c-37.296-27.334-95.132-39.823-151.723-35.85"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M179.195,213.568c-13.61,5.968-25.18,12.91-33.897,19.976"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.562,285.053c-3.008-2.208-6.152-4.316-8.999-6.061"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M399.866,277.955c-0.709-0.423-1.421-0.844-2.033-1.198"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.223,275.833c-37.683-21.286-88.42-30.14-137.384-26.632"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.566" y1="128.843" x2="410.566" y2="329.639"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.567" strokeLinecap="round" x1="410.56" y1="285.051" x2="206.35" y2="431.127"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.111" y1="277.501" x2="315.158" y2="339.75"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M370.976,212.352c-0.298,0.254-0.416,0.652-0.356,1.066"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M371.813,214.806c0.43,0.128,0.83,0.044,1.108-0.193"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M373.603,214.024c0.514-0.438,0.493-1.301-0.046-1.926c-0.54-0.624-1.391-0.773-1.901-0.335"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="371.657" y1="211.763" x2="370.976" y2="212.352"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="372.921" y1="214.613" x2="373.603" y2="214.024"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M372.462,214.245c0.316-0.27,0.3-0.796-0.026-1.182c-0.331-0.381-0.851-0.474-1.166-0.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M371.883,214.743c0.314-0.27,0.3-0.796-0.028-1.18c-0.33-0.382-0.852-0.474-1.165-0.205c-0.312,0.27-0.3,0.802,0.03,1.182    C371.05,214.923,371.571,215.013,371.883,214.743"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="370.618" y1="213.419" x2="371.269" y2="212.859"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="372.462" y1="214.245" x2="371.883" y2="214.743"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="372.798" y1="212.635" x2="373.477" y2="212.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M383.979,217.624c-0.295,0.219-0.421,0.593-0.379,1.003"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M384.693,220.092c0.407,0.17,0.794,0.128,1.07-0.079"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M386.484,219.476c0.505-0.377,0.517-1.221,0.025-1.878c-0.493-0.663-1.303-0.893-1.808-0.514"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="384.7" y1="217.084" x2="383.979" y2="217.624"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="385.763" y1="220.013" x2="386.484" y2="219.476"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M385.33,219.615c0.31-0.23,0.316-0.745,0.016-1.149c-0.303-0.405-0.798-0.547-1.108-0.314"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M384.718,220.075c0.31-0.233,0.316-0.747,0.016-1.152c-0.303-0.405-0.8-0.545-1.108-0.312c-0.312,0.23-0.316,0.744-0.016,1.149    C383.913,220.164,384.409,220.304,384.718,220.075"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="383.625" y1="218.61" x2="384.237" y2="218.152"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="385.33" y1="219.615" x2="384.693" y2="220.092"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="385.661" y1="218.019" x2="386.382" y2="217.481"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M310.961,198.721c-0.165,0.419-0.102,0.875,0.137,1.243"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M312.801,200.626c0.451-0.119,0.786-0.431,0.94-0.821"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M314.066,198.965c0.282-0.721-0.114-1.55-0.88-1.849c-0.768-0.296-1.617,0.044-1.899,0.768"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="311.288" y1="197.885" x2="310.958" y2="198.721"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="313.741" y1="199.805" x2="314.066" y2="198.965"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M313.164,199.697c0.17-0.444-0.07-0.951-0.54-1.131c-0.47-0.184-0.991,0.026-1.166,0.467"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M312.885,200.407c0.174-0.44-0.07-0.947-0.54-1.131c-0.472-0.184-0.993,0.026-1.163,0.47c-0.174,0.44,0.07,0.947,0.54,1.133    C312.194,201.061,312.715,200.851,312.885,200.407"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="311.182" y1="199.746" x2="311.458" y2="199.032"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="313.164" y1="199.697" x2="312.885" y2="200.407"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="312.826" y1="197.941" x2="313.154" y2="197.101"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M326.009,200.659c-0.198,0.386-0.174,0.833,0.026,1.214"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.661,202.713c0.456-0.065,0.814-0.326,1.003-0.688"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.075,201.226c0.342-0.661,0.028-1.507-0.705-1.884c-0.733-0.379-1.605-0.146-1.947,0.517"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="326.423" y1="199.86" x2="326.009" y2="200.659"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="328.665" y1="202.026" x2="329.075" y2="201.226"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M328.1,201.857c0.209-0.407,0.014-0.923-0.433-1.154c-0.447-0.23-0.982-0.088-1.193,0.317"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.661,202.713c0.207-0.409,0.014-0.924-0.433-1.158c-0.451-0.232-0.986-0.088-1.193,0.317    c-0.212,0.407-0.018,0.926,0.433,1.156C326.916,203.262,327.451,203.12,327.661,202.713"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="326.036" y1="201.873" x2="326.474" y2="201.021"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="328.102" y1="201.857" x2="327.661" y2="202.713"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="327.928" y1="200.177" x2="328.342" y2="199.377"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M341.415,203.515c-0.224,0.372-0.232,0.817-0.06,1.214"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M342.916,205.677c0.463-0.03,0.838-0.268,1.049-0.614"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M344.434,204.293c0.388-0.638,0.132-1.501-0.574-1.931c-0.703-0.428-1.589-0.254-1.977,0.384"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="341.883" y1="202.747" x2="341.415" y2="203.516"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.965" y1="205.063" x2="344.434" y2="204.293"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.416,204.856c0.237-0.389,0.081-0.919-0.353-1.182c-0.43-0.261-0.973-0.158-1.21,0.232"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M342.916,205.675c0.239-0.388,0.081-0.919-0.351-1.18c-0.43-0.261-0.973-0.158-1.21,0.235c-0.237,0.389-0.081,0.921,0.351,1.182    C342.136,206.174,342.68,206.07,342.916,205.675"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="341.355" y1="204.73" x2="341.854" y2="203.908"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.416" y1="204.856" x2="342.916" y2="205.677"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.19" y1="203.096" x2="343.658" y2="202.327"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M356.133,207.482c-0.258,0.326-0.309,0.756-0.181,1.161"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M357.389,209.774c0.454,0.035,0.844-0.139,1.087-0.442"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M359.033,208.625c0.444-0.563,0.275-1.431-0.37-1.94c-0.649-0.512-1.531-0.47-1.973,0.089"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="356.689" y1="206.775" x2="356.133" y2="207.482"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="358.477" y1="209.332" x2="359.033" y2="208.625"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M357.954,209.057c0.27-0.344,0.168-0.875-0.228-1.187s-0.937-0.288-1.208,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M357.482,209.657c0.27-0.344,0.168-0.875-0.228-1.187c-0.396-0.312-0.937-0.288-1.21,0.056c-0.27,0.344-0.168,0.877,0.228,1.189    S357.208,210,357.482,209.657"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="355.953" y1="208.643" x2="356.517" y2="207.926"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="357.954" y1="209.057" x2="357.389" y2="209.774"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="358.005" y1="207.338" x2="358.562" y2="206.631"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M406.721,230.743c-0.317,0.181-0.489,0.54-0.498,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M407.13,233.281c0.382,0.217,0.775,0.223,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M408.984,232.888c0.549-0.314,0.663-1.147,0.254-1.861c-0.41-0.716-1.186-1.044-1.733-0.73"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="407.504" y1="230.298" x2="406.721" y2="230.743"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="408.203" y1="233.334" x2="408.984" y2="232.888"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M407.821,232.886c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M406.493,233.644c0.337-0.191,0.405-0.702,0.156-1.14c-0.253-0.437-0.726-0.638-1.063-0.445c-0.337,0.189-0.405,0.702-0.153,1.14    C405.683,233.635,406.16,233.837,406.493,233.644"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="405.587" y1="232.059" x2="406.914" y2="231.301"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="407.821" y1="232.886" x2="406.493" y2="233.644"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="408.361" y1="231.359" x2="409.14" y2="230.913"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M406.323,131.265c-31.497-23.324-78.492-35.568-126.468-35.659c-87.045,0-157.892,40.044-160.298,90.093"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="429.933" y1="98.237" x2="429.933" y2="318.09"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.843" y1="97.393" x2="425.843" y2="113.009"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.84" y1="115.345" x2="425.84" y2="125.563"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.84" y1="127.91" x2="425.84" y2="140.466"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.84" y1="142.816" x2="425.84" y2="154.358"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.84" y1="156.685" x2="425.84" y2="167.884"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.836" y1="170.23" x2="425.836" y2="181.541"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.836" y1="183.889" x2="425.835" y2="194.898"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.835" y1="197.225" x2="425.835" y2="207.912"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.835" y1="210.406" x2="425.835" y2="221.1"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.835" y1="223.426" x2="425.835" y2="234.123"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.835" y1="236.501" x2="425.831" y2="247.982"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.831" y1="250.314" x2="425.831" y2="261.378"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.831" y1="263.705" x2="425.831" y2="275.254"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.831" y1="277.569" x2="425.831" y2="288.427"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.831" y1="290.747" x2="425.828" y2="301.979"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.828" y1="304.301" x2="425.828" y2="314.951"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="318.09" x2="428.936" y2="318.606"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="428.936" y1="318.606" x2="426.987" y2="317.483"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="318.09" x2="437.699" y2="322.574"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="438.361" y1="108.579" x2="438.361" y2="322.162"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="452.662" y1="313.728" x2="332.928" y2="398.235"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="453.73" y1="320.34" x2="453.73" y2="312.846"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="453.73" y1="312.846" x2="448.694" y2="305.855"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="448.694" y1="305.855" x2="445.066" y2="305.29"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="357.789" y1="70.494" x2="340.905" y2="93.033"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="362.633" y1="71.65" x2="362.633" y2="99.01"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="358.022" y1="71.466" x2="358.022" y2="97.553"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="426.396" y1="96.204" x2="396.205" y2="113.495"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="156.889" x2="437.291" y2="161.678"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="153.755" x2="438.361" y2="159.241"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="437.291" y1="161.678" x2="438.361" y2="161.222"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="209.876" x2="437.291" y2="214.666"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="206.744" x2="438.361" y2="212.228"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="437.291" y1="214.666" x2="438.361" y2="214.21"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.567" strokeLinecap="round" x1="445.066" y1="104.254" x2="445.066" y2="316.14"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="450.22" y1="312.888" x2="444.25" y2="317.139"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M444.25,317.139c-2.533-1.573-3.634-2.12-5.889-1.573"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="445.066" y1="104.254" x2="441.54" y2="101.665"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="440.79" y1="109.424" x2="440.79" y2="314.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="450.22" y1="312.888" x2="450.22" y2="312.043"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="450.22" y1="312.043" x2="445.066" y2="309.198"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="448.694" y1="305.855" x2="445.066" y2="308.109"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="428.936" y1="318.606" x2="433.685" y2="325.681"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="433.685" y1="325.681" x2="433.685" y2="327.12"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="446.025" y1="318.411" x2="446.025" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.192" y1="330.291" x2="429.192" y2="355.834"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="429.192" y1="355.834" x2="375.184" y2="368.413"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M424.846,353.498c0.261,0.26,0.652,0.328,1.049,0.226"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.182,352.425c0.109-0.424,0.007-0.801-0.233-1.045"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.308,350.747c-0.447-0.445-1.282-0.33-1.861,0.254c-0.581,0.588-0.689,1.422-0.239,1.864"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.308" y1="350.747" x2="426.948" y2="351.38"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="424.846" y1="353.498" x2="424.209" y2="352.865"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.619,351.867c-0.277-0.274-0.787-0.202-1.142,0.154c-0.354,0.361-0.421,0.87-0.147,1.145"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M425.873,353.702c0.274,0.274,0.787,0.202,1.142-0.154c0.354-0.358,0.419-0.87,0.144-1.142c-0.274-0.274-0.784-0.202-1.138,0.154    C425.663,352.921,425.6,353.43,425.873,353.702"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.182" y1="352.425" x2="426.619" y2="351.867"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.33" y1="353.165" x2="425.894" y2="353.725"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.135" y1="351.601" x2="424.495" y2="350.967"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M418.819,354.887c0.263,0.256,0.651,0.328,1.051,0.226"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M421.155,353.814c0.107-0.428,0.009-0.805-0.235-1.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M420.284,352.132c-0.451-0.442-1.282-0.33-1.864,0.258c-0.581,0.584-0.688,1.419-0.237,1.864"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="420.284" y1="352.132" x2="420.92" y2="352.765"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="418.819" y1="354.887" x2="418.183" y2="354.254"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M420.591,353.253c-0.274-0.272-0.784-0.202-1.138,0.158c-0.358,0.358-0.421,0.87-0.147,1.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M419.849,355.089c0.274,0.272,0.784,0.203,1.138-0.154c0.358-0.36,0.421-0.872,0.147-1.144c-0.274-0.27-0.784-0.202-1.142,0.158    C419.638,354.307,419.572,354.819,419.849,355.089"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="421.155" y1="353.814" x2="420.591" y2="353.253"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="419.305" y1="354.552" x2="419.87" y2="355.114"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="419.107" y1="352.99" x2="418.467" y2="352.357"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M424.975,345.522c0.261,0.26,0.652,0.328,1.051,0.225"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.311,344.448c0.109-0.424,0.009-0.803-0.233-1.045"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.438,342.77c-0.447-0.445-1.282-0.33-1.863,0.254c-0.579,0.584-0.688,1.419-0.237,1.864"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.438" y1="342.77" x2="427.078" y2="343.403"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="424.975" y1="345.522" x2="424.339" y2="344.889"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.747,343.891c-0.275-0.274-0.784-0.202-1.138,0.154c-0.358,0.36-0.423,0.87-0.147,1.143"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.005,345.727c0.274,0.272,0.784,0.2,1.138-0.156c0.358-0.361,0.421-0.87,0.147-1.142c-0.274-0.275-0.784-0.205-1.142,0.154    C425.794,344.943,425.729,345.453,426.005,345.727"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.311" y1="344.448" x2="426.747" y2="343.891"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.461" y1="345.189" x2="426.026" y2="345.746"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.263" y1="343.622" x2="424.625" y2="342.989"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M418.951,346.911c0.261,0.256,0.649,0.328,1.049,0.224"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M421.287,345.837c0.105-0.428,0.009-0.807-0.237-1.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M420.414,344.156c-0.451-0.442-1.282-0.33-1.863,0.258c-0.582,0.584-0.688,1.419-0.24,1.864"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="420.414" y1="344.156" x2="421.05" y2="344.789"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="418.951" y1="346.911" x2="418.311" y2="346.278"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M420.722,345.276c-0.275-0.272-0.786-0.202-1.14,0.158c-0.358,0.358-0.421,0.87-0.147,1.14"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M419.979,347.113c0.275,0.272,0.786,0.203,1.14-0.154c0.354-0.36,0.421-0.872,0.147-1.143c-0.275-0.272-0.786-0.202-1.144,0.158    C419.768,346.329,419.702,346.842,419.979,347.113"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="421.287" y1="345.837" x2="420.722" y2="345.276"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="419.435" y1="346.574" x2="420" y2="347.135"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="419.237" y1="345.011" x2="418.6" y2="344.378"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M424.846,337.74c0.261,0.258,0.652,0.328,1.049,0.224"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.182,336.667c0.109-0.424,0.007-0.803-0.233-1.047"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.308,334.988c-0.447-0.445-1.282-0.33-1.861,0.253c-0.581,0.588-0.689,1.422-0.239,1.866"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.308" y1="334.988" x2="426.948" y2="335.62"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="424.846" y1="337.74" x2="424.209" y2="337.107"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.619,336.109c-0.277-0.272-0.787-0.203-1.142,0.154c-0.354,0.36-0.421,0.872-0.147,1.144"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M425.873,337.945c0.274,0.272,0.787,0.202,1.142-0.158c0.354-0.36,0.419-0.868,0.144-1.144c-0.274-0.272-0.784-0.2-1.138,0.158    C425.663,337.161,425.6,337.672,425.873,337.945"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.182" y1="336.667" x2="426.619" y2="336.109"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.33" y1="337.407" x2="425.894" y2="337.964"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.135" y1="335.841" x2="424.495" y2="335.207"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M418.819,339.127c0.261,0.26,0.651,0.33,1.051,0.226"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M421.155,338.056c0.107-0.428,0.009-0.807-0.235-1.047"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M417.993,338.219c0.047,0.109,0.114,0.203,0.189,0.277"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="420.405" y1="336.497" x2="420.92" y2="337.009"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="418.819" y1="339.127" x2="418.183" y2="338.496"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M420.591,337.494c-0.274-0.272-0.784-0.202-1.138,0.158c-0.358,0.356-0.421,0.868-0.147,1.14"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M419.849,339.331c0.274,0.272,0.784,0.202,1.138-0.154c0.358-0.36,0.421-0.872,0.147-1.144c-0.274-0.272-0.784-0.203-1.142,0.158    C419.638,338.547,419.572,339.061,419.849,339.331"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="421.155" y1="338.056" x2="420.591" y2="337.494"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="419.305" y1="338.792" x2="419.87" y2="339.354"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="418.142" y1="448.083" x2="418.142" y2="358.409"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="432.964" y1="448.083" x2="432.964" y2="327.629"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="438.543" y1="323.692" x2="438.543" y2="337.275"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="438.543" y1="349.912" x2="438.543" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="414.194" y1="448.085" x2="414.194" y2="446.047"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="414.194" y1="446.047" x2="415.501" y2="445.295"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="415.501" y1="445.295" x2="418.142" y2="445.487"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="356.391" y1="381.676" x2="413.912" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.578" y1="376.602" x2="418.142" y2="439.595"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="360.921" y1="378.48" x2="418.142" y2="444.544"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M410.569,329.641c-3.427-2.512-7.028-4.897-10.316-6.865"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M395.374,319.944c-6.623-3.688-13.633-6.994-20.176-9.588"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,322.515c-8.571-6.256-18.238-11.719-27.825-15.944"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M408.323,326.089c-9.427-6.88-20.178-12.8-30.95-17.314"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.566,320.589c-8.575-6.165-18.189-11.563-27.683-15.748"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.566" y1="320.589" x2="410.566" y2="324.665"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.076" y1="321.396" x2="408.319" y2="322.511"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="410.566" y1="324.665" x2="408.323" y2="326.089"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.106" y1="324.097" x2="408.2" y2="322.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.713" y1="292.81" x2="399.713" y2="313.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="400.25" y1="320.636" x2="400.25" y2="322.776"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="295.7" x2="395.674" y2="305.711"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.674" y1="307.728" x2="395.674" y2="311.218"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="395.674" y1="317.888" x2="395.674" y2="319.768"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="395.674" y1="319.768" x2="395.374" y2="319.944"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M291.662,281.142c0-3.78-5.312-6.847-11.86-6.847c-6.552,0-11.859,3.068-11.859,6.847"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M267.943,281.142c0,3.783,5.307,6.847,11.859,6.847c6.547,0,11.86-3.064,11.86-6.847"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M290.115,280.17c0-3.288-4.616-5.958-10.313-5.958c-5.698,0-10.314,2.669-10.314,5.958s4.616,5.954,10.314,5.954    C285.499,286.124,290.115,283.458,290.115,280.17"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M267.943,285.972c0,3.78,5.307,6.847,11.859,6.847c6.547,0,11.86-3.067,11.86-6.847"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M291.711,290.07c0-0.775-0.226-1.522-0.614-2.169"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M268.474,288.138c-0.337,0.659-0.493,1.328-0.482,1.933"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M267.992,290.07c0,3.785,5.307,6.847,11.86,6.847c6.549,0,11.859-3.062,11.859-6.847"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M277.642,309.252c0.768,0.084,1.533,0.123,2.21,0.119c6.549,0,11.859-3.066,11.859-6.847"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="291.662" y1="281.142" x2="291.662" y2="285.972"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="291.711" y1="290.07" x2="291.711" y2="302.525"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="267.992" y1="294.145" x2="267.992" y2="290.07"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="267.943" y1="285.972" x2="267.943" y2="281.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M269.362,311.781c1.6,2.622,5.851,4.269,10.49,4.285c6.14,0,11.119-2.875,11.119-6.417"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="290.971" y1="309.649" x2="290.971" y2="304.903"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.642" y1="298.594" x2="277.642" y2="309.31"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="276.107" y1="315.694" x2="276.107" y2="316.927"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="265.73" y1="293.105" x2="277.642" y2="298.594"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.642" y1="298.594" x2="274.678" y2="300.713"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="265.73" y1="293.105" x2="262.299" y2="295.558"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M262.299,295.558c-0.88,0.619-0.712,2.187,1.449,2.655c2.161,0.465,4.195,2.075,5.509,2.794c1.31,0.719,3.729,0.919,5.421-0.295"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="277.642" y1="309.31" x2="274.678" y2="311.429"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M261.819,307.425c0.109,0.607,0.693,1.236,1.929,1.503c2.161,0.467,4.195,2.077,5.509,2.796c1.31,0.719,3.729,0.919,5.421-0.295"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="261.819" y1="307.425" x2="261.819" y2="296.561"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.117" y1="302.87" x2="271.117" y2="311.513"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="273.703" y1="302.87" x2="273.703" y2="311.513"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M276.107,316.927c0,1.191,1.677,2.159,3.744,2.159c2.066,0,3.739-0.968,3.739-2.159"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.591" y1="316.927" x2="283.591" y2="315.694"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.852" y1="316.066" x2="279.852" y2="319.086"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="263.304" y1="316.447" x2="283.975" y2="325.595"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.975" y1="325.595" x2="311.758" y2="304.585"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="291.711" y1="295.713" x2="311.758" y2="304.585"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="269.432" y1="311.815" x2="263.304" y2="316.447"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="263.707" y1="343.386" x2="284.378" y2="352.534"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="284.378" y1="352.534" x2="312.164" y2="331.524"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="263.304" y1="316.447" x2="263.707" y2="343.386"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.975" y1="325.595" x2="284.378" y2="352.534"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="311.758" y1="304.585" x2="312.164" y2="331.524"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="241.83" y1="347.344" x2="284.906" y2="366.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="306.408" y1="335.876" x2="317.575" y2="340.822"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="284.906" y1="366.414" x2="317.575" y2="341.711"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="263.508" y1="330.025" x2="241.83" y2="346.416"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="241.83" y1="346.416" x2="241.83" y2="347.344"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="317.575" y1="340.822" x2="317.575" y2="341.711"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M345.659,294.623c0-14.31-20.096-25.911-44.881-25.911s-44.879,11.602-44.879,25.911c0,4.739,2.203,9.178,5.937,12.877"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M263.171,308.759c1.621,1.438,3.446,2.754,5.249,3.816"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M311.987,319.712c20.143-2.997,33.551-13.434,33.672-25.089"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M341.403,294.623c0-12.952-18.189-23.456-40.624-23.456c-7.385,0-14.315,1.14-20.087,3.069"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M267.955,280.805c-7.717,6.11-9.574,13.657-6.137,20.462"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M311.945,317.173c17.749-2.929,29.347-12.237,29.458-22.549"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.35" y1="295.877" x2="263.371" y2="320.845"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M266.743,278.89c0,0.41,0.581,0.744,1.293,0.744c0.072,0,0.144-0.002,0.202-0.009"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.258,278.004c-0.177-0.309-0.677-0.505-1.222-0.505c-0.712,0-1.293,0.331-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="266.743" y1="278.243" x2="266.743" y2="278.89"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.132,278.146c0-0.351-0.491-0.633-1.096-0.633s-1.096,0.282-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    c0.291,0,0.554-0.065,0.752-0.174"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="268.054" y1="278.993" x2="268.054" y2="279.632"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M253.132,89.273c-0.442,0.04-0.754,0.261-0.756,0.514c0,0.288,0.402,0.517,0.9,0.517c0.051,0,0.102-0.002,0.146-0.004"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.968,89.618c0-0.286-0.403-0.521-0.9-0.521c-0.051,0-0.102,0.005-0.144,0.007"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.21,90.132c0.442-0.042,0.758-0.261,0.758-0.514"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.208" y1="90.132" x2="253.419" y2="90.3"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.924" y1="89.104" x2="253.132" y2="89.273"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M253.132,89.273c-0.442,0.04-0.754,0.261-0.756,0.514c0,0.288,0.402,0.517,0.9,0.517c0.051,0,0.102-0.002,0.146-0.004"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.968,89.618c0-0.286-0.403-0.521-0.9-0.521c-0.051,0-0.102,0.005-0.144,0.007"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.21,90.132c0.442-0.042,0.758-0.261,0.758-0.514"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.208" y1="90.132" x2="253.419" y2="90.3"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.924" y1="89.104" x2="253.132" y2="89.273"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M237.319,91.237c-0.403,0.06-0.67,0.27-0.672,0.503c0,0.288,0.405,0.517,0.901,0.517c0.081,0,0.161-0.004,0.232-0.016"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M240.204,91.472c0-0.286-0.405-0.521-0.901-0.521c-0.081,0-0.161,0.007-0.23,0.018"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.534,91.974c0.4-0.061,0.668-0.27,0.67-0.502"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="239.532" y1="91.974" x2="237.779" y2="92.242"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="239.075" y1="90.969" x2="237.319" y2="91.237"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M221.915,94.087c-0.363,0.075-0.586,0.272-0.588,0.488c0,0.288,0.403,0.517,0.9,0.517c0.116,0,0.221-0.011,0.316-0.03"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M224.83,94.208c0-0.288-0.402-0.519-0.9-0.519c-0.116,0-0.221,0.011-0.316,0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M224.242,94.696c0.363-0.077,0.584-0.272,0.588-0.488"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="224.242" y1="94.696" x2="222.541" y2="95.062"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="223.616" y1="93.722" x2="221.915" y2="94.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.069,97.795c-0.319,0.088-0.503,0.272-0.507,0.467c0,0.288,0.403,0.521,0.903,0.521c0.144,0,0.281-0.021,0.395-0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M209.994,97.803c0-0.288-0.402-0.521-0.9-0.521c-0.146,0-0.281,0.018-0.396,0.051"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M209.491,98.27c0.316-0.091,0.502-0.272,0.503-0.467"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="209.491" y1="98.27" x2="207.86" y2="98.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="208.698" y1="97.333" x2="207.069" y2="97.795"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M192.924,102.325c-0.275,0.096-0.428,0.265-0.428,0.442c0,0.286,0.403,0.519,0.9,0.519c0.175,0,0.34-0.026,0.475-0.075"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M195.841,102.216c0-0.286-0.402-0.517-0.901-0.517c-0.175,0-0.337,0.028-0.472,0.077"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M195.413,102.662c0.277-0.1,0.426-0.268,0.428-0.445"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="195.413" y1="102.662" x2="193.868" y2="103.211"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="194.469" y1="101.776" x2="192.924" y2="102.325"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M179.614,107.632c-0.23,0.103-0.354,0.258-0.354,0.416c0,0.288,0.405,0.519,0.9,0.519c0.207,0,0.396-0.039,0.549-0.105"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M182.506,107.411c0-0.286-0.405-0.517-0.901-0.517c-0.205,0-0.395,0.039-0.547,0.103"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M182.15,107.828c0.232-0.105,0.356-0.258,0.356-0.417"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="182.15" y1="107.828" x2="180.709" y2="108.462"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="181.058" y1="106.997" x2="179.614" y2="107.632"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M156.016,120.375c-0.153,0.098-0.228,0.221-0.226,0.346c0,0.288,0.402,0.519,0.9,0.519c0.27,0,0.51-0.068,0.677-0.177"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.791,119.933c0-0.286-0.402-0.517-0.9-0.517c-0.267,0-0.51,0.067-0.673,0.175"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.568,120.279c0.149-0.1,0.226-0.221,0.223-0.346"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="158.568" y1="120.279" x2="157.365" y2="121.063"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.218" y1="119.591" x2="156.016" y2="120.375"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.947,127.687c-0.116,0.093-0.17,0.198-0.168,0.303c0,0.288,0.403,0.519,0.9,0.519c0.302,0,0.565-0.082,0.73-0.214"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.643,127.14c0-0.286-0.403-0.519-0.901-0.519c-0.3,0-0.563,0.084-0.731,0.216"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.473,127.445c0.114-0.093,0.17-0.198,0.17-0.305"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="148.473" y1="127.445" x2="147.408" y2="128.296"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.01" y1="126.837" x2="145.947" y2="127.687"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M137.173,135.53c-0.086,0.084-0.123,0.175-0.121,0.261c0,0.288,0.403,0.523,0.898,0.523c0.333,0,0.623-0.103,0.78-0.261"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M139.765,134.888c0-0.288-0.403-0.521-0.9-0.521c-0.331,0-0.621,0.102-0.78,0.26"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M139.642,135.152c0.084-0.086,0.125-0.177,0.123-0.263"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="139.642" y1="135.152" x2="138.73" y2="136.055"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="138.085" y1="134.627" x2="137.173" y2="135.53"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M129.772,143.835c-0.058,0.07-0.082,0.146-0.082,0.217c0,0.288,0.402,0.519,0.9,0.519c0.363,0,0.675-0.121,0.819-0.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.248,143.1c0-0.288-0.405-0.521-0.903-0.521c-0.36,0-0.672,0.125-0.817,0.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.166,143.316c0.054-0.07,0.082-0.146,0.082-0.216"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="132.166" y1="143.316" x2="131.408" y2="144.268"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="130.527" y1="142.883" x2="129.772" y2="143.835"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.814,152.518c-0.033,0.056-0.051,0.114-0.049,0.17c0,0.288,0.405,0.517,0.903,0.517c0.391,0,0.724-0.144,0.851-0.349"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M126.157,151.696c0-0.288-0.402-0.521-0.901-0.521c-0.391,0-0.724,0.147-0.852,0.353"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M126.11,151.866c0.033-0.056,0.047-0.114,0.047-0.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="126.11" y1="151.866" x2="125.519" y2="152.857"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="124.403" y1="151.527" x2="123.814" y2="152.518"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M119.359,161.494c-0.014,0.042-0.023,0.084-0.023,0.123c0,0.288,0.405,0.517,0.9,0.517c0.426,0,0.78-0.168,0.877-0.398"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M121.558,160.598c0-0.288-0.405-0.523-0.903-0.523c-0.423,0-0.777,0.17-0.877,0.402"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M121.532,160.716c0.018-0.04,0.026-0.082,0.026-0.118"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="121.532" y1="160.716" x2="121.113" y2="161.736"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="119.778" y1="160.477" x2="119.359" y2="161.494"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M116.453,170.683c-0.007,0.025-0.009,0.049-0.009,0.068c0,0.288,0.405,0.523,0.9,0.523c0.458,0,0.833-0.195,0.896-0.452"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M118.491,169.715c0-0.288-0.405-0.521-0.9-0.521c-0.458,0-0.835,0.196-0.896,0.452"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M118.482,169.786c0.007-0.025,0.009-0.049,0.009-0.072"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="118.482" y1="169.786" x2="118.24" y2="170.821"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="116.695" y1="169.646" x2="116.453" y2="170.683"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M115.118,179.99c0,0.007,0,0.012,0,0.018c0,0.288,0.403,0.523,0.9,0.523c0.488,0,0.884-0.224,0.9-0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M116.986,178.963c0-0.286-0.403-0.521-0.9-0.521c-0.488,0-0.884,0.224-0.903,0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M116.986,178.98c0-0.005,0-0.011,0-0.018"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="116.986" y1="178.98" x2="116.918" y2="180.027"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="115.183" y1="178.945" x2="115.118" y2="179.99"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M117.172,189.295c0-0.011-0.004-0.019-0.004-0.03"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M115.369,189.326c0.032,0.279,0.426,0.489,0.9,0.489c0.498,0,0.903-0.233,0.903-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M117.058,188.219c-0.03-0.275-0.426-0.488-0.9-0.488c-0.498,0-0.901,0.23-0.901,0.517c0,0.014,0,0.023,0.004,0.033"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="117.058" y1="188.219" x2="117.168" y2="189.265"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="115.26" y1="188.283" x2="115.369" y2="189.326"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M118.998,198.525c0-0.032-0.005-0.058-0.011-0.084"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M117.205,198.607c0.072,0.254,0.451,0.435,0.889,0.438c0.5,0,0.903-0.233,0.903-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M118.698,197.406c-0.072-0.254-0.451-0.435-0.889-0.435c-0.498,0-0.903,0.23-0.903,0.517c0,0.032,0.005,0.058,0.012,0.084"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="118.698" y1="197.406" x2="118.987" y2="198.441"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="116.918" y1="197.573" x2="117.205" y2="198.607"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.383,207.605c0-0.049-0.011-0.093-0.03-0.133"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M120.609,207.736c0.103,0.233,0.468,0.386,0.87,0.388c0.498,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M121.89,206.459c-0.105-0.233-0.468-0.388-0.873-0.388c-0.495,0-0.9,0.233-0.9,0.521c0,0.046,0.012,0.093,0.03,0.13"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="121.89" y1="206.459" x2="122.353" y2="207.471"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="120.146" y1="206.722" x2="120.609" y2="207.736"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M127.29,216.449c0-0.065-0.021-0.126-0.054-0.182"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M125.547,216.63c0.133,0.209,0.472,0.337,0.844,0.339c0.496,0,0.9-0.232,0.9-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M126.602,215.286c-0.135-0.209-0.474-0.337-0.845-0.338c-0.498,0-0.9,0.232-0.9,0.519c0,0.065,0.021,0.126,0.054,0.182"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="126.602" y1="215.286" x2="127.236" y2="216.267"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="124.912" y1="215.65" x2="125.547" y2="216.63"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.679,224.975c0-0.084-0.033-0.161-0.093-0.228"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M131.967,225.203c0.156,0.184,0.472,0.289,0.81,0.289c0.496,0,0.901-0.23,0.901-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.792,223.805c-0.156-0.186-0.472-0.293-0.812-0.293c-0.495,0-0.9,0.233-0.9,0.519c0,0.086,0.033,0.161,0.093,0.228"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="132.792" y1="223.805" x2="133.586" y2="224.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="131.173" y1="224.259" x2="131.967" y2="225.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M141.482,233.097c0-0.102-0.049-0.196-0.133-0.274"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M139.814,233.367c0.17,0.16,0.463,0.249,0.766,0.249c0.5,0,0.901-0.233,0.901-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M140.396,231.932c-0.17-0.16-0.463-0.247-0.766-0.247c-0.5,0-0.901,0.232-0.901,0.517c0,0.102,0.049,0.196,0.133,0.275"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="140.396" y1="231.932" x2="141.349" y2="232.823"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="138.862" y1="232.478" x2="139.814" y2="233.367"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M150.627,240.737c0-0.119-0.067-0.228-0.182-0.314"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.008,241.05c0.177,0.135,0.445,0.207,0.717,0.207c0.5,0,0.901-0.235,0.901-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.346,239.59c-0.177-0.135-0.445-0.205-0.717-0.205c-0.5,0-0.901,0.232-0.901,0.517c0,0.119,0.067,0.23,0.182,0.317"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="149.346" y1="239.59" x2="150.444" y2="240.423"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.91" y1="240.219" x2="149.008" y2="241.05"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M172.578,254.287c0-0.153-0.116-0.293-0.303-0.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.078,254.676c0.174,0.089,0.386,0.133,0.6,0.133c0.495,0,0.9-0.235,0.9-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M170.915,253.205c-0.174-0.089-0.386-0.132-0.596-0.132c-0.498,0-0.903,0.235-0.903,0.521c0,0.154,0.118,0.295,0.303,0.389"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="170.915" y1="253.205" x2="172.274" y2="253.897"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.719" y1="253.983" x2="171.078" y2="254.676"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.17,260.069c0-0.175-0.146-0.328-0.372-0.423"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M183.741,260.49c0.163,0.067,0.349,0.1,0.528,0.096c0.5,0,0.901-0.232,0.901-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M183.329,259.033c-0.163-0.067-0.347-0.1-0.53-0.1c-0.496,0-0.9,0.235-0.9,0.523c0,0.17,0.146,0.326,0.374,0.421"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="183.329" y1="259.033" x2="184.798" y2="259.647"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="182.273" y1="259.876" x2="183.741" y2="260.49"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M198.685,265.106c0-0.189-0.177-0.356-0.447-0.449"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M197.33,265.557c0.146,0.047,0.303,0.07,0.452,0.07c0.498,0,0.903-0.233,0.903-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M196.67,264.131c-0.144-0.049-0.302-0.072-0.454-0.072c-0.496,0-0.901,0.233-0.901,0.521c0,0.189,0.177,0.356,0.449,0.447"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="196.67" y1="264.131" x2="198.238" y2="264.657"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="195.764" y1="265.027" x2="197.33" y2="265.557"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.204,88.21c-0.479,0.018-0.844,0.246-0.845,0.523c0,0.286,0.402,0.517,0.9,0.517c0.021,0,0.04,0,0.056,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.972,88.666c0-0.288-0.405-0.521-0.903-0.521c-0.018,0-0.037,0-0.054,0.004"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.126,89.187c0.477-0.018,0.845-0.246,0.845-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.126" y1="89.187" x2="269.315" y2="89.25"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.014" y1="88.148" x2="269.204" y2="88.21"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.204,88.21c-0.479,0.018-0.844,0.246-0.845,0.523c0,0.286,0.402,0.517,0.9,0.517c0.021,0,0.04,0,0.056,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.972,88.666c0-0.288-0.405-0.521-0.903-0.521c-0.018,0-0.037,0-0.054,0.004"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.126,89.187c0.477-0.018,0.845-0.246,0.845-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.126" y1="89.187" x2="269.315" y2="89.25"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.014" y1="88.148" x2="269.204" y2="88.21"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.376,88.066c-0.011,0-0.021,0-0.032,0c-0.498,0-0.9,0.232-0.9,0.521c0,0.281,0.382,0.509,0.868,0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M288.06,88.624c0-0.279-0.386-0.509-0.868-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.123,89.145c0.014,0,0.026,0,0.035,0c0.496,0,0.901-0.233,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.123" y1="89.145" x2="285.313" y2="89.104"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.191" y1="88.103" x2="285.376" y2="88.066"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.376,88.066c-0.011,0-0.021,0-0.032,0c-0.498,0-0.9,0.232-0.9,0.521c0,0.281,0.382,0.509,0.868,0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M288.06,88.624c0-0.279-0.386-0.509-0.868-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.123,89.145c0.014,0,0.026,0,0.035,0c0.496,0,0.901-0.233,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.123" y1="89.145" x2="285.313" y2="89.104"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.191" y1="88.103" x2="285.376" y2="88.066"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M301.498,88.836c-0.042-0.002-0.084-0.004-0.119-0.004c-0.498,0-0.903,0.232-0.903,0.519c0,0.261,0.339,0.479,0.78,0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.076,89.492c0-0.261-0.337-0.481-0.782-0.514"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M303.053,90.008c0.042,0.005,0.084,0.007,0.123,0.007c0.495,0,0.9-0.235,0.9-0.523"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.053" y1="90.008" x2="301.256" y2="89.869"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.295" y1="88.978" x2="301.498" y2="88.836"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M301.498,88.836c-0.042-0.002-0.084-0.004-0.119-0.004c-0.498,0-0.903,0.232-0.903,0.519c0,0.261,0.339,0.479,0.78,0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.076,89.492c0-0.261-0.337-0.481-0.782-0.514"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M303.053,90.008c0.042,0.005,0.084,0.007,0.123,0.007c0.495,0,0.9-0.235,0.9-0.523"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.053" y1="90.008" x2="301.256" y2="89.869"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.295" y1="88.978" x2="301.498" y2="88.836"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M317.408,90.514c-0.07-0.011-0.14-0.012-0.205-0.012c-0.498,0-0.903,0.232-0.903,0.519c0,0.244,0.293,0.451,0.696,0.505"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M319.869,91.263c0-0.246-0.293-0.449-0.693-0.507"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M318.761,91.768c0.072,0.011,0.144,0.016,0.207,0.016c0.496,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="318.761" y1="91.768" x2="316.995" y2="91.526"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="319.176" y1="90.756" x2="317.412" y2="90.514"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M317.408,90.514c-0.07-0.011-0.14-0.012-0.205-0.012c-0.498,0-0.903,0.232-0.903,0.519c0,0.244,0.293,0.451,0.696,0.505"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M319.869,91.263c0-0.246-0.293-0.449-0.693-0.507"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M318.761,91.768c0.072,0.011,0.144,0.016,0.207,0.016c0.496,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="318.761" y1="91.768" x2="316.995" y2="91.526"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="319.176" y1="90.756" x2="317.412" y2="90.514"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M332.962,93.086c-0.102-0.019-0.202-0.026-0.295-0.026c-0.498,0-0.901,0.233-0.901,0.521c0,0.226,0.253,0.417,0.61,0.489"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M335.285,93.919c0-0.226-0.253-0.421-0.609-0.491"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M334.091,94.41c0.098,0.023,0.198,0.032,0.291,0.03c0.498,0,0.903-0.233,0.903-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.091" y1="94.41" x2="332.376" y2="94.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.677" y1="93.428" x2="332.962" y2="93.086"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M332.962,93.086c-0.102-0.019-0.202-0.026-0.295-0.026c-0.498,0-0.901,0.233-0.901,0.521c0,0.226,0.253,0.417,0.61,0.489"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M335.285,93.919c0-0.226-0.253-0.421-0.609-0.491"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M334.091,94.41c0.098,0.023,0.198,0.032,0.291,0.03c0.498,0,0.903-0.233,0.903-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.091" y1="94.41" x2="332.376" y2="94.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.677" y1="93.428" x2="332.962" y2="93.086"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.001,96.525c-0.126-0.033-0.254-0.046-0.375-0.046c-0.498,0-0.903,0.232-0.903,0.519c0,0.209,0.216,0.388,0.528,0.475"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M350.176,97.435c0-0.21-0.214-0.388-0.526-0.472"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.899,97.907c0.123,0.033,0.253,0.049,0.375,0.049c0.496,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="348.899" y1="97.907" x2="347.25" y2="97.47"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="349.649" y1="96.963" x2="348.001" y2="96.525"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.001,96.525c-0.126-0.033-0.254-0.046-0.375-0.046c-0.498,0-0.903,0.232-0.903,0.519c0,0.209,0.216,0.388,0.528,0.475"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M350.176,97.435c0-0.21-0.214-0.388-0.526-0.472"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.899,97.907c0.123,0.033,0.253,0.049,0.375,0.049c0.496,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="348.899" y1="97.907" x2="347.25" y2="97.47"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="349.649" y1="96.963" x2="348.001" y2="96.525"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M362.384,100.799c-0.147-0.049-0.303-0.07-0.454-0.07c-0.498,0-0.901,0.232-0.901,0.519c0,0.191,0.177,0.36,0.447,0.451"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M364.399,101.778c0-0.193-0.181-0.358-0.451-0.452"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M363.044,102.225c0.144,0.049,0.303,0.074,0.454,0.072c0.498,0,0.901-0.233,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.044" y1="102.225" x2="361.474" y2="101.699"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.949" y1="101.325" x2="362.384" y2="100.799"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M362.384,100.799c-0.147-0.049-0.303-0.07-0.454-0.07c-0.498,0-0.901,0.232-0.901,0.519c0,0.191,0.177,0.36,0.447,0.451"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M364.399,101.778c0-0.193-0.181-0.358-0.451-0.452"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M363.044,102.225c0.144,0.049,0.303,0.074,0.454,0.072c0.498,0,0.901-0.233,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.044" y1="102.225" x2="361.474" y2="101.699"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.949" y1="101.325" x2="362.384" y2="100.799"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M375.969,105.866c-0.16-0.067-0.346-0.1-0.528-0.1c-0.496,0-0.898,0.235-0.898,0.523c0,0.174,0.144,0.326,0.372,0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M377.816,106.902c0-0.175-0.147-0.328-0.375-0.423"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M376.387,107.323c0.16,0.067,0.345,0.1,0.526,0.096c0.498,0,0.903-0.23,0.903-0.517"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="376.387" y1="107.323" x2="374.915" y2="106.709"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="377.441" y1="106.48" x2="375.969" y2="105.866"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M375.969,105.866c-0.16-0.067-0.346-0.1-0.528-0.1c-0.496,0-0.898,0.235-0.898,0.523c0,0.174,0.144,0.326,0.372,0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M377.816,106.902c0-0.175-0.147-0.328-0.375-0.423"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M376.387,107.323c0.16,0.067,0.345,0.1,0.526,0.096c0.498,0,0.903-0.23,0.903-0.517"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="376.387" y1="107.323" x2="374.915" y2="106.709"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="377.441" y1="106.48" x2="375.969" y2="105.866"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.634,111.676c-0.174-0.086-0.388-0.128-0.598-0.128c-0.498,0-0.9,0.232-0.9,0.517c0,0.158,0.116,0.295,0.305,0.393"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.298,112.762c0-0.156-0.118-0.295-0.307-0.391"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.799,113.151c0.172,0.086,0.384,0.13,0.598,0.13c0.498,0,0.901-0.232,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.799" y1="113.151" x2="387.441" y2="112.459"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="389.991" y1="112.371" x2="388.634" y2="111.676"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.634,111.676c-0.174-0.086-0.388-0.128-0.598-0.128c-0.498,0-0.9,0.232-0.9,0.517c0,0.158,0.116,0.295,0.305,0.393"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.298,112.762c0-0.156-0.118-0.295-0.307-0.391"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.799,113.151c0.172,0.086,0.384,0.13,0.598,0.13c0.498,0,0.901-0.232,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.799" y1="113.151" x2="387.441" y2="112.459"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="389.991" y1="112.371" x2="388.634" y2="111.676"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M270.469,59.726c-0.482,0.011-0.863,0.24-0.863,0.517c0,0.288,0.403,0.521,0.903,0.521c0.012,0,0.025,0,0.037,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M273.222,60.201c0-0.288-0.403-0.519-0.903-0.519c-0.012,0-0.025,0-0.037,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M272.359,60.722c0.482-0.014,0.863-0.244,0.863-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="272.359" y1="60.722" x2="270.544" y2="60.764"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="272.284" y1="59.682" x2="270.469" y2="59.726"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M270.469,59.726c-0.482,0.011-0.863,0.24-0.863,0.517c0,0.288,0.403,0.521,0.903,0.521c0.012,0,0.025,0,0.037,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M273.222,60.201c0-0.288-0.403-0.519-0.903-0.519c-0.012,0-0.025,0-0.037,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M272.359,60.722c0.482-0.014,0.863-0.244,0.863-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="272.359" y1="60.722" x2="270.544" y2="60.764"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="272.284" y1="59.682" x2="270.469" y2="59.726"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M253.723,60.531c-0.458,0.032-0.793,0.256-0.793,0.517c0,0.288,0.403,0.519,0.9,0.519c0.04,0,0.075-0.002,0.11-0.002"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M256.533,60.922c0-0.286-0.405-0.521-0.9-0.521c-0.04,0-0.079,0.002-0.11,0.004"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.74,61.439c0.458-0.033,0.793-0.256,0.793-0.517"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.738" y1="61.439" x2="253.94" y2="61.565"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.522" y1="60.404" x2="253.723" y2="60.531"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M253.723,60.531c-0.458,0.032-0.793,0.256-0.793,0.517c0,0.288,0.403,0.519,0.9,0.519c0.04,0,0.075-0.002,0.11-0.002"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M256.533,60.922c0-0.286-0.405-0.521-0.9-0.521c-0.04,0-0.079,0.002-0.11,0.004"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.74,61.439c0.458-0.033,0.793-0.256,0.793-0.517"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.738" y1="61.439" x2="253.94" y2="61.565"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.522" y1="60.404" x2="253.723" y2="60.531"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M237.135,62.093c-0.426,0.047-0.719,0.265-0.721,0.509c0,0.288,0.402,0.523,0.9,0.523c0.063,0,0.125-0.007,0.177-0.011"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.994,62.397c0-0.289-0.405-0.521-0.903-0.521c-0.063,0-0.125,0.004-0.177,0.011"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.269,62.905c0.426-0.051,0.723-0.267,0.724-0.509"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="239.269" y1="62.905" x2="237.491" y2="63.114"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.913" y1="61.886" x2="237.135" y2="62.093"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M237.135,62.093c-0.426,0.047-0.719,0.265-0.721,0.509c0,0.288,0.402,0.523,0.9,0.523c0.063,0,0.125-0.007,0.177-0.011"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.994,62.397c0-0.289-0.405-0.521-0.903-0.521c-0.063,0-0.125,0.004-0.177,0.011"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.269,62.905c0.426-0.051,0.723-0.267,0.724-0.509"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="239.269" y1="62.905" x2="237.491" y2="63.114"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="238.913" y1="61.886" x2="237.135" y2="62.093"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M220.812,64.4c-0.393,0.067-0.651,0.274-0.652,0.503c0,0.288,0.402,0.517,0.9,0.517c0.089,0,0.174-0.007,0.249-0.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M223.704,64.615c0-0.288-0.402-0.521-0.901-0.521c-0.086,0-0.17,0.009-0.247,0.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M223.052,65.115c0.395-0.067,0.651-0.27,0.652-0.5"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="223.052" y1="65.115" x2="221.307" y2="65.401"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="222.559" y1="64.114" x2="220.812" y2="64.4"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M220.812,64.4c-0.393,0.067-0.651,0.274-0.652,0.503c0,0.288,0.402,0.517,0.9,0.517c0.089,0,0.174-0.007,0.249-0.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M223.704,64.615c0-0.288-0.402-0.521-0.901-0.521c-0.086,0-0.17,0.009-0.247,0.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M223.052,65.115c0.395-0.067,0.651-0.27,0.652-0.5"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="223.052" y1="65.115" x2="221.307" y2="65.401"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="222.559" y1="64.114" x2="220.812" y2="64.4"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.854,67.443c-0.363,0.075-0.584,0.275-0.586,0.488c0,0.288,0.402,0.521,0.9,0.521c0.114,0,0.221-0.014,0.316-0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.769,67.564c0-0.288-0.403-0.519-0.9-0.519c-0.114,0-0.221,0.011-0.314,0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.183,68.051c0.36-0.077,0.584-0.272,0.586-0.488"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="207.183" y1="68.051" x2="205.482" y2="68.418"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.555" y1="67.078" x2="204.854" y2="67.443"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.854,67.443c-0.363,0.075-0.584,0.275-0.586,0.488c0,0.288,0.402,0.521,0.9,0.521c0.114,0,0.221-0.014,0.316-0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.769,67.564c0-0.288-0.403-0.519-0.9-0.519c-0.114,0-0.221,0.011-0.314,0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.183,68.051c0.36-0.077,0.584-0.272,0.586-0.488"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="207.183" y1="68.051" x2="205.482" y2="68.418"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.555" y1="67.078" x2="204.854" y2="67.443"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M189.355,71.196c-0.324,0.089-0.519,0.275-0.521,0.472c0,0.288,0.405,0.521,0.903,0.521c0.137,0,0.267-0.018,0.379-0.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M192.284,71.227c0-0.286-0.403-0.521-0.903-0.521c-0.137,0-0.267,0.018-0.379,0.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M191.763,71.699c0.326-0.089,0.517-0.275,0.521-0.472"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="191.761" y1="71.699" x2="190.116" y2="72.139"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="191.004" y1="70.756" x2="189.357" y2="71.196"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M189.355,71.196c-0.324,0.089-0.519,0.275-0.521,0.472c0,0.288,0.405,0.521,0.903,0.521c0.137,0,0.267-0.018,0.379-0.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M192.284,71.227c0-0.286-0.403-0.521-0.903-0.521c-0.137,0-0.267,0.018-0.379,0.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M191.763,71.699c0.326-0.089,0.517-0.275,0.521-0.472"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="191.761" y1="71.699" x2="190.116" y2="72.139"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="191.004" y1="70.756" x2="189.357" y2="71.196"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M174.416,75.64c-0.291,0.096-0.454,0.27-0.458,0.452c0,0.288,0.405,0.523,0.903,0.523c0.161,0,0.316-0.025,0.442-0.067"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M177.341,75.579c0-0.286-0.402-0.519-0.901-0.519c-0.163,0-0.314,0.025-0.44,0.067"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M176.885,76.035c0.289-0.098,0.454-0.27,0.456-0.456"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="176.882" y1="76.035" x2="175.301" y2="76.549"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="175.999" y1="75.126" x2="174.417" y2="75.64"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M174.416,75.64c-0.291,0.096-0.454,0.27-0.458,0.452c0,0.288,0.405,0.523,0.903,0.523c0.161,0,0.316-0.025,0.442-0.067"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M177.341,75.579c0-0.286-0.402-0.519-0.901-0.519c-0.163,0-0.314,0.025-0.44,0.067"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M176.885,76.035c0.289-0.098,0.454-0.27,0.456-0.456"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="176.882" y1="76.035" x2="175.301" y2="76.549"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="175.999" y1="75.126" x2="174.417" y2="75.64"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M160.127,80.747c-0.254,0.102-0.395,0.263-0.398,0.433c0,0.286,0.405,0.521,0.903,0.521c0.188,0,0.361-0.035,0.503-0.088"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.039,80.596c0-0.288-0.402-0.521-0.901-0.521c-0.188,0-0.363,0.033-0.502,0.089"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.64,81.028c0.258-0.1,0.396-0.263,0.398-0.431"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.639" y1="81.028" x2="161.136" y2="81.614"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.635" y1="80.165" x2="160.131" y2="80.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M160.127,80.747c-0.254,0.102-0.395,0.263-0.398,0.433c0,0.286,0.405,0.521,0.903,0.521c0.188,0,0.361-0.035,0.503-0.088"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.039,80.596c0-0.288-0.402-0.521-0.901-0.521c-0.188,0-0.363,0.033-0.502,0.089"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.64,81.028c0.258-0.1,0.396-0.263,0.398-0.431"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.639" y1="81.028" x2="161.136" y2="81.614"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.635" y1="80.165" x2="160.131" y2="80.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M146.579,86.489c-0.225,0.102-0.34,0.253-0.34,0.405c0,0.288,0.403,0.523,0.9,0.523c0.214,0,0.407-0.044,0.561-0.116"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.462,86.246c0-0.288-0.405-0.521-0.903-0.521c-0.212,0-0.407,0.042-0.558,0.114"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.12,86.652c0.223-0.103,0.342-0.254,0.342-0.407"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="148.001" y1="85.839" x2="146.58" y2="86.489"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M146.579,86.489c-0.225,0.102-0.34,0.253-0.34,0.405c0,0.288,0.403,0.523,0.9,0.523c0.214,0,0.407-0.044,0.561-0.116"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.462,86.246c0-0.288-0.405-0.521-0.903-0.521c-0.212,0-0.407,0.042-0.558,0.114"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.12,86.652c0.223-0.103,0.342-0.254,0.342-0.407"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="148.001" y1="85.839" x2="146.58" y2="86.489"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.848,92.822c-0.189,0.102-0.284,0.244-0.284,0.381c0,0.288,0.402,0.521,0.9,0.521c0.239,0,0.452-0.051,0.616-0.14"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.694,92.493c0-0.288-0.405-0.523-0.903-0.523c-0.235,0-0.451,0.053-0.612,0.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.405,92.873c0.191-0.103,0.289-0.244,0.289-0.381"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="136.405" y1="92.873" x2="135.077" y2="93.584"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="135.18" y1="92.109" x2="133.849" y2="92.822"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.848,92.822c-0.189,0.102-0.284,0.244-0.284,0.381c0,0.288,0.402,0.521,0.9,0.521c0.239,0,0.452-0.051,0.616-0.14"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.694,92.493c0-0.288-0.405-0.523-0.903-0.523c-0.235,0-0.451,0.053-0.612,0.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.405,92.873c0.191-0.103,0.289-0.244,0.289-0.381"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="136.405" y1="92.873" x2="135.077" y2="93.584"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="135.18" y1="92.109" x2="133.849" y2="92.822"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.02,99.717c-0.158,0.098-0.235,0.224-0.235,0.349c0,0.288,0.402,0.523,0.901,0.523c0.261,0,0.498-0.067,0.661-0.168"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.815,99.298c0-0.288-0.405-0.523-0.903-0.523c-0.263,0-0.496,0.067-0.663,0.168"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.577,99.647c0.158-0.098,0.239-0.224,0.239-0.349"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="124.577" y1="99.65" x2="123.347" y2="100.42"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.251" y1="98.943" x2="122.02" y2="99.717"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.02,99.717c-0.158,0.098-0.235,0.224-0.235,0.349c0,0.288,0.402,0.523,0.901,0.523c0.261,0,0.498-0.067,0.661-0.168"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.815,99.298c0-0.288-0.405-0.523-0.903-0.523c-0.263,0-0.496,0.067-0.663,0.168"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.577,99.647c0.158-0.098,0.239-0.224,0.239-0.349"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="124.577" y1="99.65" x2="123.347" y2="100.42"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.251" y1="98.943" x2="122.02" y2="99.717"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M111.165,107.123c-0.128,0.095-0.191,0.21-0.191,0.319c0,0.288,0.403,0.523,0.9,0.523c0.289,0,0.544-0.081,0.71-0.202"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.896,106.618c0-0.288-0.405-0.517-0.9-0.517c-0.289,0-0.544,0.077-0.71,0.196"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.705,106.939c0.13-0.095,0.191-0.207,0.191-0.321"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="113.705" y1="106.939" x2="112.584" y2="107.764"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.286" y1="106.297" x2="111.165" y2="107.123"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M111.165,107.123c-0.128,0.095-0.191,0.21-0.191,0.319c0,0.288,0.403,0.523,0.9,0.523c0.289,0,0.544-0.081,0.71-0.202"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.896,106.618c0-0.288-0.405-0.517-0.9-0.517c-0.289,0-0.544,0.077-0.71,0.196"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.705,106.939c0.13-0.095,0.191-0.207,0.191-0.321"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="113.705" y1="106.939" x2="112.584" y2="107.764"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.286" y1="106.297" x2="111.165" y2="107.123"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M101.349,114.998c-0.103,0.091-0.149,0.191-0.149,0.288c0,0.288,0.402,0.521,0.9,0.521c0.314,0,0.588-0.091,0.752-0.233"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.008,114.414c0-0.286-0.405-0.521-0.903-0.521c-0.312,0-0.588,0.093-0.751,0.232"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M103.855,114.702c0.103-0.091,0.153-0.191,0.153-0.288"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="103.855" y1="114.703" x2="102.852" y2="115.573"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.354" y1="114.125" x2="101.349" y2="114.998"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M101.349,114.998c-0.103,0.091-0.149,0.191-0.149,0.288c0,0.288,0.402,0.521,0.9,0.521c0.314,0,0.588-0.091,0.752-0.233"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.008,114.414c0-0.286-0.405-0.521-0.903-0.521c-0.312,0-0.588,0.093-0.751,0.232"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M103.855,114.702c0.103-0.091,0.153-0.191,0.153-0.288"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="103.855" y1="114.703" x2="102.852" y2="115.573"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.354" y1="114.125" x2="101.349" y2="114.998"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M92.634,123.296c-0.079,0.082-0.114,0.168-0.114,0.253c0,0.288,0.405,0.521,0.901,0.521c0.338,0,0.631-0.107,0.789-0.268"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.207,122.631c0-0.286-0.405-0.517-0.903-0.517c-0.335,0-0.63,0.105-0.787,0.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.093,122.883c0.077-0.079,0.114-0.168,0.114-0.253"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="95.093" y1="122.885" x2="94.211" y2="123.801"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="93.516" y1="122.378" x2="92.634" y2="123.296"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M92.634,123.296c-0.079,0.082-0.114,0.168-0.114,0.253c0,0.288,0.405,0.521,0.901,0.521c0.338,0,0.631-0.107,0.789-0.268"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.207,122.631c0-0.286-0.405-0.517-0.903-0.517c-0.335,0-0.63,0.105-0.787,0.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.093,122.883c0.077-0.079,0.114-0.168,0.114-0.253"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="95.093" y1="122.885" x2="94.211" y2="123.801"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="93.516" y1="122.378" x2="92.634" y2="123.296"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.075,131.961c-0.056,0.07-0.082,0.146-0.082,0.214c0,0.288,0.405,0.523,0.901,0.523c0.361,0,0.675-0.125,0.821-0.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.55,131.223c0-0.286-0.402-0.517-0.901-0.517c-0.361,0-0.673,0.121-0.817,0.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.471,131.44c0.054-0.07,0.081-0.146,0.079-0.217"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="87.467" y1="131.44" x2="86.715" y2="132.393"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.831" y1="131.009" x2="85.075" y2="131.961"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.075,131.961c-0.056,0.07-0.082,0.146-0.082,0.214c0,0.288,0.405,0.523,0.901,0.523c0.361,0,0.675-0.125,0.821-0.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.55,131.223c0-0.286-0.402-0.517-0.901-0.517c-0.361,0-0.673,0.121-0.817,0.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.471,131.44c0.054-0.07,0.081-0.146,0.079-0.217"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="87.467" y1="131.44" x2="86.715" y2="132.393"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.831" y1="131.009" x2="85.075" y2="131.961"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M78.714,140.943c-0.037,0.06-0.054,0.119-0.053,0.177c0,0.288,0.402,0.521,0.901,0.521c0.386,0,0.716-0.14,0.845-0.344"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.087,140.136c0-0.288-0.405-0.519-0.901-0.519c-0.386,0-0.716,0.139-0.845,0.34"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.03,140.313c0.04-0.06,0.058-0.119,0.056-0.177"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="81.03" y1="140.317" x2="80.408" y2="141.299"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="79.34" y1="139.957" x2="78.714" y2="140.943"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M78.714,140.943c-0.037,0.06-0.054,0.119-0.053,0.177c0,0.288,0.402,0.521,0.901,0.521c0.386,0,0.716-0.14,0.845-0.344"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.087,140.136c0-0.288-0.405-0.519-0.901-0.519c-0.386,0-0.716,0.139-0.845,0.34"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.03,140.313c0.04-0.06,0.058-0.119,0.056-0.177"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="81.03" y1="140.317" x2="80.408" y2="141.299"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="79.34" y1="139.957" x2="78.714" y2="140.943"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.596,150.184c-0.025,0.049-0.035,0.095-0.035,0.139c0,0.289,0.403,0.521,0.903,0.521c0.412,0,0.758-0.161,0.866-0.381"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.853,149.314c0-0.286-0.403-0.521-0.903-0.521c-0.412,0-0.758,0.161-0.866,0.381"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.82,149.452c0.023-0.046,0.033-0.095,0.033-0.139"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="75.82" y1="149.452" x2="75.33" y2="150.463"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="74.083" y1="149.174" x2="73.596" y2="150.184"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.596,150.184c-0.025,0.049-0.035,0.095-0.035,0.139c0,0.289,0.403,0.521,0.903,0.521c0.412,0,0.758-0.161,0.866-0.381"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.853,149.314c0-0.286-0.403-0.521-0.903-0.521c-0.412,0-0.758,0.161-0.866,0.381"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.82,149.452c0.023-0.046,0.033-0.095,0.033-0.139"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="75.82" y1="149.452" x2="75.33" y2="150.463"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="74.083" y1="149.174" x2="73.596" y2="150.184"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.746,159.628c-0.012,0.035-0.018,0.068-0.018,0.1c0,0.288,0.403,0.519,0.903,0.519c0.435,0,0.8-0.179,0.884-0.419"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.881,158.7c0-0.286-0.403-0.521-0.9-0.521c-0.437,0-0.802,0.179-0.886,0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.865,158.8c0.011-0.037,0.019-0.068,0.016-0.1"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="71.865" y1="158.802" x2="71.514" y2="159.828"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="70.095" y1="158.6" x2="69.746" y2="159.628"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.746,159.628c-0.012,0.035-0.018,0.068-0.018,0.1c0,0.288,0.403,0.519,0.903,0.519c0.435,0,0.8-0.179,0.884-0.419"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.881,158.7c0-0.286-0.403-0.521-0.9-0.521c-0.437,0-0.802,0.179-0.886,0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.865,158.8c0.011-0.037,0.019-0.068,0.016-0.1"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="71.865" y1="158.802" x2="71.514" y2="159.828"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="70.095" y1="158.6" x2="69.746" y2="159.628"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.193,169.218c-0.004,0.023-0.005,0.04-0.005,0.058c0,0.288,0.403,0.521,0.903,0.521c0.46,0,0.84-0.2,0.893-0.461"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.201,168.238c0-0.288-0.405-0.521-0.901-0.521c-0.463,0-0.842,0.2-0.896,0.461"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.192,168.297c0.005-0.023,0.009-0.042,0.009-0.06"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="69.192" y1="168.297" x2="68.983" y2="169.339"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.403" y1="168.178" x2="67.196" y2="169.216"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.193,169.218c-0.004,0.023-0.005,0.04-0.005,0.058c0,0.288,0.403,0.521,0.903,0.521c0.46,0,0.84-0.2,0.893-0.461"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.201,168.238c0-0.288-0.405-0.521-0.901-0.521c-0.463,0-0.842,0.2-0.896,0.461"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.192,168.297c0.005-0.023,0.009-0.042,0.009-0.06"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="69.192" y1="168.297" x2="68.983" y2="169.339"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.403" y1="168.178" x2="67.196" y2="169.216"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M65.954,178.896c-0.004,0.007-0.004,0.011-0.004,0.016c0,0.288,0.405,0.523,0.903,0.523c0.486,0,0.882-0.225,0.901-0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.821,177.866c0-0.286-0.403-0.521-0.9-0.521c-0.486,0-0.882,0.224-0.901,0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.821,177.884c0-0.007,0-0.011,0-0.018"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.821" y1="177.886" x2="67.756" y2="178.931"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="66.019" y1="177.849" x2="65.954" y2="178.894"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M65.954,178.896c-0.004,0.007-0.004,0.011-0.004,0.016c0,0.288,0.405,0.523,0.903,0.523c0.486,0,0.882-0.225,0.901-0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.821,177.866c0-0.286-0.403-0.521-0.9-0.521c-0.486,0-0.882,0.224-0.901,0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.821,177.884c0-0.007,0-0.011,0-0.018"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.821" y1="177.886" x2="67.756" y2="178.931"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="66.019" y1="177.849" x2="65.954" y2="178.894"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.831,188.575c0-0.009,0-0.018-0.002-0.025"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M66.03,188.598c0.021,0.279,0.421,0.498,0.901,0.498c0.496,0,0.9-0.233,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.756,187.506c-0.021-0.279-0.421-0.498-0.901-0.498c-0.496,0-0.9,0.235-0.9,0.523c0,0.007,0,0.016,0.002,0.019"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.756" y1="187.506" x2="67.829" y2="188.553"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="65.956" y1="187.549" x2="66.03" y2="188.598"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.831,188.575c0-0.009,0-0.018-0.002-0.025"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M66.03,188.598c0.021,0.279,0.421,0.498,0.901,0.498c0.496,0,0.9-0.233,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.756,187.506c-0.021-0.279-0.421-0.498-0.901-0.498c-0.496,0-0.9,0.235-0.9,0.523c0,0.007,0,0.016,0.002,0.019"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.756" y1="187.506" x2="67.829" y2="188.553"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="65.956" y1="187.549" x2="66.03" y2="188.598"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.222,198.204c0-0.023-0.004-0.044-0.005-0.065"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.428,198.265c0.053,0.263,0.442,0.456,0.893,0.46c0.496,0,0.901-0.233,0.901-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M68.997,197.101c-0.056-0.263-0.442-0.456-0.896-0.456c-0.496,0-0.898,0.233-0.898,0.517c0,0.023,0,0.047,0.005,0.065"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="68.999" y1="197.101" x2="69.217" y2="198.143"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.208" y1="197.227" x2="67.426" y2="198.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M69.222,198.204c0-0.023-0.004-0.044-0.005-0.065"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M67.428,198.265c0.053,0.263,0.442,0.456,0.893,0.46c0.496,0,0.901-0.233,0.901-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M68.997,197.101c-0.056-0.263-0.442-0.456-0.896-0.456c-0.496,0-0.898,0.233-0.898,0.517c0,0.023,0,0.047,0.005,0.065"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="68.999" y1="197.101" x2="69.217" y2="198.143"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="67.208" y1="197.227" x2="67.426" y2="198.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.916,207.74c0-0.035-0.005-0.074-0.019-0.105"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M70.132,207.843c0.084,0.246,0.46,0.414,0.882,0.416c0.496,0,0.901-0.232,0.901-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.54,206.61c-0.088-0.246-0.46-0.417-0.886-0.419c-0.496,0-0.9,0.235-0.9,0.521c0,0.037,0.005,0.074,0.018,0.103"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="71.54" y1="206.612" x2="71.896" y2="207.638"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="69.773" y1="206.815" x2="70.132" y2="207.843"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.916,207.74c0-0.035-0.005-0.074-0.019-0.105"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M70.132,207.843c0.084,0.246,0.46,0.414,0.882,0.416c0.496,0,0.901-0.232,0.901-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.54,206.61c-0.088-0.246-0.46-0.417-0.886-0.419c-0.496,0-0.9,0.235-0.9,0.521c0,0.037,0.005,0.074,0.018,0.103"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="71.54" y1="206.612" x2="71.896" y2="207.638"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="69.773" y1="206.815" x2="70.132" y2="207.843"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.899,217.125c0-0.051-0.014-0.1-0.037-0.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M74.129,217.268c0.114,0.226,0.47,0.374,0.866,0.375c0.498,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.367,215.972c-0.116-0.226-0.472-0.374-0.866-0.375c-0.498,0-0.903,0.232-0.903,0.519c0,0.051,0.014,0.1,0.037,0.144"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="75.367" y1="215.974" x2="75.862" y2="216.982"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.634" y1="216.258" x2="74.129" y2="217.267"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.899,217.125c0-0.051-0.014-0.1-0.037-0.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M74.129,217.268c0.114,0.226,0.47,0.374,0.866,0.375c0.498,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.367,215.972c-0.116-0.226-0.472-0.374-0.866-0.375c-0.498,0-0.903,0.232-0.903,0.519c0,0.051,0.014,0.1,0.037,0.144"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="75.367" y1="215.974" x2="75.862" y2="216.982"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.634" y1="216.258" x2="74.129" y2="217.267"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.141,226.301c0-0.068-0.021-0.128-0.054-0.184"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M79.396,226.483c0.137,0.205,0.475,0.337,0.845,0.337c0.496,0,0.9-0.233,0.9-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M80.452,225.136c-0.135-0.209-0.475-0.337-0.842-0.338c-0.5,0-0.903,0.232-0.903,0.519c0,0.065,0.021,0.13,0.058,0.182"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="80.455" y1="225.136" x2="81.087" y2="226.117"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="78.764" y1="225.499" x2="79.396" y2="226.48"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.141,226.301c0-0.068-0.021-0.128-0.054-0.184"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M79.396,226.483c0.137,0.205,0.475,0.337,0.845,0.337c0.496,0,0.9-0.233,0.9-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M80.452,225.136c-0.135-0.209-0.475-0.337-0.842-0.338c-0.5,0-0.903,0.232-0.903,0.519c0,0.065,0.021,0.13,0.058,0.182"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="80.455" y1="225.136" x2="81.087" y2="226.117"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="78.764" y1="225.499" x2="79.396" y2="226.48"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.618,235.21c0-0.082-0.032-0.158-0.084-0.223"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.901,235.429c0.151,0.188,0.474,0.298,0.814,0.298c0.5,0,0.903-0.23,0.903-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.771,234.04c-0.154-0.191-0.474-0.302-0.819-0.303c-0.496,0-0.901,0.235-0.901,0.523c0,0.077,0.032,0.154,0.084,0.219"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.771" y1="234.04" x2="87.534" y2="234.991"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.134" y1="234.479" x2="85.899" y2="235.429"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.618,235.21c0-0.082-0.032-0.158-0.084-0.223"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.901,235.429c0.151,0.188,0.474,0.298,0.814,0.298c0.5,0,0.903-0.23,0.903-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.771,234.04c-0.154-0.191-0.474-0.302-0.819-0.303c-0.496,0-0.901,0.235-0.901,0.523c0,0.077,0.032,0.154,0.084,0.219"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.771" y1="234.04" x2="87.534" y2="234.991"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.134" y1="234.479" x2="85.899" y2="235.429"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.284,243.797c0-0.093-0.044-0.181-0.118-0.254"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.599,244.055c0.163,0.168,0.467,0.263,0.784,0.263c0.496,0,0.901-0.235,0.901-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.277,242.627c-0.167-0.168-0.468-0.263-0.786-0.263c-0.498,0-0.9,0.233-0.9,0.521c0,0.093,0.042,0.181,0.116,0.254"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="94.277" y1="242.631" x2="95.166" y2="243.543"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="92.708" y1="243.139" x2="93.599" y2="244.051"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.284,243.797c0-0.093-0.044-0.181-0.118-0.254"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.599,244.055c0.163,0.168,0.467,0.263,0.784,0.263c0.496,0,0.901-0.235,0.901-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.277,242.627c-0.167-0.168-0.468-0.263-0.786-0.263c-0.498,0-0.9,0.233-0.9,0.521c0,0.093,0.042,0.181,0.116,0.254"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="94.277" y1="242.631" x2="95.166" y2="243.543"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="92.708" y1="243.139" x2="93.599" y2="244.051"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.094,252.012c0-0.107-0.056-0.21-0.154-0.289"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.445,252.303c0.174,0.147,0.456,0.228,0.749,0.228c0.495,0,0.9-0.233,0.9-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.927,250.853c-0.172-0.151-0.454-0.228-0.745-0.232c-0.5,0-0.903,0.233-0.903,0.521c0,0.109,0.06,0.209,0.154,0.293"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.927" y1="250.853" x2="103.939" y2="251.723"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.431" y1="251.433" x2="102.445" y2="252.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.094,252.012c0-0.107-0.056-0.21-0.154-0.289"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.445,252.303c0.174,0.147,0.456,0.228,0.749,0.228c0.495,0,0.9-0.233,0.9-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.927,250.853c-0.172-0.151-0.454-0.228-0.745-0.232c-0.5,0-0.903,0.233-0.903,0.521c0,0.109,0.06,0.209,0.154,0.293"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.927" y1="250.853" x2="103.939" y2="251.723"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.431" y1="251.433" x2="102.445" y2="252.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.99,259.801c0-0.121-0.074-0.235-0.195-0.321"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.386,260.127c0.177,0.128,0.438,0.196,0.707,0.196c0.495,0,0.898-0.235,0.898-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.668,258.659c-0.177-0.132-0.438-0.198-0.703-0.198c-0.5,0-0.901,0.235-0.901,0.521c0,0.121,0.074,0.237,0.195,0.323"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.672" y1="258.659" x2="113.796" y2="259.48"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="111.258" y1="259.305" x2="112.384" y2="260.127"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.99,259.801c0-0.121-0.074-0.235-0.195-0.321"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.386,260.127c0.177,0.128,0.438,0.196,0.707,0.196c0.495,0,0.898-0.235,0.898-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.668,258.659c-0.177-0.132-0.438-0.198-0.703-0.198c-0.5,0-0.901,0.235-0.901,0.521c0,0.121,0.074,0.237,0.195,0.323"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.672" y1="258.659" x2="113.796" y2="259.48"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="111.258" y1="259.305" x2="112.384" y2="260.127"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.92,267.12c0-0.135-0.093-0.261-0.242-0.354"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.358,267.474c0.177,0.11,0.417,0.168,0.661,0.168c0.496,0,0.901-0.235,0.901-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.442,265.997c-0.177-0.109-0.417-0.165-0.659-0.165c-0.496,0-0.9,0.233-0.9,0.519c0,0.139,0.093,0.263,0.24,0.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.442" y1="265.999" x2="124.678" y2="266.765"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.123" y1="266.706" x2="123.356" y2="267.474"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.92,267.12c0-0.135-0.093-0.261-0.242-0.354"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.358,267.474c0.177,0.11,0.417,0.168,0.661,0.168c0.496,0,0.901-0.235,0.901-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.442,265.997c-0.177-0.109-0.417-0.165-0.659-0.165c-0.496,0-0.9,0.233-0.9,0.519c0,0.139,0.093,0.263,0.24,0.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.442" y1="265.999" x2="124.678" y2="266.765"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.123" y1="266.706" x2="123.356" y2="267.474"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.808,273.921c0-0.151-0.112-0.289-0.293-0.384"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.298,274.303c0.174,0.093,0.389,0.137,0.607,0.137c0.5,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.18,272.828c-0.175-0.093-0.395-0.137-0.61-0.135c-0.496,0-0.901,0.23-0.901,0.519c0,0.153,0.114,0.288,0.293,0.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="135.182" y1="272.828" x2="136.517" y2="273.537"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.962" y1="273.595" x2="135.296" y2="274.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.808,273.921c0-0.151-0.112-0.289-0.293-0.384"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.298,274.303c0.174,0.093,0.389,0.137,0.607,0.137c0.5,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.18,272.828c-0.175-0.093-0.395-0.137-0.61-0.135c-0.496,0-0.901,0.23-0.901,0.519c0,0.153,0.114,0.288,0.293,0.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="135.182" y1="272.828" x2="136.517" y2="273.537"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.962" y1="273.595" x2="135.296" y2="274.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.581,280.163c0-0.168-0.135-0.314-0.346-0.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.127,280.573c0.167,0.072,0.363,0.111,0.554,0.107c0.498,0,0.9-0.23,0.9-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M147.808,279.107c-0.167-0.075-0.361-0.114-0.556-0.11c-0.495,0-0.9,0.232-0.9,0.517c0,0.168,0.135,0.314,0.346,0.412"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.808" y1="279.107" x2="149.236" y2="279.751"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="146.698" y1="279.926" x2="148.126" y2="280.57"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.581,280.163c0-0.168-0.135-0.314-0.346-0.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.127,280.573c0.167,0.072,0.363,0.111,0.554,0.107c0.498,0,0.9-0.23,0.9-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M147.808,279.107c-0.167-0.075-0.361-0.114-0.556-0.11c-0.495,0-0.9,0.232-0.9,0.517c0,0.168,0.135,0.314,0.346,0.412"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.808" y1="279.107" x2="149.236" y2="279.751"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="146.698" y1="279.926" x2="148.126" y2="280.57"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.165,285.805c0-0.179-0.158-0.339-0.402-0.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.769,286.24c0.153,0.06,0.328,0.086,0.496,0.086c0.498,0,0.9-0.235,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.25,284.791c-0.154-0.058-0.326-0.086-0.496-0.086c-0.496,0-0.901,0.232-0.901,0.519c0,0.182,0.16,0.338,0.407,0.435"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.253" y1="284.793" x2="162.765" y2="285.372"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="160.257" y1="285.66" x2="161.765" y2="286.24"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.165,285.805c0-0.179-0.158-0.339-0.402-0.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.769,286.24c0.153,0.06,0.328,0.086,0.496,0.086c0.498,0,0.9-0.235,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.25,284.791c-0.154-0.058-0.326-0.086-0.496-0.086c-0.496,0-0.901,0.232-0.901,0.519c0,0.182,0.16,0.338,0.407,0.435"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.253" y1="284.793" x2="162.765" y2="285.372"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="160.257" y1="285.66" x2="161.765" y2="286.24"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M175.426,289.851c-0.142-0.044-0.291-0.067-0.437-0.067c-0.498,0-0.9,0.235-0.9,0.523c0,0.193,0.182,0.363,0.461,0.454"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="175.428" y1="289.851" x2="177.013" y2="290.36"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="174.551" y1="290.761" x2="176.136" y2="291.268"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M177.474,290.797c-0.011-0.188-0.196-0.349-0.463-0.437"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M176.138,291.27c0.126,0.04,0.261,0.063,0.393,0.065"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M175.426,289.851c-0.142-0.044-0.291-0.067-0.437-0.067c-0.498,0-0.9,0.235-0.9,0.523c0,0.193,0.182,0.363,0.461,0.454"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="175.428" y1="289.851" x2="177.013" y2="290.36"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="174.551" y1="290.761" x2="176.136" y2="291.268"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.276,59.682c-0.011,0-0.023-0.004-0.032-0.004c-0.5,0-0.903,0.235-0.903,0.521c0,0.281,0.384,0.512,0.87,0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.957,60.24c0-0.282-0.386-0.51-0.868-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.022,60.759c0.011,0,0.023,0,0.032,0c0.498,0,0.903-0.232,0.903-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.021" y1="60.759" x2="287.211" y2="60.722"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.089" y1="59.719" x2="287.276" y2="59.682"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.276,59.682c-0.011,0-0.023-0.004-0.032-0.004c-0.5,0-0.903,0.235-0.903,0.521c0,0.281,0.384,0.512,0.87,0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.957,60.24c0-0.282-0.386-0.51-0.868-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.022,60.759c0.011,0,0.023,0,0.032,0c0.498,0,0.903-0.232,0.903-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.021" y1="60.759" x2="287.211" y2="60.722"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.089" y1="59.719" x2="287.276" y2="59.682"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.276,59.682c-0.011,0-0.023-0.004-0.032-0.004c-0.5,0-0.903,0.235-0.903,0.521c0,0.281,0.384,0.512,0.87,0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.957,60.24c0-0.282-0.386-0.51-0.868-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.022,60.759c0.011,0,0.023,0,0.032,0c0.498,0,0.903-0.232,0.903-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.021" y1="60.759" x2="287.211" y2="60.722"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.089" y1="59.719" x2="287.276" y2="59.682"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.276,59.682c-0.011,0-0.023-0.004-0.032-0.004c-0.5,0-0.903,0.235-0.903,0.521c0,0.281,0.384,0.512,0.87,0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.957,60.24c0-0.282-0.386-0.51-0.868-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.022,60.759c0.011,0,0.023,0,0.032,0c0.498,0,0.903-0.232,0.903-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.021" y1="60.759" x2="287.211" y2="60.722"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.089" y1="59.719" x2="287.276" y2="59.682"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.034,60.396c-0.033-0.002-0.072-0.002-0.103-0.002c-0.495,0-0.9,0.233-0.9,0.521c0,0.265,0.346,0.484,0.8,0.516"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M306.635,61.034c0-0.267-0.347-0.486-0.796-0.516"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M305.63,61.551c0.039,0.004,0.074,0.005,0.103,0.005c0.5,0,0.901-0.235,0.901-0.523"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="305.63" y1="61.551" x2="303.827" y2="61.43"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="305.838" y1="60.518" x2="304.035" y2="60.396"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M320.644,61.871c-0.058-0.007-0.117-0.011-0.17-0.011c-0.5,0-0.901,0.235-0.901,0.523c0,0.251,0.309,0.461,0.726,0.509"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M323.152,62.584c0-0.253-0.309-0.463-0.726-0.512"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M322.081,63.093c0.058,0.007,0.118,0.012,0.172,0.012c0.498,0,0.9-0.233,0.9-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="322.079" y1="63.093" x2="320.299" y2="62.891"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="322.426" y1="62.072" x2="320.648" y2="61.871"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M337.004,64.093c-0.082-0.014-0.165-0.021-0.24-0.021c-0.5,0-0.901,0.233-0.901,0.521c0,0.239,0.274,0.438,0.658,0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M339.412,64.877c0-0.24-0.277-0.438-0.661-0.503"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M338.269,65.377c0.081,0.014,0.165,0.019,0.242,0.019c0.496,0,0.901-0.232,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="338.269" y1="65.377" x2="336.52" y2="65.096"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="338.751" y1="64.373" x2="337.004" y2="64.093"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M353.013,67.048c-0.105-0.021-0.212-0.033-0.312-0.03c-0.498,0-0.901,0.23-0.901,0.517c0,0.226,0.244,0.414,0.593,0.489"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M355.307,67.897c0-0.224-0.242-0.412-0.591-0.488"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M354.097,68.386c0.105,0.023,0.21,0.033,0.31,0.032c0.495,0,0.9-0.233,0.9-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="354.097" y1="68.386" x2="352.392" y2="68.025"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="354.716" y1="67.409" x2="353.013" y2="67.048"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M368.566,70.721c-0.123-0.032-0.253-0.049-0.372-0.049c-0.498,0-0.901,0.235-0.901,0.523c0,0.209,0.21,0.388,0.526,0.472"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M370.745,71.631c0-0.209-0.214-0.391-0.526-0.475"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M369.468,72.103c0.123,0.033,0.253,0.047,0.375,0.046c0.498,0,0.901-0.232,0.901-0.517"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="369.468" y1="72.103" x2="367.819" y2="71.666"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="370.219" y1="71.155" x2="368.57" y2="70.721"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M383.576,75.084c-0.14-0.044-0.293-0.067-0.438-0.063c-0.498,0-0.9,0.232-0.9,0.519c0,0.193,0.186,0.365,0.461,0.454"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M385.624,76.05c0-0.195-0.186-0.365-0.461-0.454"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M384.286,76.505c0.14,0.044,0.291,0.067,0.438,0.067c0.498,0,0.9-0.233,0.9-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="384.286" y1="76.505" x2="382.699" y2="75.994"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="385.163" y1="75.596" x2="383.576" y2="75.084"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.947,80.116c-0.156-0.06-0.33-0.086-0.498-0.084c-0.5,0-0.901,0.23-0.901,0.519c0,0.181,0.156,0.338,0.402,0.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M399.86,81.13c0-0.179-0.16-0.338-0.403-0.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.461,81.565c0.153,0.06,0.33,0.086,0.498,0.086c0.498,0,0.901-0.233,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="398.461" y1="81.565" x2="396.949" y2="80.984"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="399.457" y1="80.696" x2="397.947" y2="80.116"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M411.586,85.783c-0.165-0.075-0.363-0.11-0.554-0.11c-0.5,0-0.901,0.235-0.901,0.523c0,0.163,0.135,0.312,0.345,0.407"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M413.361,86.838c0-0.163-0.135-0.312-0.347-0.407"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M411.902,87.249c0.168,0.075,0.363,0.11,0.556,0.11c0.498,0,0.903-0.232,0.903-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="411.902" y1="87.249" x2="410.476" y2="86.602"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="413.014" y1="86.431" x2="411.586" y2="85.783"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M424.416,92.053c-0.174-0.093-0.393-0.139-0.609-0.139c-0.496,0-0.901,0.233-0.901,0.521c0,0.151,0.111,0.286,0.291,0.382"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,93.143c0-0.153-0.112-0.288-0.295-0.382"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M424.533,93.528c0.172,0.089,0.391,0.137,0.61,0.137c0.495,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="424.533" y1="93.528" x2="423.197" y2="92.817"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.751" y1="92.761" x2="424.416" y2="92.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M436.354,98.882c-0.177-0.112-0.417-0.167-0.658-0.167c-0.498,0-0.901,0.232-0.901,0.521c0,0.137,0.091,0.261,0.24,0.354"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M437.829,100.001c0-0.137-0.091-0.261-0.24-0.354"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M436.27,100.355c0.177,0.112,0.417,0.167,0.661,0.167c0.495,0,0.898-0.232,0.898-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="436.27" y1="100.355" x2="435.035" y2="99.591"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="437.589" y1="99.647" x2="436.354" y2="98.882"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="63.72" y1="183.165" x2="69.699" y2="183.165"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="59.467" y1="180.448" x2="59.467" y2="344.157"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="59.467" y1="180.448" x2="60.465" y2="180.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="60.465" y1="180.448" x2="60.465" y2="183.23"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M60.465,183.23c0,0.719,1.117,1.647,3.276,1.694"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M60.209,194.731c0-1.189,1.275-2.25,3.632-2.378"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="63.753" y1="185.945" x2="63.72" y2="183.165"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M68.917,241.157c0,15.962,5.339,31.204,14.541,44.346"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.605,287.154c19.117,26.946,53.106,47.785,93.829,60.522"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M68.917,238.165c0,16.77,5.893,32.746,16.028,46.475"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M88.265,289.02c19.787,24.747,52.187,43.823,90.169,55.665"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="68.917" y1="214.697" x2="68.917" y2="343.41"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.503" y1="316.978" x2="421.81" y2="319.111"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="421.81" y1="319.111" x2="410.566" y2="318.283"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="114.939" y1="273.2" x2="114.939" y2="309.817"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="133.976" y1="283.099" x2="133.976" y2="309.817"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="115.827" y1="272.723" x2="117.905" y2="273.909"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="131.254" y1="281.543" x2="133.976" y2="283.099"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="114.939" y1="273.2" x2="115.827" y2="272.723"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="130.126" y1="282.145" x2="130.126" y2="309.817"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M130.126,282.145c0-0.986,0.14-2.089,1.55-3.111"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M117.905,273.909c1.075,0.63,1.97,0.852,4.611-0.516"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.739" y1="279.87" x2="133.739" y2="282.962"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="118.458" y1="276.147" x2="118.458" y2="309.817"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="127.688" y1="278.858" x2="127.688" y2="309.817"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="166.532" y1="111.853" x2="170.792" y2="114.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="170.792" y1="114.863" x2="170.792" y2="115.309"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="167.076" y1="117.362" x2="170.792" y2="119.99"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="170.792" y1="119.99" x2="170.792" y2="120.435"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.069,102.884c-0.319,0.089-0.503,0.274-0.507,0.468c0,0.288,0.403,0.521,0.903,0.521c0.144,0,0.281-0.021,0.395-0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M209.994,102.891c0-0.288-0.402-0.517-0.9-0.517c-0.146,0-0.281,0.019-0.396,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M209.491,103.361c0.316-0.091,0.502-0.275,0.503-0.47"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="209.491" y1="103.361" x2="207.86" y2="103.819"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="208.698" y1="102.427" x2="207.069" y2="102.884"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M192.924,107.416c-0.275,0.095-0.428,0.265-0.428,0.44c0,0.288,0.403,0.523,0.9,0.523c0.175,0,0.34-0.032,0.475-0.077"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M195.841,107.309c0-0.288-0.402-0.523-0.901-0.523c-0.175,0-0.337,0.032-0.472,0.077"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M195.413,107.749c0.277-0.095,0.426-0.265,0.428-0.44"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="195.413" y1="107.749" x2="193.868" y2="108.302"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="194.469" y1="106.864" x2="192.924" y2="107.416"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M179.614,112.723c-0.23,0.102-0.354,0.258-0.354,0.414c0,0.288,0.405,0.521,0.9,0.521c0.207,0,0.396-0.04,0.549-0.107"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M182.506,112.502c0-0.286-0.405-0.517-0.901-0.517c-0.205,0-0.395,0.037-0.547,0.105"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M182.15,112.916c0.232-0.102,0.356-0.254,0.356-0.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="182.15" y1="112.916" x2="180.709" y2="113.551"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="181.058" y1="112.09" x2="179.614" y2="112.723"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M253.132,93.989c-0.442,0.04-0.754,0.26-0.756,0.512c0,0.288,0.402,0.523,0.9,0.523c0.051,0,0.102-0.002,0.146-0.009"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.968,94.334c0-0.284-0.403-0.517-0.9-0.517c-0.051,0-0.102,0-0.144,0.007"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.21,94.848c0.442-0.039,0.758-0.258,0.758-0.514"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.208" y1="94.848" x2="253.419" y2="95.015"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.924" y1="93.824" x2="253.132" y2="93.987"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M253.132,93.989c-0.442,0.04-0.754,0.26-0.756,0.512c0,0.288,0.402,0.523,0.9,0.523c0.051,0,0.102-0.002,0.146-0.009"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.968,94.334c0-0.284-0.403-0.517-0.9-0.517c-0.051,0-0.102,0-0.144,0.007"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.21,94.848c0.442-0.039,0.758-0.258,0.758-0.514"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.208" y1="94.848" x2="253.419" y2="95.015"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.924" y1="93.824" x2="253.132" y2="93.987"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M237.319,95.953c-0.403,0.063-0.67,0.27-0.672,0.503c0,0.288,0.405,0.521,0.901,0.521c0.081,0,0.161-0.007,0.232-0.018"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M240.204,96.188c0-0.288-0.405-0.521-0.901-0.521c-0.081,0-0.161,0.007-0.23,0.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M239.534,96.693c0.4-0.061,0.668-0.27,0.67-0.505"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="239.532" y1="96.693" x2="237.779" y2="96.96"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="239.075" y1="95.687" x2="237.319" y2="95.953"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M221.915,98.801c-0.363,0.081-0.586,0.277-0.588,0.489c0,0.286,0.403,0.521,0.9,0.521c0.116,0,0.221-0.014,0.316-0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M224.83,98.926c0-0.286-0.402-0.521-0.9-0.521c-0.116,0-0.221,0.014-0.316,0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M224.242,99.415c0.363-0.081,0.584-0.277,0.588-0.489"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="224.242" y1="99.415" x2="222.541" y2="99.778"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="223.616" y1="98.438" x2="221.915" y2="98.801"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.204,92.929c-0.479,0.018-0.844,0.246-0.845,0.517c0,0.288,0.402,0.523,0.9,0.523c0.021,0,0.04,0,0.056-0.002"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.972,93.382c0-0.284-0.405-0.517-0.903-0.517c-0.018,0-0.037,0-0.054,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.126,93.903c0.477-0.018,0.845-0.246,0.845-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.126" y1="93.901" x2="269.315" y2="93.968"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.014" y1="92.865" x2="269.204" y2="92.929"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.204,92.929c-0.479,0.018-0.844,0.246-0.845,0.517c0,0.288,0.402,0.523,0.9,0.523c0.021,0,0.04,0,0.056-0.002"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.972,93.382c0-0.284-0.405-0.517-0.903-0.517c-0.018,0-0.037,0-0.054,0"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.126,93.903c0.477-0.018,0.845-0.246,0.845-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.126" y1="93.901" x2="269.315" y2="93.968"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="271.014" y1="92.865" x2="269.204" y2="92.929"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.376,92.907c-0.011,0-0.021,0-0.032,0c-0.498,0-0.9,0.235-0.9,0.523c0,0.279,0.382,0.507,0.868,0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M288.06,93.466c0-0.281-0.386-0.509-0.868-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.123,93.985c0.014,0,0.026,0,0.035,0c0.496,0,0.901-0.23,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.123" y1="93.985" x2="285.313" y2="93.947"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.191" y1="92.947" x2="285.376" y2="92.907"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.376,92.907c-0.011,0-0.021,0-0.032,0c-0.498,0-0.9,0.235-0.9,0.523c0,0.279,0.382,0.507,0.868,0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M288.06,93.466c0-0.281-0.386-0.509-0.868-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M287.123,93.985c0.014,0,0.026,0,0.035,0c0.496,0,0.901-0.23,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.123" y1="93.985" x2="285.313" y2="93.947"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.191" y1="92.947" x2="285.376" y2="92.907"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M301.498,93.68c-0.042-0.005-0.084-0.005-0.119-0.005c-0.498,0-0.903,0.233-0.903,0.519c0,0.263,0.339,0.481,0.78,0.516"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.076,94.334c0-0.263-0.337-0.481-0.782-0.516"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M303.053,94.852c0.042,0.004,0.084,0.004,0.123,0.004c0.495,0,0.9-0.232,0.9-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.053" y1="94.852" x2="301.256" y2="94.71"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.295" y1="93.819" x2="301.498" y2="93.68"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M301.498,93.68c-0.042-0.005-0.084-0.005-0.119-0.005c-0.498,0-0.903,0.233-0.903,0.519c0,0.263,0.339,0.481,0.78,0.516"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.076,94.334c0-0.263-0.337-0.481-0.782-0.516"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M303.053,94.852c0.042,0.004,0.084,0.004,0.123,0.004c0.495,0,0.9-0.232,0.9-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.053" y1="94.852" x2="301.256" y2="94.71"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="303.295" y1="93.819" x2="301.498" y2="93.68"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M317.408,95.359c-0.07-0.012-0.14-0.016-0.205-0.016c-0.498,0-0.903,0.235-0.903,0.523c0,0.244,0.293,0.447,0.696,0.505"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M319.869,96.106c0-0.246-0.293-0.451-0.693-0.507"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M318.761,96.611c0.072,0.012,0.144,0.014,0.207,0.014c0.496,0,0.901-0.233,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="318.761" y1="96.611" x2="316.995" y2="96.371"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="319.176" y1="95.599" x2="317.412" y2="95.359"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M317.408,95.359c-0.07-0.012-0.14-0.016-0.205-0.016c-0.498,0-0.903,0.235-0.903,0.523c0,0.244,0.293,0.447,0.696,0.505"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M319.869,96.106c0-0.246-0.293-0.451-0.693-0.507"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M318.761,96.611c0.072,0.012,0.144,0.014,0.207,0.014c0.496,0,0.901-0.233,0.901-0.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="318.761" y1="96.611" x2="316.995" y2="96.371"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="319.176" y1="95.599" x2="317.412" y2="95.359"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M332.962,97.93c-0.102-0.021-0.202-0.03-0.295-0.03c-0.498,0-0.901,0.235-0.901,0.521c0,0.228,0.253,0.421,0.61,0.491"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M335.285,98.761c0-0.228-0.253-0.421-0.609-0.491"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M334.091,99.254c0.098,0.019,0.198,0.028,0.291,0.028c0.498,0,0.903-0.232,0.903-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.091" y1="99.254" x2="332.376" y2="98.912"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.677" y1="98.27" x2="332.962" y2="97.93"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M332.962,97.93c-0.102-0.021-0.202-0.03-0.295-0.03c-0.498,0-0.901,0.235-0.901,0.521c0,0.228,0.253,0.421,0.61,0.491"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M335.285,98.761c0-0.228-0.253-0.421-0.609-0.491"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M334.091,99.254c0.098,0.019,0.198,0.028,0.291,0.028c0.498,0,0.903-0.232,0.903-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.091" y1="99.254" x2="332.376" y2="98.912"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.677" y1="98.27" x2="332.962" y2="97.93"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.001,101.586c-0.126-0.03-0.254-0.046-0.375-0.046c-0.498,0-0.903,0.233-0.903,0.521c0,0.207,0.216,0.389,0.528,0.474"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M350.176,102.497c0-0.207-0.214-0.389-0.526-0.474"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.899,102.972c0.123,0.03,0.253,0.046,0.375,0.046c0.496,0,0.901-0.232,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="348.899" y1="102.972" x2="347.25" y2="102.535"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="349.649" y1="102.023" x2="348.001" y2="101.59"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.001,101.586c-0.126-0.03-0.254-0.046-0.375-0.046c-0.498,0-0.903,0.233-0.903,0.521c0,0.207,0.216,0.389,0.528,0.474"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M350.176,102.497c0-0.207-0.214-0.389-0.526-0.474"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.899,102.972c0.123,0.03,0.253,0.046,0.375,0.046c0.496,0,0.901-0.232,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="348.899" y1="102.972" x2="347.25" y2="102.535"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="349.649" y1="102.023" x2="348.001" y2="101.59"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M362.384,105.861c-0.147-0.046-0.303-0.07-0.454-0.07c-0.498,0-0.901,0.235-0.901,0.523c0,0.191,0.177,0.356,0.447,0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M364.399,106.837c0-0.189-0.181-0.356-0.451-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M363.044,107.29c0.144,0.046,0.303,0.068,0.454,0.068c0.498,0,0.901-0.233,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.044" y1="107.29" x2="361.474" y2="106.76"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.949" y1="106.39" x2="362.384" y2="105.861"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M362.384,105.861c-0.147-0.046-0.303-0.07-0.454-0.07c-0.498,0-0.901,0.235-0.901,0.523c0,0.191,0.177,0.356,0.447,0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M364.399,106.837c0-0.189-0.181-0.356-0.451-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M363.044,107.29c0.144,0.046,0.303,0.068,0.454,0.068c0.498,0,0.901-0.233,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.044" y1="107.29" x2="361.474" y2="106.76"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="363.949" y1="106.39" x2="362.384" y2="105.861"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M375.969,110.927c-0.16-0.067-0.346-0.096-0.528-0.096c-0.496,0-0.898,0.232-0.898,0.519c0,0.174,0.144,0.326,0.372,0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M377.816,111.962c0-0.17-0.147-0.326-0.375-0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M376.387,112.385c0.16,0.067,0.345,0.1,0.526,0.1c0.498,0,0.903-0.235,0.903-0.523"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="376.387" y1="112.385" x2="374.915" y2="111.771"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="377.441" y1="111.541" x2="375.969" y2="110.927"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M375.969,110.927c-0.16-0.067-0.346-0.096-0.528-0.096c-0.496,0-0.898,0.232-0.898,0.519c0,0.174,0.144,0.326,0.372,0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M377.816,111.962c0-0.17-0.147-0.326-0.375-0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M376.387,112.385c0.16,0.067,0.345,0.1,0.526,0.1c0.498,0,0.903-0.235,0.903-0.523"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="376.387" y1="112.385" x2="374.915" y2="111.771"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="377.441" y1="111.541" x2="375.969" y2="110.927"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.634,116.741c-0.174-0.089-0.388-0.133-0.598-0.133c-0.498,0-0.9,0.235-0.9,0.523c0,0.153,0.116,0.295,0.305,0.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.298,117.824c0-0.154-0.118-0.295-0.307-0.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.799,118.213c0.172,0.089,0.384,0.132,0.598,0.132c0.498,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.799" y1="118.213" x2="387.441" y2="117.52"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="389.991" y1="117.434" x2="388.634" y2="116.741"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.634,116.741c-0.174-0.089-0.388-0.133-0.598-0.133c-0.498,0-0.9,0.235-0.9,0.523c0,0.153,0.116,0.295,0.305,0.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.298,117.824c0-0.154-0.118-0.295-0.307-0.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.799,118.213c0.172,0.089,0.384,0.132,0.598,0.132c0.498,0,0.901-0.235,0.901-0.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.799" y1="118.213" x2="387.441" y2="117.52"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="389.991" y1="117.434" x2="388.634" y2="116.741"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.989" y1="120.679" x2="397.989" y2="120.244"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.989" y1="120.244" x2="402.745" y2="117.343"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.989" y1="125.684" x2="397.989" y2="125.251"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.989" y1="125.251" x2="401.7" y2="122.989"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M410.389,113.167c0.531,0.307,1.4-0.193,1.934-1.119s0.535-1.926,0-2.236c-0.535-0.309-1.4,0.195-1.934,1.119    C409.854,111.855,409.854,112.857,410.389,113.167"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.381,65.51c0-0.782-0.652-1.414-1.463-1.414c-0.805,0-1.463,0.631-1.463,1.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,66.304c0,0.549,0.33,1.028,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,67.569c0.509-0.246,0.809-0.742,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,66.457c0-0.659-0.554-1.196-1.24-1.196c-0.682,0-1.235,0.537-1.235,1.196c0,0.396,0.198,0.749,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,67.425c0.331-0.235,0.509-0.596,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,66.457c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,67.153c0-0.419-0.349-0.756-0.782-0.756c-0.43,0-0.78,0.337-0.78,0.756c0,0.416,0.351,0.752,0.78,0.752    C280.352,67.906,280.701,67.569,280.701,67.153"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="66.457" x2="280.701" y2="67.153"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="67.153" x2="279.138" y2="66.457"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="65.51" x2="281.381" y2="66.304"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="66.304" x2="278.456" y2="65.51"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.918" y1="64.889" x2="279.918" y2="64.096"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M265.253,64.728c-0.095-0.773-0.821-1.319-1.622-1.222c-0.803,0.1-1.375,0.807-1.28,1.582"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M262.448,65.875c0.067,0.547,0.449,0.979,0.959,1.154"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.698,66.871c0.477-0.305,0.714-0.831,0.652-1.352"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M265.146,65.698c-0.081-0.656-0.696-1.119-1.375-1.037c-0.675,0.084-1.163,0.682-1.08,1.34c0.047,0.393,0.289,0.716,0.624,0.896"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.758,66.718c0.302-0.27,0.431-0.651,0.388-1.021"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.693,65.755c-0.051-0.414-0.438-0.709-0.866-0.656c-0.428,0.053-0.735,0.431-0.684,0.844"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.777,66.441c-0.051-0.412-0.438-0.703-0.866-0.652c-0.428,0.053-0.735,0.431-0.684,0.844c0.051,0.414,0.438,0.707,0.866,0.652    C264.521,67.232,264.828,66.855,264.777,66.441"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="264.693" y1="65.755" x2="264.777" y2="66.441"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="263.227" y1="66.632" x2="263.143" y2="65.943"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="265.253" y1="64.728" x2="265.351" y2="65.519"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="262.448" y1="65.875" x2="262.35" y2="65.087"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="263.725" y1="64.294" x2="263.63" y2="63.505"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.554,65.655c-0.188-0.758-0.979-1.212-1.763-1.015c-0.782,0.195-1.263,0.966-1.075,1.722"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M245.908,67.132c0.133,0.537,0.565,0.919,1.093,1.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.265,67.849c0.437-0.36,0.605-0.914,0.481-1.422"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.567,66.631c-0.16-0.64-0.828-1.026-1.493-0.859c-0.661,0.163-1.068,0.819-0.908,1.459c0.095,0.382,0.374,0.675,0.728,0.812"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.305,67.693c0.263-0.309,0.347-0.703,0.261-1.063"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.121,66.741c-0.1-0.403-0.521-0.647-0.94-0.544c-0.417,0.107-0.673,0.517-0.575,0.923"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.291,67.416c-0.102-0.405-0.524-0.649-0.942-0.545c-0.417,0.107-0.673,0.516-0.575,0.923c0.102,0.402,0.521,0.645,0.94,0.542    C248.132,68.232,248.389,67.82,248.291,67.416"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="248.121" y1="66.741" x2="248.291" y2="67.416"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="246.774" y1="67.793" x2="246.606" y2="67.12"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="248.554" y1="65.655" x2="248.745" y2="66.427"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="245.908" y1="67.132" x2="245.717" y2="66.362"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="246.986" y1="65.41" x2="246.792" y2="64.64"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.554,65.655c-0.188-0.758-0.979-1.212-1.763-1.015c-0.782,0.195-1.263,0.966-1.075,1.722"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.802,67.443c-0.284-0.675-1.119-0.965-1.861-0.649c-0.745,0.314-1.115,1.115-0.831,1.789"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M229.422,69.314c0.2,0.475,0.677,0.761,1.21,0.775"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.831,69.582c0.377-0.412,0.47-0.954,0.282-1.408"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.968,68.404c-0.244-0.574-0.951-0.817-1.578-0.549c-0.628,0.265-0.942,0.945-0.7,1.515c0.142,0.34,0.458,0.566,0.821,0.635"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.852,69.435c0.219-0.33,0.249-0.71,0.116-1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.547,68.581c-0.153-0.361-0.6-0.514-0.996-0.346c-0.398,0.168-0.595,0.596-0.442,0.954"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.816,69.221c-0.151-0.361-0.596-0.516-0.994-0.347c-0.396,0.168-0.595,0.595-0.44,0.954c0.153,0.361,0.596,0.516,0.993,0.347    C231.77,70.007,231.968,69.579,231.816,69.221"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.547" y1="68.581" x2="231.816" y2="69.221"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.381" y1="69.83" x2="230.109" y2="69.189"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.802" y1="67.443" x2="232.114" y2="68.17"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.422" y1="69.314" x2="229.109" y2="68.583"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.255" y1="67.534" x2="229.942" y2="66.801"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.802,67.443c-0.284-0.675-1.119-0.965-1.861-0.649c-0.745,0.314-1.115,1.115-0.831,1.789"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.25,69.837c-0.356-0.64-1.215-0.844-1.922-0.452c-0.705,0.395-0.991,1.228-0.637,1.87"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M214.077,71.946c0.249,0.454,0.752,0.688,1.284,0.647"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.499,71.962c0.338-0.449,0.374-0.998,0.135-1.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.515,70.773c-0.302-0.54-1.031-0.71-1.626-0.381c-0.598,0.331-0.842,1.038-0.54,1.58c0.181,0.326,0.516,0.519,0.886,0.547"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.506,71.817c0.186-0.353,0.175-0.735,0.009-1.044"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.113,70.998c-0.189-0.342-0.649-0.451-1.026-0.242c-0.375,0.21-0.526,0.656-0.337,0.996"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.452,71.604c-0.191-0.342-0.651-0.451-1.028-0.242c-0.377,0.21-0.528,0.656-0.339,0.996c0.189,0.344,0.649,0.449,1.024,0.242    C216.487,72.392,216.641,71.945,216.452,71.604"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="216.113" y1="70.998" x2="216.452" y2="71.604"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="215.086" y1="72.359" x2="214.749" y2="71.752"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="216.25" y1="69.837" x2="216.634" y2="70.529"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="214.077" y1="71.946" x2="213.691" y2="71.254"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="214.712" y1="70.087" x2="214.328" y2="69.391"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.25,69.837c-0.356-0.64-1.215-0.844-1.922-0.452c-0.705,0.395-0.991,1.228-0.637,1.87"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.072,105.582c-0.5-0.277-1.222,0.074-1.612,0.78c-0.393,0.703-0.303,1.503,0.193,1.78"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M121.35,108.525c0.331,0.184,0.761,0.093,1.129-0.198"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.198,107.022c0.053-0.491-0.123-0.879-0.43-1.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.793,106.238c-0.421-0.233-1.035,0.061-1.366,0.659c-0.33,0.598-0.254,1.272,0.167,1.508c0.244,0.133,0.544,0.093,0.824-0.077    "/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.167,106.98c-0.002-0.353-0.147-0.616-0.374-0.742"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.572,106.639c-0.268-0.149-0.651,0.037-0.863,0.416c-0.209,0.377-0.16,0.802,0.107,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.179,106.974c-0.265-0.146-0.651,0.039-0.859,0.417c-0.209,0.375-0.165,0.802,0.103,0.949    c0.265,0.149,0.651-0.037,0.859-0.416C123.491,107.548,123.447,107.123,123.179,106.974"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.572" y1="106.639" x2="123.179" y2="106.974"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.423" y1="108.341" x2="121.816" y2="108.006"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.072" y1="105.582" x2="122.768" y2="105.968"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="121.35" y1="108.525" x2="120.653" y2="108.142"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="121.172" y1="106.785" x2="120.476" y2="106.399"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M122.072,105.582c-0.5-0.277-1.222,0.074-1.612,0.78c-0.393,0.703-0.303,1.503,0.193,1.78"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M110.837,113.488c-0.482-0.186-1.11,0.274-1.4,1.03c-0.291,0.751-0.133,1.515,0.354,1.701"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M110.532,116.501c0.319,0.125,0.703-0.035,1.015-0.382"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.079,114.726c0-0.488-0.198-0.835-0.5-0.956"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M111.642,114.037c-0.409-0.158-0.94,0.233-1.184,0.87c-0.247,0.64-0.11,1.282,0.298,1.44c0.235,0.091,0.509,0.002,0.749-0.21"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.058,114.695c-0.039-0.338-0.196-0.574-0.416-0.658"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M111.481,114.465c-0.26-0.1-0.595,0.146-0.749,0.549c-0.154,0.4-0.072,0.809,0.189,0.907"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.126,114.712c-0.256-0.1-0.591,0.146-0.745,0.549c-0.156,0.403-0.072,0.809,0.188,0.908s0.595-0.146,0.749-0.547    C112.47,115.217,112.386,114.812,112.126,114.712"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="111.481" y1="114.465" x2="112.126" y2="114.712"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="111.568" y1="116.17" x2="110.921" y2="115.921"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="110.837" y1="113.488" x2="111.579" y2="113.77"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="110.532" y1="116.501" x2="109.792" y2="116.219"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="110.314" y1="114.854" x2="109.574" y2="114.568"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M110.837,113.488c-0.482-0.186-1.11,0.274-1.4,1.03c-0.291,0.751-0.133,1.515,0.354,1.701"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M101.57,121.224c-0.442-0.146-1.005,0.361-1.254,1.129c-0.251,0.77-0.093,1.507,0.353,1.65"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M101.421,124.25c0.295,0.098,0.64-0.095,0.919-0.463"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.796,122.37c-0.009-0.482-0.195-0.809-0.468-0.901"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.403,121.729c-0.375-0.121-0.849,0.309-1.063,0.959c-0.21,0.649-0.075,1.273,0.3,1.396c0.212,0.07,0.46-0.04,0.675-0.272"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.79,122.348c-0.04-0.335-0.188-0.552-0.388-0.619"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.261,122.166c-0.235-0.077-0.537,0.193-0.67,0.603c-0.13,0.409-0.047,0.805,0.188,0.88"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.924,122.382c-0.239-0.079-0.538,0.191-0.67,0.602c-0.133,0.41-0.049,0.803,0.188,0.882c0.235,0.077,0.535-0.193,0.67-0.603    C103.241,122.85,103.16,122.455,102.924,122.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.261" y1="122.166" x2="102.924" y2="122.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.441" y1="123.866" x2="101.778" y2="123.65"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.57" y1="121.223" x2="102.327" y2="121.468"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.421" y1="124.25" x2="100.668" y2="124.004"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.026" y1="122.678" x2="100.274" y2="122.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M101.57,121.224c-0.442-0.146-1.005,0.361-1.254,1.129c-0.251,0.77-0.093,1.507,0.353,1.65"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.285,129.366c-0.389-0.109-0.884,0.43-1.105,1.207c-0.224,0.775-0.089,1.494,0.298,1.605"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.241,132.396c0.256,0.074,0.563-0.137,0.807-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.456,130.444c-0.007-0.477-0.168-0.786-0.407-0.859"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.133,129.846c-0.326-0.095-0.747,0.361-0.937,1.019c-0.188,0.654-0.072,1.265,0.256,1.359c0.188,0.053,0.403-0.074,0.591-0.317    "/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.472,130.427c-0.033-0.326-0.163-0.53-0.338-0.581"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.007,130.285c-0.205-0.06-0.47,0.228-0.589,0.642c-0.118,0.414-0.047,0.8,0.161,0.859"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.675,130.474c-0.209-0.06-0.47,0.228-0.591,0.645c-0.119,0.414-0.046,0.796,0.161,0.858c0.205,0.06,0.47-0.23,0.591-0.645    C94.952,130.918,94.884,130.535,94.675,130.474"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="94.007" y1="130.285" x2="94.675" y2="130.474"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="94.246" y1="131.977" x2="93.579" y2="131.786"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="93.285" y1="129.366" x2="94.048" y2="129.585"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="93.241" y1="132.396" x2="92.478" y2="132.177"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="92.922" y1="130.867" x2="92.159" y2="130.648"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.285,129.366c-0.389-0.109-0.884,0.43-1.105,1.207c-0.224,0.775-0.089,1.494,0.298,1.605"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M80.378,146.932c-0.335-0.07-0.742,0.512-0.908,1.305c-0.168,0.787-0.035,1.486,0.298,1.557"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M80.545,149.958c0.221,0.049,0.475-0.193,0.673-0.596"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.525,147.906c-0.019-0.47-0.165-0.761-0.37-0.807"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.258,147.351c-0.284-0.06-0.626,0.431-0.77,1.1c-0.142,0.67-0.028,1.261,0.254,1.321c0.161,0.033,0.346-0.112,0.496-0.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.562,147.891c-0.037-0.321-0.154-0.507-0.303-0.54"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.162,147.799c-0.179-0.04-0.393,0.272-0.482,0.693c-0.089,0.423-0.019,0.794,0.158,0.833"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M81.841,147.942c-0.175-0.037-0.393,0.272-0.482,0.693c-0.089,0.423-0.019,0.796,0.158,0.833c0.181,0.037,0.396-0.272,0.486-0.693    C82.092,148.353,82.021,147.981,81.841,147.942"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="81.162" y1="147.799" x2="81.841" y2="147.942"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="81.516" y1="149.468" x2="80.838" y2="149.324"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="80.378" y1="146.932" x2="81.155" y2="147.099"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="80.545" y1="149.958" x2="79.768" y2="149.794"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="80.238" y1="148.47" x2="79.461" y2="148.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M80.378,146.932c-0.335-0.07-0.742,0.512-0.908,1.305c-0.168,0.787-0.035,1.486,0.298,1.557"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.941,156.028c-0.275-0.042-0.596,0.566-0.724,1.366c-0.126,0.796-0.007,1.479,0.265,1.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M76.265,159.039c0.175,0.028,0.37-0.212,0.526-0.607"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M77.033,156.89c-0.023-0.447-0.144-0.71-0.309-0.738"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M76.776,156.385c-0.233-0.035-0.505,0.484-0.614,1.159c-0.105,0.675-0.007,1.249,0.224,1.286c0.133,0.023,0.279-0.137,0.4-0.407"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M77.026,156.899c-0.032-0.314-0.13-0.491-0.251-0.514"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M76.704,156.838c-0.147-0.023-0.319,0.303-0.388,0.731c-0.067,0.424-0.004,0.787,0.142,0.812"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M77.388,156.947c-0.146-0.023-0.319,0.305-0.386,0.728c-0.068,0.428-0.002,0.791,0.144,0.816c0.146,0.023,0.316-0.303,0.386-0.731    C77.596,157.334,77.533,156.971,77.388,156.947"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="76.704" y1="156.838" x2="77.388" y2="156.947"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="77.146" y1="158.49" x2="76.458" y2="158.381"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="75.941" y1="156.028" x2="76.725" y2="156.152"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="76.265" y1="159.039" x2="75.481" y2="158.914"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M75.941,156.028c-0.275-0.042-0.596,0.566-0.724,1.366c-0.126,0.796-0.007,1.479,0.265,1.521"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="75.986" y1="157.589" x2="75.201" y2="157.467"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M72.956,165.186c-0.203-0.035-0.475,0.588-0.602,1.382c-0.125,0.8-0.061,1.473,0.146,1.507"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.284,168.196c0.132,0.023,0.291-0.226,0.423-0.623"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.952,166.032c-0.004-0.442-0.084-0.702-0.209-0.723"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.791,165.546c-0.172-0.03-0.402,0.495-0.507,1.172c-0.109,0.675-0.053,1.243,0.121,1.27c0.098,0.016,0.217-0.147,0.319-0.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.968,166.046c-0.016-0.312-0.084-0.486-0.177-0.5"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.719,165.995c-0.107-0.018-0.253,0.314-0.319,0.742c-0.068,0.423-0.033,0.784,0.077,0.801"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M74.406,166.105c-0.109-0.018-0.254,0.312-0.323,0.738c-0.065,0.424-0.032,0.786,0.077,0.803c0.11,0.018,0.256-0.314,0.324-0.742    C74.552,166.482,74.517,166.121,74.406,166.105"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.719" y1="165.995" x2="74.406" y2="166.105"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="74.161" y1="167.647" x2="73.477" y2="167.538"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="72.956" y1="165.184" x2="73.74" y2="165.309"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.284" y1="168.196" x2="72.5" y2="168.075"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M72.956,165.186c-0.203-0.035-0.475,0.588-0.602,1.382c-0.125,0.8-0.061,1.473,0.146,1.507"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.133" y1="166.763" x2="72.349" y2="166.637"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M70.602,175.278c-0.14-0.009-0.298,0.633-0.354,1.442c-0.058,0.803,0.011,1.466,0.151,1.477"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.191,178.249c0.088,0.007,0.188-0.256,0.261-0.663"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.561,176.028c-0.016-0.44-0.082-0.689-0.167-0.696"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.467,175.564c-0.119-0.009-0.254,0.538-0.302,1.217c-0.047,0.682,0.011,1.243,0.13,1.252c0.067,0.002,0.14-0.174,0.198-0.454"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.602,176.039c-0.021-0.307-0.072-0.472-0.135-0.475"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M71.435,176.02c-0.077-0.007-0.161,0.339-0.191,0.768c-0.028,0.43,0.005,0.784,0.082,0.789"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M72.126,176.069c-0.074-0.007-0.158,0.338-0.189,0.768c-0.03,0.43,0.005,0.78,0.082,0.786c0.074,0.007,0.158-0.337,0.189-0.768    C72.238,176.425,72.2,176.072,72.126,176.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="71.435" y1="176.02" x2="72.126" y2="176.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="72.019" y1="177.623" x2="71.326" y2="177.577"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="70.602" y1="175.278" x2="71.395" y2="175.332"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="71.191" y1="178.249" x2="70.399" y2="178.196"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M70.602,175.278c-0.14-0.009-0.298,0.633-0.354,1.442c-0.058,0.803,0.011,1.466,0.151,1.477"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="70.253" y1="176.662" x2="70.946" y2="176.709"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M297.636,65.091c0.095-0.773-0.479-1.482-1.279-1.578c-0.802-0.1-1.529,0.447-1.624,1.224"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M294.637,65.522c-0.067,0.547,0.198,1.061,0.651,1.356"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.578,67.037c0.54-0.182,0.894-0.638,0.961-1.158"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M294.842,65.705c-0.049,0.391,0.107,0.766,0.388,1.021"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.673,66.902c0.358-0.191,0.577-0.528,0.624-0.896c0.079-0.656-0.405-1.254-1.084-1.338c-0.677-0.084-1.293,0.381-1.371,1.037"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.843,65.95c0.051-0.414-0.254-0.791-0.682-0.844c-0.428-0.051-0.814,0.239-0.866,0.652"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M295.21,66.448c-0.051,0.414,0.254,0.791,0.682,0.844c0.428,0.053,0.816-0.239,0.866-0.652c0.049-0.412-0.254-0.791-0.682-0.842    C295.649,65.745,295.261,66.034,295.21,66.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.294" y1="65.759" x2="295.21" y2="66.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="296.759" y1="66.639" x2="296.843" y2="65.95"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="294.733" y1="64.736" x2="294.637" y2="65.522"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="297.539" y1="65.88" x2="297.636" y2="65.091"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="296.259" y1="64.298" x2="296.357" y2="63.512"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M314.271,66.371c0.189-0.758-0.293-1.531-1.077-1.728c-0.786-0.195-1.571,0.261-1.761,1.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M311.242,66.431c-0.133,0.537,0.067,1.079,0.481,1.424"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M312.985,68.17c0.556-0.114,0.965-0.524,1.093-1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M311.423,66.636c-0.096,0.384,0.011,0.773,0.261,1.061"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M313.094,68.049c0.377-0.144,0.637-0.454,0.73-0.816c0.158-0.64-0.249-1.293-0.914-1.459c-0.661-0.163-1.329,0.223-1.487,0.861"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M313.378,67.123c0.102-0.403-0.156-0.814-0.575-0.919c-0.417-0.103-0.837,0.14-0.938,0.544"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M311.696,67.42c-0.098,0.403,0.156,0.816,0.575,0.923c0.417,0.103,0.84-0.14,0.938-0.545c0.103-0.403-0.156-0.816-0.574-0.919    C312.217,66.774,311.798,67.018,311.696,67.42"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="311.865" y1="66.748" x2="311.696" y2="67.42"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="313.21" y1="67.797" x2="313.378" y2="67.123"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="311.433" y1="65.662" x2="311.242" y2="66.431"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="314.078" y1="67.139" x2="314.271" y2="66.371"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="313.001" y1="65.417" x2="313.194" y2="64.643"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M314.271,66.371c0.189-0.758-0.293-1.531-1.077-1.728c-0.786-0.195-1.571,0.261-1.761,1.019"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M330.875,68.59c0.288-0.673-0.084-1.477-0.826-1.791c-0.745-0.317-1.577-0.025-1.864,0.649"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.874,68.177c-0.203,0.477-0.077,1.017,0.282,1.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.354,70.096c0.563-0.014,1.016-0.324,1.214-0.775"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M328.021,68.409c-0.147,0.34-0.089,0.721,0.114,1.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.475,70.012c0.389-0.074,0.684-0.317,0.823-0.638c0.244-0.568-0.072-1.249-0.7-1.515c-0.628-0.265-1.336-0.019-1.577,0.551"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.877,69.196c0.154-0.361-0.044-0.787-0.44-0.956c-0.396-0.168-0.842-0.014-0.994,0.347"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M328.168,69.225c-0.153,0.36,0.046,0.789,0.442,0.958c0.398,0.168,0.844,0.012,0.996-0.346c0.151-0.361-0.044-0.789-0.444-0.958    C328.766,68.712,328.321,68.867,328.168,69.225"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="328.442" y1="68.588" x2="328.168" y2="69.225"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="329.606" y1="69.837" x2="329.877" y2="69.196"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="328.184" y1="67.448" x2="327.874" y2="68.177"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="330.568" y1="69.321" x2="330.875" y2="68.59"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="329.733" y1="67.537" x2="330.043" y2="66.809"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M330.875,68.59c0.288-0.673-0.084-1.477-0.826-1.791c-0.745-0.317-1.577-0.025-1.864,0.649"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M346.293,71.261c0.358-0.64,0.072-1.477-0.633-1.87c-0.707-0.389-1.568-0.188-1.922,0.452"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.35,70.536c-0.249,0.452-0.181,1.003,0.135,1.433"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M344.623,72.597c0.561,0.047,1.045-0.214,1.286-0.644"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.472,70.782c-0.181,0.323-0.165,0.707,0.005,1.038"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M344.751,72.527c0.398-0.032,0.716-0.244,0.887-0.547c0.3-0.54,0.06-1.249-0.538-1.58c-0.6-0.33-1.328-0.16-1.628,0.382"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M345.238,71.759c0.189-0.342,0.035-0.789-0.342-0.996c-0.374-0.209-0.835-0.102-1.024,0.239"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.536,71.608c-0.189,0.344-0.037,0.789,0.342,0.998c0.375,0.209,0.835,0.1,1.024-0.24c0.189-0.342,0.037-0.789-0.339-0.996    C344.184,71.161,343.725,71.268,343.536,71.608"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.872" y1="71.001" x2="343.536" y2="71.608"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="344.902" y1="72.366" x2="345.238" y2="71.759"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.737" y1="69.84" x2="343.35" y2="70.536"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="345.908" y1="71.953" x2="346.293" y2="71.261"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="345.272" y1="70.093" x2="345.659" y2="69.398"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M346.293,71.261c0.358-0.64,0.072-1.477-0.633-1.87c-0.707-0.389-1.568-0.188-1.922,0.452"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.658,99.955c0.06-0.505-0.133-0.93-0.477-1.156"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.188,99.07c-0.361-0.235-0.849-0.16-1.231,0.158"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.598,99.894c0-0.367-0.16-0.658-0.41-0.824"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.939,99.456c-0.177-0.116-0.4-0.114-0.605-0.014"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.523,99.831c-0.165-0.103-0.368-0.11-0.558-0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M133.774,100.259c-0.021-0.193-0.116-0.338-0.251-0.428"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="132.939" y1="99.456" x2="133.523" y2="99.831"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="132.515" y1="98.365" x2="133.181" y2="98.8"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.515,98.365c-0.463-0.3-1.107-0.17-1.568,0.289"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M222.571,105.783c0.232,0.31,0.749,0.31,1.151,0.005c0.4-0.307,0.538-0.802,0.302-1.11c-0.232-0.307-0.749-0.31-1.149-0.004    C222.471,104.98,222.334,105.478,222.571,105.783"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M207.178,109.372c0.237,0.307,0.751,0.309,1.154,0.004c0.4-0.305,0.535-0.802,0.303-1.112c-0.237-0.305-0.751-0.307-1.154-0.002    C207.079,108.565,206.944,109.063,207.178,109.372"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M214.158,105.066c0.232,0.31,0.749,0.312,1.149,0.005c0.403-0.307,0.54-0.802,0.303-1.11c-0.232-0.307-0.749-0.31-1.149-0.005    C214.058,104.263,213.921,104.761,214.158,105.066"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.135,88.633c0.235,0.309,0.751,0.31,1.151,0.005c0.403-0.307,0.54-0.805,0.303-1.11c-0.233-0.31-0.747-0.312-1.151-0.007    C203.038,87.829,202.902,88.326,203.135,88.633"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M195.376,93.263c0.232,0.309,0.749,0.31,1.151,0.005c0.4-0.305,0.538-0.805,0.302-1.112c-0.232-0.309-0.749-0.31-1.149-0.002    C195.276,92.459,195.139,92.956,195.376,93.263"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M211.025,89.22c0.237,0.307,0.751,0.309,1.152,0.004c0.403-0.305,0.538-0.803,0.303-1.112c-0.233-0.307-0.749-0.307-1.152-0.004    C210.929,88.413,210.794,88.911,211.025,89.22"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.437,116.349c0.235,0.309,0.749,0.31,1.151,0.005c0.403-0.307,0.538-0.805,0.305-1.11c-0.235-0.31-0.749-0.312-1.152-0.004    C264.341,115.545,264.204,116.042,264.437,116.349"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.227,210.506c0.233,0.307,0.749,0.31,1.149,0.004c0.405-0.305,0.54-0.803,0.303-1.112c-0.232-0.307-0.749-0.307-1.151-0.004    C264.128,209.699,263.993,210.197,264.227,210.506"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M263.171,308.759c1.621,1.438,3.446,2.754,5.249,3.816"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M236.654,332.492c0-11.297-12.526-20.904-30.306-24.505"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M206.348,357.001c18.507-3.667,30.182-13.626,30.306-24.508"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.398,332.492c0-9.925-10.683-18.414-26.05-21.892"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M206.348,354.387c16.048-3.562,25.939-12.365,26.05-21.895"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.217,374.706c-8.047-3.401-17.402-5.028-26.516-5.004c-24.787,0-44.877,11.602-44.877,25.911    c0,7.698,5.816,14.613,15.094,19.38"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M282.54,376.623c-7.252-3.036-15.66-4.485-23.84-4.465c-22.433,0-40.624,10.502-40.624,23.456c0,6.924,5.204,13.152,13.513,17.463    "/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="206.35" y1="378.624" x2="245.339" y2="348.9"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M401.491,337.861c-8.334-9.408-19.489-17.437-31.852-23.54"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M400.969,347.798c-1.205-1.871-2.524-3.695-3.769-5.239"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.902,340.983c-7.736-9.125-18.268-16.932-30.056-22.882"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.493,353.379c-9.874-11.966-24.554-21.716-41.489-28.593"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="401.491" y1="337.861" x2="401.488" y2="339.696"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="401.488" y1="339.696" x2="397.2" y2="342.56"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="397.2" y1="342.56" x2="397.2" y2="350.699"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="400.97" y1="347.802" x2="400.97" y2="350.21"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="453.73" y1="320.34" x2="452.679" y2="320.947"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="452.679" y1="320.947" x2="449.34" y2="316.073"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M385.216,364.127c0.277,0.272,0.787,0.202,1.142-0.158s0.421-0.87,0.147-1.142c-0.277-0.274-0.787-0.202-1.142,0.154    C385.009,363.343,384.944,363.851,385.216,364.127"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="268.841" y1="424.799" x2="359.771" y2="359.102"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M268.841,424.799c-3.581-0.342-7.205-0.838-10.804-1.498"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M243.514,419.602c-4.656-1.563-9.06-3.473-13.021-5.756"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="263.402" y1="392.316" x2="222.752" y2="448.083"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="234.25" y1="448.085" x2="246.674" y2="439.067"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="246.674" y1="439.067" x2="275.949" y2="398.449"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="275.949" y1="419.687" x2="275.949" y2="384.138"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="263.402" y1="392.316" x2="264.474" y2="392.63"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="274.76" y1="395.657" x2="275.949" y2="396.008"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="244.857" y1="437.146" x2="274.76" y2="395.657"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="244.857" y1="437.146" x2="244.857" y2="439.533"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="264.309" y1="394.849" x2="225.505" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="264.474" y1="392.63" x2="264.309" y2="394.849"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="264.309" y1="394.849" x2="273.34" y2="397.63"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M245.497,431.851c0,0.409,0.579,0.745,1.293,0.745c0.712,0,1.293-0.337,1.293-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.082,431.204c0-0.412-0.581-0.747-1.293-0.747c-0.714,0-1.293,0.335-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="248.079" y1="431.204" x2="248.079" y2="431.843"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="245.497" y1="431.204" x2="245.497" y2="431.851"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M247.886,431.106c0-0.347-0.491-0.633-1.096-0.633c-0.603,0-1.094,0.286-1.094,0.633c0,0.351,0.491,0.631,1.094,0.631    C247.395,431.737,247.886,431.457,247.886,431.106"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="246.806" y1="431.955" x2="246.806" y2="432.595"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="252.272" y1="442.11" x2="252.272" y2="434.305"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="252.272" y1="442.11" x2="247.186" y2="444.83"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M247.186,444.83c-1.021-0.691-2.541-1.677-4.341-2.967"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.294,433.626c-3.734-3.965-7.278-8.941-9.462-15.004"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="251.739" y1="441.717" x2="247.195" y2="439.139"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="247.23" y1="443.9" x2="247.23" y2="438.344"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="252.272" y1="442.11" x2="254.433" y2="443.527"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.433" y1="443.527" x2="254.433" y2="448.085"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M221.024,420.632c0.568,4.846,2.79,11.247,8.385,18.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M236.491,446.452c0.595,0.544,1.21,1.086,1.847,1.631"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.565" y1="436.745" x2="264.498" y2="448.083"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M280.387,435.327c-10.721-1.794-19.448-2.89-27.183-5.335"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M240.401,423.878c-3.559-2.431-7.014-5.54-10.499-9.599"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M229.902,414.28c0.84,4.102,3.502,8.897,7.44,13.787"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="275.949" y1="384.138" x2="277.321" y2="384.138"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="277.321" y1="384.138" x2="277.321" y2="388.437"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="283.735" y1="388.716" x2="283.735" y2="414.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.687" y1="388.716" x2="277.321" y2="388.716"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.687" y1="388.716" x2="284.941" y2="388.251"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="284.941" y1="388.251" x2="284.941" y2="383.605"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="284.941" y1="383.605" x2="286.199" y2="383.14"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="286.199" y1="383.14" x2="304.114" y2="393.495"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="304.114" y1="393.495" x2="304.114" y2="399.433"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="284.941" y1="388.251" x2="295.585" y2="394.401"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="300.636" y1="397.32" x2="303.113" y2="398.753"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.585" y1="391.409" x2="295.585" y2="396.617"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.585" y1="391.409" x2="296.932" y2="391.643"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="296.932" y1="391.643" x2="301.115" y2="398.056"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.115" y1="398.056" x2="301.115" y2="401.582"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.585" y1="396.617" x2="299.554" y2="402.704"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.735" y1="395.221" x2="291.357" y2="404.654"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="291.401" y1="404.376" x2="291.401" y2="407.117"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.735" y1="399.125" x2="291.357" y2="408.557"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.585" y1="395.092" x2="285.353" y2="397.223"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="306.422" y1="381.872" x2="306.422" y2="384.801"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="306.422" y1="381.872" x2="307.259" y2="381.269"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="307.259" y1="381.269" x2="319.762" y2="384.708"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="319.762" y1="384.708" x2="319.762" y2="388.163"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.422" y1="384.801" x2="302.801" y2="388.984"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.078" y1="389.373" x2="301.078" y2="391.741"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.078" y1="389.373" x2="301.915" y2="388.766"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.915" y1="388.766" x2="310.839" y2="391.222"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="310.839" y1="391.222" x2="310.839" y2="393.582"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="304.114" y1="393.495" x2="310.076" y2="395.133"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.422" y1="384.801" x2="317.991" y2="387.982"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="324.571" y1="384.357" x2="318.494" y2="384.357"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.422" y1="384.357" x2="290.834" y2="384.357"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="290.834" y1="384.357" x2="289.689" y2="385.159"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="290.834" y1="384.357" x2="290.834" y2="378.969"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="290.834" y1="378.969" x2="293.933" y2="378.969"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="297.478" y1="378.969" x2="324.571" y2="378.969"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="324.571" y1="384.357" x2="324.571" y2="378.969"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M274.944,382.063c1.677,0.521,3.487,0.761,5.2,0.752c5.505,0,10.069-2.331,10.891-5.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="297.478" y1="365.942" x2="297.478" y2="380.083"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="297.478" y1="380.083" x2="296.873" y2="380.502"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M296.873,380.502c-3.206-1.533-7.331-4.2-11.788-5.851"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="301.035" y1="373.183" x2="305.121" y2="378.969"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.035" y1="373.183" x2="301.035" y2="370.16"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.035" y1="370.16" x2="358.983" y2="355.941"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="358.983" y1="355.941" x2="359.771" y2="356.175"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="359.771" y1="356.175" x2="359.771" y2="359.102"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="301.684" y1="373.09" x2="304.384" y2="372.426"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="305.503" y1="372.15" x2="359.632" y2="358.869"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="306.236" y1="378.969" x2="306.236" y2="376.667"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="306.236" y1="376.667" x2="340.989" y2="368.141"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="309.446" y1="378.969" x2="341.824" y2="371.021"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="340.989" y1="368.141" x2="341.824" y2="368.478"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="341.824" y1="368.478" x2="341.824" y2="371.021"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M307.05,390.182c0.293-0.149,0.444-0.354,0.445-0.563"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M307.496,388.972c0-0.409-0.581-0.744-1.293-0.744c-0.712,0-1.293,0.335-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="307.496" y1="388.972" x2="307.496" y2="389.61"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="304.911" y1="388.972" x2="304.911" y2="389.619"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M307.299,388.877c0-0.349-0.491-0.633-1.096-0.633c-0.605,0-1.096,0.284-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    C306.808,389.508,307.299,389.224,307.299,388.877"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.221" y1="389.726" x2="306.221" y2="389.95"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M306.561,375.981c0,0.205,0.144,0.391,0.377,0.526"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M309.144,375.335c0-0.414-0.579-0.745-1.291-0.745c-0.714,0-1.293,0.331-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="309.144" y1="375.335" x2="309.144" y2="375.97"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.561" y1="375.335" x2="306.561" y2="375.981"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M308.948,375.235c0-0.347-0.489-0.633-1.094-0.633s-1.096,0.286-1.096,0.633c0,0.351,0.491,0.631,1.096,0.631    S308.948,375.586,308.948,375.235"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="307.868" y1="376.086" x2="307.868" y2="376.268"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.262,370.751c0,0.258,0.223,0.484,0.568,0.619"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.824,370.877c0.014-0.044,0.018-0.088,0.018-0.126"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.841,370.107c0-0.414-0.577-0.745-1.289-0.745c-0.714,0-1.291,0.331-1.291,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="329.841" y1="370.107" x2="329.841" y2="370.742"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="327.262" y1="370.107" x2="327.262" y2="370.751"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.645,370.007c0-0.347-0.489-0.631-1.093-0.631c-0.607,0-1.096,0.284-1.096,0.631c0,0.351,0.489,0.631,1.096,0.631    C329.156,370.639,329.645,370.358,329.645,370.007"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="328.568" y1="370.858" x2="328.568" y2="371.188"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="329.787" y1="380.765" x2="324.571" y2="380.739"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.331" y1="354.922" x2="311.759" y2="367.529"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="301.203" y1="373.424" x2="297.478" y2="375.506"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="334.331" y1="354.922" x2="334.331" y2="349.592"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="334.331" y1="349.592" x2="332.934" y2="348.963"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="332.934" y1="348.963" x2="297.478" y2="368.767"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="323.987" y1="346.977" x2="328.144" y2="351.641"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="334.331" y1="354.922" x2="340.703" y2="360.428"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="274.199" y1="388.328" x2="264.379" y2="393.902"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="260.742" y1="395.968" x2="235.39" y2="410.353"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="274.199" y1="388.328" x2="274.199" y2="387.12"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="274.199" y1="387.12" x2="270.749" y2="384.831"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="280.387" y1="435.327" x2="285.478" y2="431.66"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="280.565" y1="435.199" x2="280.565" y2="436.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="285.478" y1="431.66" x2="285.478" y2="421.393"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M285.478,421.393c-4.576-0.246-7.784-0.449-10.762-0.838"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.779,424.122c6.328,0.816,9.106,1.172,11.123,1.217"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.902" y1="425.339" x2="284.133" y2="423.008"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M283.728,430.901c-6.142-0.403-21.746-1.98-27.53-5.049"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M390.658,342.74c0.442-0.03,0.738-0.903,0.665-1.95c-0.075-1.045-0.493-1.864-0.935-1.833"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.388,338.957c-0.442,0.03-0.742,0.903-0.666,1.95c0.075,1.045,0.493,1.864,0.937,1.833"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M370.333,328.65c0.589-0.126,0.893-1.058,0.673-2.084c-0.219-1.024-0.877-1.754-1.47-1.626"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M369.536,324.94c-0.591,0.126-0.894,1.059-0.672,2.085c0.219,1.024,0.877,1.754,1.468,1.624"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="264.135" x2="437.291" y2="268.924"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.933" y1="260.999" x2="438.361" y2="266.487"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="437.291" y1="268.924" x2="438.361" y2="268.468"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M156.016,125.512c-0.153,0.102-0.228,0.223-0.226,0.346c0,0.288,0.402,0.521,0.9,0.521c0.27,0,0.51-0.067,0.677-0.175"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.791,125.074c0-0.288-0.402-0.521-0.9-0.521c-0.267,0-0.51,0.067-0.673,0.175"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.568,125.419c0.149-0.1,0.226-0.224,0.223-0.346"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="158.568" y1="125.419" x2="157.365" y2="126.203"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.218" y1="124.728" x2="156.016" y2="125.512"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.947,132.824c-0.116,0.093-0.17,0.202-0.168,0.303c0,0.288,0.403,0.523,0.9,0.523c0.302,0,0.565-0.084,0.73-0.217"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.643,132.279c0-0.288-0.403-0.517-0.901-0.517c-0.3,0-0.563,0.084-0.731,0.216"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.473,132.586c0.114-0.093,0.17-0.2,0.17-0.307"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="148.473" y1="132.586" x2="147.408" y2="133.433"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.01" y1="131.977" x2="145.947" y2="132.824"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M137.071,141.043c0.093,0.24,0.461,0.407,0.879,0.409c0.333,0,0.623-0.103,0.78-0.258"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M139.765,140.025c0-0.288-0.403-0.521-0.9-0.521c-0.175,0-0.342,0.032-0.477,0.081"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M139.642,140.287c0.084-0.082,0.125-0.172,0.123-0.261"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="139.642" y1="140.287" x2="138.73" y2="141.194"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M130.357,149.693c0.43,0.065,0.868-0.058,1.051-0.286"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.248,148.237c0-0.212-0.225-0.396-0.551-0.479"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.166,148.454c0.054-0.072,0.082-0.147,0.082-0.217"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="132.166" y1="148.454" x2="131.408" y2="149.407"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,186.744c0.316,0.181,0.71,0.146,1.072-0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,185.101c-0.005-0.438-0.202-0.777-0.496-0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.304,183.705c-0.549-0.314-1.324,0.014-1.733,0.73c-0.409,0.716-0.295,1.55,0.251,1.864"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.304" y1="183.705" x2="163.086" y2="184.15"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="186.744" x2="160.822" y2="186.297"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.891,184.706c-0.335-0.189-0.809,0.009-1.059,0.447c-0.251,0.438-0.182,0.951,0.153,1.14"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.649,186.676c0.335,0.189,0.812-0.009,1.063-0.447c0.249-0.438,0.179-0.951-0.154-1.142c-0.337-0.193-0.814,0.007-1.065,0.445    C162.244,185.971,162.314,186.483,162.649,186.676"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.582" y1="185.101" x2="162.891" y2="184.706"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="186.294" x2="162.649" y2="186.673"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.4" y1="184.806" x2="160.622" y2="184.359"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.303,192.632c0.232,0.305,0.749,0.309,1.149,0.002c0.402-0.305,0.54-0.803,0.303-1.11c-0.232-0.309-0.749-0.309-1.149-0.005    C171.203,191.827,171.066,192.322,171.303,192.632"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.808,278.888c0-0.151-0.112-0.288-0.293-0.384"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.298,279.27c0.174,0.093,0.389,0.137,0.607,0.137c0.5,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.18,277.794c-0.175-0.091-0.395-0.135-0.61-0.135c-0.496,0-0.901,0.232-0.901,0.521c0,0.153,0.114,0.288,0.293,0.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="135.182" y1="277.795" x2="136.517" y2="278.504"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.962" y1="278.562" x2="135.296" y2="279.27"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M136.808,278.888c0-0.151-0.112-0.288-0.293-0.384"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.298,279.27c0.174,0.093,0.389,0.137,0.607,0.137c0.5,0,0.903-0.232,0.903-0.519"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M135.18,277.794c-0.175-0.091-0.395-0.135-0.61-0.135c-0.496,0-0.901,0.232-0.901,0.521c0,0.153,0.114,0.288,0.293,0.382"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="135.182" y1="277.795" x2="136.517" y2="278.504"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="133.962" y1="278.562" x2="135.296" y2="279.27"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.581,285.13c0-0.168-0.135-0.314-0.346-0.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.127,285.54c0.167,0.072,0.363,0.111,0.554,0.111c0.498,0,0.9-0.235,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M147.808,284.074c-0.167-0.075-0.361-0.11-0.556-0.11c-0.495,0-0.9,0.232-0.9,0.517c0,0.168,0.135,0.314,0.346,0.412"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.808" y1="284.074" x2="149.236" y2="284.718"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="146.698" y1="284.893" x2="148.126" y2="285.537"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M149.581,285.13c0-0.168-0.135-0.314-0.346-0.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M148.127,285.54c0.167,0.072,0.363,0.111,0.554,0.111c0.498,0,0.9-0.235,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M147.808,284.074c-0.167-0.075-0.361-0.11-0.556-0.11c-0.495,0-0.9,0.232-0.9,0.517c0,0.168,0.135,0.314,0.346,0.412"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="147.808" y1="284.074" x2="149.236" y2="284.718"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="146.698" y1="284.893" x2="148.126" y2="285.537"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.165,290.77c0-0.177-0.158-0.337-0.402-0.431"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.769,291.207c0.153,0.06,0.328,0.086,0.496,0.086c0.498,0,0.9-0.235,0.9-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.25,289.758c-0.154-0.058-0.326-0.086-0.496-0.086c-0.496,0-0.901,0.232-0.901,0.519c0,0.182,0.16,0.34,0.407,0.435"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.253" y1="289.758" x2="162.765" y2="290.339"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="160.257" y1="290.626" x2="161.765" y2="291.207"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.165,290.77c0-0.177-0.158-0.337-0.402-0.431"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.769,291.207c0.153,0.06,0.328,0.086,0.496,0.086c0.498,0,0.9-0.235,0.9-0.523"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.25,289.758c-0.154-0.058-0.326-0.086-0.496-0.086c-0.496,0-0.901,0.232-0.901,0.519c0,0.182,0.16,0.34,0.407,0.435"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.253" y1="289.758" x2="162.765" y2="290.339"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="160.257" y1="290.626" x2="161.765" y2="291.207"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.618,239.97c0-0.079-0.032-0.154-0.084-0.221"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.901,240.189c0.151,0.189,0.474,0.3,0.814,0.302c0.5,0,0.903-0.235,0.903-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.771,238.799c-0.154-0.188-0.474-0.298-0.819-0.298c-0.496,0-0.901,0.232-0.901,0.521c0,0.079,0.032,0.154,0.084,0.219"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.771" y1="238.802" x2="87.534" y2="239.751"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.134" y1="239.241" x2="85.899" y2="240.188"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M87.618,239.97c0-0.079-0.032-0.154-0.084-0.221"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.901,240.189c0.151,0.189,0.474,0.3,0.814,0.302c0.5,0,0.903-0.235,0.903-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.771,238.799c-0.154-0.188-0.474-0.298-0.819-0.298c-0.496,0-0.901,0.232-0.901,0.521c0,0.079,0.032,0.154,0.084,0.219"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.771" y1="238.802" x2="87.534" y2="239.751"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.134" y1="239.241" x2="85.899" y2="240.188"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.284,248.56c0-0.095-0.044-0.186-0.118-0.26"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.599,248.815c0.163,0.167,0.467,0.263,0.784,0.263c0.496,0,0.901-0.232,0.901-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.277,247.389c-0.167-0.168-0.468-0.261-0.786-0.263c-0.498,0-0.9,0.232-0.9,0.521c0,0.095,0.042,0.181,0.116,0.256"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="94.277" y1="247.391" x2="95.166" y2="248.304"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="92.708" y1="247.903" x2="93.599" y2="248.813"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M95.284,248.56c0-0.095-0.044-0.186-0.118-0.26"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M93.599,248.815c0.163,0.167,0.467,0.263,0.784,0.263c0.496,0,0.901-0.232,0.901-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M94.277,247.389c-0.167-0.168-0.468-0.261-0.786-0.263c-0.498,0-0.9,0.232-0.9,0.521c0,0.095,0.042,0.181,0.116,0.256"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="94.277" y1="247.391" x2="95.166" y2="248.304"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="92.708" y1="247.903" x2="93.599" y2="248.813"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.094,256.774c0-0.109-0.056-0.21-0.154-0.289"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.445,257.063c0.174,0.149,0.456,0.232,0.749,0.232c0.495,0,0.9-0.235,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.927,255.613c-0.172-0.147-0.454-0.23-0.745-0.23c-0.5,0-0.903,0.235-0.903,0.523c0,0.109,0.06,0.209,0.154,0.291"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.927" y1="255.613" x2="103.939" y2="256.484"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.431" y1="256.193" x2="102.445" y2="257.063"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.094,256.774c0-0.109-0.056-0.21-0.154-0.289"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.445,257.063c0.174,0.149,0.456,0.232,0.749,0.232c0.495,0,0.9-0.235,0.9-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M102.927,255.613c-0.172-0.147-0.454-0.23-0.745-0.23c-0.5,0-0.903,0.235-0.903,0.523c0,0.109,0.06,0.209,0.154,0.291"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="102.927" y1="255.613" x2="103.939" y2="256.484"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.431" y1="256.193" x2="102.445" y2="257.063"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.99,264.564c0-0.125-0.074-0.237-0.195-0.323"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.386,264.887c0.177,0.13,0.438,0.198,0.707,0.198c0.495,0,0.898-0.235,0.898-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.668,263.419c-0.177-0.128-0.438-0.195-0.703-0.195c-0.5,0-0.901,0.23-0.901,0.519c0,0.121,0.074,0.237,0.195,0.323"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.672" y1="263.419" x2="113.796" y2="264.242"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="111.258" y1="264.066" x2="112.384" y2="264.885"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M113.99,264.564c0-0.125-0.074-0.237-0.195-0.323"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.386,264.887c0.177,0.13,0.438,0.198,0.707,0.198c0.495,0,0.898-0.235,0.898-0.521"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M112.668,263.419c-0.177-0.128-0.438-0.195-0.703-0.195c-0.5,0-0.901,0.23-0.901,0.519c0,0.121,0.074,0.237,0.195,0.323"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.672" y1="263.419" x2="113.796" y2="264.242"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="111.258" y1="264.066" x2="112.384" y2="264.885"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.92,271.883c0-0.137-0.093-0.263-0.242-0.354"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.358,272.237c0.177,0.111,0.417,0.163,0.661,0.163c0.496,0,0.901-0.23,0.901-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.442,270.761c-0.177-0.11-0.417-0.167-0.659-0.167c-0.496,0-0.9,0.233-0.9,0.521c0,0.137,0.093,0.263,0.24,0.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.442" y1="270.761" x2="124.678" y2="271.529"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.123" y1="271.469" x2="123.356" y2="272.237"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M124.92,271.883c0-0.137-0.093-0.263-0.242-0.354"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.358,272.237c0.177,0.111,0.417,0.163,0.661,0.163c0.496,0,0.901-0.23,0.901-0.517"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M123.442,270.761c-0.177-0.11-0.417-0.167-0.659-0.167c-0.496,0-0.9,0.233-0.9,0.521c0,0.137,0.093,0.263,0.24,0.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.442" y1="270.761" x2="124.678" y2="271.529"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.123" y1="271.469" x2="123.356" y2="272.237"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.634" y1="221.192" x2="74.129" y2="222.2"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M74.129,222.202c0.089,0.179,0.335,0.312,0.633,0.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="73.634" y1="221.192" x2="74.129" y2="222.2"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M79.34,229.754c-0.382,0.07-0.628,0.274-0.633,0.495c0,0.067,0.021,0.13,0.058,0.182"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="78.764" y1="230.431" x2="79.396" y2="231.413"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M79.396,231.413c0.137,0.209,0.475,0.337,0.845,0.34c0.174,0,0.335-0.03,0.47-0.075"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="78.764" y1="230.431" x2="79.396" y2="231.413"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M73.634,221.192c-0.23-0.475-0.023-0.852,0.046-1.024"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M146.917,268.311c0.488,0.463,1.463,0.224,2.182-0.533c0.721-0.754,0.908-1.745,0.421-2.206"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M149.52,265.571c-0.486-0.461-1.464-0.223-2.182,0.535c-0.717,0.758-0.908,1.745-0.421,2.205"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M104.939,233.377c0.519,0.61,1.584,0.554,2.378-0.123c0.793-0.679,1.015-1.719,0.496-2.327"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M107.813,230.927c-0.517-0.61-1.582-0.554-2.375,0.123c-0.794,0.679-1.021,1.719-0.5,2.327"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M89.502,190.734c0.24,0.873,1.254,1.352,2.261,1.073c1.003-0.275,1.624-1.208,1.384-2.078"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M93.146,189.729c-0.24-0.873-1.254-1.354-2.257-1.075c-1.007,0.277-1.628,1.212-1.387,2.08"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M106.303,150.089c0.517-0.61,0.296-1.65-0.498-2.327c-0.793-0.68-1.857-0.733-2.378-0.126"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M103.427,147.635c-0.519,0.609-0.295,1.649,0.5,2.329c0.793,0.675,1.857,0.733,2.376,0.125"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.986,114.854c0.537-0.402,0.472-1.405-0.151-2.241c-0.621-0.838-1.561-1.198-2.101-0.796"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M143.734,111.817c-0.54,0.4-0.472,1.403,0.149,2.24c0.623,0.84,1.563,1.196,2.103,0.798"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.866" y1="86.659" x2="412.29" y2="87.308"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="410.866" y1="86.659" x2="412.29" y2="87.308"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M361.632,74.835c0.442-0.586,0.277-1.454-0.368-1.938c-0.645-0.488-1.526-0.407-1.968,0.177"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M358.819,73.707c-0.309,0.414-0.317,0.97-0.067,1.436"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M359.792,75.926c0.549,0.125,1.063-0.068,1.363-0.458"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M358.906,73.967c-0.223,0.296-0.261,0.679-0.139,1.03"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M359.93,75.873c0.396,0.026,0.742-0.14,0.951-0.417c0.374-0.493,0.235-1.228-0.312-1.64c-0.545-0.412-1.291-0.346-1.663,0.151"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M360.518,75.182c0.235-0.312,0.147-0.775-0.198-1.037c-0.346-0.26-0.814-0.217-1.047,0.095"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M358.852,74.795c-0.235,0.312-0.144,0.775,0.198,1.037c0.345,0.26,0.814,0.217,1.051-0.095c0.235-0.312,0.147-0.775-0.198-1.035    C359.557,74.44,359.089,74.484,358.852,74.795"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="359.273" y1="74.241" x2="358.852" y2="74.795"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="360.101" y1="75.737" x2="360.518" y2="75.182"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="359.296" y1="73.074" x2="358.819" y2="73.707"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="361.155" y1="75.468" x2="361.632" y2="74.835"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="360.786" y1="73.537" x2="361.263" y2="72.904"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M361.632,74.835c0.442-0.586,0.277-1.454-0.368-1.938c-0.645-0.488-1.526-0.407-1.968,0.177"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M376.474,78.834c0.468-0.523,0.36-1.382-0.24-1.922c-0.598-0.54-1.466-0.556-1.935-0.035"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M373.768,77.466c-0.33,0.367-0.372,0.905-0.156,1.386"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M374.582,79.721c0.526,0.177,1.045,0.049,1.361-0.298"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M373.835,77.729c-0.24,0.265-0.303,0.633-0.202,0.987"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M374.712,79.688c0.388,0.067,0.735-0.054,0.961-0.3c0.396-0.442,0.305-1.17-0.202-1.626c-0.51-0.456-1.243-0.472-1.636-0.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M375.333,79.079c0.249-0.277,0.193-0.735-0.126-1.022c-0.321-0.288-0.784-0.296-1.033-0.021"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M373.709,78.553c-0.249,0.275-0.193,0.737,0.126,1.022c0.321,0.288,0.784,0.298,1.033,0.019c0.249-0.275,0.191-0.735-0.126-1.022    C374.421,78.285,373.958,78.276,373.709,78.553"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="374.173" y1="78.036" x2="373.709" y2="78.553"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="374.868" y1="79.595" x2="375.333" y2="79.079"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="374.3" y1="76.877" x2="373.768" y2="77.466"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="375.943" y1="79.423" x2="376.474" y2="78.834"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="375.691" y1="77.501" x2="376.222" y2="76.91"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M376.474,78.834c0.468-0.523,0.36-1.382-0.24-1.922c-0.598-0.54-1.466-0.556-1.935-0.035"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.949,83.548c0.502-0.484,0.458-1.35-0.105-1.931c-0.561-0.582-1.422-0.658-1.927-0.172"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.346,81.996c-0.354,0.344-0.437,0.877-0.253,1.373"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M389,84.304c0.51,0.214,1.037,0.119,1.379-0.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.39,82.266c-0.254,0.244-0.342,0.607-0.267,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M389.132,84.281c0.382,0.093,0.737-0.007,0.977-0.237c0.426-0.41,0.388-1.144-0.088-1.635c-0.474-0.489-1.205-0.558-1.631-0.144"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M389.793,83.717c0.267-0.26,0.244-0.719-0.054-1.03c-0.3-0.312-0.763-0.354-1.03-0.093"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M388.208,83.075c-0.268,0.261-0.24,0.723,0.056,1.031c0.302,0.31,0.763,0.353,1.031,0.091c0.267-0.26,0.24-0.719-0.058-1.03    C388.937,82.857,388.478,82.815,388.208,83.075"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.709" y1="82.594" x2="388.208" y2="83.075"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="389.295" y1="84.197" x2="389.793" y2="83.717"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.916" y1="81.445" x2="388.346" y2="81.996"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="390.379" y1="84.101" x2="390.949" y2="83.548"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="390.261" y1="82.164" x2="390.831" y2="81.614"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M390.949,83.548c0.502-0.484,0.458-1.35-0.105-1.931c-0.561-0.582-1.422-0.658-1.927-0.172"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M404.536,88.622c0.502-0.488,0.456-1.354-0.105-1.933c-0.561-0.581-1.424-0.658-1.926-0.174"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M401.932,87.07c-0.354,0.34-0.435,0.873-0.249,1.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M402.584,89.374c0.516,0.217,1.038,0.123,1.38-0.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M401.977,87.336c-0.256,0.247-0.342,0.61-0.27,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M402.719,89.352c0.381,0.095,0.738-0.004,0.979-0.233c0.426-0.41,0.384-1.144-0.089-1.635c-0.475-0.491-1.205-0.558-1.631-0.147"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M403.38,88.789c0.267-0.258,0.242-0.721-0.058-1.031c-0.3-0.309-0.759-0.351-1.028-0.093"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M401.797,88.148c-0.27,0.258-0.246,0.719,0.054,1.031c0.3,0.31,0.759,0.349,1.03,0.091c0.268-0.26,0.244-0.723-0.056-1.03    C402.526,87.931,402.065,87.889,401.797,88.148"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="402.295" y1="87.664" x2="401.797" y2="88.148"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="402.88" y1="89.271" x2="403.38" y2="88.79"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="402.505" y1="86.516" x2="401.932" y2="87.066"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="403.963" y1="89.171" x2="404.536" y2="88.62"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="403.849" y1="87.235" x2="404.419" y2="86.684"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M404.536,88.622c0.502-0.488,0.456-1.354-0.105-1.933c-0.561-0.581-1.424-0.658-1.926-0.174"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M417.15,94.701c0.552-0.361,0.645-1.203,0.207-1.878c-0.442-0.677-1.247-0.937-1.803-0.574"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M414.887,92.679c-0.363,0.237-0.528,0.682-0.474,1.158"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M415.22,95.09c0.438,0.265,0.914,0.265,1.261,0.042"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M414.882,92.954c-0.268,0.172-0.409,0.479-0.412,0.821"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M415.312,95.067c0.333,0.149,0.666,0.125,0.919-0.039c0.468-0.307,0.549-1.017,0.177-1.591c-0.374-0.574-1.058-0.791-1.526-0.484"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M415.982,94.645c0.296-0.193,0.344-0.642,0.111-1.003c-0.235-0.361-0.666-0.498-0.961-0.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M414.549,93.715c-0.295,0.193-0.345,0.64-0.112,1.003c0.235,0.361,0.665,0.498,0.961,0.305c0.298-0.195,0.346-0.642,0.112-1.003    C415.275,93.657,414.845,93.522,414.549,93.715"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="415.131" y1="93.336" x2="414.549" y2="93.715"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="415.398" y1="95.024" x2="415.982" y2="94.645"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="415.554" y1="92.249" x2="414.891" y2="92.679"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="416.481" y1="95.132" x2="417.15" y2="94.701"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="416.687" y1="93.261" x2="417.353" y2="92.83"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M417.15,94.701c0.552-0.361,0.645-1.203,0.207-1.878c-0.442-0.677-1.247-0.937-1.803-0.574"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M361.255,88.606c0.444-0.588,0.275-1.452-0.37-1.94c-0.642-0.488-1.522-0.405-1.964,0.177"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M358.443,87.479c-0.312,0.412-0.321,0.968-0.067,1.438"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M359.417,89.697c0.545,0.125,1.063-0.068,1.363-0.458"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M358.531,87.738c-0.226,0.295-0.265,0.68-0.142,1.03"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M359.552,89.643c0.396,0.026,0.742-0.137,0.952-0.416c0.375-0.495,0.233-1.228-0.31-1.642c-0.545-0.41-1.291-0.344-1.663,0.153"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M360.139,88.952c0.235-0.312,0.147-0.775-0.195-1.035c-0.345-0.258-0.814-0.216-1.051,0.096"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M358.477,88.566c-0.235,0.312-0.147,0.775,0.198,1.035c0.342,0.261,0.814,0.217,1.047-0.093c0.235-0.312,0.147-0.775-0.198-1.037    C359.182,88.212,358.713,88.254,358.477,88.566"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="358.894" y1="88.013" x2="358.477" y2="88.566"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="359.722" y1="89.508" x2="360.139" y2="88.952"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="358.92" y1="86.844" x2="358.443" y2="87.479"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="360.779" y1="89.239" x2="361.255" y2="88.606"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="360.408" y1="87.308" x2="360.885" y2="86.674"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M361.255,88.606c0.444-0.588,0.275-1.452-0.37-1.94c-0.642-0.488-1.522-0.405-1.964,0.177"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M349.125,90.116c0.354,0.132,0.951-0.607,1.329-1.647c0.379-1.044,0.396-1.989,0.042-2.12c-0.358-0.128-0.954,0.61-1.333,1.65    C348.788,89.039,348.767,89.988,349.125,90.116"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.449,272.348c0.54-0.282,0.68-1.082,0.31-1.789c-0.367-0.707-1.101-1.047-1.643-0.766c-0.54,0.281-0.679,1.082-0.312,1.787    C200.173,272.286,200.909,272.628,201.449,272.348"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M187.028,267.414c0.5-0.261,0.605-1.044,0.239-1.749c-0.368-0.705-1.073-1.066-1.57-0.807c-0.5,0.261-0.605,1.045-0.237,1.749    C185.826,267.313,186.531,267.676,187.028,267.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M173.949,261.741c0.458-0.228,0.54-0.989,0.184-1.701s-1.014-1.101-1.473-0.873c-0.458,0.228-0.54,0.989-0.184,1.703    C172.832,261.579,173.493,261.97,173.949,261.741"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M150.604,248.106c0.428-0.17,0.538-0.905,0.246-1.645c-0.291-0.74-0.875-1.203-1.303-1.035c-0.426,0.168-0.538,0.907-0.246,1.647    C149.594,247.812,150.176,248.275,150.604,248.106"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M140.886,240.747c0.377-0.126,0.477-0.84,0.226-1.596c-0.253-0.752-0.763-1.263-1.144-1.136c-0.377,0.126-0.475,0.842-0.223,1.598    C139.998,240.365,140.509,240.873,140.886,240.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M132.195,232.741c0.326-0.081,0.44-0.772,0.253-1.543c-0.186-0.773-0.602-1.338-0.93-1.261c-0.324,0.081-0.438,0.772-0.253,1.543    C131.454,232.253,131.869,232.818,132.195,232.741"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M119.441,215.144c0.335-0.018,0.566-0.68,0.521-1.475c-0.051-0.793-0.363-1.419-0.7-1.4c-0.333,0.023-0.565,0.682-0.516,1.475    C118.794,214.539,119.106,215.167,119.441,215.144"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M115.023,206.035c0.272-0.019,0.451-0.673,0.403-1.47c-0.049-0.794-0.31-1.422-0.581-1.405c-0.272,0.014-0.452,0.672-0.405,1.468    C114.492,205.423,114.753,206.053,115.023,206.035"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="123.672" y1="218.349" x2="122.956" y2="218.661"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="122.956" y1="218.661" x2="122.956" y2="221.546"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M175.426,294.937c-0.142-0.044-0.291-0.067-0.437-0.067c-0.498,0-0.9,0.233-0.9,0.521c0,0.195,0.182,0.365,0.461,0.456"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="175.428" y1="294.937" x2="177.013" y2="295.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="174.551" y1="295.848" x2="176.136" y2="296.358"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M177.457,295.799c-0.054-0.154-0.223-0.281-0.445-0.351"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M176.138,296.358c0.082,0.026,0.167,0.044,0.247,0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M175.426,294.937c-0.142-0.044-0.291-0.067-0.437-0.067c-0.498,0-0.9,0.233-0.9,0.521c0,0.195,0.182,0.365,0.461,0.456"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="175.428" y1="294.937" x2="177.013" y2="295.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="174.551" y1="295.848" x2="176.136" y2="296.358"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="433.685" y1="327.12" x2="435.823" y2="329.614"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="435.823" y1="329.614" x2="441.03" y2="344.273"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M441.03,344.273c0.889,2.056,0.291,3.29-1.096,4.728c-1.391,1.44-2.126,1.165-3.564-0.616c-1.44-1.78-3.406-4.385-3.406-4.385"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M440.171,341.707c0.893,2.052,0.295,3.288-1.096,4.725c-1.387,1.44-2.122,1.165-3.562-0.617c-0.835-1.035-1.849-2.345-2.552-3.264    "/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M434.744,342.237c0.333,0.4,1.063,0.342,1.631-0.132c0.568-0.472,0.756-1.18,0.423-1.58c-0.333-0.402-1.066-0.344-1.633,0.13    C434.597,341.127,434.408,341.835,434.744,342.237"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="439.369" y1="339.599" x2="440.907" y2="337.782"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M440.907,337.782c0.688-0.889-0.067-1.712-0.682-1.608c-0.619,0.103-1.801,0.77-1.801,0.77"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M443.396,369.939c0.337-0.191,0.403-0.702,0.154-1.14c-0.249-0.438-0.724-0.638-1.061-0.445c-0.337,0.191-0.405,0.702-0.156,1.14    C442.586,369.932,443.061,370.132,443.396,369.939"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M443.112,377.408c0.335-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.447c-0.337,0.191-0.407,0.703-0.154,1.142    C442.3,377.401,442.775,377.601,443.112,377.408"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="424.582" y1="356.91" x2="425.303" y2="359.135"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.303" y1="359.135" x2="426.114" y2="359.174"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.114" y1="359.174" x2="430.498" y2="355.359"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="430.498" y1="355.359" x2="430.498" y2="353.412"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="430.498" y1="353.412" x2="429.192" y2="352.844"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="429.192" y1="350.652" x2="431.835" y2="352.155"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="428.306" y1="357.266" x2="428.834" y2="359.053"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="428.834" y1="359.053" x2="431.552" y2="360.596"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M442.826,390.722c0.337-0.193,0.405-0.705,0.156-1.143c-0.249-0.438-0.726-0.635-1.063-0.444c-0.333,0.189-0.405,0.702-0.153,1.14    C442.016,390.713,442.493,390.911,442.826,390.722"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M332.928,398.235c-5.544,9.352-14.753,25.278-28.186,34.2"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M299.177,414.494c4.7-5.467,8.853-13.684,12.319-20.515"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="328.072" y1="406.279" x2="339.088" y2="420.923"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="339.088" y1="420.923" x2="343.983" y2="417.373"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="343.983" y1="417.373" x2="343.983" y2="416.126"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="343.983" y1="416.126" x2="339.184" y2="410.612"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="339.184" y1="418.619" x2="339.184" y2="393.821"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="339.235" y1="373.938" x2="339.235" y2="392.476"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="330.048" y1="399.789" x2="330.048" y2="381.162"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="330.048" y1="399.789" x2="331.39" y2="399.789"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="330.048" y1="388.951" x2="331.871" y2="388.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M331.871,388.951c0.67-1.489,3.409-4.385,7.364-5.742"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="322.351" y1="386.137" x2="330.048" y2="395.166"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="324.029" y1="384.89" x2="330.048" y2="391.948"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="285.478" y1="425.217" x2="298.099" y2="418.117"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="298.099" y1="418.117" x2="298.099" y2="417.059"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="298.099" y1="417.059" x2="288.719" y2="410.479"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M294.763,417.228c0-0.398-0.558-0.719-1.245-0.719c-0.689,0-1.249,0.321-1.249,0.719c0,0.4,0.559,0.723,1.249,0.723    C294.205,417.95,294.763,417.628,294.763,417.228"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M305.821,424.929c0-5.049-5.181-9.404-12.773-11.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M302.845,431.257c2.043-2.013,3.004-4.218,2.976-6.328"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M304.742,432.435c-4.869,1.896-11.118,4.157-21.809,1.058"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.775" y1="422.798" x2="302.845" y2="431.257"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="291.787" y1="421.667" x2="301.694" y2="421.667"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="301.694" y1="421.667" x2="301.694" y2="430.622"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M298.099,418.117c2.636,1.822,6.987,5.477,7.691,7.489"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.478,430.108c2.882,0.386,6.538,3.974,7.394,4.916"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="291.045" y1="432.698" x2="296.226" y2="429.822"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.226,429.822c1.056-0.386,0.77-0.481,1.44-0.193"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M312.635,430.204c0-1.259-0.239-2.489-0.649-3.559"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.395,447.257c0.909,0.044,1.81,0.068,2.59,0.063c16.372,0,29.649-7.661,29.649-17.116"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.397,443.918c0.908,0.054,1.806,0.084,2.589,0.079c12.735,0,23.142-5.751,23.854-13.036"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="280.397" y1="437.593" x2="280.397" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="301.591" y1="448.085" x2="315.609" y2="438.551"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="315.609" y1="438.551" x2="323.282" y2="419.075"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="323.282" y1="419.075" x2="321.097" y2="416.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.397,440.149c7.847,0.682,15.255-1.775,18.461-5.805"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.397" y1="438.744" x2="283.752" y2="438.839"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="283.752" y1="438.839" x2="284.617" y2="438.074"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="284.617" y1="438.074" x2="284.617" y2="433.963"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.272,426.955c0-0.268-0.375-0.486-0.837-0.486c-0.465,0-0.842,0.217-0.842,0.486s0.377,0.484,0.842,0.484    C303.897,427.439,304.272,427.223,304.272,426.955"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M259.349,433.025c0-0.445-0.624-0.805-1.391-0.805c-0.768,0-1.394,0.36-1.394,0.805c0,0.442,0.626,0.803,1.394,0.803    C258.725,433.828,259.349,433.467,259.349,433.025"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.2,428.203c0.526-0.507,0.503-1.386-0.051-1.971c-0.556-0.582-1.438-0.644-1.964-0.142c-0.53,0.505-0.507,1.387,0.051,1.97    C279.792,428.642,280.671,428.707,281.2,428.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M282.844,421.248c0.107-1.175-0.037-1.705,1.259-2.569c1.294-0.863,1.964-1.436,2.397-1.631c0.431-0.191,0.382-1.919,0.24-2.925    c-0.144-1.008-0.531-1.293-1.131-1.436"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M286.136,412.894c1.036-0.163,2.091,0.075,3.003,0.845c0.914,0.768,0.577,1.631,0.623,2.59c0.051,0.959,0.577,1.44-0.623,2.061    c-1.2,0.624-3.529,1.086-4.739,0.089"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M279.646,416.991c-0.103,1.256-0.149,1.785,0.856,2.122c1.01,0.335,1.68,0.191,1.871,0.191"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="319.445" y1="428.815" x2="319.445" y2="448.085"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="347.905" x2="208.125" y2="348.563"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="348.563" x2="208.125" y2="349.314"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="349.314" x2="206.35" y2="350.05"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="352.013" x2="208.125" y2="352.672"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="352.672" x2="208.125" y2="353.421"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="353.421" x2="206.35" y2="354.159"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="293.326" x2="208.125" y2="293.985"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="293.985" x2="208.125" y2="294.734"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="294.734" x2="206.35" y2="295.471"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="297.433" x2="208.125" y2="298.093"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="298.093" x2="208.125" y2="298.843"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="298.843" x2="206.35" y2="299.58"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="388.582" x2="208.125" y2="389.24"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="389.24" x2="208.125" y2="389.991"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="389.991" x2="206.35" y2="390.729"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="392.69" x2="208.125" y2="393.351"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="393.351" x2="208.125" y2="394.098"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="394.098" x2="206.35" y2="394.836"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="431.127" x2="208.125" y2="431.786"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="431.786" x2="208.125" y2="432.537"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="432.537" x2="206.35" y2="433.272"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="206.35" y1="435.236" x2="208.125" y2="435.894"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="435.894" x2="208.125" y2="436.643"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="208.125" y1="436.643" x2="206.35" y2="437.379"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="252.272" y1="434.305" x2="250.999" y2="433.063"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="251.073" y1="434.375" x2="245.987" y2="437.092"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="247.186" y1="444.83" x2="249.838" y2="446.596"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="249.838" y1="446.596" x2="253.255" y2="444.619"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M240.462,443.574c1.68,1.752,3.448,3.271,5.035,4.511"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="276.737" y1="438.409" x2="276.737" y2="434.724"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.04" y1="242.229" x2="85.04" y2="244.348"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.04" y1="247.136" x2="85.04" y2="257.624"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.04" y1="260.413" x2="85.04" y2="271.464"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.04" y1="274.253" x2="85.04" y2="284.621"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.04" y1="287.761" x2="85.04" y2="298.401"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.04" y1="301.192" x2="85.04" y2="306.469"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.992,257.633c-0.27,0.051-0.47,0.317-0.559,0.693"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.773,260.122c0.235,0.342,0.521,0.488,0.777,0.444"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.432,260.397c0.47-0.089,0.724-0.817,0.57-1.626c-0.153-0.81-0.658-1.394-1.124-1.307"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.878" y1="257.465" x2="84.992" y2="257.633"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.55" y1="260.566" x2="86.432" y2="260.397"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.336,260.017c0.286-0.056,0.44-0.502,0.349-0.998c-0.096-0.495-0.405-0.854-0.689-0.798"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M83.709,260.323c0.286-0.053,0.442-0.498,0.346-0.996c-0.091-0.496-0.4-0.852-0.689-0.8c-0.284,0.056-0.44,0.503-0.346,0.996    C83.112,260.02,83.421,260.38,83.709,260.323"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="83.365" y1="258.528" x2="84.996" y2="258.221"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.336" y1="260.017" x2="83.709" y2="260.323"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.12" y1="258.84" x2="87.006" y2="258.67"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.992,271.473c-0.27,0.051-0.47,0.319-0.559,0.696"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.773,273.967c0.235,0.337,0.521,0.488,0.777,0.438"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.432,274.238c0.47-0.088,0.724-0.817,0.57-1.628c-0.153-0.81-0.658-1.394-1.124-1.303"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.878" y1="271.308" x2="84.992" y2="271.473"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.55" y1="274.405" x2="86.432" y2="274.238"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.336,273.858c0.286-0.053,0.44-0.5,0.349-0.996c-0.096-0.496-0.405-0.852-0.689-0.8"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M83.709,274.168c0.286-0.056,0.442-0.503,0.346-0.996c-0.091-0.496-0.4-0.856-0.689-0.8c-0.284,0.053-0.44,0.498-0.346,0.994    C83.112,273.865,83.421,274.221,83.709,274.168"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="83.365" y1="272.372" x2="84.996" y2="272.062"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.336" y1="273.858" x2="83.709" y2="274.168"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.12" y1="272.681" x2="87.006" y2="272.514"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.992,244.358c-0.27,0.051-0.47,0.317-0.559,0.695"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.773,246.849c0.235,0.338,0.521,0.489,0.777,0.44"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.432,247.121c0.47-0.086,0.724-0.814,0.57-1.624c-0.153-0.812-0.658-1.396-1.124-1.307"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.878" y1="244.192" x2="84.992" y2="244.358"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.55" y1="247.289" x2="86.432" y2="247.124"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.336,246.742c0.286-0.053,0.44-0.5,0.349-0.996c-0.096-0.496-0.405-0.852-0.689-0.8"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M83.709,247.05c0.286-0.053,0.442-0.5,0.346-0.994c-0.091-0.498-0.4-0.854-0.689-0.802c-0.284,0.054-0.44,0.5-0.346,0.996    C83.112,246.747,83.421,247.105,83.709,247.05"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="83.365" y1="245.254" x2="84.996" y2="244.946"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.336" y1="246.742" x2="83.709" y2="247.05"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.12" y1="245.565" x2="87.006" y2="245.398"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.992,284.63c-0.27,0.051-0.47,0.316-0.559,0.695"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.773,287.121c0.235,0.338,0.521,0.488,0.777,0.44"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.432,287.392c0.47-0.088,0.724-0.817,0.57-1.628c-0.153-0.807-0.658-1.394-1.124-1.303"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.878" y1="284.462" x2="84.992" y2="284.63"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.55" y1="287.561" x2="86.432" y2="287.392"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.336,287.012c0.286-0.053,0.44-0.5,0.349-0.996c-0.096-0.496-0.405-0.852-0.689-0.8"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M83.709,287.322c0.286-0.056,0.442-0.5,0.346-0.996c-0.091-0.496-0.4-0.856-0.689-0.8c-0.284,0.053-0.44,0.498-0.346,0.994    C83.112,287.019,83.421,287.375,83.709,287.322"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="83.365" y1="285.526" x2="84.996" y2="285.216"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.336" y1="287.012" x2="83.709" y2="287.322"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.12" y1="285.837" x2="87.006" y2="285.668"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.992,298.41c-0.27,0.051-0.47,0.319-0.559,0.695"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M84.773,300.9c0.235,0.342,0.521,0.489,0.777,0.444"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M86.432,301.176c0.47-0.089,0.724-0.817,0.57-1.628c-0.153-0.809-0.658-1.393-1.124-1.307"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.878" y1="298.242" x2="84.992" y2="298.41"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.55" y1="301.344" x2="86.432" y2="301.176"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M85.336,300.795c0.286-0.053,0.44-0.502,0.349-0.996c-0.096-0.495-0.405-0.856-0.689-0.8"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M83.709,301.106c0.286-0.056,0.442-0.503,0.346-0.998c-0.091-0.495-0.4-0.854-0.689-0.802c-0.284,0.056-0.44,0.503-0.346,1    C83.112,300.799,83.421,301.158,83.709,301.106"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="83.365" y1="299.31" x2="84.996" y2="298.999"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="85.336" y1="300.797" x2="83.709" y2="301.106"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="86.12" y1="299.617" x2="87.006" y2="299.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="88.269" y1="289.023" x2="86.834" y2="287.096"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="178.427" y1="367.383" x2="206.35" y2="376.993"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="178.427" y1="372.873" x2="205.287" y2="382.116"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="205.287" y1="382.116" x2="206.35" y2="381.255"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.234,379.283c0.451-0.21,0.574-0.898,0.275-1.536c-0.298-0.64-0.908-0.986-1.359-0.775c-0.451,0.212-0.575,0.901-0.274,1.542    C200.173,379.152,200.783,379.497,201.234,379.283"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.712,374.19c0.451-0.21,0.575-0.898,0.275-1.536c-0.298-0.64-0.908-0.986-1.359-0.775c-0.451,0.212-0.575,0.901-0.274,1.542    C185.654,374.059,186.263,374.404,186.712,374.19"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M285.385,361.626c0-0.644-0.738-1.165-1.649-1.165c-0.914,0-1.65,0.521-1.65,1.165c0,0.645,0.737,1.165,1.65,1.165    C284.647,362.79,285.385,362.271,285.385,361.626"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M250.601,345.764c0-0.642-0.74-1.165-1.649-1.165c-0.91,0-1.649,0.523-1.649,1.165c0,0.644,0.738,1.166,1.649,1.166    C249.861,346.93,250.601,346.408,250.601,345.764"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M313.02,341.76c0-0.642-0.738-1.165-1.649-1.165c-0.908,0-1.649,0.523-1.649,1.165c0,0.645,0.74,1.168,1.649,1.168    C312.282,342.928,313.02,342.405,313.02,341.76"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.08,349.745c-0.107,0.161-0.158,0.335-0.156,0.496c0,0.642,0.74,1.165,1.649,1.165c0.514,0,0.972-0.167,1.277-0.428"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.616,343.784c-0.188,0.202-0.275,0.428-0.275,0.647c0,0.642,0.74,1.165,1.65,1.165c0.602,0,1.128-0.228,1.419-0.574"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M314.9,429.249c0,0.414,0.575,0.745,1.291,0.745c0.712,0,1.289-0.331,1.289-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M317.48,428.605c0-0.412-0.577-0.747-1.289-0.747c-0.716,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="317.48" y1="428.605" x2="317.48" y2="429.242"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="314.9" y1="428.605" x2="314.9" y2="429.249"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M317.284,428.507c0-0.349-0.488-0.633-1.093-0.633s-1.094,0.284-1.094,0.633c0,0.347,0.489,0.633,1.094,0.633    S317.284,428.854,317.284,428.507"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="316.205" y1="429.357" x2="316.205" y2="429.992"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M310.679,438.651c0,0.412,0.577,0.747,1.289,0.747s1.293-0.335,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M313.261,438.007c0-0.41-0.581-0.747-1.293-0.747s-1.289,0.337-1.289,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="313.261" y1="438.007" x2="313.261" y2="438.644"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="310.679" y1="438.007" x2="310.679" y2="438.651"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M313.062,437.909c0-0.349-0.489-0.633-1.094-0.633s-1.094,0.284-1.094,0.633c0,0.347,0.489,0.631,1.094,0.631    S313.062,438.256,313.062,437.909"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="311.986" y1="438.76" x2="311.986" y2="439.395"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M298.972,441.82c0,0.409,0.579,0.744,1.291,0.744c0.712,0,1.293-0.335,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M301.555,441.171c0-0.41-0.581-0.744-1.293-0.744c-0.712,0-1.291,0.333-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="301.555" y1="441.171" x2="301.555" y2="441.811"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="298.972" y1="441.171" x2="298.972" y2="441.82"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M301.359,441.077c0-0.351-0.489-0.633-1.096-0.633c-0.605,0-1.094,0.282-1.094,0.633c0,0.347,0.489,0.631,1.094,0.631    C300.87,441.708,301.359,441.424,301.359,441.077"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="300.28" y1="441.924" x2="300.28" y2="442.562"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M151.374,233.786c0.321,0.174,0.714,0.135,1.068-0.074"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M153.32,232.109c-0.014-0.438-0.212-0.775-0.514-0.942"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M152.019,230.736c-0.554-0.305-1.324,0.035-1.721,0.758c-0.396,0.724-0.27,1.554,0.284,1.857"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="152.019" y1="230.736" x2="152.807" y2="231.168"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="151.374" y1="233.786" x2="150.583" y2="233.351"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M152.626,231.729c-0.342-0.186-0.812,0.019-1.054,0.465c-0.242,0.44-0.167,0.952,0.175,1.138"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M152.417,233.697c0.337,0.186,0.809-0.021,1.052-0.465c0.244-0.44,0.163-0.952-0.174-1.138c-0.342-0.186-0.812,0.025-1.054,0.465    C152,233.004,152.075,233.511,152.417,233.697"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="153.32" y1="232.109" x2="152.626" y2="231.729"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="151.747" y1="233.332" x2="152.417" y2="233.697"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="151.135" y1="231.85" x2="150.348" y2="231.418"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M174.125,220.629c0.309,0.2,0.705,0.188,1.073,0.003"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M176.187,219.094c0.014-0.44-0.161-0.787-0.447-0.973"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M174.98,217.631c-0.528-0.338-1.321-0.056-1.766,0.64c-0.447,0.693-0.377,1.529,0.154,1.873"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="174.982" y1="217.631" x2="175.74" y2="218.121"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="174.125" y1="220.629" x2="173.369" y2="220.145"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M175.519,218.666c-0.328-0.21-0.812-0.037-1.084,0.389c-0.274,0.423-0.23,0.938,0.095,1.147"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M175.173,220.616c0.324,0.209,0.809,0.035,1.08-0.389c0.274-0.428,0.232-0.94-0.095-1.151c-0.324-0.207-0.809-0.033-1.08,0.393    C174.803,219.892,174.845,220.406,175.173,220.616"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="176.187" y1="219.094" x2="175.519" y2="218.666"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="174.53" y1="220.203" x2="175.173" y2="220.616"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="174.025" y1="218.685" x2="173.267" y2="218.198"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,132.957c0.316,0.179,0.71,0.146,1.072-0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,131.314c-0.005-0.438-0.202-0.777-0.496-0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.304,129.92c-0.549-0.316-1.324,0.012-1.733,0.728c-0.409,0.716-0.295,1.55,0.251,1.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.304" y1="129.92" x2="163.086" y2="130.364"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="132.957" x2="160.822" y2="132.51"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.891,130.92c-0.335-0.189-0.809,0.009-1.059,0.447c-0.251,0.438-0.182,0.951,0.153,1.142"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.649,132.886c0.335,0.193,0.812-0.005,1.063-0.444c0.249-0.438,0.179-0.951-0.156-1.144c-0.335-0.189-0.812,0.009-1.063,0.447    C162.244,132.184,162.314,132.696,162.649,132.886"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.582" y1="131.314" x2="162.891" y2="130.92"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="132.509" x2="162.649" y2="132.886"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.4" y1="131.02" x2="160.622" y2="130.572"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,227.246c0.316,0.182,0.71,0.149,1.072-0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,225.606c-0.005-0.442-0.202-0.78-0.496-0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.304,224.208c-0.549-0.312-1.324,0.012-1.733,0.728c-0.409,0.716-0.295,1.55,0.251,1.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.304" y1="224.208" x2="163.086" y2="224.656"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="227.246" x2="160.822" y2="226.799"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.891,225.212c-0.335-0.193-0.809,0.009-1.059,0.447c-0.251,0.438-0.182,0.947,0.153,1.138"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.649,227.178c0.335,0.193,0.812-0.009,1.063-0.447c0.249-0.438,0.179-0.947-0.154-1.14c-0.337-0.193-0.814,0.009-1.065,0.447    C162.244,226.476,162.314,226.985,162.649,227.178"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.582" y1="225.606" x2="162.891" y2="225.212"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="226.797" x2="162.649" y2="227.178"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.4" y1="225.308" x2="160.622" y2="224.861"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,213.717c0.316,0.181,0.71,0.147,1.072-0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,212.075c-0.005-0.438-0.202-0.777-0.496-0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.304,210.677c-0.549-0.312-1.324,0.016-1.733,0.731c-0.409,0.716-0.295,1.547,0.251,1.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.304" y1="210.677" x2="163.086" y2="211.125"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="213.717" x2="160.822" y2="213.271"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.891,211.681c-0.335-0.193-0.809,0.007-1.059,0.445c-0.251,0.438-0.182,0.951,0.153,1.144"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.649,213.647c0.335,0.191,0.812-0.009,1.063-0.447c0.249-0.435,0.179-0.947-0.154-1.14c-0.337-0.189-0.814,0.007-1.065,0.445    C162.244,212.943,162.314,213.457,162.649,213.647"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.582" y1="212.075" x2="162.891" y2="211.681"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="213.27" x2="162.649" y2="213.647"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.4" y1="211.779" x2="160.622" y2="211.333"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,173.271c0.316,0.181,0.71,0.149,1.072-0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,171.63c-0.005-0.44-0.202-0.779-0.496-0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.304,170.234c-0.549-0.312-1.324,0.012-1.733,0.728c-0.409,0.716-0.295,1.55,0.251,1.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.304" y1="170.234" x2="163.086" y2="170.681"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="173.271" x2="160.822" y2="172.824"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.891,171.237c-0.335-0.193-0.809,0.005-1.059,0.444c-0.251,0.438-0.182,0.951,0.153,1.144"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.649,173.203c0.335,0.189,0.812-0.009,1.063-0.445c0.249-0.438,0.179-0.952-0.154-1.142c-0.337-0.191-0.814,0.009-1.065,0.447    C162.244,172.498,162.314,173.01,162.649,173.203"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.582" y1="171.63" x2="162.891" y2="171.237"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="172.824" x2="162.649" y2="173.203"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.4" y1="171.332" x2="160.622" y2="170.886"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M161.606,159.772c0.316,0.179,0.71,0.146,1.072-0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M163.582,158.129c-0.005-0.438-0.202-0.777-0.496-0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.304,156.731c-0.549-0.312-1.324,0.014-1.733,0.731c-0.409,0.716-0.295,1.55,0.251,1.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="162.304" y1="156.731" x2="163.086" y2="157.178"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.606" y1="159.77" x2="160.822" y2="159.325"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.891,157.734c-0.335-0.191-0.809,0.007-1.059,0.445c-0.251,0.438-0.182,0.952,0.153,1.143"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M162.649,159.702c0.335,0.191,0.812-0.007,1.063-0.445c0.249-0.438,0.179-0.952-0.154-1.144c-0.337-0.189-0.814,0.009-1.065,0.447    C162.244,158.999,162.314,159.511,162.649,159.702"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.582" y1="158.129" x2="162.891" y2="157.734"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.984" y1="159.323" x2="162.649" y2="159.702"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="161.4" y1="157.832" x2="160.622" y2="157.385"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.184,304.754c-0.319,0.182-0.489,0.538-0.498,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.593,307.291c0.381,0.217,0.772,0.224,1.072,0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.445,306.9c0.547-0.314,0.661-1.147,0.253-1.863c-0.409-0.717-1.186-1.044-1.733-0.731"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.965" y1="304.306" x2="396.184" y2="304.754"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.664" y1="307.346" x2="398.445" y2="306.9"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.284,306.899c0.335-0.193,0.405-0.705,0.153-1.144c-0.249-0.438-0.724-0.638-1.061-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.956,307.655c0.333-0.189,0.405-0.703,0.153-1.14c-0.249-0.438-0.724-0.637-1.059-0.447c-0.337,0.193-0.407,0.705-0.154,1.144    C395.144,307.649,395.619,307.847,395.956,307.655"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.049" y1="306.067" x2="396.375" y2="305.31"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.284" y1="306.899" x2="395.956" y2="307.655"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.943" y1="306.329" x2="398.722" y2="305.881"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.201" y1="305.031" x2="397.984" y2="304.585"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M267.22,388.584c0,0.412,0.575,0.747,1.289,0.747s1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.8,387.94c0-0.412-0.577-0.747-1.291-0.747s-1.289,0.335-1.289,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="269.8" y1="387.94" x2="269.8" y2="388.579"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="267.22" y1="387.94" x2="267.22" y2="388.584"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.604,387.84c0-0.347-0.489-0.631-1.094-0.631s-1.096,0.284-1.096,0.631c0,0.353,0.491,0.633,1.096,0.633    S269.604,388.193,269.604,387.84"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="268.527" y1="388.691" x2="268.527" y2="389.329"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.178,367.366c0,0.414,0.577,0.745,1.289,0.745c0.714,0,1.291-0.331,1.291-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M345.758,366.721c0-0.41-0.577-0.745-1.291-0.745c-0.712,0-1.289,0.335-1.289,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="345.758" y1="366.721" x2="345.758" y2="367.359"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.178" y1="366.721" x2="343.178" y2="367.366"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M345.563,366.624c0-0.351-0.489-0.631-1.096-0.631c-0.603,0-1.093,0.281-1.093,0.631c0,0.347,0.489,0.631,1.093,0.631    C345.074,367.255,345.563,366.971,345.563,366.624"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="344.484" y1="367.475" x2="344.484" y2="368.11"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M341.271,364.967c0,0.412,0.577,0.747,1.291,0.747c0.712,0,1.289-0.335,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.851,364.323c0-0.412-0.577-0.745-1.289-0.745c-0.714,0-1.291,0.333-1.291,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.851" y1="364.323" x2="343.851" y2="364.961"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="341.271" y1="364.323" x2="341.271" y2="364.967"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M343.658,364.223c0-0.347-0.493-0.631-1.096-0.631s-1.096,0.284-1.096,0.631c0,0.351,0.493,0.633,1.096,0.633    S343.658,364.574,343.658,364.223"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="342.578" y1="365.074" x2="342.578" y2="365.712"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M325.355,368.353c0,0.412,0.579,0.744,1.291,0.744s1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.937,367.706c0-0.409-0.579-0.744-1.291-0.744s-1.291,0.335-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="327.937" y1="367.706" x2="327.937" y2="368.345"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="325.355" y1="367.706" x2="325.355" y2="368.353"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.742,367.61c0-0.351-0.493-0.631-1.096-0.631c-0.603,0-1.096,0.281-1.096,0.631c0,0.347,0.493,0.633,1.096,0.633    C327.249,368.243,327.742,367.957,327.742,367.61"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="326.664" y1="368.46" x2="326.664" y2="369.095"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M304.097,373.539c0,0.412,0.581,0.747,1.293,0.747s1.293-0.335,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M306.682,372.896c0-0.412-0.581-0.747-1.293-0.747s-1.293,0.335-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.682" y1="372.896" x2="306.682" y2="373.531"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="304.099" y1="372.896" x2="304.099" y2="373.539"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M306.486,372.798c0-0.349-0.491-0.633-1.096-0.633s-1.096,0.284-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    S306.486,373.145,306.486,372.798"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="305.407" y1="373.646" x2="305.407" y2="374.283"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M307.071,386.995c0,0.409,0.581,0.744,1.293,0.744c0.712,0,1.293-0.335,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M309.656,386.348c0-0.412-0.581-0.745-1.293-0.745c-0.712,0-1.293,0.333-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="309.656" y1="386.348" x2="309.656" y2="386.986"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="307.071" y1="386.348" x2="307.071" y2="386.995"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M309.46,386.251c0-0.351-0.491-0.635-1.096-0.635c-0.605,0-1.096,0.284-1.096,0.635c0,0.349,0.491,0.63,1.096,0.63    C308.969,386.881,309.46,386.6,309.46,386.251"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="308.381" y1="387.098" x2="308.381" y2="387.739"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M292.758,396.797c0,0.412,0.581,0.747,1.294,0.747c0.71,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M295.343,396.154c0-0.412-0.581-0.745-1.291-0.745c-0.714,0-1.294,0.333-1.294,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.343" y1="396.154" x2="295.343" y2="396.792"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="292.758" y1="396.154" x2="292.758" y2="396.797"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M295.147,396.054c0-0.347-0.491-0.631-1.094-0.631c-0.607,0-1.096,0.284-1.096,0.631c0,0.351,0.489,0.633,1.096,0.633    C294.656,396.687,295.147,396.404,295.147,396.054"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="294.067" y1="396.904" x2="294.067" y2="397.543"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M286.485,398.239c0,0.41,0.581,0.747,1.293,0.747c0.712,0,1.293-0.337,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M289.07,397.595c0-0.414-0.581-0.747-1.293-0.747c-0.712,0-1.293,0.333-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="289.07" y1="397.595" x2="289.07" y2="398.23"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="286.485" y1="397.595" x2="286.485" y2="398.239"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M288.873,397.495c0-0.347-0.491-0.633-1.096-0.633c-0.605,0-1.096,0.286-1.096,0.633c0,0.351,0.491,0.631,1.096,0.631    C288.382,398.127,288.873,397.846,288.873,397.495"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="287.795" y1="398.346" x2="287.795" y2="398.984"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.633,406.51c0,0.412,0.577,0.747,1.293,0.747c0.712,0,1.289-0.335,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.214,405.866c0-0.412-0.577-0.747-1.289-0.747c-0.716,0-1.293,0.335-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.214" y1="405.866" x2="281.214" y2="406.505"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.633" y1="405.866" x2="278.633" y2="406.51"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.02,405.766c0-0.347-0.491-0.631-1.094-0.631c-0.605,0-1.096,0.284-1.096,0.631c0,0.351,0.491,0.633,1.096,0.633    C280.529,406.4,281.02,406.117,281.02,405.766"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.939" y1="406.617" x2="279.939" y2="407.255"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.771,391.339c0,0.412,0.579,0.744,1.293,0.744c0.712,0,1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.355,390.692c0-0.41-0.579-0.745-1.291-0.745c-0.714,0-1.293,0.335-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.355" y1="390.692" x2="281.355" y2="391.33"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.771" y1="390.692" x2="278.771" y2="391.339"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,390.596c0-0.351-0.489-0.631-1.094-0.631c-0.607,0-1.096,0.281-1.096,0.631c0,0.347,0.489,0.631,1.096,0.631    C280.669,391.227,281.158,390.943,281.158,390.596"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.08" y1="391.446" x2="280.08" y2="392.081"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M269.523,398.379c0,0.412,0.581,0.744,1.291,0.744c0.716,0,1.293-0.331,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M272.107,397.732c0-0.412-0.577-0.744-1.293-0.744c-0.71,0-1.291,0.331-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="272.107" y1="397.732" x2="272.107" y2="398.37"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="269.523" y1="397.732" x2="269.523" y2="398.379"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M271.91,397.636c0-0.351-0.491-0.631-1.096-0.631c-0.605,0-1.094,0.281-1.094,0.631c0,0.347,0.489,0.633,1.094,0.633    C271.419,398.269,271.91,397.983,271.91,397.636"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="270.832" y1="398.486" x2="270.833" y2="399.121"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M263.902,397.194c0,0.41,0.575,0.747,1.291,0.747c0.714,0,1.289-0.337,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M266.482,396.55c0-0.414-0.575-0.749-1.289-0.749c-0.716,0-1.291,0.335-1.291,0.749"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="266.482" y1="396.55" x2="266.482" y2="397.185"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="263.902" y1="396.55" x2="263.902" y2="397.194"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M266.286,396.45c0-0.347-0.489-0.633-1.093-0.633c-0.605,0-1.094,0.286-1.094,0.633c0,0.351,0.489,0.631,1.094,0.631    C265.796,397.081,266.286,396.801,266.286,396.45"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="265.207" y1="397.301" x2="265.207" y2="397.939"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M241.083,428.759c0,0.412,0.581,0.745,1.293,0.745s1.289-0.333,1.289-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M243.665,428.112c0-0.409-0.577-0.744-1.289-0.744s-1.293,0.335-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="243.665" y1="428.112" x2="243.665" y2="428.751"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="241.083" y1="428.112" x2="241.083" y2="428.759"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M243.47,428.017c0-0.353-0.489-0.633-1.094-0.633s-1.096,0.281-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    S243.47,428.365,243.47,428.017"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="242.389" y1="428.866" x2="242.393" y2="429.501"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M260.882,410.74c0,0.412,0.577,0.744,1.289,0.744c0.712,0,1.293-0.331,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M263.464,410.093c0-0.409-0.581-0.744-1.293-0.744c-0.712,0-1.289,0.335-1.289,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="263.464" y1="410.093" x2="263.464" y2="410.732"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="260.882" y1="410.093" x2="260.882" y2="410.74"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M263.266,409.997c0-0.351-0.489-0.631-1.094-0.631c-0.605,0-1.096,0.281-1.096,0.631c0,0.349,0.491,0.633,1.096,0.633    C262.776,410.63,263.266,410.346,263.266,409.997"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="262.189" y1="410.847" x2="262.189" y2="411.482"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M255.257,409.555c0,0.41,0.577,0.747,1.293,0.747c0.712,0,1.289-0.337,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.839,408.911c0-0.414-0.577-0.745-1.289-0.745c-0.716,0-1.293,0.331-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="257.839" y1="408.911" x2="257.839" y2="409.546"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.259" y1="408.911" x2="255.259" y2="409.555"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.643,408.811c0-0.347-0.491-0.633-1.093-0.633c-0.605,0-1.096,0.286-1.096,0.633c0,0.351,0.491,0.631,1.096,0.631    C257.152,409.442,257.643,409.162,257.643,408.811"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="256.564" y1="409.662" x2="256.564" y2="410.3"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M254.582,419.476c0,0.412,0.581,0.747,1.293,0.747c0.712,0,1.293-0.335,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.167,418.833c0-0.412-0.581-0.747-1.293-0.747c-0.712,0-1.293,0.335-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="257.167" y1="418.833" x2="257.167" y2="419.467"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.582" y1="418.833" x2="254.582" y2="419.476"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M256.971,418.734c0-0.349-0.491-0.633-1.096-0.633s-1.096,0.284-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    S256.971,419.082,256.971,418.734"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.892" y1="419.583" x2="255.892" y2="420.22"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M248.961,418.292c0,0.41,0.577,0.745,1.289,0.745c0.716,0,1.293-0.335,1.293-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M251.543,417.647c0-0.414-0.577-0.745-1.293-0.745c-0.712,0-1.289,0.331-1.289,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="251.543" y1="417.647" x2="251.543" y2="418.282"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="248.961" y1="417.647" x2="248.961" y2="418.292"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M251.346,417.547c0-0.347-0.491-0.631-1.096-0.631c-0.602,0-1.093,0.284-1.093,0.631c0,0.351,0.491,0.633,1.093,0.633    C250.855,418.18,251.346,417.898,251.346,417.547"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="250.268" y1="418.398" x2="250.268" y2="419.036"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.409,357.039c0,0.412,0.577,0.747,1.289,0.747c0.712,0,1.293-0.335,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.991,356.396c0-0.412-0.581-0.747-1.293-0.747c-0.712,0-1.289,0.335-1.289,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.991" y1="356.396" x2="232.991" y2="357.031"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.409" y1="356.396" x2="230.409" y2="357.039"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.792,356.296c0-0.347-0.489-0.633-1.094-0.633s-1.096,0.286-1.096,0.633c0,0.351,0.491,0.631,1.096,0.631    S232.792,356.646,232.792,356.296"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.716" y1="357.146" x2="231.716" y2="357.785"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M234.287,358.907c0,0.412,0.581,0.744,1.293,0.744c0.712,0,1.293-0.331,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M236.872,358.26c0-0.412-0.581-0.744-1.293-0.744c-0.712,0-1.293,0.331-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="236.872" y1="358.26" x2="236.872" y2="358.898"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="234.287" y1="358.26" x2="234.287" y2="358.907"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M236.675,358.162c0-0.349-0.491-0.63-1.096-0.63c-0.605,0-1.096,0.281-1.096,0.63c0,0.351,0.491,0.635,1.096,0.635    C236.184,358.797,236.675,358.513,236.675,358.162"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="235.597" y1="359.012" x2="235.597" y2="359.651"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M210.71,372.266c0,0.412,0.577,0.747,1.291,0.747c0.714,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M213.291,371.623c0-0.412-0.577-0.747-1.291-0.747c-0.714,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="213.291" y1="371.623" x2="213.291" y2="372.259"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="210.71" y1="371.623" x2="210.71" y2="372.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M213.095,371.524c0-0.349-0.491-0.633-1.094-0.633c-0.605,0-1.096,0.284-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    C212.604,372.156,213.095,371.872,213.095,371.524"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="212.018" y1="372.375" x2="212.018" y2="373.01"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M214.589,374.132c0,0.412,0.581,0.747,1.291,0.747c0.714,0,1.293-0.335,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M217.173,373.489c0-0.412-0.579-0.745-1.293-0.745c-0.71,0-1.291,0.333-1.291,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="217.173" y1="373.489" x2="217.173" y2="374.127"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="214.589" y1="373.489" x2="214.589" y2="374.132"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.976,373.39c0-0.349-0.489-0.633-1.096-0.633c-0.605,0-1.094,0.284-1.094,0.633c0,0.351,0.489,0.631,1.094,0.631    C216.487,374.022,216.976,373.741,216.976,373.39"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="215.898" y1="374.239" x2="215.899" y2="374.878"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M251.971,371.335c0,0.41,0.581,0.745,1.293,0.745c0.712,0,1.289-0.335,1.289-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M254.552,370.689c0-0.414-0.577-0.745-1.289-0.745c-0.712,0-1.293,0.331-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.552" y1="370.689" x2="254.552" y2="371.324"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="251.971" y1="370.689" x2="251.971" y2="371.335"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M254.359,370.59c0-0.347-0.491-0.631-1.096-0.631c-0.605,0-1.094,0.284-1.094,0.631c0,0.351,0.489,0.633,1.094,0.633    C253.868,371.223,254.359,370.94,254.359,370.59"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="253.279" y1="371.44" x2="253.279" y2="372.079"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M215.328,398.893c0,0.409,0.577,0.744,1.291,0.744s1.289-0.335,1.289-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M217.908,398.244c0-0.41-0.575-0.744-1.289-0.744s-1.291,0.333-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="217.908" y1="398.244" x2="217.908" y2="398.884"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="215.328" y1="398.244" x2="215.328" y2="398.893"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M217.715,398.149c0-0.351-0.491-0.633-1.096-0.633c-0.603,0-1.094,0.282-1.094,0.633c0,0.347,0.491,0.631,1.094,0.631    C217.224,398.781,217.715,398.497,217.715,398.149"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="216.634" y1="398.997" x2="216.634" y2="399.635"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M223.937,380.436c0,0.409,0.577,0.744,1.289,0.744c0.714,0,1.293-0.335,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M226.519,379.787c0-0.41-0.579-0.744-1.293-0.744c-0.712,0-1.289,0.333-1.289,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="226.519" y1="379.787" x2="226.519" y2="380.427"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="223.937" y1="379.787" x2="223.937" y2="380.436"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M226.321,379.692c0-0.351-0.489-0.633-1.094-0.633c-0.605,0-1.096,0.282-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    C225.831,380.323,226.321,380.039,226.321,379.692"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="225.244" y1="380.539" x2="225.244" y2="381.177"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.936,329.87c0,0.412,0.577,0.747,1.289,0.747c0.716,0,1.293-0.335,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M235.518,329.227c0-0.412-0.577-0.747-1.293-0.747c-0.712,0-1.289,0.335-1.289,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="235.518" y1="329.227" x2="235.518" y2="329.863"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.936" y1="329.227" x2="232.936" y2="329.87"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M235.32,329.129c0-0.349-0.489-0.633-1.094-0.633c-0.605,0-1.094,0.284-1.094,0.633c0,0.347,0.489,0.631,1.094,0.631    C234.83,329.76,235.32,329.476,235.32,329.129"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="234.243" y1="329.979" x2="234.243" y2="330.614"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.287,312.865c0,0.412,0.577,0.747,1.293,0.747c0.712,0,1.289-0.335,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M218.869,312.222c0-0.412-0.577-0.747-1.289-0.747c-0.716,0-1.293,0.335-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="218.869" y1="312.222" x2="218.869" y2="312.86"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="216.287" y1="312.222" x2="216.287" y2="312.865"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M218.672,312.122c0-0.347-0.488-0.631-1.093-0.631s-1.094,0.284-1.094,0.631c0,0.351,0.489,0.633,1.094,0.633    S218.672,312.472,218.672,312.122"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="217.594" y1="312.972" x2="217.594" y2="313.611"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M224.534,347.102c0,0.41,0.577,0.745,1.289,0.745c0.716,0,1.293-0.335,1.293-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M227.115,346.455c0-0.412-0.577-0.744-1.293-0.744c-0.712,0-1.289,0.331-1.289,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="227.115" y1="346.455" x2="227.115" y2="347.093"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="224.534" y1="346.455" x2="224.534" y2="347.102"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M226.919,346.357c0-0.347-0.491-0.633-1.096-0.633c-0.602,0-1.093,0.286-1.093,0.633c0,0.351,0.491,0.631,1.093,0.631    C226.428,346.988,226.919,346.707,226.919,346.357"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="225.84" y1="347.206" x2="225.84" y2="347.846"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="149.12" y1="86.652" x2="147.699" y2="87.305"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="149.12" y1="86.652" x2="147.699" y2="87.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.69,73.067c-0.44-0.582-1.322-0.665-1.968-0.177c-0.642,0.486-0.809,1.354-0.37,1.938"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M198.833,75.461c0.31,0.412,0.842,0.574,1.359,0.461"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.234,75.14c0.27-0.495,0.228-1.044-0.065-1.438"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.079,73.96c-0.37-0.495-1.117-0.559-1.663-0.151c-0.544,0.412-0.686,1.145-0.312,1.642c0.223,0.296,0.582,0.44,0.952,0.416"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.22,74.991c0.132-0.374,0.068-0.752-0.14-1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.715,74.237c-0.233-0.312-0.703-0.354-1.047-0.096c-0.344,0.26-0.435,0.723-0.198,1.035"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.132,74.789c-0.233-0.312-0.703-0.354-1.047-0.095c-0.344,0.26-0.431,0.724-0.198,1.035c0.237,0.312,0.703,0.356,1.051,0.095    C201.279,75.565,201.369,75.102,201.132,74.789"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.715" y1="74.237" x2="201.132" y2="74.789"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="199.887" y1="75.73" x2="199.469" y2="75.174"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.69" y1="73.067" x2="201.169" y2="73.7"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="198.833" y1="75.461" x2="198.352" y2="74.828"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="199.201" y1="73.53" x2="198.726" y2="72.897"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.69,73.067c-0.44-0.582-1.322-0.665-1.968-0.177c-0.642,0.486-0.809,1.354-0.37,1.938"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.688,76.87c-0.468-0.521-1.335-0.505-1.936,0.039c-0.598,0.54-0.705,1.398-0.24,1.917"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M184.043,79.416c0.331,0.368,0.861,0.467,1.364,0.3"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.372,78.844c0.235-0.505,0.16-1.033-0.153-1.386"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.152,77.724c-0.396-0.44-1.128-0.424-1.635,0.032c-0.509,0.458-0.6,1.186-0.203,1.626c0.237,0.263,0.596,0.363,0.958,0.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.356,78.709c0.105-0.375,0.021-0.735-0.203-0.986"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.814,78.031c-0.249-0.277-0.71-0.268-1.033,0.019c-0.321,0.288-0.375,0.749-0.126,1.026"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.277,78.548c-0.247-0.279-0.712-0.27-1.03,0.018c-0.321,0.288-0.379,0.749-0.13,1.026c0.251,0.277,0.714,0.268,1.033-0.021    C186.472,79.281,186.528,78.825,186.277,78.548"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="185.814" y1="78.031" x2="186.277" y2="78.548"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="185.118" y1="79.591" x2="184.655" y2="79.076"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="185.688" y1="76.87" x2="186.216" y2="77.459"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="184.043" y1="79.416" x2="183.511" y2="78.825"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="184.297" y1="77.494" x2="183.765" y2="76.903"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.688,76.87c-0.468-0.521-1.335-0.505-1.936,0.039c-0.598,0.54-0.705,1.398-0.24,1.917"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.071,81.438c-0.505-0.486-1.366-0.41-1.929,0.172c-0.561,0.581-0.607,1.447-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M169.608,84.094c0.356,0.342,0.891,0.407,1.38,0.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.892,83.362c0.196-0.521,0.084-1.044-0.253-1.373"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.596,82.257c-0.426-0.41-1.158-0.346-1.629,0.149c-0.477,0.491-0.514,1.222-0.091,1.633c0.258,0.247,0.623,0.323,0.98,0.235"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.866,83.227c0.079-0.382-0.03-0.735-0.27-0.97"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.276,82.587c-0.267-0.26-0.726-0.217-1.028,0.091c-0.3,0.312-0.323,0.773-0.054,1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.776,83.069c-0.267-0.258-0.726-0.216-1.026,0.091c-0.302,0.31-0.324,0.773-0.056,1.033c0.267,0.258,0.728,0.216,1.028-0.091    C172.022,83.792,172.045,83.329,171.776,83.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="171.276" y1="82.587" x2="171.776" y2="83.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="170.694" y1="84.194" x2="170.194" y2="83.71"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="171.071" y1="81.44" x2="171.639" y2="81.989"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.61" y1="84.095" x2="169.038" y2="83.541"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.726" y1="82.157" x2="169.154" y2="81.607"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.071,81.438c-0.505-0.486-1.366-0.41-1.929,0.172c-0.561,0.581-0.607,1.447-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M157.482,86.509c-0.502-0.484-1.366-0.407-1.926,0.175c-0.561,0.581-0.609,1.443-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M156.021,89.164c0.354,0.346,0.889,0.407,1.38,0.207"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.307,88.433c0.196-0.517,0.084-1.04-0.251-1.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.01,87.331c-0.426-0.41-1.156-0.346-1.633,0.146c-0.472,0.491-0.514,1.222-0.086,1.635c0.253,0.246,0.617,0.321,0.975,0.237"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.277,88.301c0.082-0.386-0.03-0.738-0.267-0.97"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M157.691,87.661c-0.268-0.26-0.73-0.219-1.028,0.091c-0.298,0.309-0.323,0.77-0.056,1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.191,88.143c-0.27-0.261-0.73-0.219-1.029,0.091c-0.296,0.31-0.324,0.77-0.056,1.03c0.27,0.261,0.731,0.219,1.031-0.091    C158.433,88.862,158.461,88.403,158.191,88.143"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.691" y1="87.661" x2="158.191" y2="88.143"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.105" y1="89.264" x2="156.607" y2="88.783"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.482" y1="86.509" x2="158.056" y2="87.063"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="156.021" y1="89.164" x2="155.453" y2="88.615"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="156.139" y1="87.231" x2="155.569" y2="86.677"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M157.482,86.509c-0.502-0.484-1.366-0.407-1.926,0.175c-0.561,0.581-0.609,1.443-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M144.432,92.24c-0.554-0.36-1.361-0.102-1.803,0.575c-0.438,0.679-0.346,1.519,0.209,1.878"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M143.502,95.125c0.368,0.24,0.847,0.209,1.265-0.044"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.572,93.831c0.061-0.503-0.13-0.931-0.474-1.158"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.104,92.947c-0.47-0.303-1.152-0.088-1.524,0.484c-0.37,0.574-0.293,1.287,0.177,1.592c0.268,0.174,0.605,0.177,0.917,0.04"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.516,93.77c0-0.365-0.161-0.659-0.412-0.823"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M144.856,93.329c-0.298-0.193-0.726-0.054-0.963,0.309c-0.233,0.36-0.182,0.81,0.111,1"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.437,93.708c-0.295-0.193-0.724-0.056-0.961,0.305c-0.233,0.361-0.182,0.81,0.112,1.003s0.724,0.056,0.961-0.305    C145.782,94.348,145.735,93.901,145.437,93.708"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="144.856" y1="93.329" x2="145.437" y2="93.708"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="144.588" y1="95.017" x2="144.004" y2="94.638"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="144.432" y1="92.24" x2="145.098" y2="92.673"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="143.504" y1="95.125" x2="142.838" y2="94.694"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="143.301" y1="93.254" x2="142.636" y2="92.822"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M144.432,92.24c-0.554-0.36-1.361-0.102-1.803,0.575c-0.438,0.679-0.346,1.519,0.209,1.878"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="149.12" y1="86.652" x2="147.699" y2="87.305"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="149.12" y1="86.652" x2="147.699" y2="87.305"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.69,73.067c-0.44-0.582-1.322-0.665-1.968-0.177c-0.642,0.486-0.809,1.354-0.37,1.938"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M198.833,75.461c0.31,0.412,0.842,0.574,1.359,0.461"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.234,75.14c0.27-0.495,0.228-1.044-0.065-1.438"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.079,73.96c-0.37-0.495-1.117-0.559-1.663-0.151c-0.544,0.412-0.686,1.145-0.312,1.642c0.223,0.296,0.582,0.44,0.952,0.416"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.22,74.991c0.132-0.374,0.068-0.752-0.14-1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.715,74.237c-0.233-0.312-0.703-0.354-1.047-0.096c-0.344,0.26-0.435,0.723-0.198,1.035"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.132,74.789c-0.233-0.312-0.703-0.354-1.047-0.095c-0.344,0.26-0.431,0.724-0.198,1.035c0.237,0.312,0.703,0.356,1.051,0.095    C201.279,75.565,201.369,75.102,201.132,74.789"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.715" y1="74.237" x2="201.132" y2="74.789"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="199.887" y1="75.73" x2="199.469" y2="75.174"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.69" y1="73.067" x2="201.169" y2="73.7"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="198.833" y1="75.461" x2="198.352" y2="74.828"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="199.201" y1="73.53" x2="198.726" y2="72.897"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.69,73.067c-0.44-0.582-1.322-0.665-1.968-0.177c-0.642,0.486-0.809,1.354-0.37,1.938"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.688,76.87c-0.468-0.521-1.335-0.505-1.936,0.039c-0.598,0.54-0.705,1.398-0.24,1.917"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M184.043,79.416c0.331,0.368,0.861,0.467,1.364,0.3"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.372,78.844c0.235-0.505,0.16-1.033-0.153-1.386"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.152,77.724c-0.396-0.44-1.128-0.424-1.635,0.032c-0.509,0.458-0.6,1.186-0.203,1.626c0.237,0.263,0.596,0.363,0.958,0.303"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.356,78.709c0.105-0.375,0.021-0.735-0.203-0.986"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.814,78.031c-0.249-0.277-0.71-0.268-1.033,0.019c-0.321,0.288-0.375,0.749-0.126,1.026"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.277,78.548c-0.247-0.279-0.712-0.27-1.03,0.018c-0.321,0.288-0.379,0.749-0.13,1.026c0.251,0.277,0.714,0.268,1.033-0.021    C186.472,79.281,186.528,78.825,186.277,78.548"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="185.814" y1="78.031" x2="186.277" y2="78.548"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="185.118" y1="79.591" x2="184.655" y2="79.076"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="185.688" y1="76.87" x2="186.216" y2="77.459"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="184.043" y1="79.416" x2="183.511" y2="78.825"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="184.297" y1="77.494" x2="183.765" y2="76.903"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M185.688,76.87c-0.468-0.521-1.335-0.505-1.936,0.039c-0.598,0.54-0.705,1.398-0.24,1.917"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.071,81.438c-0.505-0.486-1.366-0.41-1.929,0.172c-0.561,0.581-0.607,1.447-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M169.608,84.094c0.356,0.342,0.891,0.407,1.38,0.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.892,83.362c0.196-0.521,0.084-1.044-0.253-1.373"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.596,82.257c-0.426-0.41-1.158-0.346-1.629,0.149c-0.477,0.491-0.514,1.222-0.091,1.633c0.258,0.247,0.623,0.323,0.98,0.235"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.866,83.227c0.079-0.382-0.03-0.735-0.27-0.97"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.276,82.587c-0.267-0.26-0.726-0.217-1.028,0.091c-0.3,0.312-0.323,0.773-0.054,1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.776,83.069c-0.267-0.258-0.726-0.216-1.026,0.091c-0.302,0.31-0.324,0.773-0.056,1.033c0.267,0.258,0.728,0.216,1.028-0.091    C172.022,83.792,172.045,83.329,171.776,83.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="171.276" y1="82.587" x2="171.776" y2="83.069"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="170.694" y1="84.194" x2="170.194" y2="83.71"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="171.071" y1="81.44" x2="171.639" y2="81.989"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.61" y1="84.095" x2="169.038" y2="83.541"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.726" y1="82.157" x2="169.154" y2="81.607"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M171.071,81.438c-0.505-0.486-1.366-0.41-1.929,0.172c-0.561,0.581-0.607,1.447-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M157.482,86.509c-0.502-0.484-1.366-0.407-1.926,0.175c-0.561,0.581-0.609,1.443-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M156.021,89.164c0.354,0.346,0.889,0.407,1.38,0.207"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.307,88.433c0.196-0.517,0.084-1.04-0.251-1.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.01,87.331c-0.426-0.41-1.156-0.346-1.633,0.146c-0.472,0.491-0.514,1.222-0.086,1.635c0.253,0.246,0.617,0.321,0.975,0.237"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.277,88.301c0.082-0.386-0.03-0.738-0.267-0.97"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M157.691,87.661c-0.268-0.26-0.73-0.219-1.028,0.091c-0.298,0.309-0.323,0.77-0.056,1.031"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M158.191,88.143c-0.27-0.261-0.73-0.219-1.029,0.091c-0.296,0.31-0.324,0.77-0.056,1.03c0.27,0.261,0.731,0.219,1.031-0.091    C158.433,88.862,158.461,88.403,158.191,88.143"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.691" y1="87.661" x2="158.191" y2="88.143"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.105" y1="89.264" x2="156.607" y2="88.783"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="157.482" y1="86.509" x2="158.056" y2="87.063"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="156.021" y1="89.164" x2="155.453" y2="88.615"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="156.139" y1="87.231" x2="155.569" y2="86.677"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M157.482,86.509c-0.502-0.484-1.366-0.407-1.926,0.175c-0.561,0.581-0.609,1.443-0.103,1.931"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M144.432,92.24c-0.554-0.36-1.361-0.102-1.803,0.575c-0.438,0.679-0.346,1.519,0.209,1.878"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M143.502,95.125c0.368,0.24,0.847,0.209,1.265-0.044"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.572,93.831c0.061-0.503-0.13-0.931-0.474-1.158"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.104,92.947c-0.47-0.303-1.152-0.088-1.524,0.484c-0.37,0.574-0.293,1.287,0.177,1.592c0.268,0.174,0.605,0.177,0.917,0.04"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.516,93.77c0-0.365-0.161-0.659-0.412-0.823"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M144.856,93.329c-0.298-0.193-0.726-0.054-0.963,0.309c-0.233,0.36-0.182,0.81,0.111,1"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M145.437,93.708c-0.295-0.193-0.724-0.056-0.961,0.305c-0.233,0.361-0.182,0.81,0.112,1.003s0.724,0.056,0.961-0.305    C145.782,94.348,145.735,93.901,145.437,93.708"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="144.856" y1="93.329" x2="145.437" y2="93.708"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="144.588" y1="95.017" x2="144.004" y2="94.638"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="144.432" y1="92.24" x2="145.098" y2="92.673"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="143.504" y1="95.125" x2="142.838" y2="94.694"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="143.301" y1="93.254" x2="142.636" y2="92.822"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M144.432,92.24c-0.554-0.36-1.361-0.102-1.803,0.575c-0.438,0.679-0.346,1.519,0.209,1.878"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.381,78.671c0-0.78-0.652-1.414-1.463-1.414c-0.805,0-1.463,0.633-1.463,1.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,79.465c0,0.549,0.33,1.028,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,80.73c0.509-0.246,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,79.618c0-0.659-0.554-1.196-1.24-1.196c-0.682,0-1.235,0.537-1.235,1.196c0,0.396,0.198,0.749,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,80.586c0.331-0.235,0.509-0.596,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,79.618c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,80.312c0-0.416-0.349-0.754-0.782-0.754c-0.43,0-0.78,0.338-0.78,0.754c0,0.417,0.351,0.754,0.78,0.754    C280.352,81.066,280.701,80.73,280.701,80.312"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="79.618" x2="280.701" y2="80.312"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="80.312" x2="279.138" y2="79.618"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="78.671" x2="281.381" y2="79.465"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="79.465" x2="278.456" y2="78.671"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.918" y1="78.05" x2="279.918" y2="77.257"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,125.411c-0.319,0.181-0.491,0.538-0.5,0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,127.949c0.382,0.217,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,127.556c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.726"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="124.965" x2="426.045" y2="125.411"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="128.001" x2="428.306" y2="127.554"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,127.554c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,127.933c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.445c-0.337,0.189-0.403,0.7-0.154,1.138    C425.668,127.924,426.143,128.126,426.48,127.933"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="126.345" x2="426.236" y2="125.967"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="127.554" x2="426.452" y2="127.949"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="126.06" x2="428.465" y2="125.614"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,314.775c-0.319,0.181-0.491,0.538-0.5,0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,317.313c0.382,0.216,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,316.92c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.726"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="314.33" x2="426.045" y2="314.775"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="317.366" x2="428.306" y2="316.918"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,316.918c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.638-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,317.297c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.447c-0.337,0.191-0.403,0.703-0.154,1.142    C425.668,317.29,426.143,317.49,426.48,317.297"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="315.71" x2="426.236" y2="315.331"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="316.918" x2="426.452" y2="317.313"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="315.424" x2="428.465" y2="314.979"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,301.807c-0.319,0.181-0.491,0.538-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,304.345c0.382,0.217,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,303.952c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.726"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="301.362" x2="426.045" y2="301.807"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="304.398" x2="428.306" y2="303.952"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,303.95c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,304.329c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.445c-0.337,0.191-0.403,0.7-0.154,1.138    C425.668,304.32,426.143,304.522,426.48,304.329"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="302.744" x2="426.236" y2="302.363"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="303.95" x2="426.452" y2="304.345"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="302.456" x2="428.465" y2="302.011"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,288.257c-0.319,0.181-0.491,0.54-0.5,0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,290.795c0.382,0.217,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,290.402c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.726"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="287.812" x2="426.045" y2="288.257"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="290.847" x2="428.306" y2="290.402"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,290.4c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,290.779c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.445c-0.337,0.191-0.403,0.7-0.154,1.138    C425.668,290.77,426.143,290.972,426.48,290.779"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="289.194" x2="426.236" y2="288.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="290.4" x2="426.452" y2="290.795"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="288.906" x2="428.465" y2="288.46"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,275.079c-0.319,0.179-0.491,0.538-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,277.616c0.382,0.217,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,277.222c0.549-0.314,0.663-1.147,0.253-1.863c-0.409-0.716-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="274.631" x2="426.045" y2="275.079"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="277.667" x2="428.306" y2="277.222"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,277.22c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.638-1.063-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,277.601c0.337-0.193,0.405-0.703,0.156-1.142c-0.253-0.437-0.728-0.638-1.063-0.445c-0.337,0.189-0.403,0.702-0.154,1.14    C425.668,277.592,426.143,277.792,426.48,277.601"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="276.013" x2="426.236" y2="275.635"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="277.22" x2="426.452" y2="277.616"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="275.728" x2="428.465" y2="275.28"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,247.819c-0.319,0.182-0.491,0.54-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,250.356c0.382,0.217,0.775,0.224,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,249.964c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.714-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="247.371" x2="426.045" y2="247.819"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="250.409" x2="428.306" y2="249.964"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,249.962c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,250.341c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.64-1.063-0.445c-0.337,0.191-0.403,0.7-0.154,1.138    C425.668,250.332,426.143,250.534,426.48,250.341"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="248.755" x2="426.236" y2="248.375"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="249.962" x2="426.452" y2="250.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="248.467" x2="428.465" y2="248.02"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,261.209c-0.319,0.182-0.491,0.538-0.5,0.952"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,263.747c0.382,0.219,0.775,0.224,1.073,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,263.356c0.549-0.312,0.663-1.147,0.253-1.863c-0.409-0.716-1.184-1.044-1.733-0.731"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="260.762" x2="426.045" y2="261.209"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="263.803" x2="428.306" y2="263.356"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,263.352c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,263.731c0.337-0.189,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.447c-0.337,0.193-0.403,0.703-0.154,1.142    C425.668,263.724,426.143,263.924,426.48,263.731"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="262.144" x2="426.236" y2="261.765"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="263.352" x2="426.452" y2="263.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="261.858" x2="428.465" y2="261.411"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,234.005c-0.319,0.179-0.491,0.538-0.5,0.947"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,236.543c0.382,0.214,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,236.148c0.549-0.312,0.663-1.147,0.253-1.863c-0.409-0.716-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="233.558" x2="426.045" y2="234.005"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="236.596" x2="428.306" y2="236.148"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,236.148c0.337-0.193,0.405-0.705,0.156-1.144c-0.253-0.438-0.726-0.637-1.063-0.444"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,236.527c0.337-0.193,0.405-0.705,0.156-1.144c-0.253-0.438-0.728-0.635-1.063-0.444c-0.337,0.189-0.403,0.703-0.154,1.14    C425.668,236.519,426.143,236.717,426.48,236.527"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="234.94" x2="426.236" y2="234.561"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="236.148" x2="426.452" y2="236.543"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="234.654" x2="428.465" y2="234.207"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,220.929c-0.319,0.184-0.491,0.54-0.5,0.952"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,223.466c0.382,0.219,0.775,0.223,1.073,0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,223.077c0.549-0.316,0.663-1.151,0.253-1.864c-0.409-0.716-1.184-1.042-1.733-0.73"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="220.483" x2="426.045" y2="220.929"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="223.521" x2="428.306" y2="223.077"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,223.072c0.337-0.189,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.638-1.063-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,223.452c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.64-1.063-0.447c-0.337,0.193-0.403,0.702-0.154,1.14    C425.668,223.444,426.143,223.644,426.48,223.452"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="221.865" x2="426.236" y2="221.486"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="223.072" x2="426.452" y2="223.466"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="221.578" x2="428.465" y2="221.132"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,207.91c-0.319,0.181-0.491,0.538-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,210.448c0.382,0.217,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,210.055c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="207.463" x2="426.045" y2="207.91"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="210.5" x2="428.306" y2="210.055"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,210.051c0.337-0.191,0.405-0.7,0.156-1.138c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,210.432c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.64-1.063-0.445c-0.337,0.191-0.403,0.7-0.154,1.138    C425.668,210.423,426.143,210.625,426.48,210.432"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="208.846" x2="426.236" y2="208.466"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="210.051" x2="426.452" y2="210.448"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="208.559" x2="428.465" y2="208.113"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,194.731c-0.319,0.179-0.491,0.538-0.5,0.947"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,197.269c0.382,0.216,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,196.875c0.549-0.314,0.663-1.147,0.253-1.863c-0.409-0.714-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="194.284" x2="426.045" y2="194.731"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="197.32" x2="428.306" y2="196.875"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,196.873c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,197.252c0.337-0.191,0.405-0.702,0.156-1.14c-0.253-0.437-0.728-0.638-1.063-0.445c-0.337,0.189-0.403,0.702-0.154,1.138    C425.668,197.243,426.143,197.445,426.48,197.252"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="195.666" x2="426.236" y2="195.287"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="196.873" x2="426.452" y2="197.269"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="195.38" x2="428.465" y2="194.933"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,181.392c-0.319,0.182-0.491,0.54-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,183.93c0.382,0.217,0.775,0.224,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,183.537c0.549-0.314,0.663-1.147,0.253-1.864c-0.409-0.714-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="180.944" x2="426.045" y2="181.392"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="183.982" x2="428.306" y2="183.537"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,183.535c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,183.914c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.64-1.063-0.445c-0.337,0.191-0.403,0.7-0.154,1.138    C425.668,183.905,426.143,184.107,426.48,183.914"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="182.328" x2="426.236" y2="181.948"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="183.535" x2="426.452" y2="183.93"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="182.041" x2="428.465" y2="181.593"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,167.734c-0.319,0.182-0.491,0.538-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,170.272c0.382,0.217,0.775,0.224,1.073,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,169.881c0.549-0.312,0.663-1.147,0.253-1.863c-0.409-0.717-1.184-1.044-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="167.291" x2="426.045" y2="167.734"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="170.328" x2="428.306" y2="169.881"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,169.879c0.337-0.191,0.405-0.703,0.156-1.142c-0.253-0.438-0.726-0.637-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,170.26c0.337-0.193,0.405-0.705,0.156-1.144c-0.253-0.438-0.728-0.637-1.063-0.445c-0.337,0.191-0.403,0.703-0.154,1.142    C425.668,170.251,426.143,170.449,426.48,170.26"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="168.669" x2="426.236" y2="168.29"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="169.879" x2="426.452" y2="170.272"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="168.383" x2="428.465" y2="167.94"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,154.184c-0.319,0.182-0.491,0.538-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,156.722c0.382,0.217,0.775,0.225,1.073,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,156.331c0.549-0.312,0.663-1.147,0.253-1.863c-0.409-0.717-1.184-1.044-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="153.741" x2="426.045" y2="154.184"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="156.778" x2="428.306" y2="156.331"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,156.329c0.337-0.191,0.405-0.703,0.156-1.142c-0.253-0.438-0.726-0.637-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,156.71c0.337-0.193,0.405-0.705,0.156-1.144c-0.253-0.438-0.728-0.637-1.063-0.445c-0.337,0.191-0.403,0.703-0.154,1.142    C425.668,156.701,426.143,156.899,426.48,156.71"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="155.121" x2="426.236" y2="154.74"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="156.329" x2="426.452" y2="156.722"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="154.833" x2="428.465" y2="154.389"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,140.318c-0.319,0.181-0.491,0.54-0.5,0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,142.856c0.382,0.217,0.775,0.223,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,142.463c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.726"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="139.873" x2="426.045" y2="140.318"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="142.909" x2="428.306" y2="142.463"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,142.462c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,142.84c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.638-1.063-0.445c-0.337,0.189-0.403,0.7-0.154,1.138    C425.668,142.832,426.143,143.033,426.48,142.84"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="141.255" x2="426.236" y2="140.874"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="142.462" x2="426.452" y2="142.856"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="140.967" x2="428.465" y2="140.522"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.045,112.848c-0.319,0.181-0.491,0.54-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.452,115.386c0.382,0.217,0.775,0.221,1.073,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M428.306,114.993c0.549-0.314,0.663-1.149,0.253-1.864c-0.409-0.716-1.184-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="426.826" y1="112.401" x2="426.045" y2="112.848"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.525" y1="115.438" x2="428.306" y2="114.993"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M427.143,114.989c0.337-0.191,0.405-0.7,0.156-1.138c-0.253-0.438-0.726-0.64-1.063-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M426.48,115.37c0.337-0.193,0.405-0.702,0.156-1.14c-0.253-0.438-0.728-0.64-1.063-0.445c-0.337,0.191-0.403,0.7-0.154,1.138    C425.668,115.361,426.143,115.563,426.48,115.37"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="425.573" y1="113.784" x2="426.236" y2="113.404"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.143" y1="114.989" x2="426.452" y2="115.386"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="427.683" y1="113.497" x2="428.465" y2="113.051"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.05,297.365c0,0.414,0.579,0.747,1.289,0.747c0.716,0,1.293-0.333,1.293-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M259.632,296.721c0-0.412-0.577-0.747-1.293-0.747c-0.71,0-1.289,0.335-1.289,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="259.632" y1="296.721" x2="259.632" y2="297.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="257.05" y1="296.721" x2="257.05" y2="297.365"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M259.435,296.623c0-0.349-0.491-0.633-1.096-0.633c-0.605,0-1.094,0.284-1.094,0.633c0,0.347,0.489,0.631,1.094,0.631    C258.944,297.254,259.435,296.97,259.435,296.623"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="258.357" y1="297.473" x2="258.357" y2="298.108"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M294.209,270.284c0,0.412,0.577,0.747,1.293,0.747c0.712,0,1.289-0.335,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.79,269.64c0-0.412-0.577-0.747-1.289-0.747c-0.716,0-1.293,0.335-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="296.79" y1="269.64" x2="296.79" y2="270.275"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="294.209" y1="269.64" x2="294.209" y2="270.284"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.596,269.54c0-0.347-0.489-0.633-1.094-0.633c-0.605,0-1.094,0.286-1.094,0.633c0,0.351,0.489,0.631,1.094,0.631    C296.106,270.171,296.596,269.891,296.596,269.54"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.515" y1="270.391" x2="295.515" y2="271.029"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M341.938,292.001c0,0.412,0.581,0.744,1.294,0.744c0.712,0,1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M344.523,291.354c0-0.412-0.579-0.744-1.291-0.744c-0.714,0-1.294,0.331-1.294,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="344.523" y1="291.354" x2="344.523" y2="291.993"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="341.941" y1="291.354" x2="341.941" y2="292.001"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M344.325,291.258c0-0.351-0.489-0.631-1.093-0.631c-0.607,0-1.096,0.281-1.096,0.631c0,0.347,0.489,0.633,1.096,0.633    C343.835,291.891,344.325,291.605,344.325,291.258"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.248" y1="292.108" x2="343.248" y2="292.743"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M325.292,274.994c0,0.414,0.579,0.745,1.291,0.745s1.291-0.331,1.291-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.874,274.351c0-0.412-0.579-0.747-1.291-0.747s-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="327.874" y1="274.351" x2="327.874" y2="274.986"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="325.292" y1="274.351" x2="325.292" y2="274.994"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M327.679,274.253c0-0.351-0.493-0.633-1.096-0.633s-1.096,0.282-1.096,0.633c0,0.347,0.493,0.633,1.096,0.633    S327.679,274.6,327.679,274.253"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="326.6" y1="275.103" x2="326.6" y2="275.738"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M333.539,309.229c0,0.412,0.575,0.747,1.291,0.747c0.712,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M336.12,308.586c0-0.412-0.579-0.747-1.291-0.747c-0.716,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="336.12" y1="308.586" x2="336.12" y2="309.221"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="333.539" y1="308.586" x2="333.539" y2="309.229"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M335.922,308.486c0-0.347-0.489-0.631-1.093-0.631c-0.607,0-1.096,0.284-1.096,0.631c0,0.349,0.489,0.633,1.096,0.633    C335.433,309.119,335.922,308.835,335.922,308.486"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="334.845" y1="309.336" x2="334.845" y2="309.973"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M254.666,319.733c0,0.412,0.579,0.744,1.291,0.744c0.714,0,1.293-0.331,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.25,319.086c0-0.412-0.579-0.744-1.293-0.744c-0.712,0-1.291,0.331-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="257.25" y1="319.086" x2="257.25" y2="319.724"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.666" y1="319.086" x2="254.666" y2="319.733"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.053,318.99c0-0.351-0.489-0.631-1.096-0.631c-0.605,0-1.094,0.281-1.094,0.631c0,0.349,0.489,0.63,1.094,0.63    C256.564,319.619,257.053,319.339,257.053,318.99"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="255.975" y1="319.839" x2="255.975" y2="320.477"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.608,317.053c0,0.412,0.581,0.744,1.293,0.744s1.289-0.331,1.289-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M260.189,316.406c0-0.412-0.577-0.744-1.289-0.744s-1.293,0.331-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="260.189" y1="316.406" x2="260.189" y2="317.045"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="257.608" y1="316.406" x2="257.608" y2="317.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M259.996,316.31c0-0.351-0.491-0.633-1.096-0.633s-1.094,0.282-1.094,0.633c0,0.347,0.489,0.631,1.094,0.631    S259.996,316.657,259.996,316.31"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="258.916" y1="317.157" x2="258.916" y2="317.795"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M228.389,308.293c0,0.412,0.577,0.747,1.293,0.747c0.71,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.972,307.646c0-0.409-0.581-0.745-1.291-0.745c-0.716,0-1.293,0.337-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.972" y1="307.646" x2="230.972" y2="308.284"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="228.389" y1="307.646" x2="228.389" y2="308.293"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.776,307.549c0-0.351-0.489-0.631-1.094-0.631s-1.096,0.281-1.096,0.631c0,0.349,0.491,0.633,1.096,0.633    S230.776,307.898,230.776,307.549"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.695" y1="308.4" x2="229.695" y2="309.035"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.332,305.611c0,0.412,0.575,0.747,1.291,0.747c0.714,0,1.289-0.335,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.911,304.968c0-0.41-0.575-0.747-1.289-0.747c-0.716,0-1.291,0.337-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.911" y1="304.968" x2="233.911" y2="305.604"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.332" y1="304.968" x2="231.332" y2="305.611"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.715,304.869c0-0.349-0.488-0.633-1.093-0.633c-0.605,0-1.094,0.284-1.094,0.633c0,0.347,0.489,0.631,1.094,0.631    C233.227,305.501,233.715,305.217,233.715,304.869"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.636" y1="305.72" x2="232.636" y2="306.355"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M254.766,251.126c0,0.412,0.575,0.747,1.289,0.747c0.714,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.346,250.483c0-0.412-0.577-0.747-1.291-0.747c-0.714,0-1.289,0.335-1.289,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="257.346" y1="250.483" x2="257.346" y2="251.121"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="254.766" y1="250.483" x2="254.766" y2="251.126"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M257.15,250.384c0-0.349-0.489-0.633-1.094-0.633c-0.605,0-1.093,0.284-1.093,0.633c0,0.351,0.488,0.631,1.093,0.631    C256.661,251.016,257.15,250.735,257.15,250.384"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="256.073" y1="251.233" x2="256.073" y2="251.872"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M279.408,250.146c0,0.412,0.581,0.747,1.293,0.747s1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.991,249.502c0-0.41-0.579-0.747-1.291-0.747s-1.293,0.337-1.293,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.991" y1="249.502" x2="281.991" y2="250.139"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.408" y1="249.502" x2="279.408" y2="250.146"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.797,249.404c0-0.349-0.491-0.633-1.096-0.633s-1.096,0.284-1.096,0.633c0,0.347,0.491,0.631,1.096,0.631    S281.797,249.751,281.797,249.404"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.718" y1="250.255" x2="280.718" y2="250.89"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M305.163,251.325c0,0.412,0.581,0.744,1.291,0.744c0.714,0,1.293-0.331,1.293-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M307.747,250.677c0-0.412-0.579-0.744-1.293-0.744c-0.71,0-1.291,0.331-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="307.747" y1="250.677" x2="307.747" y2="251.318"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="305.163" y1="250.677" x2="305.163" y2="251.325"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M307.55,250.581c0-0.351-0.491-0.631-1.096-0.631c-0.603,0-1.094,0.281-1.094,0.631c0,0.349,0.491,0.633,1.094,0.633    C307.059,251.214,307.55,250.93,307.55,250.581"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="306.473" y1="251.43" x2="306.473" y2="252.068"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M329.808,254.918c0,0.414,0.577,0.745,1.291,0.745c0.712,0,1.293-0.331,1.293-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M332.392,254.274c0-0.412-0.581-0.747-1.293-0.747c-0.714,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="332.392" y1="254.274" x2="332.392" y2="254.911"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="329.808" y1="254.274" x2="329.808" y2="254.918"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M332.195,254.176c0-0.349-0.489-0.633-1.096-0.633c-0.603,0-1.096,0.284-1.096,0.633c0,0.347,0.493,0.633,1.096,0.633    C331.706,254.809,332.195,254.524,332.195,254.176"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="331.115" y1="255.027" x2="331.115" y2="255.662"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M374.856,268.247c0,0.412,0.579,0.744,1.291,0.744s1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M377.437,267.6c0-0.412-0.579-0.744-1.291-0.744s-1.291,0.331-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="377.437" y1="267.6" x2="377.437" y2="268.239"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="374.856" y1="267.6" x2="374.856" y2="268.247"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M377.243,267.502c0-0.349-0.493-0.63-1.096-0.63c-0.603,0-1.096,0.281-1.096,0.63c0,0.351,0.493,0.631,1.096,0.631    C376.75,268.133,377.243,267.853,377.243,267.502"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="376.162" y1="268.351" x2="376.162" y2="268.991"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.976,279.295c0,0.412,0.577,0.744,1.293,0.744c0.71,0,1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M401.56,278.648c0-0.412-0.581-0.744-1.291-0.744c-0.716,0-1.293,0.331-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="401.56" y1="278.648" x2="401.56" y2="279.286"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="398.976" y1="278.648" x2="398.976" y2="279.295"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M401.362,278.551c0-0.351-0.489-0.633-1.093-0.633c-0.607,0-1.094,0.282-1.094,0.633c0,0.347,0.488,0.631,1.094,0.631    C400.872,279.183,401.362,278.898,401.362,278.551"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="400.283" y1="279.398" x2="400.283" y2="280.037"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.249,277.203c0,0.412,0.581,0.744,1.293,0.744c0.714,0,1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.833,276.555c0-0.412-0.577-0.744-1.291-0.744c-0.712,0-1.293,0.331-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.833" y1="276.555" x2="397.833" y2="277.195"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.249" y1="276.555" x2="395.249" y2="277.203"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.638,276.459c0-0.351-0.493-0.631-1.096-0.631c-0.605,0-1.094,0.281-1.094,0.631c0,0.349,0.489,0.633,1.094,0.633    C397.145,277.092,397.638,276.808,397.638,276.459"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.56" y1="277.308" x2="396.56" y2="277.946"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M386.727,288.376c0,0.412,0.579,0.744,1.291,0.744c0.716,0,1.291-0.331,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M389.309,287.729c0-0.409-0.575-0.744-1.291-0.744c-0.712,0-1.291,0.335-1.291,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="389.309" y1="287.729" x2="389.309" y2="288.367"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="386.727" y1="287.729" x2="386.727" y2="288.376"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M389.114,287.633c0-0.351-0.489-0.631-1.096-0.631c-0.603,0-1.093,0.281-1.093,0.631c0,0.347,0.489,0.633,1.093,0.633    C388.625,288.266,389.114,287.98,389.114,287.633"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="388.036" y1="288.483" x2="388.036" y2="289.118"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M383.002,286.286c0,0.41,0.577,0.744,1.293,0.744c0.712,0,1.291-0.333,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M385.586,285.638c0-0.41-0.579-0.745-1.291-0.745c-0.716,0-1.293,0.335-1.293,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="385.586" y1="285.638" x2="385.586" y2="286.277"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="383.001" y1="285.638" x2="383.001" y2="286.286"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M385.388,285.542c0-0.351-0.489-0.633-1.093-0.633c-0.607,0-1.096,0.282-1.096,0.633c0,0.347,0.489,0.631,1.096,0.631    C384.898,286.173,385.388,285.889,385.388,285.542"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="384.309" y1="286.389" x2="384.309" y2="287.028"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M326.343,333.455c0,0.41,0.579,0.747,1.291,0.747c0.716,0,1.294-0.337,1.294-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M328.928,332.812c0-0.412-0.579-0.747-1.294-0.747c-0.712,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="328.926" y1="332.812" x2="328.926" y2="333.447"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="326.343" y1="332.812" x2="326.343" y2="333.455"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M328.73,332.712c0-0.347-0.489-0.633-1.096-0.633c-0.603,0-1.093,0.286-1.093,0.633c0,0.351,0.489,0.631,1.093,0.631    C328.24,333.343,328.73,333.062,328.73,332.712"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="327.651" y1="333.562" x2="327.651" y2="334.201"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M322.619,331.363c0,0.412,0.575,0.747,1.291,0.747c0.712,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M325.201,330.719c0-0.412-0.579-0.747-1.291-0.747c-0.716,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="325.201" y1="330.719" x2="325.201" y2="331.354"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="322.619" y1="330.719" x2="322.619" y2="331.363"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M325.003,330.619c0-0.347-0.489-0.631-1.093-0.631c-0.607,0-1.096,0.284-1.096,0.631c0,0.349,0.489,0.633,1.096,0.633    C324.513,331.253,325.003,330.968,325.003,330.619"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="323.924" y1="331.472" x2="323.924" y2="332.107"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M346.394,318.706c0,0.414,0.581,0.745,1.291,0.745c0.716,0,1.293-0.331,1.293-0.745"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.978,318.062c0-0.41-0.577-0.747-1.293-0.747c-0.71,0-1.291,0.337-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="348.978" y1="318.062" x2="348.978" y2="318.698"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="346.394" y1="318.062" x2="346.394" y2="318.706"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M348.781,317.964c0-0.351-0.489-0.631-1.096-0.631c-0.603,0-1.093,0.281-1.093,0.631c0,0.347,0.489,0.633,1.093,0.633    C348.292,318.597,348.781,318.311,348.781,317.964"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="347.704" y1="318.814" x2="347.704" y2="319.449"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M342.671,316.615c0,0.412,0.577,0.747,1.291,0.747c0.712,0,1.289-0.335,1.289-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M345.251,315.971c0-0.412-0.577-0.747-1.289-0.747c-0.714,0-1.291,0.335-1.291,0.747"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="345.251" y1="315.971" x2="345.251" y2="316.606"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="342.671" y1="315.971" x2="342.671" y2="316.615"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M345.054,315.873c0-0.349-0.489-0.633-1.093-0.633c-0.607,0-1.096,0.284-1.096,0.633c0,0.347,0.489,0.631,1.096,0.631    C344.565,316.504,345.054,316.22,345.054,315.873"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="343.977" y1="316.722" x2="343.977" y2="317.359"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M366.53,303.279c0,0.412,0.577,0.747,1.289,0.747c0.714,0,1.291-0.335,1.291-0.747"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M369.11,302.635c0-0.414-0.577-0.745-1.291-0.745c-0.712,0-1.289,0.331-1.289,0.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="369.11" y1="302.635" x2="369.11" y2="303.273"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="366.53" y1="302.635" x2="366.53" y2="303.279"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M368.915,302.535c0-0.347-0.489-0.631-1.096-0.631c-0.603,0-1.093,0.284-1.093,0.631c0,0.351,0.489,0.633,1.093,0.633    C368.426,303.168,368.915,302.886,368.915,302.535"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="367.837" y1="303.386" x2="367.837" y2="304.024"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M362.803,301.19c0,0.409,0.577,0.744,1.293,0.744c0.71,0,1.291-0.335,1.291-0.744"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M365.387,300.543c0-0.412-0.581-0.744-1.291-0.744c-0.716,0-1.293,0.331-1.293,0.744"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="365.387" y1="300.543" x2="365.387" y2="301.181"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="362.803" y1="300.543" x2="362.803" y2="301.19"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M365.189,300.443c0-0.347-0.489-0.631-1.093-0.631c-0.607,0-1.096,0.284-1.096,0.631c0,0.353,0.489,0.633,1.096,0.633    C364.699,301.076,365.189,300.795,365.189,300.443"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="364.11" y1="301.293" x2="364.11" y2="301.932"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.13,223.726c-0.321,0.179-0.489,0.537-0.498,0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.538,226.264c0.381,0.214,0.772,0.221,1.072,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.389,225.871c0.549-0.312,0.663-1.147,0.254-1.864c-0.409-0.716-1.186-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.91" y1="223.279" x2="396.13" y2="223.726"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.61" y1="226.316" x2="398.389" y2="225.871"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.226,225.869c0.337-0.193,0.405-0.705,0.156-1.144s-0.726-0.637-1.059-0.444"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.565,226.248c0.337-0.193,0.403-0.705,0.154-1.144c-0.253-0.435-0.724-0.635-1.061-0.445    c-0.337,0.191-0.405,0.705-0.156,1.144C395.755,226.239,396.23,226.437,396.565,226.248"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.658" y1="224.659" x2="396.319" y2="224.282"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.229" y1="225.869" x2="396.538" y2="226.264"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.77" y1="224.375" x2="398.55" y2="223.928"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.084,210.734c-0.317,0.181-0.489,0.538-0.495,0.949"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.496,213.271c0.381,0.214,0.772,0.221,1.072,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.347,212.877c0.549-0.312,0.663-1.147,0.254-1.863c-0.41-0.716-1.186-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.868" y1="210.286" x2="396.084" y2="210.734"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.568" y1="213.324" x2="398.347" y2="212.877"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.184,212.877c0.337-0.195,0.405-0.703,0.156-1.142s-0.726-0.638-1.063-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.523,213.256c0.333-0.193,0.403-0.705,0.154-1.14c-0.253-0.438-0.728-0.638-1.061-0.447c-0.339,0.189-0.405,0.702-0.156,1.14    S396.184,213.447,396.523,213.256"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.616" y1="211.668" x2="396.277" y2="211.289"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.184" y1="212.877" x2="396.496" y2="213.271"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.728" y1="211.382" x2="398.508" y2="210.935"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.088,183.803c-0.317,0.184-0.489,0.54-0.498,0.952"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.496,186.341c0.384,0.219,0.772,0.225,1.072,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.348,185.948c0.551-0.312,0.661-1.147,0.253-1.861c-0.409-0.716-1.182-1.044-1.733-0.73"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.868" y1="183.358" x2="396.088" y2="183.803"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.568" y1="186.397" x2="398.348" y2="185.948"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.187,185.946c0.337-0.189,0.405-0.702,0.153-1.14c-0.249-0.438-0.724-0.638-1.059-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.523,186.327c0.337-0.191,0.405-0.703,0.154-1.142c-0.249-0.438-0.724-0.638-1.061-0.445c-0.337,0.193-0.405,0.702-0.153,1.14    C395.712,186.318,396.188,186.518,396.523,186.327"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.616" y1="184.74" x2="396.281" y2="184.359"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.187" y1="185.946" x2="396.496" y2="186.341"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.728" y1="184.452" x2="398.508" y2="184.007"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.088,170.928c-0.317,0.179-0.489,0.538-0.498,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.496,173.466c0.384,0.217,0.772,0.221,1.072,0.053"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.348,173.071c0.551-0.314,0.661-1.147,0.253-1.863c-0.409-0.714-1.182-1.04-1.733-0.728"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.868" y1="170.481" x2="396.088" y2="170.928"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.568" y1="173.519" x2="398.348" y2="173.071"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.187,173.07c0.337-0.191,0.405-0.702,0.153-1.14c-0.249-0.438-0.724-0.638-1.059-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.523,173.449c0.337-0.191,0.405-0.702,0.154-1.138c-0.249-0.438-0.724-0.64-1.061-0.447c-0.337,0.191-0.405,0.702-0.153,1.14    C395.712,173.442,396.188,173.643,396.523,173.449"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.616" y1="171.863" x2="396.281" y2="171.484"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.187" y1="173.07" x2="396.496" y2="173.466"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.728" y1="171.577" x2="398.508" y2="171.13"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.075,157.104c-0.317,0.181-0.489,0.537-0.498,0.947"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.484,159.639c0.382,0.217,0.772,0.226,1.07,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.338,159.249c0.545-0.314,0.659-1.147,0.251-1.864c-0.407-0.716-1.186-1.044-1.733-0.73"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.856" y1="156.655" x2="396.075" y2="157.104"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.554" y1="159.695" x2="398.338" y2="159.249"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.175,159.248c0.333-0.193,0.405-0.705,0.153-1.144c-0.249-0.438-0.726-0.637-1.059-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.51,159.626c0.337-0.193,0.403-0.705,0.154-1.144s-0.724-0.637-1.061-0.445c-0.335,0.191-0.405,0.703-0.156,1.142    C395.7,159.618,396.175,159.816,396.51,159.626"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.604" y1="158.037" x2="396.268" y2="157.659"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.175" y1="159.248" x2="396.484" y2="159.639"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.715" y1="157.75" x2="398.496" y2="157.304"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.04,130.018c-0.319,0.182-0.489,0.538-0.5,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.449,132.556c0.381,0.217,0.773,0.224,1.072,0.054"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.301,132.165c0.547-0.314,0.661-1.147,0.253-1.863c-0.409-0.717-1.184-1.045-1.733-0.731"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.821" y1="129.571" x2="396.04" y2="130.018"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.521" y1="132.61" x2="398.301" y2="132.165"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.138,132.163c0.337-0.193,0.407-0.705,0.154-1.143c-0.249-0.438-0.724-0.638-1.061-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.475,132.54c0.337-0.189,0.405-0.703,0.156-1.142c-0.253-0.438-0.726-0.638-1.063-0.445c-0.337,0.193-0.403,0.702-0.156,1.14    C395.665,132.531,396.139,132.731,396.475,132.54"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="395.569" y1="130.953" x2="396.232" y2="130.574"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.138" y1="132.163" x2="396.449" y2="132.556"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.682" y1="130.667" x2="398.461" y2="130.22"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.535,197.017c-0.151-0.766-0.914-1.263-1.706-1.107c-0.793,0.153-1.315,0.898-1.165,1.664"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M261.815,198.355c0.105,0.54,0.519,0.945,1.04,1.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.132,199.191c0.452-0.337,0.651-0.879,0.554-1.394"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.048,198.078c-0.082-0.41-0.489-0.673-0.91-0.591c-0.424,0.081-0.703,0.481-0.623,0.887"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M264.178,198.76c-0.077-0.41-0.484-0.673-0.91-0.591c-0.421,0.081-0.702,0.481-0.619,0.887c0.077,0.41,0.484,0.675,0.909,0.591    C263.979,199.565,264.258,199.167,264.178,198.76"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="264.048" y1="198.078" x2="264.178" y2="198.76"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="262.648" y1="199.056" x2="262.515" y2="198.374"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="264.535" y1="197.017" x2="264.686" y2="197.797"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="261.815" y1="198.355" x2="261.664" y2="197.574"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="262.981" y1="196.687" x2="262.829" y2="195.91"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M297.176,197.378c0.151-0.766-0.372-1.512-1.165-1.668c-0.793-0.153-1.556,0.344-1.706,1.11"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M294.156,197.597c-0.107,0.54,0.123,1.072,0.554,1.398"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M295.985,199.242c0.551-0.144,0.937-0.572,1.04-1.087"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.327,198.179c0.077-0.41-0.2-0.809-0.624-0.891c-0.421-0.081-0.828,0.182-0.91,0.593"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M296.192,198.86c0.082-0.41-0.198-0.807-0.619-0.891c-0.424-0.082-0.831,0.184-0.912,0.591c-0.079,0.41,0.198,0.809,0.623,0.891    C295.708,199.532,296.115,199.267,296.192,198.86"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="296.327" y1="198.178" x2="296.192" y2="198.86"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="294.661" y1="198.56" x2="294.793" y2="197.881"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="297.176" y1="197.378" x2="297.025" y2="198.155"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="294.154" y1="197.597" x2="294.305" y2="196.82"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="295.859" y1="196.492" x2="296.012" y2="195.71"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.091,264.296c-0.316,0.184-0.488,0.54-0.496,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.502,266.834c0.382,0.217,0.772,0.224,1.07,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.355,266.444c0.545-0.314,0.661-1.149,0.253-1.864c-0.412-0.716-1.187-1.044-1.736-0.73"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.875" y1="263.85" x2="396.091" y2="264.296"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.572" y1="266.89" x2="398.355" y2="266.444"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.193,266.439c0.333-0.189,0.405-0.702,0.154-1.14c-0.253-0.438-0.728-0.637-1.061-0.447"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.865,267.2c0.333-0.193,0.403-0.705,0.154-1.143c-0.253-0.438-0.724-0.638-1.061-0.445c-0.337,0.193-0.405,0.702-0.156,1.14    C395.055,267.19,395.53,267.39,395.865,267.2"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="394.958" y1="265.611" x2="396.286" y2="264.852"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.193" y1="266.439" x2="395.865" y2="267.2"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.85" y1="265.871" x2="398.631" y2="265.424"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.112" y1="264.575" x2="397.892" y2="264.128"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.033,251.142c-0.317,0.182-0.489,0.538-0.498,0.951"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.442,253.68c0.382,0.217,0.772,0.224,1.07,0.056"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M398.296,253.289c0.545-0.316,0.659-1.147,0.251-1.863c-0.407-0.716-1.186-1.044-1.733-0.731"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="396.814" y1="250.695" x2="396.033" y2="251.142"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.512" y1="253.733" x2="398.296" y2="253.289"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.133,253.285c0.333-0.189,0.405-0.703,0.153-1.142c-0.249-0.438-0.726-0.638-1.059-0.445"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.805,254.043c0.333-0.189,0.405-0.702,0.153-1.14c-0.249-0.438-0.726-0.638-1.059-0.447c-0.337,0.195-0.405,0.703-0.156,1.142    C394.995,254.036,395.469,254.236,395.805,254.043"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="394.899" y1="252.456" x2="396.226" y2="251.698"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.133" y1="253.285" x2="395.805" y2="254.043"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.791" y1="252.717" x2="398.571" y2="252.27"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.052" y1="251.419" x2="397.833" y2="250.974"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.318,237.076c0-0.777-0.652-1.41-1.463-1.41c-0.805,0-1.463,0.633-1.463,1.41"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.392,237.871c0,0.552,0.328,1.028,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.508,239.135c0.512-0.246,0.809-0.738,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.095,238.027c0-0.661-0.554-1.194-1.24-1.194c-0.68,0-1.235,0.533-1.235,1.194c0,0.396,0.198,0.745,0.507,0.966"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.585,238.993c0.331-0.232,0.509-0.595,0.51-0.966"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,238.027c0-0.417-0.349-0.754-0.782-0.754c-0.43,0-0.78,0.337-0.78,0.754"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,238.72c0-0.414-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.338-0.78,0.752c0,0.416,0.351,0.754,0.78,0.754    C280.288,239.474,280.637,239.135,280.637,238.72"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.637" y1="238.027" x2="280.637" y2="238.72"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.075" y1="238.72" x2="279.075" y2="238.027"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.318" y1="237.076" x2="281.318" y2="237.871"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.392" y1="237.871" x2="278.392" y2="237.076"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.855" y1="236.461" x2="279.855" y2="235.666"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.318,223.712c0-0.779-0.652-1.412-1.463-1.412c-0.805,0-1.463,0.633-1.463,1.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.392,224.505c0,0.551,0.328,1.03,0.814,1.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.508,225.771c0.512-0.246,0.809-0.742,0.81-1.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.095,224.663c0-0.663-0.554-1.196-1.24-1.196c-0.68,0-1.235,0.533-1.235,1.196c0,0.393,0.198,0.744,0.507,0.965"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.585,225.627c0.331-0.232,0.509-0.593,0.51-0.965"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,224.663c0-0.419-0.349-0.756-0.782-0.756c-0.43,0-0.78,0.337-0.78,0.756"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,225.355c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752s0.351,0.752,0.78,0.752    C280.288,226.108,280.637,225.771,280.637,225.355"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.637" y1="224.663" x2="280.637" y2="225.355"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.075" y1="225.355" x2="279.075" y2="224.663"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.318" y1="223.712" x2="281.318" y2="224.505"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.392" y1="224.505" x2="278.392" y2="223.712"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.855" y1="223.095" x2="279.855" y2="222.3"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.318,196.813c0-0.777-0.652-1.41-1.463-1.41c-0.805,0-1.463,0.633-1.463,1.41"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.392,197.608c0,0.552,0.328,1.03,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.508,198.872c0.512-0.244,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,197.764c0-0.417-0.349-0.756-0.782-0.756c-0.43,0-0.78,0.339-0.78,0.756"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,198.458c0-0.416-0.349-0.754-0.782-0.754c-0.43,0-0.78,0.338-0.78,0.754c0,0.414,0.351,0.752,0.78,0.752    C280.288,199.211,280.637,198.872,280.637,198.458"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.637" y1="197.764" x2="280.637" y2="198.458"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.075" y1="198.458" x2="279.075" y2="197.764"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.318" y1="196.813" x2="281.318" y2="197.608"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.392" y1="197.608" x2="278.392" y2="196.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.855" y1="196.198" x2="279.855" y2="195.403"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.318,183.132c0-0.779-0.652-1.412-1.463-1.412c-0.805,0-1.463,0.633-1.463,1.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.392,183.928c0,0.551,0.328,1.028,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.508,185.192c0.512-0.246,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.095,184.082c0-0.661-0.554-1.198-1.24-1.198c-0.68,0-1.235,0.537-1.235,1.198c0,0.395,0.198,0.744,0.507,0.966"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.585,185.048c0.331-0.233,0.509-0.596,0.51-0.966"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,184.082c0-0.419-0.349-0.754-0.782-0.754c-0.43,0-0.78,0.335-0.78,0.754"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.637,184.775c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752c0,0.417,0.351,0.754,0.78,0.754    C280.288,185.529,280.637,185.192,280.637,184.775"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.637" y1="184.082" x2="280.637" y2="184.775"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.075" y1="184.775" x2="279.075" y2="184.082"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.318" y1="183.132" x2="281.318" y2="183.928"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.392" y1="183.928" x2="278.392" y2="183.132"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.855" y1="182.512" x2="279.855" y2="181.72"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.776,143.759c-0.132,0.193-0.195,0.428-0.198,0.668"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M396.484,146.017c0.382,0.216,0.772,0.223,1.07,0.054"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.554" y1="146.071" x2="398.338" y2="145.624"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M397.175,145.624c0.27-0.158,0.367-0.517,0.261-0.887"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M395.847,146.381c0.337-0.193,0.405-0.705,0.153-1.144c-0.249-0.435-0.723-0.635-1.059-0.444c-0.337,0.189-0.405,0.703-0.156,1.14    C395.037,146.373,395.511,146.571,395.847,146.381"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="394.941" y1="144.794" x2="396.268" y2="144.037"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="397.175" y1="145.624" x2="395.847" y2="146.381"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.413,210.437c0,0.551,0.326,1.03,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.529,211.702c0.509-0.244,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.114,210.591c0-0.326-0.133-0.617-0.353-0.835"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.994,209.755c-0.237,0.232-0.354,0.533-0.354,0.837c0,0.396,0.2,0.747,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.606,211.56c0.331-0.235,0.509-0.596,0.509-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.658,210.591c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.658,211.284c0-0.414-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.338-0.78,0.752c0,0.417,0.351,0.756,0.78,0.756    C280.309,212.04,280.658,211.702,280.658,211.284"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.658" y1="210.591" x2="280.658" y2="211.284"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.096" y1="211.284" x2="279.096" y2="210.591"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.339" y1="209.757" x2="281.339" y2="210.437"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.413" y1="210.437" x2="278.413" y2="209.757"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,170.565c0,0.549,0.33,1.028,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,171.83c0.509-0.244,0.809-0.742,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,170.718c0-0.323-0.135-0.617-0.353-0.833"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M279.036,169.881c-0.237,0.235-0.353,0.535-0.353,0.837c0,0.396,0.198,0.749,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,171.686c0.331-0.233,0.509-0.596,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,170.718c0-0.414-0.349-0.751-0.782-0.751c-0.43,0-0.78,0.337-0.78,0.751"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,171.414c0-0.417-0.349-0.756-0.782-0.756c-0.43,0-0.78,0.338-0.78,0.756c0,0.416,0.351,0.752,0.78,0.752    C280.352,172.166,280.701,171.83,280.701,171.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="170.718" x2="280.701" y2="171.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="171.414" x2="279.138" y2="170.718"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="169.888" x2="281.381" y2="170.565"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="170.565" x2="278.456" y2="169.885"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,116.685c0,0.551,0.33,1.03,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,117.95c0.509-0.244,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,116.84c0-0.323-0.135-0.617-0.353-0.831"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M279.036,116.003c-0.237,0.235-0.353,0.537-0.353,0.837c0,0.396,0.198,0.747,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,117.808c0.331-0.232,0.509-0.595,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,116.84c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,117.536c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752c0,0.416,0.351,0.752,0.78,0.752    C280.352,118.288,280.701,117.952,280.701,117.536"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="116.84" x2="280.701" y2="117.536"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="117.536" x2="279.138" y2="116.84"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="116.01" x2="281.381" y2="116.685"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="116.685" x2="278.456" y2="116.008"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.381,156.962c0-0.782-0.652-1.415-1.463-1.415c-0.805,0-1.463,0.633-1.463,1.415"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,157.755c0,0.549,0.33,1.03,0.814,1.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,159.021c0.509-0.246,0.809-0.742,0.81-1.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,157.909c0-0.659-0.554-1.196-1.24-1.196c-0.682,0-1.235,0.537-1.235,1.196c0,0.395,0.198,0.747,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,158.878c0.331-0.235,0.509-0.598,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,157.909c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,158.602c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752c0,0.419,0.351,0.756,0.78,0.756    C280.352,159.358,280.701,159.021,280.701,158.602"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="157.909" x2="280.701" y2="158.602"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="158.602" x2="279.138" y2="157.909"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="156.962" x2="281.381" y2="157.755"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="157.755" x2="278.456" y2="156.962"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.918" y1="156.342" x2="279.918" y2="155.547"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.381,143.279c0-0.782-0.652-1.414-1.463-1.414c-0.805,0-1.463,0.631-1.463,1.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,144.072c0,0.551,0.33,1.03,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,145.336c0.509-0.244,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,144.226c0-0.659-0.554-1.194-1.24-1.194c-0.682,0-1.235,0.535-1.235,1.194c0,0.396,0.198,0.749,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,145.194c0.331-0.235,0.509-0.596,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,144.226c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,144.922c0-0.419-0.349-0.756-0.782-0.756c-0.43,0-0.78,0.337-0.78,0.756c0,0.414,0.351,0.752,0.78,0.752    C280.352,145.675,280.701,145.336,280.701,144.922"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="144.226" x2="280.701" y2="144.922"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="144.922" x2="279.138" y2="144.226"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="143.279" x2="281.381" y2="144.072"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="144.072" x2="278.456" y2="143.279"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.918" y1="142.658" x2="279.918" y2="141.865"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.381,129.999c0-0.779-0.652-1.414-1.463-1.414c-0.805,0-1.463,0.635-1.463,1.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,130.791c0,0.554,0.33,1.03,0.814,1.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,132.058c0.509-0.246,0.809-0.738,0.81-1.266"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,130.949c0-0.663-0.554-1.196-1.24-1.196c-0.682,0-1.235,0.533-1.235,1.196c0,0.393,0.198,0.745,0.509,0.965"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,131.914c0.331-0.232,0.509-0.593,0.51-0.965"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,130.949c0-0.419-0.349-0.756-0.782-0.756c-0.43,0-0.78,0.337-0.78,0.756"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,131.642c0-0.417-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.335-0.78,0.752c0,0.416,0.351,0.752,0.78,0.752    C280.352,132.395,280.701,132.058,280.701,131.642"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="130.949" x2="280.701" y2="131.642"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="131.642" x2="279.138" y2="130.949"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="129.999" x2="281.381" y2="130.791"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="130.791" x2="278.456" y2="129.999"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.918" y1="129.381" x2="279.918" y2="128.585"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.381,103.09c0-0.78-0.652-1.414-1.463-1.414c-0.805,0-1.463,0.633-1.463,1.414"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M278.456,103.884c0,0.549,0.33,1.028,0.814,1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.571,105.149c0.509-0.246,0.809-0.74,0.81-1.265"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M281.158,104.037c0-0.659-0.554-1.196-1.24-1.196c-0.682,0-1.235,0.537-1.235,1.196c0,0.396,0.198,0.749,0.509,0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.648,105.005c0.331-0.235,0.509-0.596,0.51-0.968"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,104.037c0-0.416-0.349-0.752-0.782-0.752c-0.43,0-0.78,0.337-0.78,0.752"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M280.701,104.729c0-0.414-0.349-0.751-0.782-0.751c-0.43,0-0.78,0.337-0.78,0.751c0,0.419,0.351,0.756,0.78,0.756    C280.352,105.485,280.701,105.149,280.701,104.729"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="280.701" y1="104.037" x2="280.701" y2="104.729"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.138" y1="104.729" x2="279.138" y2="104.037"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="281.381" y1="103.09" x2="281.381" y2="103.884"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="278.456" y1="103.884" x2="278.456" y2="103.09"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="279.918" y1="102.469" x2="279.918" y2="101.676"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M186.633,214.748c0.224,0.293,0.602,0.414,1.008,0.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M189.095,214.01c0.165-0.409,0.123-0.796-0.086-1.068"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M188.466,212.226c-0.384-0.505-1.224-0.505-1.88-0.007c-0.656,0.498-0.877,1.312-0.496,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="188.466" y1="212.226" x2="189.009" y2="212.942"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="186.633" y1="214.748" x2="186.089" y2="214.033"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M188.615,213.377c-0.233-0.307-0.751-0.309-1.151-0.002c-0.403,0.303-0.537,0.801-0.303,1.11"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M188.087,215.7c0.233,0.307,0.749,0.31,1.152,0.004c0.4-0.305,0.535-0.803,0.302-1.108c-0.233-0.31-0.751-0.31-1.151-0.007    C187.987,214.897,187.852,215.392,188.087,215.7"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="189.541" y1="214.595" x2="188.615" y2="213.377"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="187.161" y1="214.485" x2="188.087" y2="215.7"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="186.775" y1="213.703" x2="186.233" y2="212.985"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="187.966" y1="212.8" x2="187.42" y2="212.084"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M200.306,209.604c0.224,0.289,0.598,0.414,1.01,0.367"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M202.768,208.864c0.167-0.405,0.119-0.794-0.086-1.07"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M202.139,207.079c-0.382-0.5-1.226-0.503-1.882-0.005c-0.654,0.5-0.879,1.31-0.495,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.139" y1="207.079" x2="202.682" y2="207.794"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.306" y1="209.604" x2="199.762" y2="208.887"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M202.288,208.231c-0.233-0.305-0.751-0.307-1.151-0.002c-0.403,0.305-0.54,0.803-0.303,1.108"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.758,210.556c0.235,0.307,0.749,0.31,1.152,0.002c0.402-0.303,0.537-0.802,0.303-1.11c-0.235-0.307-0.749-0.307-1.152-0.004    C201.662,209.75,201.525,210.248,201.758,210.556"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.214" y1="209.448" x2="202.288" y2="208.231"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.834" y1="209.337" x2="201.758" y2="210.556"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="200.45" y1="208.557" x2="199.904" y2="207.841"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.637" y1="207.652" x2="201.092" y2="206.938"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.328,202.006c0.223,0.289,0.6,0.414,1.01,0.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.792,201.266c0.165-0.405,0.119-0.793-0.086-1.068"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.159,199.481c-0.381-0.5-1.224-0.503-1.88-0.004c-0.656,0.498-0.877,1.308-0.493,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.159" y1="199.484" x2="232.707" y2="200.198"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.328" y1="202.006" x2="229.786" y2="201.291"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.312,200.635c-0.237-0.307-0.751-0.31-1.154-0.002c-0.402,0.303-0.537,0.802-0.303,1.11"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.78,202.96c0.237,0.305,0.751,0.307,1.154,0.002c0.402-0.305,0.537-0.803,0.303-1.108c-0.237-0.31-0.751-0.31-1.154-0.007    C231.684,202.154,231.549,202.65,231.78,202.96"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.238" y1="201.854" x2="232.312" y2="200.635"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.854" y1="201.743" x2="231.78" y2="202.96"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="230.472" y1="200.961" x2="229.928" y2="200.246"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.661" y1="200.058" x2="231.114" y2="199.341"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M214.837,205.591c0.221,0.289,0.598,0.414,1.008,0.368"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M217.299,204.851c0.165-0.407,0.119-0.794-0.086-1.07"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.666,203.067c-0.381-0.503-1.224-0.505-1.88-0.007c-0.656,0.498-0.877,1.31-0.493,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="216.666" y1="203.067" x2="217.213" y2="203.781"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="214.837" y1="205.591" x2="214.293" y2="204.874"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.818,204.218c-0.237-0.305-0.751-0.307-1.154-0.002c-0.4,0.303-0.535,0.802-0.303,1.108"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M216.287,206.544c0.237,0.307,0.752,0.31,1.152,0.002c0.403-0.305,0.538-0.801,0.303-1.108c-0.233-0.309-0.749-0.309-1.152-0.005    C216.191,205.737,216.056,206.235,216.287,206.544"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="217.743" y1="205.437" x2="216.818" y2="204.218"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="215.361" y1="205.325" x2="216.287" y2="206.544"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="214.979" y1="204.546" x2="214.433" y2="203.829"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="216.166" y1="203.641" x2="215.621" y2="202.925"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.558,195.119c0.219,0.293,0.596,0.414,1.005,0.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.021,194.382c0.165-0.409,0.118-0.796-0.089-1.07"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.387,192.597c-0.381-0.503-1.224-0.505-1.88-0.007c-0.656,0.498-0.879,1.312-0.495,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.387" y1="192.597" x2="203.931" y2="193.313"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.558" y1="195.119" x2="201.013" y2="194.402"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.538,193.748c-0.235-0.307-0.749-0.307-1.152-0.004c-0.4,0.305-0.537,0.803-0.303,1.112"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.009,196.071c0.233,0.31,0.751,0.312,1.151,0.003c0.403-0.305,0.538-0.803,0.303-1.108c-0.233-0.309-0.747-0.31-1.151-0.005    C202.91,195.268,202.775,195.766,203.009,196.071"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="204.463" y1="194.966" x2="203.538" y2="193.748"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.083" y1="194.856" x2="203.009" y2="196.071"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.7" y1="194.074" x2="201.153" y2="193.356"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.886" y1="193.171" x2="202.34" y2="192.455"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.609,180.396c0.221,0.289,0.598,0.414,1.007,0.367"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.071,179.655c0.167-0.405,0.119-0.793-0.088-1.07"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.438,177.87c-0.381-0.5-1.222-0.503-1.88-0.004c-0.656,0.498-0.877,1.308-0.493,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.438" y1="177.87" x2="203.984" y2="178.586"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.609" y1="180.396" x2="201.065" y2="179.678"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.591,179.022c-0.237-0.305-0.751-0.309-1.154-0.002c-0.4,0.305-0.535,0.803-0.303,1.108"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.061,181.348c0.233,0.307,0.751,0.31,1.151,0.004c0.403-0.305,0.538-0.803,0.303-1.112c-0.233-0.307-0.751-0.307-1.152-0.004    C202.963,180.541,202.828,181.039,203.061,181.348"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="204.515" y1="180.239" x2="203.591" y2="179.022"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.133" y1="180.129" x2="203.061" y2="181.348"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.749" y1="179.35" x2="201.206" y2="178.633"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.939" y1="178.443" x2="202.393" y2="177.73"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.609,166.138c0.221,0.289,0.598,0.412,1.007,0.368"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.071,165.398c0.167-0.407,0.119-0.794-0.088-1.07"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.438,163.615c-0.381-0.503-1.222-0.505-1.88-0.005c-0.656,0.496-0.877,1.308-0.493,1.812"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.438" y1="163.615" x2="203.984" y2="164.329"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.609" y1="166.138" x2="201.065" y2="165.421"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.591,164.765c-0.237-0.305-0.751-0.307-1.154-0.002c-0.4,0.305-0.535,0.802-0.303,1.11"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.061,167.091c0.233,0.307,0.751,0.31,1.151,0.002c0.403-0.305,0.538-0.802,0.303-1.108c-0.233-0.309-0.751-0.31-1.152-0.005    C202.963,166.284,202.828,166.782,203.061,167.091"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="204.515" y1="165.984" x2="203.591" y2="164.765"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.133" y1="165.874" x2="203.061" y2="167.091"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.749" y1="165.093" x2="201.206" y2="164.376"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.939" y1="164.188" x2="202.393" y2="163.473"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.87,136.79c0.219,0.293,0.596,0.417,1.005,0.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.333,136.053c0.165-0.407,0.118-0.794-0.089-1.07"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.7,134.266c-0.381-0.5-1.224-0.505-1.88-0.004c-0.656,0.5-0.879,1.31-0.495,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.7" y1="134.266" x2="204.243" y2="134.983"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.87" y1="136.79" x2="201.325" y2="136.076"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.851,135.42c-0.235-0.309-0.749-0.31-1.152-0.002c-0.4,0.305-0.537,0.802-0.303,1.108"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.321,137.745c0.233,0.305,0.751,0.307,1.151,0.002c0.403-0.303,0.54-0.801,0.303-1.11c-0.233-0.307-0.747-0.309-1.151-0.003    C203.221,136.939,203.086,137.435,203.321,137.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="204.775" y1="136.635" x2="203.851" y2="135.42"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.395" y1="136.527" x2="203.321" y2="137.745"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.011" y1="135.744" x2="201.465" y2="135.031"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.198" y1="134.841" x2="202.654" y2="134.127"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M201.818,151.517c0.221,0.293,0.596,0.414,1.007,0.37"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.28,150.777c0.165-0.407,0.118-0.794-0.089-1.066"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.647,148.993c-0.381-0.503-1.222-0.505-1.88-0.005c-0.656,0.498-0.877,1.308-0.495,1.813"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.647" y1="148.995" x2="204.191" y2="149.71"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.818" y1="151.517" x2="201.272" y2="150.801"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.8,150.145c-0.237-0.307-0.751-0.309-1.154-0.003c-0.4,0.305-0.535,0.801-0.303,1.112"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M203.27,152.471c0.232,0.305,0.749,0.309,1.149,0.002c0.403-0.307,0.54-0.803,0.303-1.11c-0.232-0.309-0.749-0.309-1.151-0.005    C203.172,151.666,203.033,152.16,203.27,152.471"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="204.722" y1="151.364" x2="203.8" y2="150.145"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="202.342" y1="151.254" x2="203.27" y2="152.471"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="201.958" y1="150.471" x2="201.414" y2="149.754"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="203.145" y1="149.568" x2="202.602" y2="148.851"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.366,143.591c-0.74,0.36-1.098,0.719-1.179,1.678c-0.012,0.163-0.023,0.368-0.03,0.596"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.156,146.602c0.014,1.051,0.114,2.319,0.353,3.06c0.277,0.87,0.356,1.212,0.858,1.41"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="146.448" x2="183.809" y2="158.429"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.116" y1="145.766" x2="183.906" y2="157.311"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="146.448" x2="232.728" y2="145.92"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.728" y1="145.92" x2="231.116" y2="145.766"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.366,157.639c-0.74,0.361-1.098,0.719-1.179,1.68c-0.012,0.165-0.023,0.367-0.03,0.595"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.156,160.651c0.014,1.052,0.114,2.317,0.353,3.06c0.277,0.873,0.356,1.214,0.858,1.414"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="160.496" x2="183.809" y2="172.477"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="231.116" y1="159.814" x2="183.906" y2="171.36"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.796" y1="160.496" x2="232.728" y2="159.968"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.728" y1="159.968" x2="231.116" y2="159.814"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.105,123.809c-0.267,0.065-0.452,0.34-0.523,0.724"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.014,126.307c0.256,0.328,0.549,0.461,0.8,0.402"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.689,126.493c0.461-0.11,0.679-0.852,0.482-1.654c-0.196-0.8-0.73-1.357-1.191-1.245"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.98" y1="123.594" x2="232.105" y2="123.809"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.814" y1="126.709" x2="233.689" y2="126.493"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.571,126.172c0.284-0.068,0.416-0.524,0.295-1.014c-0.118-0.489-0.447-0.831-0.73-0.761"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.265,126.735c0.282-0.067,0.416-0.523,0.295-1.012c-0.119-0.489-0.447-0.833-0.73-0.761c-0.284,0.068-0.416,0.521-0.295,1.012    C229.657,126.465,229.983,126.805,230.265,126.735"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.83" y1="124.962" x2="232.137" y2="124.397"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.571" y1="126.172" x2="230.265" y2="126.735"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.361" y1="125.807" x2="234.236" y2="125.591"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.008" y1="124.357" x2="233.88" y2="124.141"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.105,138.077c-0.267,0.067-0.452,0.344-0.523,0.724"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.014,140.578c0.256,0.324,0.549,0.458,0.8,0.4"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.689,140.764c0.461-0.114,0.679-0.856,0.482-1.656c-0.196-0.798-0.73-1.357-1.191-1.243"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.98" y1="137.865" x2="232.105" y2="138.077"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.814" y1="140.978" x2="233.689" y2="140.764"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.571,140.439c0.284-0.068,0.416-0.523,0.295-1.012c-0.118-0.491-0.447-0.833-0.73-0.761"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.265,141.004c0.282-0.068,0.416-0.523,0.295-1.014c-0.119-0.489-0.447-0.831-0.73-0.761c-0.284,0.07-0.416,0.524-0.295,1.014    S229.983,141.073,230.265,141.004"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.83" y1="139.229" x2="232.137" y2="138.666"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.571" y1="140.439" x2="230.265" y2="141.004"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.361" y1="140.075" x2="234.236" y2="139.862"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.008" y1="138.624" x2="233.88" y2="138.412"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.084,152.688c-0.268,0.063-0.451,0.34-0.523,0.721"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M231.996,155.186c0.254,0.328,0.545,0.461,0.798,0.402"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.669,155.372c0.46-0.111,0.679-0.852,0.481-1.654c-0.196-0.801-0.73-1.357-1.191-1.245"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.959" y1="152.473" x2="232.084" y2="152.688"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.794" y1="155.587" x2="233.669" y2="155.372"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.552,155.051c0.282-0.068,0.416-0.523,0.295-1.014c-0.119-0.489-0.447-0.833-0.731-0.763"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.246,155.615c0.281-0.07,0.416-0.523,0.293-1.014c-0.118-0.489-0.444-0.833-0.73-0.765c-0.282,0.072-0.416,0.523-0.295,1.014    C229.634,155.344,229.962,155.682,230.246,155.615"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.809" y1="153.837" x2="232.117" y2="153.274"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.552" y1="155.051" x2="230.246" y2="155.615"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.343" y1="154.682" x2="234.215" y2="154.47"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.989" y1="153.235" x2="233.861" y2="153.02"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.103,167.159c-0.268,0.067-0.452,0.346-0.523,0.724"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.012,169.66c0.256,0.326,0.549,0.46,0.802,0.4"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.687,169.848c0.463-0.116,0.679-0.854,0.482-1.657c-0.196-0.798-0.73-1.356-1.191-1.243"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.978" y1="166.947" x2="232.103" y2="167.159"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.814" y1="170.06" x2="233.687" y2="169.848"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M232.57,169.525c0.284-0.072,0.414-0.523,0.296-1.015c-0.119-0.489-0.449-0.83-0.731-0.761"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M230.263,170.086c0.284-0.068,0.414-0.523,0.295-1.012c-0.121-0.491-0.447-0.83-0.73-0.761s-0.416,0.523-0.295,1.012    C229.651,169.815,229.981,170.158,230.263,170.086"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="229.828" y1="168.313" x2="232.135" y2="167.749"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="232.57" y1="169.525" x2="230.263" y2="170.086"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.359" y1="169.157" x2="234.234" y2="168.945"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="233.005" y1="167.708" x2="233.878" y2="167.496"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="441.03" y1="344.273" x2="440.171" y2="341.707"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="440.171" y1="341.707" x2="435.823" y2="329.614"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="    M262.299,295.558c-0.37,0.263-0.556,0.693-0.482,1.138"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="265.73" y1="293.105" x2="267.992" y2="294.145"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M233.291,251.186c0.233,0.309,0.747,0.309,1.151,0.005c0.402-0.305,0.537-0.803,0.303-1.112c-0.233-0.307-0.751-0.31-1.151-0.002    C233.191,250.381,233.054,250.879,233.291,251.186"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M204.543,257.386c0.237,0.307,0.752,0.307,1.152,0.004c0.403-0.305,0.538-0.803,0.305-1.112c-0.237-0.307-0.751-0.31-1.154-0.002    C204.447,256.579,204.312,257.077,204.543,257.386"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M218.962,256.891c0.235,0.307,0.751,0.31,1.151,0.002c0.403-0.305,0.54-0.802,0.303-1.108c-0.233-0.309-0.747-0.31-1.151-0.005    C218.865,256.084,218.728,256.583,218.962,256.891"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M188.422,253.341c0.232,0.31,0.747,0.312,1.151,0.005c0.4-0.307,0.537-0.803,0.303-1.11c-0.233-0.309-0.751-0.31-1.151-0.005    C188.322,252.538,188.185,253.036,188.422,253.341"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="    M251.581,249.828c-3.125,0.31-6.21,0.672-8.838,1.024"/>
	</g>
	
		<rect x="7.565" y="332.921" transform="matrix(-1 -8.979452e-11 8.979452e-11 -1 224.8933 824.685)" fill="#FFFFFF" width="209.764" height="158.843"/>
	
		<polygon fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="   212.666,379.91 264.498,355.648 212.666,371.406  "/>
	<polygon fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" points="212.666,371.406 264.498,355.648    212.666,379.91 212.666,486.094 14.24,486.094 14.24,338.591 212.666,338.591  "/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="101.165" y1="381.448" x2="101.165" y2="395.925"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="101.165" y1="367.542" x2="101.165" y2="372.61"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="101.165" y1="347.094" x2="101.165" y2="360.199"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="170.245" y1="426.539" x2="180.255" y2="430.976"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="109.981" y1="399.833" x2="163.852" y2="423.706"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="101.165" y1="395.925" x2="103.248" y2="396.848"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="180.255" y1="430.976" x2="180.255" y2="352.866"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="180.255" y1="430.976" x2="204.162" y2="412.517"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="23.731" y1="417.413" x2="159.986" y2="477.796"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="23.731" y1="419.366" x2="155.115" y2="477.591"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="190.76" y1="477.695" x2="204.162" y2="467.766"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="188.199" y1="477.64" x2="204.162" y2="465.814"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="28.971" y1="421.688" x2="23.73" y2="425.667"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M26.378,427.528c0,1.482,2.081,2.683,4.648,2.683s4.648-1.202,4.648-2.683"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M35.674,427.528c0-1.815-2.081-3.287-4.648-3.287s-4.648,1.472-4.648,3.287"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M28.597,428.49c0.341-0.64,1.241-1.769,2.457-2.531"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M31.763,425.578c0.795-0.356,1.689-0.524,2.629-0.316"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M33.511,428.49c-0.339-0.637-1.233-1.76-2.441-2.521"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M30.287,425.553c-0.781-0.339-1.654-0.494-2.57-0.291"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M178.951,472.191c0-0.85-0.456-1.639-1.219-2.275"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M165.007,472.191c0,2.223,3.121,4.025,6.972,4.025c3.85,0,6.972-1.803,6.972-4.025"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M166.226,469.916c-0.832,0.701-1.227,1.5-1.219,2.275"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M165.007,472.991c0,2.223,3.121,4.025,6.972,4.025c3.85,0,6.972-1.803,6.972-4.025"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M166.226,472.191c0,1.834,2.576,3.321,5.753,3.321c3.177,0,5.752-1.487,5.752-3.321"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M177.732,469.143c0-1.834-2.576-3.322-5.752-3.322c-3.177,0-5.753,1.487-5.753,3.322"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M177.008,468.742c0-1.603-2.252-2.903-5.029-2.903c-2.778,0-5.03,1.3-5.03,2.903c0,1.604,2.252,2.904,5.03,2.904   C174.756,471.646,177.008,470.346,177.008,468.742"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="174.855" y1="472.019" x2="174.855" y2="475.067"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="166.226" y1="472.191" x2="166.226" y2="469.143"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="165.007" y1="472.191" x2="165.007" y2="472.991"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="178.951" y1="472.191" x2="178.951" y2="472.991"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.103" y1="472.019" x2="169.103" y2="475.067"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M169.103,472.019c0.723-0.4,4.514-0.514,5.752,0"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="177.732" y1="469.143" x2="177.732" y2="472.191"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M174.855,472.019c0.972-1.066,2.776-3.637,2.874-2.971"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M169.103,472.019c-0.972-1.066-2.777-3.637-2.875-2.971"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M181.98,468.068c0-1.162-1.63-2.102-3.641-2.102c-1.033,0-1.966,0.248-2.63,0.648"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M181.159,469.397c0.555-0.391,0.823-0.865,0.821-1.329"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="181.152" y1="469.402" x2="178.702" y2="471.124"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M181.685,468.897c-0.751-1.011-2.711-1.508-4.499-1.165"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M45.11,412.316c0-0.85-0.455-1.638-1.219-2.274"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M31.167,412.316c0,2.223,3.121,4.025,6.972,4.025s6.972-1.802,6.972-4.025"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M32.386,410.042c-0.831,0.701-1.227,1.5-1.219,2.274"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M31.167,413.115c0,2.223,3.121,4.026,6.972,4.026s6.972-1.803,6.972-4.026"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M32.386,412.316c0,1.835,2.575,3.321,5.752,3.321c3.177,0,5.753-1.486,5.753-3.321"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M43.892,409.268c0-1.835-2.576-3.322-5.753-3.322c-3.177,0-5.752,1.487-5.752,3.322"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M43.168,408.868c0-1.604-2.251-2.903-5.029-2.903c-2.777,0-5.029,1.299-5.029,2.903c0,1.603,2.252,2.903,5.029,2.903   C40.916,411.771,43.168,410.471,43.168,408.868"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="41.015" y1="412.144" x2="41.015" y2="415.192"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="32.386" y1="412.316" x2="32.386" y2="409.268"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="31.167" y1="412.316" x2="31.167" y2="413.115"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="45.11" y1="412.316" x2="45.11" y2="413.115"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="35.263" y1="412.144" x2="35.263" y2="415.192"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M35.263,412.144c0.724-0.401,4.514-0.514,5.752,0"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="43.892" y1="409.268" x2="43.892" y2="412.316"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M41.015,412.144c0.972-1.067,2.777-3.638,2.875-2.972"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M35.263,412.144c-0.972-1.067-2.777-3.638-2.874-2.972"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M48.14,408.193c0-1.162-1.631-2.103-3.641-2.103c-1.033,0-1.966,0.249-2.63,0.648"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M47.319,409.523c0.555-0.392,0.823-0.865,0.821-1.33"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="47.312" y1="409.528" x2="44.862" y2="411.249"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M47.845,409.022c-0.751-1.011-2.711-1.507-4.499-1.166"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M113.853,408.658c0-0.673-0.547-1.271-1.407-1.66"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M107.978,410.318c0.668,0.299,1.46,0.444,2.234,0.442c2.011,0,3.641-0.942,3.641-2.102"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M97.628,404.645c0,0.673,0.547,1.271,1.407,1.66"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M103.504,402.984c-0.668-0.299-1.46-0.444-2.235-0.442c-2.011,0-3.641,0.942-3.641,2.103"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="99.034" y1="406.305" x2="107.977" y2="410.318"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.447" y1="406.998" x2="110.112" y2="405.951"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="103.72" y1="403.082" x2="103.504" y2="402.985"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M113.493,409.571c-0.256-0.307-0.625-0.562-1.046-0.747"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M103.504,404.81c-0.668-0.299-1.46-0.444-2.235-0.442c-1.436,0-2.677,0.48-3.28,1.189"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="112.447" y1="408.824" x2="110.112" y2="407.777"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="103.72" y1="404.907" x2="103.504" y2="404.81"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M103.72,388.346c0,1.02,1.431,1.845,3.196,1.845c1.765,0,3.196-0.826,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M110.112,388.346c0-1.02-1.431-1.845-3.196-1.845c-1.765,0-3.196,0.826-3.196,1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M109.024,387.781c0-0.672-0.943-1.218-2.108-1.218c-1.165,0-2.108,0.545-2.108,1.218c0,0.672,0.943,1.217,2.108,1.217   C108.081,388.998,109.024,388.453,109.024,387.781"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="110.112" y1="388.346" x2="110.112" y2="410.76"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="103.72" y1="408.407" x2="103.72" y2="388.346"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M162.115,380.71c0,1.601,2.248,2.898,5.021,2.898c2.772,0,5.021-1.297,5.021-2.898"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M172.157,378.049c0-1.601-2.249-2.898-5.021-2.898c-2.723,0-4.939,1.251-5.019,2.816"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M171.525,377.7c0-1.399-1.964-2.534-4.388-2.534c-2.424,0-4.39,1.135-4.39,2.534c0,1.4,1.965,2.534,4.39,2.534   C169.56,380.235,171.525,379.1,171.525,377.7"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="169.646" y1="380.561" x2="169.646" y2="383.22"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="162.115" y1="380.71" x2="162.115" y2="378.049"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="164.625" y1="380.561" x2="164.625" y2="383.22"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M164.625,380.561c0.632-0.349,3.941-0.45,5.021,0"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="172.157" y1="378.049" x2="172.157" y2="380.71"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M169.646,380.561c0.847-0.931,2.423-3.175,2.508-2.594"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M164.625,380.561c-0.848-0.931-2.423-3.175-2.508-2.594"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M163.939,377.7c0,1.02,1.431,1.845,3.197,1.845c1.765,0,3.196-0.826,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M170.332,377.7c0-1.019-1.43-1.845-3.196-1.845c-1.766,0-3.197,0.827-3.197,1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M173.986,435.106c0-0.672-0.547-1.271-1.406-1.66"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M168.11,436.766c0.668,0.299,1.46,0.444,2.235,0.442c2.01,0,3.64-0.941,3.64-2.102"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M157.76,431.093c0,0.673,0.548,1.272,1.407,1.66"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M163.635,429.433c-0.668-0.299-1.459-0.444-2.235-0.442c-2.01,0-3.641,0.942-3.641,2.102"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="159.167" y1="432.752" x2="168.11" y2="436.766"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="172.579" y1="433.447" x2="170.245" y2="432.399"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.852" y1="429.53" x2="163.635" y2="429.433"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M173.625,436.02c-0.256-0.307-0.625-0.562-1.045-0.748"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M163.635,431.259c-0.668-0.299-1.459-0.444-2.235-0.443c-1.435,0-2.677,0.48-3.28,1.19"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="172.579" y1="435.272" x2="170.245" y2="434.225"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="163.852" y1="431.356" x2="163.635" y2="431.259"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M163.852,414.794c0,1.019,1.432,1.845,3.197,1.845c1.766,0,3.196-0.826,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M170.245,414.794c0-1.019-1.43-1.846-3.196-1.846c-1.765,0-3.197,0.827-3.197,1.846"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M169.157,414.229c0-0.673-0.944-1.218-2.108-1.218c-1.164,0-2.108,0.545-2.108,1.218c0,0.672,0.944,1.217,2.108,1.217   C168.213,415.446,169.157,414.901,169.157,414.229"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="170.245" y1="414.794" x2="170.245" y2="437.208"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="163.852" y1="434.855" x2="163.852" y2="414.794"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" x1="167.048" y1="413.921" x2="167.048" y2="409.198"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M163.852,390.267c0,1.019,1.432,1.845,3.197,1.845c1.766,0,3.196-0.827,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M170.245,390.267c0-1.02-1.43-1.846-3.196-1.846c-1.765,0-3.197,0.826-3.197,1.846"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M160.949,390.267c0,1.945,2.731,3.521,6.099,3.521c3.368,0,6.1-1.576,6.1-3.521"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M173.148,390.267c0-1.945-2.731-3.522-6.1-3.522c-3.368,0-6.099,1.577-6.099,3.522"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M160.949,391.019c0,1.945,2.731,3.521,6.099,3.521c3.368,0,6.1-1.577,6.1-3.521"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="173.148" y1="390.267" x2="173.148" y2="391.019"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="160.949" y1="390.267" x2="160.949" y2="391.019"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M170.178,390.643c-0.313-0.867-1.634-1.464-3.129-1.468c-1.534,0-2.816,0.624-3.129,1.468"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M163.852,403.051c0,1.019,1.432,1.845,3.197,1.845c1.766,0,3.196-0.826,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M170.245,403.051c0-1.02-1.43-1.846-3.196-1.846c-1.765,0-3.197,0.826-3.197,1.846"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M160.3,403.051c0,2.152,3.022,3.895,6.748,3.895c3.727,0,6.748-1.744,6.748-3.895"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M173.796,403.051c0-2.152-3.021-3.896-6.748-3.896c-3.726,0-6.748,1.745-6.748,3.896"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M160.3,404.098c0,2.152,3.022,3.895,6.748,3.895c3.727,0,6.748-1.744,6.748-3.895"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="173.796" y1="403.051" x2="173.796" y2="404.098"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="160.3" y1="403.051" x2="160.3" y2="404.098"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M170.114,403.574c-0.409-0.799-1.671-1.317-3.065-1.322c-1.442,0-2.661,0.551-3.065,1.322"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="167.048" y1="413.921" x2="167.048" y2="409.198"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" x1="167.048" y1="389.908" x2="167.048" y2="385.185"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="167.048" y1="389.908" x2="167.048" y2="385.185"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" x1="167.048" y1="403.1" x2="167.048" y2="395.925"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="167.048" y1="403.1" x2="167.048" y2="395.925"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M101.983,354.164c0,1.601,2.248,2.898,5.021,2.898c2.772,0,5.021-1.297,5.021-2.898"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M112.025,351.504c0-1.601-2.249-2.898-5.021-2.898c-2.723,0-4.939,1.251-5.019,2.816"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M111.392,351.155c0-1.399-1.965-2.534-4.388-2.534c-2.424,0-4.39,1.135-4.39,2.534c0,1.4,1.965,2.534,4.39,2.534   C109.428,353.689,111.392,352.554,111.392,351.155"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="109.514" y1="354.015" x2="109.514" y2="356.675"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="101.983" y1="354.164" x2="101.983" y2="351.504"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="104.493" y1="354.015" x2="104.493" y2="356.675"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M104.493,354.015c0.632-0.349,3.941-0.45,5.021,0"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="112.025" y1="351.504" x2="112.025" y2="354.164"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M109.514,354.015c0.847-0.931,2.423-3.175,2.508-2.594"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M104.493,354.015c-0.848-0.931-2.423-3.175-2.508-2.594"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M103.807,351.155c0,1.02,1.431,1.845,3.197,1.845c1.765,0,3.196-0.826,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M110.2,351.155c0-1.019-1.43-1.845-3.196-1.845c-1.766,0-3.197,0.827-3.197,1.845"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" x1="106.916" y1="387.375" x2="106.916" y2="382.652"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M103.719,363.721c0,1.019,1.432,1.845,3.197,1.845c1.766,0,3.196-0.827,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M110.112,363.721c0-1.02-1.43-1.846-3.196-1.846c-1.765,0-3.197,0.826-3.197,1.846"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M100.817,363.721c0,1.945,2.731,3.521,6.099,3.521c3.368,0,6.1-1.576,6.1-3.521"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M113.016,363.721c0-1.945-2.731-3.522-6.1-3.522c-3.368,0-6.099,1.577-6.099,3.522"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M100.817,364.474c0,1.945,2.731,3.521,6.099,3.521c3.368,0,6.1-1.577,6.1-3.521"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="113.016" y1="363.721" x2="113.016" y2="364.474"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="100.817" y1="363.721" x2="100.817" y2="364.474"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M110.045,364.097c-0.313-0.867-1.634-1.464-3.129-1.468c-1.534,0-2.816,0.624-3.129,1.468"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M103.719,376.506c0,1.019,1.432,1.845,3.197,1.845c1.766,0,3.196-0.826,3.196-1.845"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M110.112,376.506c0-1.02-1.43-1.846-3.196-1.846c-1.765,0-3.197,0.826-3.197,1.846"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" d="   M100.168,376.506c0,2.152,3.022,3.895,6.748,3.895c3.727,0,6.748-1.744,6.748-3.895"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M113.664,376.506c0-2.152-3.021-3.896-6.748-3.896c-3.726,0-6.748,1.745-6.748,3.896"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M100.168,377.553c0,2.152,3.022,3.895,6.748,3.895c3.727,0,6.748-1.744,6.748-3.895"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="113.664" y1="376.506" x2="113.664" y2="377.553"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" x1="100.168" y1="376.506" x2="100.168" y2="377.553"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.5" strokeLinecap="round" d="   M109.981,377.029c-0.409-0.799-1.671-1.317-3.065-1.322c-1.442,0-2.661,0.551-3.065,1.322"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="106.916" y1="387.375" x2="106.916" y2="382.652"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" x1="106.916" y1="363.363" x2="106.916" y2="358.639"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="106.916" y1="363.363" x2="106.916" y2="358.639"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" x1="106.916" y1="376.554" x2="106.916" y2="369.379"/>
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.25" strokeLinecap="round" x1="106.916" y1="376.554" x2="106.916" y2="369.379"/>
</g>
<g id="Annotation39" onMouseOver={() => {highlightAnnotation(39)}} onMouseOut={() => {lowlightAnnotation(39)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,293.625c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,298.596,486.878,293.625L486.878,293.625L486.878,293.625"/><text transform="matrix(1 0 0 1 472.1443 296.9982)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">39</text>
</g>
<g id="Annotation40" onMouseOver={() => {highlightAnnotation(40)}} onMouseOut={() => {lowlightAnnotation(40)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,318.588c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,323.559,486.878,318.588L486.878,318.588L486.878,318.588"/><text transform="matrix(1 0 0 1 472.1443 321.9621)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">40</text>
</g>
<g id="Annotation41" onMouseOver={() => {highlightAnnotation(41)}} onMouseOut={() => {lowlightAnnotation(41)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,354.387c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,359.358,486.878,354.387L486.878,354.387L486.878,354.387"/><text transform="matrix(1 0 0 1 472.1443 357.7614)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">41</text>
</g>
<g id="Annotation42" onMouseOver={() => {highlightAnnotation(42)}} onMouseOut={() => {lowlightAnnotation(42)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,268.351c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,273.322,486.878,268.351L486.878,268.351L486.878,268.351"/><text transform="matrix(1 0 0 1 472.1443 271.7245)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">42</text>
</g>
<g id="Annotation43" onMouseOver={() => {highlightAnnotation(43)}} onMouseOut={() => {lowlightAnnotation(43)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,243.611c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,248.582,486.878,243.611L486.878,243.611L486.878,243.611"/><text transform="matrix(1 0 0 1 472.1443 246.985)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">43</text>
</g>
<g id="Annotation44" onMouseOver={() => {highlightAnnotation(44)}} onMouseOut={() => {lowlightAnnotation(44)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,130.5c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,135.471,486.878,130.5L486.878,130.5L486.878,130.5"/>
	<text transform="matrix(1 0 0 1 472.1443 133.8742)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">44</text>
</g>
<g id="Annotation45" onMouseOver={() => {highlightAnnotation(45)}} onMouseOut={() => {lowlightAnnotation(45)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M486.878,205.857c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S486.878,210.828,486.878,205.857L486.878,205.857L486.878,205.857"/><text transform="matrix(1 0 0 1 472.1443 209.2307)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">45</text>
</g>
<g id="Annotation46" onMouseOver={() => {highlightAnnotation(46)}} onMouseOut={() => {lowlightAnnotation(46)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M430.918,50.249c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S430.918,55.22,430.918,50.249L430.918,50.249L430.918,50.249"/><text transform="matrix(1 0 0 1 416.1848 53.6223)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">46</text>
</g>
<g id="Annotation47" onMouseOver={() => {highlightAnnotation(47)}} onMouseOut={() => {lowlightAnnotation(47)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M133.915,50.249c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S133.915,55.22,133.915,50.249L133.915,50.249L133.915,50.249"/><text transform="matrix(1 0 0 1 119.1814 53.6223)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">47</text>
</g>
<g id="Annotation48" onMouseOver={() => {highlightAnnotation(48)}} onMouseOut={() => {lowlightAnnotation(48)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M66.46,108.538c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S66.46,113.509,66.46,108.538L66.46,108.538L66.46,108.538"/><text transform="matrix(1 0 0 1 51.7263 111.9113)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">48</text>
</g>
<g id="Annotation49" onMouseOver={() => {highlightAnnotation(49)}} onMouseOut={() => {lowlightAnnotation(49)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M46.941,358.309c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S46.941,363.28,46.941,358.309L46.941,358.309L46.941,358.309"/><text transform="matrix(1 0 0 1 32.208 361.6835)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">49</text>
</g>
<g id="Annotation50" onMouseOver={() => {highlightAnnotation(50)}} onMouseOut={() => {lowlightAnnotation(50)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M47.276,467.889c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S47.276,472.86,47.276,467.889L47.276,467.889L47.276,467.889"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M46.941,378.351c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S46.941,383.322,46.941,378.351L46.941,378.351L46.941,378.351"/><text transform="matrix(1 0 0 1 32.5427 471.2629)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">50</text><text transform="matrix(1 0 0 1 32.208 381.725)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">50</text>
</g>
<g id="Annotation51" onMouseOver={() => {highlightAnnotation(51)}} onMouseOut={() => {lowlightAnnotation(51)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M67.643,467.889c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S67.643,472.86,67.643,467.889L67.643,467.889L67.643,467.889"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M67.817,358.309c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S67.817,363.28,67.817,358.309L67.817,358.309L67.817,358.309"/><text transform="matrix(1 0 0 1 52.9094 471.2629)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">51</text><text transform="matrix(1 0 0 1 53.0835 361.6835)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">51</text>
</g>
<g id="Annotation52" onMouseOver={() => {highlightAnnotation(52)}} onMouseOut={() => {lowlightAnnotation(52)}}>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M88.357,467.889c0-4.969-4.029-9-9-9c-4.971,0-9,4.031-9,9   c0,4.971,4.029,9,9,9C84.328,476.889,88.357,472.86,88.357,467.889L88.357,467.889L88.357,467.889"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M67.817,378.351c0-4.969-4.029-9-9-9s-9,4.031-9,9   c0,4.971,4.029,9,9,9S67.817,383.322,67.817,378.351L67.817,378.351L67.817,378.351"/><text transform="matrix(1 0 0 1 73.6238 471.2629)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">52</text><text transform="matrix(1 0 0 1 53.0835 381.725)" fontFamily="'Arial'" fontWeight="bold" fontSize="10px">52</text>
</g>
<g id="Annotations">
	
		<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="79.534" y1="378.614" x2="67.817" y2="378.632"/>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M91.551,406.947c0.001,1.488,1.206,2.692,2.693,2.692    c1.488-0.001,2.692-1.206,2.692-2.694c-0.001-1.486-1.206-2.691-2.694-2.691C92.755,404.255,91.55,405.461,91.551,406.947z"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M92.756,406.947c0,0.822,0.667,1.488,1.488,1.487    c0.823,0,1.488-0.667,1.487-1.489c0-0.82-0.666-1.486-1.489-1.485C93.421,405.46,92.755,406.127,92.756,406.947z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="94.243" y1="406.946" x2="79.534" y2="406.965"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M91.551,351.504c0.001,1.488,1.206,2.692,2.693,2.692    c1.488-0.001,2.692-1.206,2.692-2.694c-0.001-1.486-1.206-2.691-2.694-2.691C92.755,348.812,91.55,350.018,91.551,351.504z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="94.243" y1="351.503" x2="79.534" y2="351.522"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M92.756,351.504c0,0.822,0.667,1.488,1.488,1.487    c0.823,0,1.488-0.667,1.487-1.489c0-0.82-0.666-1.486-1.489-1.485C93.421,350.018,92.755,350.684,92.756,351.504z"/>
		
			<polyline fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" points="    94.243,351.503 79.534,351.51 79.534,406.946   "/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M38.138,423.326c1.488,0,2.693-1.205,2.693-2.692    c0-1.488-1.205-2.693-2.693-2.693c-1.486,0-2.692,1.205-2.692,2.693C35.446,422.121,36.652,423.326,38.138,423.326z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="38.138" y1="420.634" x2="38.138" y2="458.889"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M38.138,422.121c0.822,0,1.488-0.666,1.488-1.487    c0-0.823-0.666-1.488-1.488-1.488c-0.82,0-1.486,0.665-1.486,1.488C36.652,421.455,37.318,422.121,38.138,422.121z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="38.138" y1="420.634" x2="38.138" y2="458.889"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M373.049,99.882c-1.052-1.052-2.756-1.052-3.808-0.001    c-1.052,1.052-1.052,2.756,0,3.808c1.051,1.051,2.756,1.051,3.808-0.001C374.1,102.638,374.1,100.933,373.049,99.882z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="371.145" y1="101.786" x2="416.146" y2="57.071"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M372.197,100.734c-0.581-0.581-1.523-0.581-2.104-0.001    c-0.582,0.582-0.581,1.523,0,2.104c0.58,0.58,1.521,0.581,2.103-0.001C372.777,102.256,372.777,101.314,372.197,100.734z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="371.145" y1="101.786" x2="416.146" y2="57.071"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M181.888,122.101c-1.052-1.052-2.756-1.052-3.808-0.001    c-1.052,1.052-1.052,2.756,0,3.808c1.051,1.051,2.756,1.051,3.808-0.001C182.94,124.856,182.939,123.151,181.888,122.101z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="179.985" y1="124.004" x2="130.324" y2="57.463"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M181.036,122.953c-0.581-0.581-1.523-0.581-2.104-0.001    c-0.582,0.582-0.581,1.523,0,2.104c0.58,0.58,1.521,0.581,2.103-0.001C181.616,124.474,181.616,123.533,181.036,122.953z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="179.985" y1="124.004" x2="130.324" y2="57.463"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M173.431,225.79c-1.052-1.052-2.756-1.052-3.808-0.001    c-1.052,1.052-1.052,2.756,0,3.808c1.051,1.051,2.756,1.051,3.808-0.001C174.482,228.546,174.481,226.841,173.431,225.79z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="171.527" y1="227.694" x2="64.043" y2="114.726"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M172.578,226.642c-0.581-0.581-1.523-0.581-2.104-0.001    c-0.582,0.582-0.581,1.523,0,2.104c0.58,0.58,1.521,0.581,2.103-0.001C173.158,228.164,173.158,227.222,172.578,226.642z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="171.527" y1="227.694" x2="64.043" y2="114.726"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M416.686,206.172c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C415.481,208.864,416.686,207.658,416.686,206.172z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="413.994" y1="206.172" x2="468.878" y2="206.172"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M415.481,206.172c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C414.815,207.658,415.481,206.992,415.481,206.172z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="413.994" y1="206.172" x2="468.878" y2="206.172"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M416.686,244.517c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C415.481,247.209,416.686,246.003,416.686,244.517z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="413.994" y1="244.517" x2="470.194" y2="210.5"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M415.481,244.517c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C414.815,246.003,415.481,245.337,415.481,244.517z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="413.994" y1="244.517" x2="470.194" y2="210.5"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M416.686,153.49c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C415.481,156.182,416.686,154.976,416.686,153.49z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="413.994" y1="153.49" x2="471.12" y2="200.016"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M415.481,153.49c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C414.815,154.976,415.481,154.31,415.481,153.49z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="413.994" y1="153.49" x2="471.12" y2="200.016"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M420.326,130.816c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C419.121,133.508,420.326,132.302,420.326,130.816z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="417.634" y1="130.816" x2="468.878" y2="130.816"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M419.121,130.816c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C418.455,132.302,419.121,131.636,419.121,130.816z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="417.634" y1="130.816" x2="468.878" y2="130.816"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M309.374,318.904c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C308.169,321.596,309.374,320.39,309.374,318.904z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="306.682" y1="318.904" x2="468.878" y2="318.904"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M308.169,318.904c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C307.503,320.39,308.169,319.724,308.169,318.904z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="306.682" y1="318.904" x2="468.878" y2="318.904"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M297.849,354.703c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C296.644,357.395,297.849,356.189,297.849,354.703z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="295.157" y1="354.703" x2="468.878" y2="354.703"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M296.644,354.703c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C295.978,356.189,296.644,355.523,296.644,354.703z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="295.157" y1="354.703" x2="468.878" y2="354.703"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M291.412,293.94c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C290.207,296.632,291.412,295.426,291.412,293.94z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="288.72" y1="293.94" x2="468.878" y2="293.94"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M290.207,293.94c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C289.541,295.426,290.207,294.76,290.207,293.94z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="288.72" y1="293.94" x2="468.878" y2="293.94"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M326.722,284.566c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C325.517,287.258,326.722,286.052,326.722,284.566z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="324.03" y1="284.566" x2="468.878" y2="269.479"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M325.517,284.566c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C324.851,286.052,325.517,285.386,325.517,284.566z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="324.03" y1="284.566" x2="468.878" y2="269.479"/>
	</g>
	<g>
		<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M326.722,262.939c0-1.488-1.205-2.693-2.692-2.693    c-1.488,0-2.693,1.205-2.693,2.693c0,1.486,1.205,2.692,2.693,2.692C325.517,265.631,326.722,264.425,326.722,262.939z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" x1="324.03" y1="262.939" x2="468.878" y2="245.075"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M325.517,262.939c0-0.822-0.666-1.488-1.487-1.488    c-0.823,0-1.488,0.666-1.488,1.488c0,0.82,0.665,1.486,1.488,1.486C324.851,264.425,325.517,263.759,325.517,262.939z"/>
		
			<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" x1="324.03" y1="262.939" x2="468.878" y2="245.075"/>
	</g>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,130.5c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,130.5c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,135.471,486.878,130.5L486.878,130.5L486.878,130.5"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,205.857c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,205.857c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,210.828,486.878,205.857L486.878,205.857   L486.878,205.857"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,318.588c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,318.588c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,323.559,486.878,318.588L486.878,318.588   L486.878,318.588"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,293.625c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,293.625c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,298.596,486.878,293.625L486.878,293.625   L486.878,293.625"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,268.351c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,268.351c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,273.322,486.878,268.351L486.878,268.351   L486.878,268.351"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,243.611c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,243.611c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,248.582,486.878,243.611L486.878,243.611   L486.878,243.611"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,354.387c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M486.878,354.387c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S486.878,359.358,486.878,354.387L486.878,354.387   L486.878,354.387"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M47.276,467.889c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M47.276,467.889c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S47.276,472.86,47.276,467.889L47.276,467.889   L47.276,467.889"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M67.643,467.889c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M67.643,467.889c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S67.643,472.86,67.643,467.889L67.643,467.889   L67.643,467.889"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M88.357,467.889c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M88.357,467.889c0-4.969-4.029-9-9-9c-4.971,0-9,4.031-9,9c0,4.971,4.029,9,9,9C84.328,476.889,88.357,472.86,88.357,467.889   L88.357,467.889L88.357,467.889"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M46.941,358.309c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M46.941,358.309c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S46.941,363.28,46.941,358.309L46.941,358.309   L46.941,358.309"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M46.941,378.351c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M46.941,378.351c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S46.941,383.322,46.941,378.351L46.941,378.351   L46.941,378.351"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M67.817,378.351c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M67.817,378.351c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S67.817,383.322,67.817,378.351L67.817,378.351   L67.817,378.351"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M67.817,358.309c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M67.817,358.309c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S67.817,363.28,67.817,358.309L67.817,358.309   L67.817,358.309"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M430.918,50.249c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M430.918,50.249c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S430.918,55.22,430.918,50.249L430.918,50.249   L430.918,50.249"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M133.915,50.249c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M133.915,50.249c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S133.915,55.22,133.915,50.249L133.915,50.249   L133.915,50.249"/>
	
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFFFFF" strokeWidth="2.835" strokeLinecap="round" strokeLinejoin="round" d="   M66.46,108.538c0-2.484-1.007-4.734-2.635-6.363c-1.758-1.758-4.061-2.637-6.365-2.637c-2.486,0-4.736,1.008-6.363,2.637   c-1.759,1.757-2.637,4.06-2.637,6.363c0,2.487,1.008,4.737,2.637,6.364c1.756,1.759,4.06,2.636,6.363,2.636   c2.485,0,4.735-1.006,6.365-2.636c1.757-1.756,2.635-4.059,2.635-6.362"/>
	
		<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#000000" strokeWidth="0.425" strokeLinecap="round" strokeLinejoin="round" d="   M66.46,108.538c0-4.969-4.029-9-9-9s-9,4.031-9,9c0,4.971,4.029,9,9,9S66.46,113.509,66.46,108.538L66.46,108.538L66.46,108.538"/>
	
</g>
</svg>
					)
			default:
				return(
					<P>Cannot find that graphic, sorry!</P>
				);
		}
	}

	return { get }

}
 
export default useGetSvg;