const theme = {
	brand: "agco",
	logo: "/images/logo-agco.png",
	searchIcon: '/images/icons/icon-search-red.svg',
	languageIcon: '/images/icons/icon-globe-red.svg',
	cartIcon: '/images/icons/icon-shoppingcart-red.svg',
	userIcon: '/images/icons/icon-user-red.svg',
	colors: {
		White: '#FFFFFF',
		Black: '#202020',
		GreySuperLight: '#F8F8F8',
		GreyLight: '#DDDDDD',
		GreyMid: '#B0B0B0',
		GreyDark: '#3A3A3A',
		GreyDarker: '#282827',
		Primary: '#C00031',
		Red: '#DE0101',
		RedLight: '#FDF2F2',			// Danger backgrounds	
		RedSuperLight: '#FEFAFA', 		//Warning backgrounds
		Yellow: '#FFC806',
		YellowLight: '#FFFAE7',
		Teal: '#0BA7C9',
		TealLight: '#DFF4F8',
		Green: '#0BA7C9',
		GreenLight: '#E6F7E9',
	},
	space: {
		xxl: '60px',
		xl: '40px',
		l: '30px',
		m: '24px',
		ms: '20px',
		s: '16px',
		xs: '6px',
		xxs: '4px',
		xxxs: '2px',
		attention: '10px 0px 10px 20px',
		table: '9px 20px',
	},
	fontSizes: {
		xxxxl: '72px',
		xxxl: '48px',
		xxl: '36px',
		xl: '26px',
		l: '24px',
		ml: '20px',
		m: '18px',
		s: '16px',
		xs: '14px',
		xxs: '12px'
	},
	fontWeights: {
		bold: 900,
		semiBold: 700,
	},
	lineHeights: {
		xxxl: '54px',
		xxl: '41px',
		xl: '31px',
		l: '29px',
		ml: '25px',
		m: '23px',
		s: '20px',
		xs: '18px',
	},
	letterSpacings: {
		s: "0.05em",
	},
	fonts: {
		title: 'Obvia',
		regular: 'Oxygen-Regular',
		bold: 'Oxygen-Bold',
		light: 'Oxygen-Light',
		nav: 'Roboto-Regular',
		slabBold: 'RobotoSlab-Bold',
		slabRegular: 'RobotoSlab-Regular',
		robotoRegular: 'Roboto-Regular',
		robotoBold: 'Roboto-Bold',
	},
	radii: {
		s: '1px',
		m: '3px',
		l: '6px',
		xl: '16px',
		xxl: '20px',
	},
	shadows: {
		modal: '0 2px 20px 0 #575757',
	},
	borderWidths: {
		l: 4,
		m: 2,
		s: 1,
	},
	sizes: {
		contentContainer: '1500px',
	},
	screens: {
	l: '1200px',
    md: '1025px',
    sm: '768px',
	smp: '740px',
	xs: '420px',
  },
};

export default theme;
