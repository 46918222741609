import React from 'react';
import styled from 'styled-components';

import SidebarPanel from './SidebarPanel';
import SidebarInfoRow from './SidebarInfoRow';

const Machine = styled.img`
	display: block;
	border-left: solid 3px ${({ theme }) => theme.colors.Primary};
	width: 400px;
`;

const SidebarMyMachine = () => {
	return ( 
		<>
			<Machine src='/images/machine.jpg' />
			<SidebarPanel title="MY MACHINE">
					<SidebarInfoRow label="Name:" text="Tower Dryer"/>
					<SidebarInfoRow label="Year:" text="2019 - 2022" />
					<SidebarInfoRow label="Serial:" text="TD042TU4861" />
				</SidebarPanel>
		</>
	 );
}
 
export default SidebarMyMachine;