import React from 'react';
import styled from 'styled-components';
import Table from './topic/Table';

import partsLists from '../data/partslists';
import useBasket from '../hooks/useBasket';

const ShoppingCartAdd = styled.img`
	display: inline;
	cursor: pointer;
`;

const StyledTable = styled(Table)`
	z-index: 100;
`;

const PartRow = styled.tr`
	&.active {
		background-color: ${({ theme }) => theme.colors.GreySuperLight};
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.GreySuperLight};
	}
`;

const PartsList = ({ list, addToBasket }) => {	

	function highlightAnnotation(number) {
		document.getElementById("Annotation" + number).classList.add("active")
	}

	function lowlightAnnotation(number) {
		document.getElementById("Annotation" + number).classList.remove("active")
	}

	return ( 
		<StyledTable>
			<thead>
				<tr>
					<th>Item</th>
					<th>Part Number</th>
					<th>Description</th>
					<th>Qty</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{list.map((part) => (
					<PartRow key={ part.id } className={ `annotation${part.annotation}` } data-annotation={ part.annotation } onMouseOver={(e) => {highlightAnnotation(part.annotation)}} onMouseOut={(e) => {lowlightAnnotation(part.annotation)}}>
						<td>{ part.annotation }</td>
						<td>{ part.partNumber }</td>
						<td>{ part.name }</td>
						<td>{ part.quantity }</td>
						<td> <ShoppingCartAdd onClick={() => {addToBasket(part)}} part={part} src="/images/icons/icon-shoppingcart-add-orange.svg" /> </td>
					</PartRow>
				))}
			</tbody>
		</StyledTable>
	 );
}
 
export default PartsList;