import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ThemeContext } from 'styled-components';

const Primary = css`
    background-color: ${({ theme }) => theme.colors.Primary};
    color: ${({ theme }) => theme.colors.Black};
    border: solid 3px ${({ theme }) => theme.colors.Primary};

    &:hover {
        background-color: transparent;
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.GreyDarker};
        border-color: ${({ theme }) => theme.colors.GreyDarker};
        color: ${({ theme }) => theme.colors.GreyLight};
    }

    &:disabled {
		background-color: ${({ theme }) => theme.colors.GreySuperLight};
		border-color: ${({ theme }) => theme.colors.GreySuperLight};
		color: ${({ theme }) => theme.colors.GreyMid};
    }
`;

const AgcoPrimary = css`
	font-family: ${({ theme }) => theme.fonts.slabBold};
    background-color: ${({ theme }) => theme.colors.Primary};
    color: ${({ theme }) => theme.colors.White};
    border: solid 3px ${({ theme }) => theme.colors.Primary};

    &:hover {
        background-color: ${({ theme }) => theme.colors.GreyDark};
		color: ${({ theme }) => theme.colors.White};
		border-color: ${({ theme }) => theme.colors.GreyDark};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.Black};
        border-color: ${({ theme }) => theme.colors.Black};
        color: ${({ theme }) => theme.colors.White};
    }
`;

const MenuButton = css`
	background-color: ${({ theme }) => theme.colors.GreyDark};
	font-family: ${({ theme }) => theme.fonts.nav};
	color: ${({ theme }) => theme.colors.White};
	text-transform: uppercase;
	font-size: ${({ theme }) => theme.fontSizes.xs};
	width: 250px;
	text-align: left;
`;

const Outline = css`
    background-color: ${({ theme }) => theme.colors.White};
    color: ${({ theme }) => theme.colors.Black};
    border: solid 3px ${({ theme }) => theme.colors.GreyDarker};

    &:hover {
        border-color: ${({ theme }) => theme.colors.Primary};
    }

    &:active {
        border-color: ${({ theme }) => theme.colors.GreyMid};
        color: ${({ theme }) => theme.colors.GreyMid};
    }

	&:disabled {
		border-color: ${({ theme }) => theme.colors.GreyMid};
		color: ${({ theme }) => theme.colors.GreyMid};
	}
`;

const Btn = styled.button`
    display: ${({ block }) => block ? "block" : "inline-block"};
	${({ width }) => width ? `width: ${width}` : ""};
	${({ block }) => block ? "width: 100%;" : ""};
    padding: 10px;
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    cursor: pointer;
	transition: all 0.5s ease;

    ${({ type }) => type === "primary" && Primary}
    ${({ type }) => type === "outline" && Outline}
	${({ type }) => type === "agcoprimary" && AgcoPrimary}
	${({ type }) => type === "menubutton" && MenuButton}
`;

const Button = ({ children, type = "Primary", disabled, block = false, onClick, width}) => {
    let lowerType = type.toLowerCase();
    const themeContext = useContext(ThemeContext);
	if (themeContext.brand === "agco" && lowerType === "primary")
		lowerType = "agcoprimary";

    return ( 
        <Btn type={ lowerType } disabled={disabled} block={block} onClick={onClick} width={ width }>{ children }</Btn>
     );
}
 
export default Button;