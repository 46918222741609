import React, { useContext } from 'react';
import styled from 'styled-components';

import TopicContainer from '../components/layout/TopicContainer';
import PageHeading from '../components/PageHeading';
import H2 from '../components/typography/H2';
import CartTable from '../components/CartTable';
import Spacer from '../components/layout/Spacer';
import Sidebar from '../components/Sidebar';
import SidebarPanel from '../components/SidebarPanel';
import Button from '../components/Button';
import { ComingSoonContext } from '../App';

const StyledH2 = styled(H2)`
	margin-bottom: 30px;
`;

const Cart = ({ basket, decreaseQuantity, increaseQuantity, removeItem, emptyBasket }) => {
	const [visible, setVisible] = React.useState(false);
	const { toggleComingSoon } = useContext(ComingSoonContext);
	
	return ( 
		<>
			<TopicContainer>
				<PageHeading title="SHOPPING CART" subtitle={basket && basket.length > 0 ? "AMEND OR CHECK OUT" : "YOUR SHOPPING CART IS EMPTY"}/>
				<Spacer height="45px" />
				<CartTable list={basket} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} removeItem={removeItem}/>
				<StyledH2>SAVED FOR LATER</StyledH2>
				<CartTable />
			</TopicContainer>
			<Sidebar>
				<SidebarPanel title="MY ORDER" />
				<Spacer height="15px" block={true} />
					<Button type="primary" block={true} onClick={toggleComingSoon}>ORDER</Button>
					<Spacer height="5px" block={true} />
					<Button type="outline" block={true} onClick={emptyBasket}>EMPTY CART</Button>
		</Sidebar>
		</>
	 );
}
 
export default Cart;