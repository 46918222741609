import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import themeAgco from '../config/theme-agco';
import useAuth from '../hooks/useAuth';
import Button from './Button';
import FormField from './FormField';
import Spacer from './layout/Spacer';

import ARoute from './typography/ARoute';
import P from './typography/P';

const Container = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
`;

const LoginContainer = styled.div`
	width: 440px;
	background-color: ${({ theme }) => theme.colors.White};
`;

const LoginForm = styled.form`
	width: 100%;
	padding: 10px;
`;

const LoginHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 88px;
	background-color: #F0F0F0;
`;

const Logo = styled.img`

`;

const LoginTitle = styled.div`
	width: 100%;
	margin-top: 25px;
	text-align: center;
	font-size: ${themeAgco.fontSizes.ml};
	font-family: ${themeAgco.fonts.robotoRegular};
`;

const StyledFormField = styled(FormField)`
	margin-top: 20px;
	&:focus {
		border-bottom: solid 3px ${themeAgco.colors.Primary};
	}
`;

const StyledARoute = styled(ARoute)`
	color: ${themeAgco.colors.Primary} !important;
`;

const StyledButton = styled.button`
	display: block;
	width: 100%;
	font-family: ${({ themeinfo }) => themeinfo.fonts.slabBold};
    background-color: ${({ themeinfo }) => themeinfo.colors.Primary};
    color: ${({ themeinfo }) => themeinfo.colors.White};
    border: solid 3px ${({ themeinfo }) => themeinfo.colors.Primary};
	background-color: ${themeAgco.colors.Primary};
	padding: 10px;
    &:hover {
        background-color: ${({ themeinfo }) => themeinfo.colors.GreyDark};
		color: ${({ themeinfo }) => themeinfo.colors.White};
		border-color: ${({ themeinfo }) => themeinfo.colors.GreyDark};
    }

    &:active {
        background-color: ${({ themeinfo }) => themeinfo.colors.Black};
        border-color: ${({ themeinfo }) => themeinfo.colors.Black};
        color: ${({ themeinfo }) => themeinfo.colors.White};
    }
`;

const LoginBox = ({ login }) => {

	const [username, setUsername] = React.useState("");
	const [password, setPassword] = React.useState("");
	const navigate = useNavigate();
	const [errorShowing, setErrorShowing ] = React.useState(false);

	function HandleUsernameUpdate(event) {
		setUsername(event.target.value);
	}

	function HandlePasswordUpdate(event) {
		setPassword(event.target.value);
	}

	function HandleLoginButtonClick(event) {
		event.preventDefault();
		if(login(username, password)) {
			navigate('/brand');
		} else {
			setErrorShowing(true);
		};
	}

	return ( 
		<Container>
			<LoginContainer>
				<LoginHeader>
					<Logo src={ '/images/logo-agco.png' } />
				</LoginHeader>
				<LoginTitle>Service Portal Login</LoginTitle>
				<LoginForm>
					<StyledFormField onChange={HandleUsernameUpdate} placeholder="Username" type="text" brand="agco" width="100%"/>
					<StyledFormField onChange={HandlePasswordUpdate} placeholder="Password" type="password" brand="agco" width="100%" />
					<Spacer height="45px" block={true} />
					<StyledARoute to='#' brand="agco">Forgot Password</StyledARoute>
					<Spacer height="20px" block={ true } />
					<StyledButton themeinfo={themeAgco} onClick={HandleLoginButtonClick} type="agcoprimary" block={true}>Log In</StyledButton>
					{errorShowing && (<P>User credentials are invalid. Please try again.</P>)}
				</LoginForm>
			</LoginContainer>
		</Container>
	 );
}
 
export default LoginBox;