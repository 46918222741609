import React from 'react';
import styled from 'styled-components';
import TopicEmbed from '../TopicEmbed';

import topics from '../../data/topics';

const Link = styled.span`
	color: ${({ theme }) => theme.colors.Primary};
	font-family: ${({ theme }) => theme.fonts.bold};
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;

const Arrow = styled.img`
    display: inline;
    margin-left: 8px;
    margin-top: -2px;
	transition: transform 0.5s ease;
	${({ open }) => open && "transform: rotate(90deg);"}
`;

const IncludedTopic = ({ children, topicId }) => {

	const [visible, setVisible] = React.useState(false);

	const topic = topics.filter(t => t.id === topicId)[0];

	function toggleVisible() {
		setVisible(!visible);
	}

	return ( 
		<>
			<Link onClick={toggleVisible} title={ topic.subtitle }>{ topic.title }  <Arrow open={visible} src="/images/icons/icon-arrow-right-primary.svg" /></Link>
			<TopicEmbed topicId={topicId} visible={visible} />
		</>
		);
}
 
export default IncludedTopic;