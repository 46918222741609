import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import HeaderSmall from './HeaderSmall';
import Spacer from './layout/Spacer';
import Modal from './Modal';
import H1 from './typography/H1';
import P from './typography/P';

const Container = styled.div`
    padding: 10px;
`;

const StyledH1 = styled(H1)`
    margin-top: 20px;
`;

const ComingSoon = ({ children, visible, onCloseClick }) => {

    return ( 
        <Modal onClose={onCloseClick} isVisible={visible} width="622px">
            <HeaderSmall />
            <Container>
                <StyledH1>Sorry!</StyledH1>
                <P>That feature isn't available in this proof of concept.</P>
                <Spacer width="10px" height="30px" block={true} />
                <Button type="primary" block={true} onClick={onCloseClick}>Okay</Button>
            </Container>
        </Modal>
     );
}
 
export default ComingSoon;