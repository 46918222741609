import React from 'react';
import styled from 'styled-components';

export default styled.table`
    width: 100%;
    margin-top: 20px;
    font-size: ${({ theme }) => theme.fontSizes.xs};

    thead {
        tr {
            background-color: ${({ theme }) => theme.colors.GreyDarker};
            border-bottom: solid 2px ${({ theme }) => theme.colors.Primary};
            th {
                font-family: ${({ theme }) => theme.fonts.title};
                font-weight: ${({ theme }) => theme.fontWeights.semiBold};
                text-align: left;
                padding: 3px 12px 3px 12px;
            }
        }
    }

    tbody {
        background-color: ${({ theme }) => theme.colors.White};
		tr {
			border-bottom: solid 1px ${({ theme }) => theme.colors.GreyLight};
			&:last-child { border-bottom: solid 2px ${({ theme }) => theme.colors.Primary}}
			td {
				padding: 12px;
			}
		}

    }
    
    th {
        color: ${({ theme }) => theme.colors.GreyLight};
    }

`;
 