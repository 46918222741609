import React from 'react';
import users from '../data/users';

export default function useAuth() {

	const [authenticatedUser, setAuthenticatedUser] = React.useState(null);
	const [siPage, setSiPage] = React.useState(null);
	const [courseComplete, setCourseComplete] = React.useState(false);

	function login(username, password) {
		let loggedIn = true;
		if (!username || !password) {
			console.error("Username or password not supplied.");
			return false;
		}

		const user = users.filter(u => u.username === username)[0];
		if (!user) {
			console.error("User not found");
			return false;
		}

		if (!(password === "password")) {
			console.error("Password is incorrect");
			return false;
		}

		setAuthenticatedUser(user);
		localStorage.setItem("user", JSON.stringify(user));
		return loggedIn;
	}

	function logout() {
		setAuthenticatedUser(null);
		localStorage.removeItem("brand");
		localStorage.removeItem("user");
	}

	function isAuthenticated(){
		if (authenticatedUser) {
			console.log("authenticatedUser", authenticatedUser);
			return true;
		} else if ( (!authenticatedUser) && localStorage.getItem("user")) {
			console.log(localStorage.getItem("user"));
			setAuthenticatedUser(localStorage.getItem("User"));
			return true;
		}else {
			console.log("User is not authenticated.");
			return false;
		}
	}

	return {login, logout, isAuthenticated, authenticatedUser, setAuthenticatedUser, siPage, setSiPage, setCourseComplete, courseComplete}

}