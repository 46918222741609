import React, { useContext } from 'react';
import styled from 'styled-components';
import CartQty from './CartQty';
import Spacer from './layout/Spacer';
import ARoute from './typography/ARoute';
import { ComingSoonContext } from '../App';

const Container = styled.div`
	display: flex;
	margin-bottom: 10px;
	border: solid 1px ${({ theme }) => theme.colors.GreyLight};
	padding: 20px;
	width: 100%;
`;

const PartName = styled.div`
	font-family: ${({ theme }) => theme.fonts.bold};
	font-size: ${({ theme }) => theme.fontSizes.l};
`;

const PartNumber = styled.div`
	font-family: ${({ theme }) => theme.fonts.bold};
	font-size: ${({ theme }) => theme.fontSizes.m};
	color: ${({ theme }) => theme.colors.GreyMid};
`;

const LinkIcon = styled.img`
	display: inline;
	margin-top: -3px;
	margin-right: 10px;
`;

const Left = styled.div`
	width: 75%;
`;

const Right = styled.div`
	width: 25%;
`;

const CartItem = ({ saveForLater = false, name, partNumber, quantity, increaseQuantity, decreaseQuantity, removeItem }) => {

	const { toggleComingSoon } = useContext(ComingSoonContext);

	return ( 
		<Container>
			<Left>
				<PartName>{ name }</PartName>
				<PartNumber>{ partNumber }</PartNumber>
				<Spacer block={true} height="45px" />
				<ARoute to={'#'} onClick={toggleComingSoon}><LinkIcon src="/images/icons/icon-epc-orange.svg" />Link to Page</ARoute>
			</Left>
			<Right>
				<CartQty quantity={quantity} onIncrease={increaseQuantity} onDecrease={decreaseQuantity} partNumber={ partNumber }/>
				<span>
					<ARoute to='#' onClick={toggleComingSoon}>Save for Later</ARoute>
					<Spacer height='57px' width='15px'/> | <Spacer height='57px' width='15px'/>
					<ARoute to='#' onClick={() => removeItem(partNumber)}>Remove</ARoute></span>
			</Right>
		</Container>
	 );
}
 
export default CartItem;