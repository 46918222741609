import styled from 'styled-components';

export default styled.h1`
	font-family: ${({ theme }) => theme.fonts.title};
	font-size: ${({ theme }) => theme.fontSizes.xxxl};
	line-height: ${({ theme }) => theme.lineHeights.xxxl};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
	color: ${({ theme }) => theme.colors.Black};
	margin-top: 65px;
`;
