import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex-box;
    width: ${({ theme }) => theme.sizes.contentContainer};
	padding-left: 10px;
	padding-right: 10px;
`;

const PageWideContainer = ({ children }) => {
    return ( 
        <Container>
            { children }
        </Container>
     );
}
 
export default PageWideContainer;