const topics = [
	{
		id: "D019938",
		title: "Gearbox Oil Level Check",
		subtitle: "Check and Adjust",
		href: "/si/D019938",
		app: 0,
		icon: "si",
		keywords: ["gearbox", "oil", "level", "transmission"],
		breadcrumb: [ 
				{name: "Unload Section", href: "/sel/4", id: "4"},
				{name: "Unload Module", href: "/sel/4/1", id: "41"},
				{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
				{name: "Gearbox Oil Level Check", href: null, id: null}
			 ]
	},
	{
		id: "trainingdash",
		title: "Training Dashboard",
		subtitle: "Training",
		href: "/trn",
		app: 3,
		icon: "training",
		keywords: ["dash", "board", "learning"],
		breadcrumb: [ 
				{name: "Dashboard", href: "/trn", id: null},
				{name: "Lock Out and Tag Out", href: "/trn/course/T019938", id: null}
			 ]
	},
	{
		id: "T019938",
		title: "Lock Out and Tag Out",
		subtitle: "eLearning",
		href: "/si/T019938",
		app: 3,
		icon: "training",
		keywords: ["safety", "locks", "locking", "tags", "tagging"],
		breadcrumb: [ 
				{name: "Dashboard", href: "/trn", id: null},
				{name: "Lock Out and Tag Out", href: "/trn/course/T019938", id: null}
			 ]
	},
	{
		id: "D120186",
		title: "Gearbox Oil Filter",
		subtitle: "Remove and Install",
		href: "/si/D120186",
		app: 0,
		icon: "si",
		keywords: ["gearbox", "oil", "level", "transmission", "filter", ],
		breadcrumb: [ 
				{name: "Unload Section", href: "/sel/4", id: "4"},
				{name: "Unload Module", href: "/sel/4/1", id: "41"},
				{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
				{name: "Gearbox Oil Filter", href: null, id: null}
			 ]
	},
	{
		id: "T011040",
		title: "Gearbox Module",
		subtitle: "Schematic Viewer",
		href: "/si/T011040",
		app: 0,
		icon: "schematics",
		keywords: ["schematic", "diagram", "gearbox", "transmission", "filter", ],
		breadcrumb: [ 
				{name: "Unload Section", href: "/sel/4", id: "4"},
				{name: "Unload Module", href: "/sel/4/1", id: "41"},
				{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
				{name: "Gearbox Module", href: null, id: null}
			 ]
	},
	{
		id: "D191220",
		title: "Trumpet Housing",
		subtitle: "Video",
		href: "/si/D191220",
		app: 0,
		icon: "video",
		keywords: ["trumpet", "gearbox", "tractor", "transmission", "filter", ],
		breadcrumb: [ 
				{name: "Unload Section", href: "/sel/4", id: "4"},
				{name: "Unload Module", href: "/sel/4/1", id: "41"},
				{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
				{name: "Trumpet Housing", href: null, id: null}
			 ]
	},{
		id: "D010162",
		title: "Gearbox Oil",
		subtitle: "Drain and Refill",
		href: "/si/D010162",
		app: 0,
		icon: "si",
		keywords: ["oil", "drain", "refill", "drainage", "sump", "plug" ],
		breadcrumb: [ 
				{name: "Unload Section", href: "/sel/4", id: "4"},
				{name: "Unload Module", href: "/sel/4/1", id: "41"},
				{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
				{name: "Gearbox Oil - Drain and Refill", href: null, id: null}
			 ]
	},
	{
		id: "D150218",
		title: "Flat Unload Section",
		subtitle: "Parts Catalogue",
		href: "/epc",
		app: 1,
		icon: "epc",
		keywords: ["parts", "gearbox", "oil", "epc"],
		breadcrumb: [ 
			{name: "Unload Section", href: "/sel/4", id: "4"},
			{name: "Unload Module", href: "/sel/4/1", id: "41"},
			{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
			{name: "Flat Unload Section", href: null, id: null}
		 ]
	},
	{
		id: "D240987",
		title: "Fluid Specifications",
		subtitle: "Technical Specifications",
		href: "/si/D240987",
		app: 0,
		icon: "si",
		keywords: ["fluid", "specs", "oil", "refilling", "capacities", "volume"],
		breadcrumb: [ 
			{name: "Unload Section", href: "/sel/4", id: "4"},
			{name: "Unload Module", href: "/sel/4/1", id: "41"},
			{name: "Gearbox", href: "/sel/4/1/2", id: "412"},
			{name: "Fluid Specifications", href: null, id: null}
		 ]
	},
	{
		id: "maintenancedash",
		title: "Maintenance Dashboard",
		subtitle: "Maintenance",
		href: "/mcs",
		app: 2,
		icon: "checklist",
		keywords: ["maintenance", "dashboard", "history"],
		breadcrumb: [ 
			{name: "Dashboard", href: "/mcs", id: null}
		 ],
		 searchable: false,
	},
	{
		id: "cl1",
		title: "Pre-Season Service",
		subtitle: "Maintenance Checklist",
		href: "/mcs/cl1",
		app: 2,
		icon: "checklist",
		keywords: ["maintenance", "before", "preceding", "checksheet", "list", "checklist"],
		breadcrumb: [ 
			{name: "General", href: "/sel/0", id: "0"},
			{name: "Maintenance Checklists", href: "/sel/0/3", id: "03"},
			{name: "Pre-Season Checks", href: "/sel/0/3/1", id: "031"},
			{name: "Pre-Season Checklist", href: null, id: null}
		 ]
	},
	{
		id: "cl2",
		title: "End of Season Shutdown",
		subtitle: "Maintenance Checklist",
		href: "/mcs/cl2",
		app: 2,
		icon: "checklist",
		keywords: ["maintenance", "after", "following", "checksheet", "list", "checklist"],
		breadcrumb: [ 
			{name: "General", href: "/sel/0", id: "0"},
			{name: "Maintenance Checklists", href: "/sel/0/3", id: "03"},
			{name: "End of Season Checks", href: "/sel/0/3/2", id: "032"},
			{name: "End of Season Shut-Down", href: null, id: null}
		 ]
	},
	{
		id: "sidash",
		title: "Service Information Dashboard",
		subtitle: "Service Information",
		href: "/si",
		app: 0,
		icon: "si",
		keywords: ["service", "information", "dashboard"],
		breadcrumb: [ 
			{name: "Dashboard", href: "/si", id: null}
		 ],
		 searchable: false
	}
]

export default topics;