import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import UserMenuPopup from './UserMenuPopup';

const User = styled.a`
	& > img {
		display: inline;
	}
`;

const UserIcon = styled.img`
	cursor: pointer;
`;

const UserMenu = ({ logout }) => {

	const themeContext = useContext(ThemeContext);
	const [visible, setVisible] = React.useState(false);

	function toggle() {
		console.log("toggling");
		setVisible(!visible);
	}

	return ( 
		<>
			<User onClose={ toggle } onClick={toggle}>
				<UserIcon src={themeContext.userIcon}/>
			</User>
			<UserMenuPopup onClose={toggle} visible={visible} logout={logout} />
		</>
	 );
}
 
export default UserMenu;