import React, { useContext } from 'react';
import styled from 'styled-components';
import PageWideContainer from '../components/layout/PageWideContainer';
import PageHeading from '../components/PageHeading';
import { AuthContext, SearchContext, SelectorContext } from '../App';
import topics from '../data/topics';
import TopicContainer from '../components/layout/TopicContainer';
import PageContainer from '../components/layout/PageContainer';

import structure from '../data/structure';
import { useNavigate } from 'react-router-dom';

const SmallerContainer = styled.div`
	width: 822px;
	margin-right: auto;
	margin-left: auto;
`;

const Title = styled.h1`
	color: ${({ theme }) => theme.colors.White};
	font-family: ${({ theme }) => theme.fonts.title};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
	font-size: ${({ theme }) => theme.fontSizes.xxxl};
	text-align: center;
	width: 100%;
	margin-top: 150px;
`;

const SearchBox = styled.input`
	width: 100%;
	margin-top: 30px;
	padding: 10px 20px 10px 20px;
	font-size: ${({ theme }) => theme.fontSizes.xl};
	border-bottom: solid 2px ${({ theme }) => theme.colors.GreyDark};
	transition: all 0.5s ease;
	color: ${({ theme }) => theme.colors.Black};

	&:focus {
		border-bottom: solid 2px ${({ theme }) => theme.colors.Primary};
	}

	&::placeholder {
		color: ${({ theme }) => theme.colors.GreyLight};
	}
`;

const Sections = styled.div`
	width: 100%;
	margin-top: 53px;
`;

const Section = styled.div`
	position: relative;
	display: inline-block;
	width: 404px;
	height: 108px;
	background-color: ${({ theme }) => theme.colors.Primary};
	color: ${({ theme }) => theme.colors.Black};
	margin-top: 10px;
	cursor: pointer;
	transition: all 0.5s ease;
	
	&:nth-child(even) {
		margin-left: 13px;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.Black};
		color: ${({ theme }) => theme.colors.Primary};
	}

`;

const SectionNumber = styled.div`
	position: absolute;
	top: 10px;
	left: 30px;
	font-size: ${({ theme }) => theme.fontSizes.xxxxl};
	font-family: ${({ theme }) => theme.fonts.title};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const SectionTitle = styled.div`
	width: 100%;
	margin-top: 10px;
	padding-left: 100px;
	font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const SectionShortDesc = styled.div`
	position: absolute;
	width: 100%;
	padding-left: 100px;
	padding-right: 10px;
	font-size: ${({ theme }) => theme.fontSizes.xs};
	margin-top: 10px;
`;

const Dashboard = () => {

	const navigate = useNavigate();
	const { toggleSearch } = useContext(SearchContext);
	const { siPage } = useContext(AuthContext);
	const menuContext = useContext(SelectorContext);
	React.useEffect(() => {
		menuContext.setBreadcrumb(topics.filter(topic => topic.id === "sidash")[0].breadcrumb);
	}, []);

	if (siPage) {
		navigate(siPage);
	}

	return ( 
		<SmallerContainer>
			<Title>GSI Service Portal</Title>
			<SearchBox onClick={toggleSearch} placeholder="Search..." />
			<Sections>
				{ structure.map(section => (
					<Section key={section.id} onClick={ () => {navigate(`/sel/${section.id}`)} }>
						<SectionNumber>{ section.id }</SectionNumber>
						<SectionTitle>{ section.name }</SectionTitle>
						<SectionShortDesc>{ section.shortdesc }</SectionShortDesc>
					</Section>
				))}
			</Sections>
		</SmallerContainer>

		);
}
 
export default Dashboard;