import React from 'react';
import styled from 'styled-components';
import topics from '../data/topics';

import TopicContainer from '../components/layout/TopicContainer';
import PageHeading from '../components/PageHeading';
import H3 from '../components/typography/H3';

import Sidebar from '../components/Sidebar';
import SidebarPanel from '../components/SidebarPanel';
import SidebarInfoRow from '../components/SidebarInfoRow';
import Button from '../components/Button';
import Spacer from '../components/layout/Spacer';

import useGetTopic from '../hooks/useGetTopic';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectorContext } from '../App';

const CourseList = styled.div`
	width: 100%;
`;

const CourseListItem = styled.div`
	position: relative;
	display: inline-block;	
	margin-top: 30px;
	margin-right: 35px;
`;

const CourseTitle = styled.div`
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: ${({ theme }) => theme.colors.White};
	font-family: ${({ theme }) => theme.fonts.title};
	font-size: ${({ theme }) => theme.fontSizes.xs};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const TrainingCourse = () => {
	
	const menuContext = React.useContext(SelectorContext);
	const { getTopic } = useGetTopic();
	const { courseId } = useParams();
	const navigate = useNavigate();
	const meta = topics.filter(topic => topic.id === courseId)[0];

	React.useEffect(() => {
		menuContext.setBreadcrumb(meta.breadcrumb);
	}, [])

	return ( 
		<>
			<TopicContainer>
				{ getTopic(courseId) }
			</TopicContainer>
			<Sidebar>
				<SidebarPanel title="QUIZ">
						<Spacer height={"15px"} block={true} />
						<Button type="primary" onClick={() => {navigate(`/trn/quiz/T019938`)}} block={true}>START</Button>
					</SidebarPanel>
			</Sidebar>
		</>
	 );
}
 
export default TrainingCourse;