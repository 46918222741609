import React from 'react';
import styled from 'styled-components';
import H5 from '../typography/H5';

const Proc = styled.ol`
    margin-left: 30px;
    list-style: normal;
    list-style-type: 1;
`;

const Procedure = ({ children, title }) => {
    return ( 
        <>
            {title && (<H5>{ title }</H5>)}
            <Proc>
                { children }
            </Proc>
        </>
     );
}
 
export default Procedure;