import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BrandContext } from '../App';

import brands from '../data/brands';
import BrandTile from './BrandTile';

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 87%;
	align-content: center;
	justify-content: center;
	`;
	
	const Container = styled.div`
	display: flex;
	height: 464px;
	flex-direction: row;
	flex-wrap: wrap;
	margin: auto;
`;

const BrandSelector = () => {

	const { changeBrand } = React.useContext(BrandContext);
	const navigate = useNavigate();

	function HandleClick(name) {
		console.log(name);
		changeBrand(name);
		navigate("/si");
	}

    return (
		<Wrapper>
			<Container>
				{
					brands.map(brand => (
					<BrandTile key={ brand.id } name={ brand.name } logo={brand.logo} href={brand.href} enabled={brand.enabled} onClick={HandleClick}/>
					))
				}
			</Container>
		</Wrapper> 
     );
}
 
export default BrandSelector;