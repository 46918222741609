import React from 'react';
import styled, { css } from 'styled-components';

const Danger = css`
    border-left-color: ${({ theme }) => theme.colors.Red};
    background-color: ${({ theme }) => theme.colors.RedLight};
`;

const Warning = css`
    border-left-color: ${({ theme }) => theme.colors.Red};
    background-color: ${({ theme }) => theme.colors.RedSuperLight};
`;

const Caution = css`
    border-left-color: ${({ theme }) => theme.colors.Yellow};
    background-color: ${({ theme }) => theme.colors.YellowLight};
`;

const Note = css`
    border-left-color: ${({ theme }) => theme.colors.Teal};
    background-color: ${({ theme }) => theme.colors.TealLight};
`;

const Hint = css`
    border-left-color: ${({ theme }) => theme.colors.GreyMid};
    background-color: ${({ theme }) => theme.colors.GreySuperLight};
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizes.m};
    color: ${({ theme }) => theme.colors.Black};
    text-transform: ${({ type }) => {
        return type === "note" || type === "hint" ? "capitalize" : "uppercase";
    } }
`;

const Container = styled.div`
    border-left-width: 3px;
    padding: 26px 20px 26px 20px;
    margin-top: 20px;

    ${({ type }) => type === "danger" && Danger}
    ${({ type }) => type === "warning" && Warning}
    ${({ type }) => type === "caution" && Caution}
    ${({ type }) => type === "note" && Note}
    ${({ type }) => type === "hint" && Hint}

    & > p {
        margin-top: 5px;
    }
`;

const Admonition = ({ children, type }) => {
    const typeLower = type.toLowerCase();
    return ( 
        <Container type={typeLower}>
            <Title type={typeLower}>{ typeLower }</Title>
            { children }
        </Container>
     );
}
 
export default Admonition;