import React from 'react';
import styled from 'styled-components';
import Page from '../components/layout/Page';

const Selector = () => {
	return ( 
		<>
		</>
	 );
}
 
export default Selector;