import React from 'react';
import styled from 'styled-components';

import { Routes, Route, Outlet, Link } from 'react-router-dom';
import Header from '../Header';
import PageContainer from './PageContainer';
import { useContext } from 'react';
import { BrandContext } from '../../App';

const Background = styled.div`
	min-width: 100vw;
	min-height: 100vh;
	background-image: url(${({ image }) => image ? image : '/images/background-brightFields.jpg'});
	background-position: fixed;
	background-size: cover;
`;

const HeroPage = ({  basket, backgroundImage, showHeader=true }) => {
	
	const { changeBrand } = useContext(BrandContext);

	changeBrand("gsi");
	
	return (
		<>
			<Background image={backgroundImage}>
				{showHeader && (<Header selectorVisible={false} basket={ basket } />)}
					<Outlet />
			</Background>
		</>
		);
}
 
export default HeroPage;