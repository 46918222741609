import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import H5 from './typography/H5';
import SelectorMenuItem from './SelectorMenuItem';

import ARoute from './typography/ARoute';
import { SelectorContext } from '../App';
import apps from '../data/apps';

const Container = styled.div`
	position: absolute;
	top: 49px;
	background-color: ${({ theme }) => theme.colors.GreyDark};
	width: ${({ theme }) => theme.sizes.contentContainer};
	color: ${({ theme }) => theme.colors.GreyLight};
	padding: 10px;
	z-index: 10;
`;

const MenuHeader = styled(H5)`
	margin: 0px 30px 10px 20px;	
	color: ${({ theme }) => theme.colors.GreyLight};
`;

const Menu = styled.div`
	display: flex;
	//background-color: ${({ theme }) => theme.colors.Primary};
	width: 100%;
`;

const MenuPanel = styled.ul`
	width: 25%;
	color: ${({ theme }) => theme.colors.GreyLight};
	padding-bottom: 30px;
`;

const ActiveStyle = css`
	font-weight: ${({ theme }) => theme.fontWeights.bold};
	color: ${({ theme }) => theme.colors.White};
	font-style: italic;
`;

const MenuLink = styled(ARoute)`
	color: ${({ theme }) => theme.colors.GreyLight};
	font-family: ${({ theme }) => theme.fonts.regular};
	${(p) => p.active && ActiveStyle}
`;

const MenuLinkIcon = styled.img`
	display: inline;
	margin-top: -2px;
	margin-right: 10px;
`;

const SelectorMenu = () => {

	const { breadcrumb, menus, toggleSelector} = useContext(SelectorContext);

	function handleTopicClick() {
		toggleSelector();
	}

	return ( 
		<Container>
			<MenuHeader>SELECT TOPIC</MenuHeader>
			<Menu>
				<MenuPanel>
					{ menus[0].map(( item ) => (
						// Transient prop $active - if this wasn't used, there would be an error when React tried to pass an 'active="false"' to the DOM.
						<SelectorMenuItem type="folder" key={ item.id }><MenuLink $active={breadcrumb[0].id === item.id} to={`/sel/${item.id.substring(0,1)}`}>{ item.id }. { item.name }</MenuLink></SelectorMenuItem>
					)) }
				</MenuPanel>
				<MenuPanel>
					{menus[1] && menus[1].map(( item ) => (
						<SelectorMenuItem type="folder" key={ item.id }><MenuLink $active={ breadcrumb[1] && breadcrumb[1].id === item.id} to={`/sel/${item.id.substring(0,1)}/${ item.id.substring(1,2) }`}>{ item.id }. { item.name }</MenuLink></SelectorMenuItem>
					))}
				</MenuPanel>
				<MenuPanel>
					{ menus[2] && menus[2].map(( item ) => (
						<SelectorMenuItem type="folder" key={ item.id }><MenuLink $active={ breadcrumb[2] && breadcrumb[2].id === item.id} to={`/sel/${item.id.substring(0,1)}/${item.id.substring(1,2)}/${item.id.substring(2,3)}`}>{ item.id }. { item.name }</MenuLink></SelectorMenuItem>
					))}
				</MenuPanel>
				<MenuPanel>
					{ menus[3] && menus[3].map(( item, index ) => (
						<SelectorMenuItem key={ index } type="folder"><MenuLink $active={ breadcrumb[3] && breadcrumb[3].name === item.name} onClick={handleTopicClick} to={ item.href }><MenuLinkIcon title={apps.filter(app => app.id === item.app)[0].title} src={`/images/icons/icon-${item.type}-greylight.svg`} />{ item.name }</MenuLink></SelectorMenuItem>
					))}
				</MenuPanel>
			</Menu>
		</Container>
	 );
}
 
export default SelectorMenu;