const apps = [
	{
		id: 0,
		title: "Service Information",
		href: "/si",
		comingsoon: false,
		icon: "icon-si-_COLOR_.svg",
	},
	{
		id: 1,
		title: "Parts Catalogue",
		href: "/epc",
		comingsoon: false,
		icon: "icon-epc-_COLOR_.svg",
	},
	{
		id: 2,
		title: "Maintenance",
		href: "/mcs",
		comingsoon: false,
		icon: "icon-checklist-_COLOR_.svg",
	},
	{
		id: 3,
		title: "Training",
		href: "/trn",
		comingsoon: false,
		icon: "icon-training-_COLOR_.svg",
	},
	{
		id: 4,
		title: "Operators Manual",
		href: "/om",
		comingsoon: false,
		icon: "icon-om-_COLOR_.svg",
	}
]

export default apps;