const users = [
	{
		id: 1,
		username: "james.massey",
		apps: [0, 3]
	},
	{
		id: 2,
		username: "david.ferguson",
		apps: [0, 1, 2, 3, 4],
	},
	{
		id: 3,
		username: "alison.cumberland",
		apps: [0, 2, 3]
	}
]

export default users;